<script lang="ts" context="module">
	import { getAppConfig } from "../../../../common/config/app-config";
	import { getContextedUrl, getStr } from "../../../../common/util";
	import {
		getServiceBlockForCode,
		getServiceProductCodes,
		jsVasDetailsClickHandler,
	} from "./atc-utils";
	import AtcVasPrimePriceDetails from "./AtcVasPrimePriceDetails.svelte";

	const { lang, currency, extraThemeResourcePath } = getAppConfig();
	const imageBaseUrl = `${extraThemeResourcePath}/images`;

	const extra_service_store_device = `${imageBaseUrl}/extra_service_store_device.svg`;
	const extra_service_unpack_device = `${imageBaseUrl}/extra_service_unpack_device.svg`;
</script>

<script lang="ts">
	export let productattributes;
	export let entry;
	export let selectedServices;
	export let parentEntry;
	export let product;
	export let parentEntryNumber;
	export let loopindex;

	let servicesBlockContent = "";
	let servicesBlockImage = "";
	let servicesBlockLabel = "";
	let servicesBlockLink = "";

	$: if (product?.servicesBlocks?.length > 0) {
		({
			servicesBlockContent,
			servicesBlockImage,
			servicesBlockLabel,
			servicesBlockLink,
		} = getServiceBlockForCode(
			product.servicesBlocks,
			"extraServicesSetupPDPContent",
		));
	}

	function getServiceDetails(selectedServices, vasPrice) {
		if (selectedServices?.length > 0) {
			const selectedService =
				selectedServices?.find(
					(selectedService) =>
						selectedService.vasType === vasPrice?.vasType?.code &&
						selectedService?.priceType === vasPrice?.priceType,
				) || null;
			return {
				vasOptionSelected: !!selectedService ? "selected" : "",
				selectedEntrynumber: selectedService?.entryNumber || null,
			};
		}
		return {
			vasOptionSelected: "",
			selectedEntrynumber: null,
		};
	}
</script>

<div class="row-flex extra-setup-service-details">
	<div class="col-flex-xs-1 col-flex-md-1 c_extra-setup-logo">
		<img alt="Extrasetup" src={servicesBlockImage} />
	</div>
	<div class="col-flex-xs-4 col-flex-md-3 c_extra-setup-label">
		{servicesBlockLabel}
	</div>
	<div class="col-flex-xs-12 col-flex-md-12 c_extra-setup-content">
		<span class="">
			{servicesBlockContent}
		</span>
	</div>
	<div class="col-flex-xs-12 col-flex-md-12 c_extra-setup-link">
		<a href={servicesBlockLink} target="_blank">
			{getStr("extra.vas.service.learn.more")}
		</a>
	</div>
	<div class="col-flex-xs-9 col-flex-md-12 c_extra-setup-service-info">
		<p class="c_vas-details-heading-info">
			{productattributes.extraSetupServicePdpLabel}
		</p>
		<div
			class="col-flex-xs-12 simple-flex c_vas-details-items js-extraSetup-button-active-remove js-vas-group-addtocart-{entry.vasGroup}"
		>
			{#each entry.vasPrices as vasPrice, vasPriceIndex}
				{@const serviceProductCodes = getServiceProductCodes(
					vasPrice,
					entry,
				)}
				{@const { vasOptionSelected, selectedEntrynumber } =
					getServiceDetails(selectedServices, vasPrice)}
				<button
					class="c_vas-details-list js-vas-details-svelte"
					on:click={jsVasDetailsClickHandler}
					class:selected={vasPrice?.defaultWarranty ||
						vasOptionSelected?.length > 0}
					data-item-add="false"
					data-type={entry.vasGroup}
					data-productcode={product.code}
					data-source="Cart Popup"
					data-serviceid={vasPriceIndex + 1}
					data-addon="no addon"
					data-plan={vasPrice.vasType.label}
					data-price="{vasPrice.servicePrice
						.formattedValue} {currency}"
					data-vasType={vasPrice.vasType.code}
					data-entryNumber="extra-setup-service-{parentEntryNumber}"
				>
					<div>
						<section>
							<div class="c_vas-details-list-year">
								{#if vasPrice.percentageDiscount !== null && vasPrice.percentageDiscount.value > 0}
									<span
										class="c_product-details-title-reduction"
									>
										{#if lang === "en"}
											{vasPrice.percentageDiscount
												.value}%&nbsp;<span
												>{getStr(
													"product.pourcentageDiscount.off",
												)}</span
											>
										{:else}
											%{vasPrice.percentageDiscount
												.value}&nbsp;<span
												>{getStr(
													"product.pourcentageDiscount.off",
												)}</span
											>
										{/if}
									</span>
								{/if}
								{vasPrice.vasType.label}
							</div>
							<div
								class="vas-name"
								class:hide={vasPrice.noPlanOption}
							>
								<span>{vasPrice.vasType.name}</span>
							</div>
						</section>
						{#if vasPrice.discountedPrice !== null}
							<div
								class="c_product-price-prices <c:if test='{vasPrice.noPlanOption}'>hide</c:if>"
							>
								<div class="c_product-price-was-is">
									<span class="c_product-price-previous"
										>{vasPrice.servicePrice.value}&nbsp;
										{currency}
									</span>
								</div>
								<div class="c_product-price-current">
									<format:price
										priceData={vasPrice.discountedPrice}
										displayFreeForZero="true"
										addCurrency="true"
									/>
								</div>
							</div>
						{:else}
							<div
								class="c_vas-details-list-price <c:if test='{vasPrice.noPlanOption}'>hide</c:if>"
							>
								<format:price
									priceData={vasPrice.servicePrice}
									displayFreeForZero="true"
									addCurrency="true"
								/>
							</div>
							<AtcVasPrimePriceDetails {vasPrice} />
						{/if}
						{#each serviceProductCodes as code}
							<c:set
								var="freeGiftData"
								value={vasPrice.serviceFreeGiftMap[code]}
							/>
							{@const freeGiftData =
								vasPrice.serviceFreeGiftMap[code] || null}
							{#if !!freeGiftData}
								{@const sourceQty =
									freeGiftData.freeGiftQtuantities[
										"sourceQty"
									]}
								{@const targetQty =
									freeGiftData.freeGiftQtuantities[
										"targetQty"
									]}
								<div
									class="c_gift-items js-freegiftexist"
									class:js-freegiff-set-fixed={sourceQty ===
										targetQty}
									class:c_gift-items-fixed={sourceQty ===
										targetQty}
								>
									<div class="c_gift-items-title">
										{#if sourceQty < targetQty}
											<span
												class="c_gift-items-title--color"
											>
												{getStr(
													"product.promotion.freegifts.display.lower.txt",
													[sourceQty],
												)}
											</span>
										{:else}
											<span
												class="c_gift-items-title--color"
											>
												{getStr(
													"text.fixed.freegift.count.display.txt.vas",
													[sourceQty],
												)}
											</span>&nbsp;{getStr(
												"product.promotion.freegifts.item.worth.txt",
											)}&nbsp;
											<span
												class="c_gift-items-title--color mobile-font"
												>{freeGiftData.freeGiftWorth}{currency}</span
											>
										{/if}
									</div>
								</div>
							{/if}
						{/each}
					</div>
					<div>
						<div class="c_vas-details-list-button hide">
							<form
								id="vasAddToCartForm{product.code}{vasPriceIndex}-{loopindex}"
								action={getContextedUrl("/cart/vas/add")}
								modelAttribute="vasAddtocartForm"
								method="post"
								class="c_form js-formValidation js-vas-addtocart-form"
								data-ajax="true"
								data-submit="vasaddtocart"
							>
								<div
									id="test_vasAddToCartButton_$"
									style="display:inline"
								>
									{#if vasPrice.noPlanOption}
										<input
											type="hidden"
											name="vasProductCode"
											value={entry.noPlanProductCode}
										/>
										<input
											type="hidden"
											name="noPlanOption"
											value={vasPrice.noPlanOption}
										/>
									{:else}
										<input
											type="hidden"
											name="vasProductCode"
											value={entry.serviceProductCode}
										/>
									{/if}
									<input
										type="hidden"
										name="vasType"
										value={vasPrice.vasType.code}
									/>
									<input
										type="hidden"
										name="priceType"
										value={vasPrice.priceType}
									/>
									<input
										type="hidden"
										name="priceTo"
										value={vasPrice.priceTo}
									/>
									<input
										type="hidden"
										name="productCode"
										value={product.code}
									/>
									<input
										type="hidden"
										name="parentEntryNumber"
										value={parentEntryNumber}
									/>
									<input type="hidden" name="qty" value="1" />
									<input
										type="hidden"
										name="showApplyService"
										value={entry.showApplyService}
									/>
									<input
										type="hidden"
										name="vasEntryShowService"
										value={entry.showApplyService}
									/>
									<input
										type="hidden"
										name="vasGroup"
										value={entry.vasGroup}
									/>
									<input
										type="hidden"
										name="entryNumber"
										value={typeof selectedEntrynumber ===
										"number"
											? selectedEntrynumber
											: ""}
										id="vasEntryNumber"
										class="js-vasEntryNumber"
									/>
									<input
										type="hidden"
										name="serviceGroupNumber"
										value=""
									/>
									<input
										type="hidden"
										name="installService"
										value={entry.installService}
									/>
									{#each serviceProductCodes as code}
										{@const freeGiftData =
											vasPrice.serviceFreeGiftMap[code] ||
											null}
										{#if !!freeGiftData}
											{#each freeGiftData.freeGiftProducts as freeGift}
												<input
													type="hidden"
													name="freeGiftCodesMap[{code}]"
													value={freeGift.code}
												/>
												<input
													type="hidden"
													name="freeGiftRuleIdMap[{code}]"
													value={freeGiftData.freeGiftRuleId}
												/>
											{/each}
										{/if}
									{/each}
									<button
										type="button"
										id="vas-button-{product.code}{vasPriceIndex}"
										aria-labelledby="vas-button-{product.code}{vasPriceIndex} vasAddToCartForm{product.code}{vasPriceIndex}"
										class="c_vas-details-list-button-vas"
									>
									</button>
								</div>
							</form>
						</div>
					</div>
				</button>
			{/each}
		</div>
	</div>
</div>
<div class="row-flex js-apply-services">
	<div class="col-flex-xs-6 col-flex-md-12"></div>
	<div class="col-flex-xs-12 col-flex-md-12 simple-flex">
		<div
			class="js-apply-service-option-{product.code}-extra-setup-service-{parentEntryNumber}"
		>
			<div class="row-flex c_apply-service-title">
				{product.attributeLabels.extraContentApplyService}
			</div>
			<div class="row-flex c_apply-service">
				<div
					data-applyservice="false"
					data-entrynumber={parentEntryNumber}
					data-vasGroup={entry.vasGroup}
					class="col-flex-xs-6 col-flex-sm-6 col-flex-md-6 c_apply-service-content js-apply-service-content selected"
				>
					<span class="selected-icon"></span>
					<img alt="ExtraSetup" src={extra_service_store_device} />
					<div class="c_apply-service-info">
						{product.attributeLabels.extraTextNotApplyService}
					</div>
				</div>
				<div
					data-applyservice="true"
					data-entrynumber={parentEntryNumber}
					data-vasGroup={entry.vasGroup}
					class="col-flex-xs-6 col-flex-sm-6 col-flex-md-6 c_apply-service-content js-apply-service-content"
				>
					<span class="selected-icon"></span>
					<img alt="ExtraSetup" src={extra_service_unpack_device} />
					<div class="c_apply-service-info">
						{product.attributeLabels.extraTextApplyService}
					</div>
				</div>
			</div>
		</div>
	</div>
	{#if parentEntry?.lmdData?.lmdConfigurations?.length > 0}
		<div class="c_lmd-service">
			<div class="c_lmd-service-title">
				{getStr("addtocart.service.lmd.heading")}
			</div>
			<div class="c_lmd-service-container row-flex">
				{#each parentEntry.lmdData.lmdConfigurations as lmdConfiguration, lmdvasPriceIndex}
					<div
						data-index={lmdvasPriceIndex}
						class="c_lmd-service-content js-apply-lmd-service"
						class:disabled={lmdConfiguration.installRestricted}
					>
						<span class="selected-icon"></span>
						<div class="c_lmd-service-content-info">
							{lmdConfiguration.description}
						</div>
						<div class="c_lmd-service-content-price">
							{currency}&nbsp;
							<strong
								>{lmdConfiguration.price.formattedValue}</strong
							>
						</div>
						<form class="lmd-service-form-{lmdvasPriceIndex}">
							<input
								type="hidden"
								name="productCode_{lmdvasPriceIndex}"
								value={lmdConfiguration.productCode}
							/>
							<input
								type="hidden"
								name="parentEntryNumber_{lmdvasPriceIndex}"
								value={parentEntry.entryNumber}
							/>
							<input
								type="hidden"
								class="js-lmd-service-group-number"
								name="serviceGroupNumber_{lmdvasPriceIndex}"
								value=""
							/>
							<input
								type="hidden"
								class="{lmdConfiguration.productCode}_entryNumber"
								name="entryNumber_{lmdvasPriceIndex}"
								value=""
							/>
							<input
								type="hidden"
								class="{lmdConfiguration.productCode}_entryType"
								name="entryType_{lmdvasPriceIndex}"
								value={lmdConfiguration.description}
							/>
							<input
								type="hidden"
								name="installRestricted_{lmdvasPriceIndex}"
								value={lmdConfiguration.installRestricted}
							/>
						</form>
					</div>
				{/each}
			</div>
		</div>
	{/if}
</div>
