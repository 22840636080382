// import ProductTile from './modules/common/components/productTile/ProductTileMain.svelte';
import RecommendationsSvelteComponent from './modules/home/component/Recommendation/RecommendationsMain.svelte';
import RecentlyViewedSvelteComponent from './modules/home/component/RecentlyViewed.svelte';
import RichRelavanceSvelteComponent from './modules/home/component/RichRelavance.svelte';
import RestockablePopupContentSvelteComponent from './modules/common/components/restockable/RestockablePopupContent.svelte';
import AddToCartDialogSvelteComponent from './modules/product-details/components/AddToCartDialog.svelte';


if (window['RecommendationsSvelteComponent'] === undefined) {
    window['RecommendationsSvelteComponent'] = RecommendationsSvelteComponent;
}
if (window['RecentlyViewedSvelteComponent'] === undefined) {
    window['RecentlyViewedSvelteComponent'] = RecentlyViewedSvelteComponent;
}
if (window['RichRelavanceSvelteComponent'] === undefined) {
    window['RichRelavanceSvelteComponent'] = RichRelavanceSvelteComponent;
}
if (window['RestockablePopupContentSvelteComponent'] === undefined) {
    window['RestockablePopupContentSvelteComponent'] = RestockablePopupContentSvelteComponent;
}

if (window['AddToCartDialogSvelteComponent'] === undefined) {
    window['AddToCartDialogSvelteComponent'] = AddToCartDialogSvelteComponent;
}