import { writable } from "svelte/store";

export const algoliaClient = writable(null);
export const algoliaConfigdata:any = writable({});
export const algoliaIndexConfig = writable({
    brandIndex : null,
    brandIndexKey : null,	
    categoryIndex : null, 
    categoryIndexKey : null, 
    productIndex : null,
    productOriginalIndex : null,
    productOriginalIndexKey : null,
    productIndexKey : null,
    articleIndex : null, 
    articleIndexKey : null, 
    keywordIndex : null,
    keywordIndexKey : null,
    querySuggestionIndexKey: null
});
export const algoliaSearchData = writable([])