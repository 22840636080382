<script>
    import { APP_CONSTANTS } from "../../../../../common/config/app-constants";
    import { isDirectionRTL } from "../../../../../common/util";
    import Icon from "../../Icon.svelte";
    const isRTL = isDirectionRTL();

    export let rating;
    export let reviews;
</script>

<div class="tile-rating-review-container align-items-center" class:rtl={isRTL}>
    <div class="icon_rating d-flex align-items-center">
        <Icon
            glyph="icon-star"
            mobileSize={16}
            desktopSize={16}
            type="primary"
        />
    </div>
    <div class="rating">{Math.round(rating * 10) / 10}</div>
    {#if reviews}
        <div class="customer_review"><span>(</span>{reviews}<span>)</span></div>
    {/if}
</div>

<style lang="scss">
    @import "../../../styles/colors.new.scss";

    .tile-rating-review-container {
        display: flex;
        width: fit-content;
        font-size: 1.2em;
        line-height: 14px;
        font-weight: 500;
        color: $neutral-dark-03;
        @include desktop-min-width {
            font-size: 14px;
            line-height: 16px;
        }
        &.rtl {
            direction: rtl;
            // background: black;
        }
        .rating {
            margin-left: 3.5px;
            margin-right: 2.5px;
            @include desktop-min-width {
                margin-left: 4px;
            }
        }
        .icon_rating {
            margin-bottom: 2px;
        }
    }
</style>
