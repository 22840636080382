<script context="module" lang="ts">
    export type SponsoredTagVariantType =
        | "pdp"
        | "product-carousal"
        | "product-carousal-dark"
        | "plp-card"
        | "plp-banner"
        | "banner" // include extra space after card (to accomodate sponsored)
        | "banner-inline"; // avoid extra space after card
</script>

<script lang="ts">
    import { getStr } from "../../../common/util";

    export let variant: SponsoredTagVariantType;
    $: variantClassname = `variant-${variant}`;
</script>

<section class="sponsored-tag-container {variantClassname}">
    {getStr("extra.sponsored.text")}
</section>

<style lang="scss">
    @import "../../common/styles/colors.new.scss";

    .sponsored-tag-container {
        color: $neutral-dark-03;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;

        &.variant-banner,
        &.variant-banner-inline,
        &.variant-plp-banner {
            text-align: right;
            margin-top: 5px;
            position: absolute;
            right: 10px;
            z-index: 1;

            @include desktop-min-width {
                right: 0;
            }
        }
        &.variant-product-carousal,
        &.variant-product-carousal-dark,
        &.variant-pdp {
            text-align: right;
            flex-grow: 1;
            font-size: 22px;
            line-height: 40px;
        }
        &.variant-product-carousal-dark {
            color: $primary-white;
        }
        &.variant-pdp {
            font-size: 11px;
            line-height: 12px;
        }
        &.variant-plp-card {
            position: absolute;
            right: 16px;
            bottom: 8px;
        }
        &.variant-plp-banner {
            right: 48px;
        }
    }
</style>
