<script lang="ts" context="module">
    import { onMount } from "svelte";
    import { getAppConfig } from "../../../../common/config/app-config";
    import {
        formatNumber,
        getJoodMembershipType,
        getLangSpecificFieldname,
        getProductTags,
        getStr,
        removeHTMLScrollbar,
        roundToNDigits,
    } from "../../../../common/util";

    const {
        regexEmail,
        unbxdEnabled,
        unbxdSearchConfig,
        algoliaConfig,
        lang,
        currency,
        outOfStock,
        userDefaultCityCode,
        userSelectedCityCode,
        user,
        pdplAvailable,
    } = getAppConfig();
    const discountDecimals = +(unbxdEnabled
        ? unbxdSearchConfig.decimalCount
        : algoliaConfig.decimalCount);

    const tasheelDesktopImage =
        getAppConfig().algoliaConfig.tasheelInfo.tasheelImageUrl;
    const tasheelMobileImage =
        getAppConfig().algoliaConfig.tasheelInfo.tasheelIconUrl;
    const variant: ProductTilePriceGroupVariantType = "restock-popup";
</script>

<script lang="ts">
    import { notifyAlert } from "../../../product-details/services/pdp-data-services";
    import { openRestockablePopup } from "../../../product-details/store/pdp_store";
    import JoodDayBannerNew from "../../../product-listing-search/components/JoodDayBannerNew.svelte";
    import JoodSavingsAccordionCard from "../../../product-listing-search/components/JoodSavingsAccordionCard.svelte";
    import ExtraRichRelevanceComponent from "../../lazy-components/ExtraRichRelevanceComponent.svelte";
    import Icon from "../Icon.svelte";
    import PrivacyPolicyCheckbox from "../PrivacyPolicyCheckbox.svelte";
    import ProductTileName from "../productTile/components/ProductTileName.svelte";
    import ProductTilePriceGoldAccordion from "../productTile/components/ProductTilePriceGoldAccordion.svelte";
    import ProductTilePriceNew from "../productTile/components/ProductTilePriceNew.svelte";
    import type { ProductTilePriceGroupVariantType } from "../productTile/components/ProductTilePriceNewGroup.svelte";
    import TagContainer from "../tag/TagContainer.svelte";

    export let product;

    let notifyEmailText = "";
    let emailInput: HTMLInputElement;
    let restockableRRComponent;
    let showError: boolean = false;
    let isPolicyChecked = true;

    $: productImageUrl = `${product?.amplienceProductBaseUrl}&fmt=auto&w=536&h=536`;
    $: productName =
        product?.name || product?.[getLangSpecificFieldname("name")];
    $: productBrandName =
        product?.brandName || product?.[getLangSpecificFieldname("brand")];
    $: productTags = product && getProductTags(product);

    $: productCurrentPrice = product?.price?.formattedValue || product?.price;
    $: simpleDiscount = product?.wasPrice - productCurrentPrice;
    $: hasSimpleDiscount = product && simpleDiscount > 0;
    $: simpleDiscountPercent =
        hasSimpleDiscount &&
        roundToNDigits(
            (simpleDiscount * 100) / product?.wasPrice,
            discountDecimals,
        );
    $: showJoodGoldAccordion = getJoodMembershipType() === "BASICPRIME";

    onMount(() => {
        notifyEmailText = user?.uid || "";
        removeHTMLScrollbar();
        // load rr component
        restockableRRComponent.onLoad();
    });

    function onCloseButtonClick() {
        removeHTMLScrollbar(false);
        window.ACC.qac._showQACPopup(false);
        $openRestockablePopup = false;
    }

    function onNotifyMeButtonClick() {
        showError = false;
        if (emailInput.validity.valid) {
            // do the API call
            const email = notifyEmailText,
                mobileNo = "",
                alertType = "STOCK",
                productCode = product?.code || product?.productCode,
                city = userSelectedCityCode || userDefaultCityCode;

            const CSRFToken = window.ACC.config.CSRFToken;

            notifyAlert({
                email,
                mobileNo,
                alertType,
                productCode,
                CSRFToken,
                city,
            })
                .then((response) => {
                    // emailAlreadyExists = false;
                    if (response.success) {
                        // showSuccessMsg= true;
                        window.ACC.main.showCouponCodeSnackbar(
                            "",
                            false,
                            getStr("extra.restock.success.message"),
                        );
                        window.ACC.qac.setSKUNotifyForCurrentUser(productCode);
                        window["ACC"].mixpanel.trackNotifyMe(
                            productCode,
                            productName,
                            productCurrentPrice,
                        );
                        onCloseButtonClick();
                    } else {
                        if (response?.errorMessage?.length > 0) {
                            if (
                                response?.errorMessageKey ===
                                "stock.alert.exists.error"
                            ) {
                                window.ACC.qac.setSKUNotifyForCurrentUser(
                                    productCode,
                                );
                            }
                            window["ACC"].main.showCouponCodeSnackbar(
                                "",
                                false,
                                response?.errorMessage,
                            );
                        }
                    }
                })
                .catch((error) => {
                    window["ACC"].main.showCouponCodeSnackbar("", false, error);
                });
        } else {
            showError = true;
        }
    }
    function handlePolicyChange(event) {
        isPolicyChecked = event.detail;
    }
</script>

<section class="restockable-popup-content">
    <button class="btn-icon" on:click={onCloseButtonClick}>
        <Icon glyph="close" desktopSize={24} />
    </button>
    <section class="restockable-section product-info-section">
        <section class="left-section">
            <img src={productImageUrl} alt={productName} />
        </section>
        <section class="right-section">
            <TagContainer {productTags} />
            <ProductTileName brandName={productBrandName} name={productName} />
            <section class="full-price border-top">
                <div class="first-row">
                    <span class="currency">{currency}</span>
                    <strong class="amount"
                        >{formatNumber(productCurrentPrice)}</strong
                    >
                    <span class="incOfVat">{getStr("extra.vatLable")}</span>
                </div>
                {#if hasSimpleDiscount}
                    <div class="second-row">
                        <span class="old-price"
                            >{formatNumber(product?.wasPrice)}</span
                        >
                        <strong class="simple-discount-amount"
                            >{@html getStr("extra.cart.saved.amt", [
                                currency,
                                formatNumber(simpleDiscount),
                            ])}</strong
                        >
                        <strong class="simple-discount-percent"
                            >{getStr("product.percentageDiscount.off", [
                                simpleDiscountPercent,
                            ])}</strong
                        >
                    </div>
                {/if}
            </section>
            {#if product?.priceValueTasheel > 0}
                <section class="baseeta-price border-top">
                    <div class="first-row">
                        <span class="currency">{currency}</span>
                        <strong class="amount"
                            >{product?.priceValueTasheel}/{getStr(
                                "extra.mon.text",
                            )}</strong
                        >
                        <span class="baseeta-img">
                            <img
                                src={tasheelDesktopImage}
                                class="hide-on-mobile"
                                alt="Taqsset Extra"
                            />
                            <img
                                src={tasheelMobileImage}
                                class="hide-on-desktop"
                                alt="Taqsset Extra"
                            />
                        </span>
                    </div>
                </section>
            {/if}

            <!-- Jood prices -->
            <section class="jood-prices-section">
                <ProductTilePriceNew
                    isMegasaleProduct={[]}
                    {variant}
                    priceType="BASICPRIME"
                    algoliaProductHit={product}
                />
                <JoodDayBannerNew
                    {variant}
                    bannerType="BASICPRIME"
                    algoliaProductHit={product}
                />
                {#if showJoodGoldAccordion}
                    <ProductTilePriceGoldAccordion
                        {variant}
                        algoliaProductHit={product}
                    />
                {:else}
                    <ProductTilePriceNew
                        isMegasaleProduct={[]}
                        {variant}
                        priceType="VIPPRIME"
                        algoliaProductHit={product}
                    />
                    <JoodDayBannerNew
                        {variant}
                        bannerType="VIPPRIME"
                        algoliaProductHit={product}
                    />
                {/if}
                <JoodSavingsAccordionCard
                    variant="pdp-product"
                    data={product}
                />
            </section>
        </section>
    </section>
    <section class="restockable-section white-bg notify-section">
        <h2>
            <Icon
                type="primary"
                glyph="icon-qac-notify-lg"
                desktopSize={44}
                mobileSize={24}
            />
            <span>{getStr("extra.restockable.notify.me.when")}</span>
        </h2>
        <section class="field-container">
            <div class="form-control">
                <label for="restockNotifyInput"
                    >{getStr("extra.restockable.email")}</label
                >
                <input
                    bind:this={emailInput}
                    bind:value={notifyEmailText}
                    pattern={regexEmail}
                    required
                    type="text"
                    name="notifyStockInput"
                    id="restockNotifyInput"
                    class="form-control-input"
                    placeholder={getStr("extra.restockable.email.sample")}
                />
                <div class="form-error" class:hide={!showError}>
                    {#if emailInput?.validity.valueMissing}
                        {getStr("extra.pdp.provideEmail")}
                    {:else if emailInput?.validity.patternMismatch}
                        {getStr("extra.entervalidemail")}
                    {/if}
                </div>
            </div>
            {#if pdplAvailable}
                <PrivacyPolicyCheckbox
                    {isPolicyChecked}
                    isPopup={true}
                    on:policyChange={handlePolicyChange}
                />
            {/if}
            <button
                disabled={!isPolicyChecked}
                type="button"
                on:click={onNotifyMeButtonClick}
                class="btn btn-primary"
                >{getStr("extra.restockable.notify.me")}</button
            >
        </section>
    </section>
    <section class="restockable-section white-bg rr-section section-gap">
        <ExtraRichRelevanceComponent
            metaData={{ placementId: "add_to_cart_page.rank1" }}
            bind:this={restockableRRComponent}
        />
    </section>
    <section class="restockable-section restockable-cta-section white-bg">
        <button
            type="button"
            on:click={onCloseButtonClick}
            class="btn btn-primary btn-link"
        >
            {getStr("extra.restockable.continue.shopping")}
        </button>
    </section>
</section>

<style lang="scss">
    @import "../../../common/styles/colors.new.scss";

    .restockable-popup-content {
        background-color: $neutral-light-04;
        position: relative;

        .restockable-section {
            padding: 48px;

            @include responsive {
                padding: 12px;
            }

            &.section-gap {
                margin-top: 8px;
            }
            &.white-bg {
                background-color: $primary-white;
            }
            &:empty {
                padding: 0;
                margin: 0;
            }

            &.product-info-section {
                display: flex;
                gap: 12px;
                align-items: flex-start;

                .left-section {
                    img {
                        max-width: 140px;
                        max-height: 140px;
                        object-fit: contain;

                        @include responsive {
                            max-width: 48px;
                            max-height: 48px;
                        }
                    }
                }
                .right-section {
                    flex-grow: 1;

                    :global(.tag-container) {
                        margin-bottom: 8px;
                    }
                    :global(.tile-name-container) {
                        font-size: 16px;
                        line-height: 18px;

                        @include responsive {
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }
                    .full-price {
                        margin-top: 20px;

                        @include responsive {
                            margin-top: 0;
                        }
                    }
                    .full-price,
                    .baseeta-price {
                        padding-block: 12px 8px;

                        @include responsive {
                            padding-block: 8px 0;
                        }
                    }
                    .jood-prices-section {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        @include responsive {
                            margin-top: 16px;
                        }
                    }
                    .first-row {
                        font-size: 24px;
                        line-height: 28px;

                        @include responsive {
                            font-size: 16px;
                            line-height: 18px;
                        }
                    }
                    .second-row {
                        font-size: 14px;
                        line-height: 16px;
                    }
                    .incOfVat {
                        font-size: 12px;
                        line-height: 14px;
                        color: $neutral-dark-02;
                    }
                    .old-price {
                        color: $neutral-dark-04;
                        text-decoration: line-through;
                    }
                    .simple-discount-amount {
                        color: $functional-orange;
                    }
                    .simple-discount-percent {
                        display: inline-block;
                        font-size: 10px;
                        line-height: 12px;
                        padding: 4px 8px;
                        background-color: $functional-orange;
                        border-radius: 5px;
                        color: $primary-white;
                    }
                    .baseeta-img {
                        display: inline-block;

                        img {
                            max-height: 15px;
                        }
                    }
                }
            }
            &.notify-section {
                h2 {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 20px;
                    color: $color-bg-black;
                    margin: 0;
                }
            }
            &.restockable-cta-section {
                padding-top: 24px;

                button {
                    align-self: flex-start;
                }
            }
        }
    }

    .border-top {
        border-top: 1px solid $secondary-gray-02;

        @include responsive {
            border: 0 !important;
        }
    }
    label {
        padding: 0 0 8px 0;
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }
    .form-control-input {
        border: 1.4px solid $secondary-gray-01;
        border-radius: 5px;
        color: $neutral-dark-01;
        padding: 8px 16px;
        width: 100%;
        height: 48px;
        font-size: 14px;
        font-weight: 400;
        &.form-error {
            border: 1.4px solid $error-red;
        }
        &::placeholder {
            color: $secondary-gray-01;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
        }
        @include desktop-min-width {
            font-size: 16px;
            padding: 14px 16px;
            line-height: 18px;
            border-radius: 8px;
            &::placeholder {
                font-size: 16px;
            }
        }
    }
    .field-container {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        gap: 25px;

        .form-control {
            border: 0;
            background-color: transparent;
            padding: 0;
        }
    }
    button {
        align-self: flex-end;
        padding: 15px 40px;
        border-radius: 100px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;

        &.btn-link {
            padding: 15px 0;
            border: 0;
            background-color: transparent;
            color: $icon-only-icon-blue;
            text-decoration: none;
            font-weight: 500;
        }
    }
    .btn-icon {
        border: none;
        background: transparent;
        color: $icon-only-icon-blue;
        position: absolute;
        right: 24px;
        top: 24px;

        @include responsive {
            padding: 0;
        }
    }
    .form-error {
        color: $error-red;
        font-size: 14px;
        padding: 10px 0 0 0;
        margin: 0;
    }
</style>
