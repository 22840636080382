<script lang="ts" context="module">
    import { getAppConfig } from "../../../common/config/app-config";
    import {
        JoodMembershipType,
        getJoodMembershipType,
        getStr,
        slideOpenAnimation,
    } from "../../../common/util";
    import Icon from "../../common/components/Icon.svelte";
    import PdpVasPriceTile from "../../product-details/components/PDPVasPriceTile.svelte";
    import "../../../common/i18n/i18n";

    export type JoodSavingsAccordionType = "pdp-product" | "pdp-service";

    const { encodedContextPath, currency, themeResourcePath } = getAppConfig();
    const joodPageLink = `${encodedContextPath}/jood/benefits`;
    const joodCardImageUrl = `${themeResourcePath}/images/jood/jood-gold-card.png`;
    const userJoodMembershipType = getJoodMembershipType();
</script>

<script lang="ts">
    export let variant: JoodSavingsAccordionType;
    export let data: any;

    let open: boolean = false;

    $: visible = getComponentVisibility(variant, data, userJoodMembershipType);
    $: title = visible && getTitle(variant, data);
    $: isBlueUser = userJoodMembershipType === "BASICPRIME";
    $: linkText = isBlueUser
        ? getStr("extra.jood.gold.upgrade.message")
        : getStr("extra.pdp.jood.tile.subscribetojoobtext");

    function getTitle(variant: JoodSavingsAccordionType, data: any) {
        switch (variant) {
            case "pdp-product":
                return getStr("extra.pdp.jood.tile.expectedsavingtext", [
                    currency,
                    data?.expectedPrimeSaving?.value,
                ]);
            case "pdp-service":
                return isBlueUser
                    ? getStr("extra.pdp.prime.jood.gold.revelprice.text")
                    : getStr("extra.jood.pdp.getoff.gold.text");
        }
    }
    function getComponentVisibility(
        variant: JoodSavingsAccordionType,
        data: any,
        userJoodMembershipType: JoodMembershipType,
    ) {
        /*
            Rules:
            1. if gray user and we have the prime data, display
        */
        switch (variant) {
            case "pdp-product":
                return (
                    userJoodMembershipType === "NOPRIME" &&
                    !!data?.expectedPrimeSaving?.value
                );
            case "pdp-service":
                return true;
        }
    }
</script>

{#if visible}
    <section
        class="jood-savings-accordion variant-{variant} user-{userJoodMembershipType}"
        class:open
    >
        <button class="header" on:click={() => (open = !open)}>
            {#if variant === "pdp-product"}
                <img src={joodCardImageUrl} alt="Jood gold" />
            {/if}
            <section class="title">
                <h2>{title}</h2>
                {#if !isBlueUser}
                    <a href={joodPageLink}>{linkText}</a>
                {/if}
            </section>
            <section class="down-arrow" class:rotate180={open}>
                <Icon
                    type="font"
                    glyph="chevron_down"
                    mobileSize={14}
                    desktopSize={14}
                />
            </section>
        </button>
        {#if open}
            <section class="content" transition:slideOpenAnimation>
                {#if variant === "pdp-product"}
                    <ul class="discount-list">
                        <li>
                            {@html getStr("extra.pdp.jood.tile.upto15offtext")}
                        </li>
                        <li>{@html getStr("extra.pdp.jood.tile.15offtext")}</li>
                        <li>{@html getStr("extra.pdp.jood.tile.25offtext")}</li>
                        <li>
                            {@html getStr("extra.pdp.jood.tile.muchmoretext")}
                        </li>
                    </ul>
                    <p class="disclaimer">
                        {getStr("extra.pdp.jood.tile.totalsavingtext")}
                    </p>
                {:else if variant === "pdp-service"}
                    <section class="pdp-service-jood-discount-list">
                        {#each data as addonVasPrice}
                            <PdpVasPriceTile
                                variant="saving"
                                data={addonVasPrice}
                                active={false}
                                readonly={true}
                            />
                        {/each}
                    </section>
                    {#if isBlueUser}
                        <a href={joodPageLink}>{linkText}</a>
                    {/if}
                {/if}
            </section>
        {/if}
    </section>
{/if}

<style lang="scss">
    @import "../../common/styles/colors.new.scss";

    .jood-savings-accordion {
        padding: 12px;
        background-color: $jood-gold-light;
        border-radius: 12px;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;

        &.variant- {
            &pdp-service {
                margin-top: 8px;
            }
        }
        .header {
            display: flex;
            align-items: center;
            gap: 18px;
            color: $icon-only-icon-blue;
            position: relative;
            border: 0;
            background-color: transparent;
            text-align: start;

            img {
                object-fit: contain;
                width: 80px;
                border-radius: 8px;
            }
            .title {
                padding-top: 10px;
                display: flex;
                gap: 4px;
                flex-direction: column;
                align-items: flex-start;

                @include responsive {
                    padding-top: 0;
                }

                h2 {
                    color: $functional-orange;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 18px;
                    margin: 0;
                }
                a {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14px;
                }
            }

            .down-arrow {
                position: absolute;
                top: 0;
                right: 0;
                transition: transform 250ms ease;

                &.rotate180 {
                    transform: rotate(180deg);
                }
            }
        }
        .content {
            padding-top: 12px;
            display: flex;
            gap: 6px;
            flex-direction: column;

            .discount-list {
                display: flex;
                list-style: none;
                margin: 0;
                flex-direction: column;
                gap: 6px;
                padding: 12px;
                border-radius: 12px;
                background-color: $jood-gold-lightest;

                li {
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 400;

                    strong {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
            .disclaimer {
                margin: 0;
                margin-top: 12px;
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
            }

            .pdp-service-jood-discount-list {
                display: flex;
                gap: 12px;
            }

            a {
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
            }
        }

        &.user-BASICPRIME {
            .header .title {
                padding: 0;
                h2 {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                    color: $neutral-dark-01;
                }
            }
        }
    }
</style>
