<script lang="ts">
    import { _ } from "svelte-i18n";
    import { getAppConfig } from "../../../common/config/app-config";
    import { getStr } from "../../../common/util";
    import Snackbar from "../../common/components/Snackbar.svelte";
    import { openSnackBar } from "../../product-details/services/pdp-data-services";
    import { openSnackbar } from "../../product-details/store/pdp_store";
    const {
        primeLevel,
        earlyAccessCouponPosition,
        earlyAccessBasicCoupon,
        earlyAccessVipCoupon,
    } = getAppConfig();

    export let pageClass = null;

    let voucherCode = "";
    let positionClass = "c_megavoucher_center";

    if (earlyAccessCouponPosition === "topLeft") {
        positionClass = "c_megavoucher_topleft";
    } else if (earlyAccessCouponPosition === "topRight") {
        positionClass = "c_megavoucher_topright";
    } else if (earlyAccessCouponPosition === "bottomLeft") {
        positionClass = "c_megavoucher_bottomleft";
    } else if (earlyAccessCouponPosition === "bottomRight") {
        positionClass = "c_megavoucher_bottomright";
    }

    if (primeLevel === "VIPPRIME") {
        voucherCode = earlyAccessVipCoupon;
    } else if (primeLevel === "BASICPRIME") {
        voucherCode = earlyAccessBasicCoupon;
    }

    function onCopyCodeClicked() {
        navigator.clipboard.writeText(voucherCode);
        openSnackBar($_(getStr("extra.voucher.copy.message")));
        openSnackbar.update((x) => (x = false));
    }
</script>

<button
    on:click={onCopyCodeClicked}
    class="{pageClass} js-couponCodeCopy js-megasale-voucher c_megavoucher {positionClass}"
>
    <span class="js-couponCodeValue">{voucherCode}</span>
    <img class="copy-icon" src="https://media.extra.com/i/aurora/copy" alt="" />
</button>

{#if pageClass != "pdp-megasale-voucher" && pageClass != "home-megasale-voucher" && pageClass != "plp-megasale-voucher"}
    <Snackbar />
{/if}
