<script lang="ts">
    import FeatureTag from "../../tag/FeatureTag.svelte";
    import TimerTag from "../../tag/TimerTag.svelte";

    export let tagsData = [];
    export let isMegasaleProduct = [];
</script>

<div class="bottom-tag-conatiner sticky-conatiner">
    {#each tagsData as data}
        {#if data.timerTag}
            <TimerTag {...data} />
        {:else if data && Object.keys(data).length}
            <FeatureTag {...data} {isMegasaleProduct} />
        {/if}
    {/each}
</div>

<style lang="scss">
    @import "../../../styles/colors.new.scss";
    .bottom-tag-conatiner {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        gap: 4px;
        &.sticky-conatiner {
            // position: absolute;
            // left: 0;
            // right: 0;
            margin-top: -8px;
            overflow-x: scroll;
            scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
            @include desktop-min-width {
                margin-top: -12px;
            }
        }
    }
</style>
