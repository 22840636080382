<script lang="ts">
    import { getAppConfig } from "../../../common/config/app-config";
    import { getStr } from "../../../common/util";
    import Tag from "../../common/components/tag/Tag.svelte";

    export let stockFlag = false;
    export let stockStatus;
    export let stockCount;

    let { inStock, outOfStock } = getAppConfig();
    inStock = inStock.toUpperCase();
    outOfStock = outOfStock.toUpperCase();

    let label;
    let fontSize = 10;
    let theme = "";

    $: if (stockFlag) {
        if (stockStatus == "inStock") {
            label = inStock;
            theme = "green";
        } else if (stockStatus == "lowStock") {
            label = `${stockCount}  ${getStr("extra.pcsleftLable")}`;
            theme = "orange-white";
        }
    } else {
        label = outOfStock;
        theme = "gray";
    }
</script>

<Tag {label} {fontSize} {theme} />
