<script lang="ts" context="module">
  import { writable } from "svelte/store";
  import { getAppConfig } from "../../../../../common/config/app-config";

  declare global {
    interface Window {
      ACC: any;
    }
  }

  export type QACButtonVariant = "plp-tile" | "product-tile";

  const {
    quickAddToCartEnable,
    restockable,
    userDefaultCityCode,
    userSelectedCityCode,
  } = getAppConfig();
  const qacProdctResponseList = writable<any>(
    window.ACC?.qac?.var?.qacProdctResponseList || [],
  );
  const qacRestockableSKUs = writable<string>(
    sessionStorage.getItem(window.ACC.qac.var.QAC_NOTIFY_SS_KEY) || "",
  );

  // qac
  window.removeEventListener(
    window.ACC.qac.var.QAC_RESPONSE_DATA_LIST_CHANGE_EVENT_NAME,
    onQACGlobalResponseDataListChange,
  );
  window.addEventListener(
    window.ACC.qac.var.QAC_RESPONSE_DATA_LIST_CHANGE_EVENT_NAME,
    onQACGlobalResponseDataListChange,
  );

  // restockable
  window.removeEventListener(
    window.ACC.qac.var.RESTOCKABLE_SKU_LIST_CHANGE_EVENT_NAME,
    onRestockableListChange,
  );
  window.addEventListener(
    window.ACC.qac.var.RESTOCKABLE_SKU_LIST_CHANGE_EVENT_NAME,
    onRestockableListChange,
  );

  function onQACGlobalResponseDataListChange() {
    qacProdctResponseList.set([...window.ACC.qac.var.qacProdctResponseList]);
  }
  function onRestockableListChange() {
    qacRestockableSKUs.set(
      sessionStorage.getItem(window.ACC.qac.var.QAC_NOTIFY_SS_KEY) || "",
    );
  }
</script>

<script lang="ts">
  import { onMount } from "svelte";
  import {
    getStr,
    handleGlobalQacHandler,
    handleGlobalRestockableHandler,
    isMobile,
    isRunningUnderAppWebView,
  } from "../../../../../common/util";
  import Icon from "../../Icon.svelte";

  export let product;
  export let variant: QACButtonVariant = "product-tile";
  export let isRRComponent: boolean = false;

  let tooltip: string = "";

  $: sku = product?.code || product?.productCode;
  $: isSKUInCart =
    $qacProdctResponseList?.filter((x) => x.productCode === sku).length > 0;
  $: isRestockable = !!product?.restockableCities?.includes(
    userSelectedCityCode || userDefaultCityCode,
  );
  $: isRestockNotifyEnabled =
    ($qacRestockableSKUs &&
      $qacRestockableSKUs.includes(
        window.ACC.qac.getSKUNotifyEntryForSKU(sku),
      )) ||
    false;
  $: glyph = getGlyph(isRestockable, isSKUInCart, isRestockNotifyEnabled);
  $: visible = isQACRestockableVisible(product, isRRComponent);
  $: desktopSize = variant === "plp-tile" ? 24 : 32;
  $: isPreOrder = !isRestockable && !!product?.preorder;
  $: isTextVisible = getIfTextShouldBeVisible(variant);

  function getGlyph(
    isRestockable: boolean,
    isSKUInCart: boolean,
    isRestockNotifyEnabled: boolean,
  ) {
    if (isRestockable) {
      tooltip = "Notify when stock changes";
      if (isRestockNotifyEnabled) {
        return "icon-qac-notify-filled";
      } else {
        return "icon-qac-notify";
      }
    }

    if (isSKUInCart) {
      tooltip = "Remove from cart";
      return "icon-qac-cart-remove";
    } else {
      tooltip = "Quick add to cart";
      return "icon-qac-cart-add";
    }
  }

  function onQACButtonClick(event) {
    if ("ACC" in window && "qac" in window.ACC) {
      if (isRestockable) {
        if (isRestockNotifyEnabled) return; // do nothing if already enabled

        if (isRunningUnderAppWebView()) {
          window.location.assign(window.ACC.qac.getRestockNotifyAppUrl(sku));
        } else {
          window.ACC.qac.showRestockablePopupForProduct(product);
        }
        return;
      }

      if (isSKUInCart && variant !== "plp-tile") {
        if (isRunningUnderAppWebView()) {
          window.location.assign(window.ACC.qac.getQACCartDeleteAppUrl(sku, 0));
        } else {
          window.ACC.qac.removeFromCart(sku);
        }
      } else {
        if (isRunningUnderAppWebView()) {
          window.location.assign(window.ACC.qac.getQACCartAddAppUrl(sku));
        } else {
          window.ACC.qac.addToCart(sku);
        }
      }
    } else {
      console.error(
        "[QAC] ACC.qac not found! May be it is not included for the current page. ✖︎",
      );
    }
  }

  // this method controls the visibility of both qac and restockable buttons on product tile
  function isQACRestockableVisible(product, isRRComponent) {
    // restockable visibility
    const restockFlagVisible = restockable && isRestockable && !isRRComponent;

    // qac visibility
    let qacFlagVisible = quickAddToCartEnable && product?.isAddToCartEnabled;
    if (qacFlagVisible) {
      const userCity = userSelectedCityCode || userDefaultCityCode;
      const isSKUInStock =
        product?.inStockCities?.includes(userCity) ||
        product?.sellingOutFastCities?.includes(userCity) ||
        ["inStock", "lowStock"].includes(product?.stock?.stockLevelStatus);
      const isNotComingSoon = !product?.comingSoon;
      const isNotADigitalGiftCard = !product?.isDigitalGiftCard;

      qacFlagVisible = isSKUInStock && isNotComingSoon && isNotADigitalGiftCard;
    }

    return restockFlagVisible || qacFlagVisible;
  }

  function getButtonText() {
    let labelKey = "extra.giftcard.add.to.cartBtn";

    if (isRestockable) {
      labelKey = "extra.restockable.notify.me";
    } else if (isPreOrder) {
      labelKey = "PRE_ORDER";
    }

    return getStr(labelKey);
  }

  function getIfTextShouldBeVisible(variant) {
    /*
        text is visible for qac and restockable for desktop plp page, and for qac for mobile plp page
      */
    const isPLPTile = variant === "plp-tile";
    const isAddToCart = !isRestockable;
    const visible = isPLPTile && (isAddToCart || !isMobile());

    return visible;
  }

  onMount(() => {
    handleGlobalQacHandler();
    handleGlobalRestockableHandler();
  });
</script>

{#if visible}
  <button
    class="product-qac-button variant-{variant}"
    class:restock-button={isRestockable}
    class:qac-button={!isRestockable}
    class:rr-component={isRRComponent}
    data-qac-button={(!isRestockable && sku) || undefined}
    data-restock-button={(isRestockable && sku) || undefined}
    data-svelte="true"
    data-sku={sku}
    title={tooltip}
    on:click|stopPropagation|preventDefault={onQACButtonClick}
  >
    {#if (isRestockable && variant === "plp-tile") || variant !== "plp-tile"}
      <Icon {glyph} mobileSize={24} {desktopSize} type="primary" />
    {/if}
    {#if isTextVisible}
      {getButtonText()}
    {/if}
  </button>
{/if}

<style lang="scss">
  @import "../../../../common/styles/colors.new.scss";
  .product-qac-button {
    border: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
    display: flex;
    gap: 4px;
    min-width: fit-content;
    align-items: center;
    font-family: "Dubai";

    &.variant-plp-tile {
      &.restock-button {
        font-size: 16px;
        line-height: 18px;
        color: $icon-only-icon-blue;
        font-weight: 500;
      }
      &.qac-button {
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        color: $primary-black;
        background-color: $primary-yellow-01;
        padding: 13px;
        border-radius: 40px;

        @include responsive {
          flex-basis: 75%;
          justify-content: center;
          font-size: 14px;
          line-height: 18px;
          padding: 8px;
        }
      }
    }
  }
</style>
