<script>
    import { createEventDispatcher } from "svelte";

    import { APP_CONSTANTS } from "../../../../../common/config/app-constants";
    import Icon from "../../Icon.svelte";
    import TagContainer from "../../tag/TagContainer.svelte";
    import ProductQacButton from "./ProductQACButton.svelte";

    export let product;
    export let productTags = [];
    export let productCode;
    export let showTags = true;
    export let showFavIcon = true;
    export let favIconMobileWidth = 24;
    export let favIconDesktopWidth = 32;
    export let productDataForGTM;

    export let isRRComponent;
    export let isWishlisted = false;
    const dispatch = createEventDispatcher();

    let toggleWish = false;
    const toggleWishlist = (event) => {
        event.stopPropagation();
        toggleWish = !toggleWish;
        if (productDataForGTM !== undefined) {
            window["ACC"].extragtm.click.addToWishlistV2(productDataForGTM);
        }
        // stopping the addToWishlist API, if already wishlisted, to prevent API calls on click
        if (toggleWish) {
            dispatch("changeWishList", {
                toggleWish: toggleWish,
                event: event,
                productCode: productCode,
            });
        }
    };
</script>

<div
    style:--min-height-desktop="{favIconDesktopWidth}px"
    style:--min-height-mobile="{favIconMobileWidth}px"
    class="tile-head-container"
>
    {#if showTags}
        <TagContainer {productTags} />
    {/if}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    {#if showFavIcon}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- {#if toggleWish || isWishlisted} -->
        {#if isWishlisted}
            <div
                class="fav-wrapper {isWishlisted ? 'active' : ''}"
                on:click={(event) => toggleWishlist(event)}
            >
                <Icon
                    glyph="heart_filled"
                    mobileSize={favIconMobileWidth}
                    desktopSize={favIconDesktopWidth}
                    type="font"
                />
            </div>
        {:else}
            <div
                class="fav-wrapper"
                on:click={(event) => toggleWishlist(event)}
            >
                <Icon
                    glyph="heart"
                    mobileSize={favIconMobileWidth}
                    desktopSize={favIconDesktopWidth}
                    type="font"
                />
            </div>
        {/if}
        <ProductQacButton {product} {isRRComponent} />
    {/if}
</div>

<style lang="scss">
    @import "../../../styles/colors.new.scss";
    :global {
        .tile-head-container {
            .tag-container {
                padding: 0 !important;
                margin-right: 4px;
                overflow-x: auto;
                scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
                flex-wrap: wrap;
                flex-direction: row;
                gap: 4px;

                @include responsive {
                    margin-right: 0;
                }
                &::-webkit-scrollbar {
                    display: none;
                }
                @include desktop-max-width {
                    flex-direction: column;
                    z-index: 10;
                }
                .tag {
                    padding: 4px 8px !important;
                }
            }
        }
    }
    .tile-head-container {
        position: absolute;
        top: 10px;
        left: 8px;
        right: 12px;
        min-height: var(--min-height-mobile);

        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        max-width: 100%;
        gap: 10px;

        @include responsive {
            gap: 0;
        }
        @include desktop-min-width {
            top: 8px;
            left: 8px;
            right: 17px;
            min-height: var(--min-height-desktop);
        }
        .fav-wrapper {
            color: $icon-only-icon-blue;
            margin-left: auto;
            cursor: pointer;

            @include desktop-min-width {
                margin-top: 0;
            }
            &.active {
                // border: 1px solid $icon-only-icon-blue;
                .img-icon {
                    background-color: $icon-only-icon-blue;
                }
            }
        }
    }
</style>
