<script lang="ts">
    import { getAppConfig } from "../../../common/config/app-config";
    import PictureTag from "../lazy-components/PictureTag.svelte";
    import { transformationTemplates } from "../services/ampliance-transforamtion-template-mapper";

    export let data;
    const { themeResourcePath } = getAppConfig();

    const gradientBg = `${themeResourcePath}/images/brand-gradient.png`;
</script>

<a class="brandTiles-container-slider-slide" href={data?.link}>
    <section
        class="brandTiles-container-slider-slide-info"
        style={`background:url(${gradientBg}), linear-gradient(0deg, #000000ff, #00000000)`}
    >
        <span class="brandTiles-container-slider-slide-info-title"
            >{data?.headingText}</span
        >
        <span class="brandTiles-container-slider-slide-info-subTitle"
            >{data?.subHeadingText}</span
        >
    </section>
    <section class="theme-icon">
        <PictureTag
            image={data?.image}
            mobileImage={data?.mobileImage}
            getAllResolutions={false}
            templateName={transformationTemplates.extraBrandTileCarousel}
        />
    </section>
</a>

<style lang="scss">
    @import "../styles/colors.new.scss";
    .brandTiles-container {
        .theme-icon {
            :global(img) {
                border-radius: 12px;
            }
        }
    }

    .brandTiles-container-slider {
        width: 100%;
        &-slide {
            // position: relative;
            // height: 310px;
            // display: inline-block;
            @include desktop-max-width {
                height: 200px;
                max-width: 132px;
            }
            &-info {
                position: absolute;
                left: 0;
                right: 0;
                height: 100%;
                bottom: 0;
                justify-content: end;
                padding: 20px;
                display: flex;
                gap: 4px;
                flex-direction: column;
                border-radius: 12px;
                @include desktop-max-width {
                    gap: 0;
                    padding: 10px;
                }
                &-title {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 28px;
                    color: $color-bg-grey;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    @include desktop-max-width {
                        font-size: 14px;
                        line-height: 16px;
                        color: $primary-white;
                    }
                }
                &-subTitle {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 18px;
                    color: $primary-white;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    @include desktop-max-width {
                        font-size: 12px;
                        line-height: 14px;
                        color: $neutral-light-05;
                    }
                }
            }
        }
    }
</style>
