<script lang="ts">
    import { nearestPickupStore } from "./../store/pdp_store.js";
    import ProductDeliveryDescription from "./ProductDeliveryDescription.svelte";
    import ProductDeliveryHeader from "./ProductDeliveryHeader.svelte";
    import { _ } from "svelte-i18n";
    import { onDestroy, onMount } from "svelte";
    import { error, options, success } from "../services/pdp-data-services.js";
    import Text from "../../common/components/Text.svelte";
    import {
        getDateStringInCurrentLang,
        getStr,
    } from "../../../common/util.js";
    import OnDemandPdpBlock from "./OnDemandPDPBlock.svelte";
    import { getAppConfig } from "../../../common/config/app-config.js";

    export let cfsPosPickUpInStoreData;
    export let homeDeliveryData;
    export let code;
    export let isHomeDeliveryEnabled;
    export let stock;

    // export let isTransactional;

    export let deliveryLeadMessage = null;
    export let deliveryLeadTimeNow = null;
    export let deliveryLeadTimeBeforeBy = null;
    export let displayFreeDelivery;
    export const deliveryPayment = null;
    export let expressDeliveryMode = null;
    export let isCollectFromStoreEnabled: boolean;
    export let productStatus;
    export let shippingClassification;
    export let standardDeliveryCost;
    export let deliveryLeadTimeBefore = null;
    export let onDemandDeliveryMode = null;
    const { currentCity, userSelectedCity, lang, isTransactional } =
        getAppConfig();

    let _geoWatch;

    const currentUserCity = currentCity || userSelectedCity;
    const currentCityName =
        lang === "en"
            ? currentUserCity?.nameEn || userSelectedCity
            : currentUserCity?.nameAr || userSelectedCity;

    $: defaultPos = cfsPosPickUpInStoreData?.defaultPos;
    $: cnbh = defaultPos?.collectNbHours;
    $: icih = defaultPos?.isCollectInHours;
    $: bc = defaultPos?.beforeClosure;
    $: chiam = defaultPos?.closingHoursInAm;
    $: storeCollectHours = defaultPos?.storeCollectHours;

    $: storeInStock = stock?.stockLevelStatus?.code != "outOfStock";
    $: cfsInStock =
        cfsPosPickUpInStoreData?.defaultPos?.stockData?.stockLevelStatus
            ?.code === "inStock" ||
        cfsPosPickUpInStoreData?.defaultPos?.stockData?.stockLevelStatus
            ?.code === "lowStock";
    $: cfsStockStatus =
        cfsPosPickUpInStoreData?.defaultPos?.stockData?.stockLevelStatus?.code;
    $: cfsStockCount =
        cfsPosPickUpInStoreData?.defaultPos?.stockData?.stockLevel;
    $: storePickupDesc = $_(getStr("pdp.storePickupDescription"), {
        values: {
            hours: cfsPosPickUpInStoreData?.defaultPos.storeCollectHours,
            closingTime: `${cfsPosPickUpInStoreData?.defaultPos.closingTime} ${cfsPosPickUpInStoreData?.defaultPos.closingHoursInAm ? "AM" : "PM"}`,
        },
    });
    // $: storePickupLocation = cfsPosPickUpInStoreData != null ? cfsPosPickUpInStoreData?.defaultPos.displayName : '';
    $: storePickupLocation =
        cfsPosPickUpInStoreData != null
            ? lang != "en"
                ? cfsPosPickUpInStoreData?.defaultPos.nameAr
                : cfsPosPickUpInStoreData?.defaultPos.nameEn
            : "";
    // $: $nearestPickupStore = cfsPosPickUpInStoreData != null ? cfsPosPickUpInStoreData?.alternativePos : null;
    const storePickupTitle = $_(getStr("pdp.storePickupTitle"));

    $: deliveryInStock =
        homeDeliveryData?.currentCityStockData?.stockLevelStatus?.code ===
            "inStock" ||
        homeDeliveryData?.currentCityStockData?.stockLevelStatus?.code ===
            "lowStock";
    $: deliveryStockStatus =
        homeDeliveryData?.currentCityStockData?.stockLevelStatus?.code;
    $: deliveryStockCount = homeDeliveryData?.currentCityStockData?.stockLevel;

    $: deliveryLocation = homeDeliveryData?.city;
    const deliveryTitle = $_(getStr("pdp.deliveryTitle"));

    $: diffrentStorelable = getStr("extra.pdp.oos.diffrentStorelable");
    $: currentlydeliverynotavailable = getStr(
        "extra.pdp.oos.currentlydeliverynotavailable",
    );
    $: neareststoreforproductlable = getStr(
        "extra.pdp.oos.neareststoreforproductlable",
    );
    console.log(isHomeDeliveryEnabled, "isHomeDeliveryEnabled");

    onMount(async () => {
        _geoWatch = navigator.geolocation.getCurrentPosition(
            success,
            error,
            options,
        );
    });

    onDestroy(() => {
        if (_geoWatch) {
            navigator.geolocation.clearWatch(_geoWatch);
        }
    });
</script>

<section class="product_delivery">
    {#if (storeInStock === true || $nearestPickupStore !== null) && isCollectFromStoreEnabled && (isTransactional == "true" || isTransactional == true) && productStatus != "PRE_ORDER" && cfsPosPickUpInStoreData}
        <div class="mini-card store-pick-up">
            <ProductDeliveryHeader
                inStock={cfsInStock}
                stockStatus={cfsStockStatus}
                stockCount={cfsStockCount}
                title={storePickupTitle}
                location={storePickupLocation}
                isStorePickup={true}
                {code}
                {isCollectFromStoreEnabled}
                {cfsPosPickUpInStoreData}
            />
            {#if cfsInStock}
                <div class="description">
                    {#if cnbh > 0}
                        {#if icih}
                            <ProductDeliveryDescription
                                icon={`icon-shop`}
                                collectInHours={storeCollectHours}
                            />
                        {:else}
                            <ProductDeliveryDescription
                                icon={`icon-shop`}
                                collectInHours={storeCollectHours}
                                icihtime={cnbh}
                            />
                        {/if}
                    {:else}
                        <ProductDeliveryDescription
                            icon={`icon-shop`}
                            description={getStr("product.pickupinstore.closed")}
                        />
                    {/if}
                </div>
            {:else}
                <div class="outofstock">
                    <span class="outofstock-heading">{diffrentStorelable}</span>
                    {#if isCollectFromStoreEnabled && $nearestPickupStore !== null && productStatus !== "PRE_ORDER"}
                        <span class="outofstock-subheading"
                            >{neareststoreforproductlable}
                            <span
                                data-productcode={code}
                                data-storename={$nearestPickupStore.name}
                                class="nearest-store select-store-button__new-modal"
                            >
                                {$nearestPickupStore.displayName}
                            </span>
                        </span>
                    {/if}
                </div>
            {/if}
        </div>
    {/if}
    {#if (storeInStock || deliveryInStock) && isHomeDeliveryEnabled && (isTransactional == "true" || isTransactional == true)}
        <div class="mini-card delivery">
            <ProductDeliveryHeader
                inStock={deliveryInStock}
                stockStatus={deliveryStockStatus}
                stockCount={deliveryStockCount}
                title={deliveryTitle}
                location={currentCityName}
                {code}
                {isCollectFromStoreEnabled}
                {cfsPosPickUpInStoreData}
            />
            {#if homeDeliveryData.currentCityStockData?.stockLevelStatus?.code != "outOfStock"}
                <div class="description">
                    <section class="delivery-info-block std-delivery-block">
                        <ProductDeliveryDescription
                            icon={`icon-standard_delivery`}
                            deliveryType={getStr(
                                "account.addressbook.update.deliverymode",
                            )}
                            deliveryDate={getDateStringInCurrentLang(
                                deliveryLeadTimeNow || deliveryLeadTimeBeforeBy,
                                lang,
                            )}
                            deliveryCost={displayFreeDelivery === true
                                ? 0
                                : standardDeliveryCost?.value}
                            currency={standardDeliveryCost?.currencyIso || ""}
                        />
                        {#if shippingClassification === "LARGE"}
                            <div class="info">
                                <Text key="largeItemDeliveryLeadMessage" />
                            </div>
                        {/if}
                        {#if shippingClassification === "SMALL"}
                            {#if deliveryLeadMessage}
                                <div
                                    class="c_predelivery-message"
                                    id="c_predelivery-message"
                                >
                                    {@html deliveryLeadMessage}
                                </div>
                            {:else}
                                {#if deliveryLeadTimeNow !== null}
                                    <div class="info">
                                        <Text key="product.dlt.order.now" />
                                        <span
                                            ><strong
                                                >{getDateStringInCurrentLang(
                                                    deliveryLeadTimeNow,
                                                    lang,
                                                )}</strong
                                            ></span
                                        >
                                    </div>
                                {/if}
                                {#if deliveryLeadTimeBefore !== null}
                                    <div class="info">
                                        <Text
                                            key="product.dlt.order.before"
                                            args={[deliveryLeadTimeBefore]}
                                        />
                                        <span
                                            ><strong
                                                >{getDateStringInCurrentLang(
                                                    deliveryLeadTimeBeforeBy,
                                                    lang,
                                                )}</strong
                                            ></span
                                        >
                                    </div>
                                {/if}
                            {/if}
                        {/if}
                    </section>
                </div>
                <div class="shopping-fees-and-policies-link-wrapper">
                    <a target="_blank" href="/delivery"
                        ><Text
                            key="extra.pdp.view.all.our.shipping.fees.and.policies"
                        /></a
                    >
                </div>
            {:else}
                <div class="outofstock">
                    <span class="outofstock-heading"
                        >{currentlydeliverynotavailable}</span
                    >
                    <!--{#if isCollectFromStoreEnabled && $nearestPickupStore !== null}
                        <span class="outofstock-subheading">{neareststoreforproductlable} <span on:click={() => nearestStoreClicked = true} class="nearest-store">{$nearestPickupStore.displayName}</span></span>
                    {/if}-->
                </div>
            {/if}
        </div>
    {/if}

    {#if expressDeliveryMode || onDemandDeliveryMode}
        <div class="mini-card on-demand">
            <OnDemandPdpBlock {expressDeliveryMode} {onDemandDeliveryMode} />
        </div>
    {/if}
</section>

<style lang="scss">
    @import "../../common/styles/colors.new.scss";

    .product_delivery {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .mini-card {
            padding: 8px;
            border-radius: 8px;
            background-color: $primary-white;
        }
    }
    .description {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 12px;

        .delivery-info-block {
            .info {
                font-weight: 400;
                font-size: 14px;
                font-weight: 16px;
                margin-top: 12px;
            }
        }

        @include desktop-min-width {
            margin-top: 14px;
        }
    }
    .outofstock {
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-top: 12px;
        margin-bottom: 24px;
        &-heading {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: $functional-orange;
        }
        &-subheading {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;

            .nearest-store {
                font-weight: 500;
                color: $icon-only-icon-blue;
                cursor: pointer;
            }
        }

        @include desktop-min-width {
            margin-top: 14px;
            gap: 4px;

            &-heading {
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
            }
            &-subheading {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;

                .nearest-store {
                    font-weight: 500;
                }
            }
        }
    }

    .total-outofstock-subheading {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        margin-top: 4px;

        @include desktop-min-width {
            font-size: 36px;
            line-height: 40px;
            margin-top: 8px;
        }
    }
    .shopping-fees-and-policies-link-wrapper {
        padding-top: 1rem;
        padding-bottom: 0;
        a {
            font-size: 1.4rem;
            font-weight: 500;
        }
    }
    :global {
        #c_predelivery-message {
            color: $neutral-dark-01;
            p {
                color: $neutral-dark-01;
            }
        }
    }
</style>
