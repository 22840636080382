<script lang="ts">
    import Button, { Label } from "@smui/button";
</script>

<div class="recommendation-cat-container">
    <div class="first-btn">
        <Button>
            <Label>Explore more TVs</Label>
        </Button>
    </div>
    <div class="sec-btn">
        <Button on:click={() => {}}>
            <Label>Yup, got my TV</Label>
        </Button>
    </div>
</div>

<style lang="scss">
    @import "../../../../common/styles/colors.new.scss";

    :global {
        .recommendation {
            &-cat-container {
                display: flex;
                flex-direction: column;
                gap: 12px;

                @include desktop-min-width {
                    flex-direction: row-reverse;
                    gap: 0px;
                }
                .first-btn,
                .sec-btn {
                    .mdc-button {
                        width: 100%;
                        font-size: 16px;
                        line-height: 18px;
                        font-weight: 700;
                        min-height: 48px;
                        .mdc-button__label {
                            white-space: nowrap;
                        }
                        @include desktop-min-width {
                            width: auto;
                            padding: 15px 68px;
                        }
                    }
                }

                .first-btn {
                    .mdc-button {
                        border: 1px solid $primary-blue;
                        border-radius: 100px;
                    }
                }

                .sec-btn {
                    .mdc-button {
                        color: $icon-only-icon-blue;
                        @include desktop-min-width {
                            padding: 0;
                            padding-right: 20.5px;
                        }
                    }
                }
            }
        }
    }
</style>
