import { getAppConfig } from "../../../common/config/app-config";
import { APP_CONSTANTS } from "../../../common/config/app-constants";
import { reAssignPriceValues } from "../../common/services/reassign-price-values";
import { delayPromise } from "./hybris";
import { UnbxdAvailableAPIs, getProductsFromUnbxdUsingAlgoliaRequestAsync } from "./unbxd";

const { userSelectedCityCode, userDefaultCityCode, isMegaSaleDuringAccessStarted, primeLevel, unbxdEnabled, restockable } = getAppConfig();
const cityCode = userSelectedCityCode || userDefaultCityCode;


export function getAlgoliaConfig() {
    return window['ACC'].config.algoliaConfig;
}
export function getProductsByUrlFromAlgoliaAsync(algoliaUrl: string, ...optionalParams) {
    const parsedUrl = parseAlgoliaUrl(algoliaUrl);
    const filtersFromOptionalParams = optionalParams.filter(p => 'filters' in p).map(p => p.filters).join(' AND ');
    if (filtersFromOptionalParams.length > 0) {
        // optional params also has some filters, merge those as well
        if (parsedUrl.filters.length > 0) {
            parsedUrl.filters += ' AND ';
        }
        parsedUrl.filters += filtersFromOptionalParams;

        // remove all the filters from optionalParams
        optionalParams = optionalParams.filter(p => !('filters' in p));
    }
    if ('indexSuffix' in parsedUrl) {
        optionalParams.push(OPTION_INDEX_SUFFIX(parsedUrl.indexSuffix));
        delete parsedUrl.indexSuffix;
    }
    return getProductsFromAlgoliaAsync(
        [],
        OPTION_FILTERS(parsedUrl.filters),
        ...optionalParams
    );
}
export function combineFilters(optionalParams) {
    const allFiltersCombined = optionalParams.filter(p => 'filters' in p).map(p => p.filters).join(' AND ');
    const otherParams = optionalParams = optionalParams.filter(p => !('filters' in p));

    return [OPTION_FILTERS(allFiltersCombined), ...otherParams];
}
export function getProductsByRuleContextFromAlgoliaAsync(ruleName: string, ...optionalParams) {
    return getProductsFromAlgoliaAsync(
        [],
        OPTION_RULE_CONTEXTS(ruleName),
        OPTION_HITS_PER_PAGE(10), // defaults to 10 results, if not supplied by mistake
        ...optionalParams
    );
}

export function composeSingleProductQueryForAlgolia(query, ...optionalParams) {
    optionalParams.push(OPTION_FILTERS(FILTER_IN_STOCK_CITY(cityCode)));
    return {
        query,
        params: Object.assign({}, { attributesToHighlight: [] }, ...combineFilters(optionalParams))
    };
}
export function getProductsByMultiQueriesFromAlgoliaAsync(multiQueries: any[]) {
    const { indexes } = getAlgoliaConfig();
    const { indexName } = indexes.filter(i => i.indexType === 'Product')[0];
    const queryArray = [...multiQueries.map(x => Object.assign({}, x, { indexName }))];

    let promise;
    if (unbxdEnabled) {
        promise = Promise.all(
            queryArray.map(q => getProductsFromUnbxdUsingAlgoliaRequestAsync('*', q.params))
        ).then(responses => {
            const results = responses.map((r: any) => r.results[0]);
            return { results };
        });
    } else {
        promise = Promise.reject("Algolia is no more supported");
    }

    const finalPromise = promise.then(({ results }) => results);

    if (APP_CONSTANTS.IS_DEMO_MODE) {
        return finalPromise.then(delayPromise(2000));
    }
    return finalPromise;
}
export function getProductsFromAlgoliaAsync(productCodes: string[], ...optionalParams) {
    const facetFilters = [productCodes.map(p => `productCode:${p}`)];

    const indexSuffixIndex = optionalParams.findIndex(p => ('INDEX_SUFFIX' in p));
    let indexSuffix = '';
    if (indexSuffixIndex > -1) {
        indexSuffix = optionalParams[indexSuffixIndex].INDEX_SUFFIX;
        delete optionalParams[indexSuffixIndex].INDEX_SUFFIX;
    }

    const alreadyHasStockFilter = optionalParams?.filter(p => ('filters' in p && p.filters.indexOf(FILTER_IN_STOCK_CITY(cityCode)) > -1)).length > 0;

    if (!alreadyHasStockFilter) {
        optionalParams.push(OPTION_FILTERS(FILTER_IN_STOCK_CITY(cityCode)));
    }
    optionalParams = combineFilters(optionalParams);
    const params = Object.assign({}, { facetFilters, attributesToHighlight: [] }, ...optionalParams);

    let additionalUnbxdParams = new URLSearchParams();
    if (unbxdEnabled && indexSuffix?.length > 0) {
        const sortParam = indexSuffix.replace(/_/g, ' ').trim();
        additionalUnbxdParams.set('sort', sortParam);
    }
    const promise = (unbxdEnabled
        ? getProductsFromUnbxdUsingAlgoliaRequestAsync('*', params, UnbxdAvailableAPIs.NONE, additionalUnbxdParams)
        : Promise.reject("Algolia is no more supported"))
        .then((res) => {
            const results = res.results;
            if (typeof results[0]['hits'] != 'undefined' && results[0]['hits'].length > 0 && isMegaSaleDuringAccessStarted == true && primeLevel) {
                results[0]['hits'] = reAssignPriceValues(results[0]['hits']);
            }
            return results[0];
        });

    if (APP_CONSTANTS.IS_DEMO_MODE) {
        return promise.then(delayPromise(2000));
    }
    return promise;
}

// to be used to modify the index name
export function OPTION_INDEX_SUFFIX(suffix: string) {
    return { INDEX_SUFFIX: suffix };
}
export function OPTION_ATTRIBUTES_TO_RETRIEVE(attrList: string[]) {
    return { attributesToRetrieve: attrList };
}
export function OPTION_RULE_CONTEXTS(ruleName: string) {
    return { ruleContexts: [ruleName] };
}
export function OPTION_HITS_PER_PAGE(hitsPerPage: number) {
    return { hitsPerPage };
}
export function OPTION_OPTIONAL_FILTERS(optionalFilters: string[]) {
    return { optionalFilters };
}

export function OPTION_FILTERS(filters: string) {
    return { filters };
}
export function OPTION_FILTERS_CATEGORY_FILTER(categoryId: string) {
    return { filters: `categories:${categoryId}` };
}

export function OPTION_FACETS(facetList: string | string[]) {
    const facets = typeof facetList === 'string' ? [facetList] : facetList;
    return { facets };
}

export function OPTION_FACET_FILTER(facetFilterList: string | string[]) {
    const facetFilters = typeof facetFilterList === 'string' ? [facetFilterList] : facetFilterList;

    return { facetFilters };
}

export function OPTION_NUMERIC_FILTER(numericFilterList: string | string[]) {
    const numericFilters = typeof numericFilterList === 'string' ? [numericFilterList] : [numericFilterList];
    return { numericFilters };
}

export function OPTION_PRICE_FILTER(numericFilterList: string | string[]) {
    return OPTION_NUMERIC_FILTER(numericFilterList);
}

export function OPTION_FACET_FILTERS(filterObjects: any[]) {
    // [{ attr1: 'val1' }, {attr2: 'val2', attr3: 'val3'}]
    // [attr1:val1, [attr2:val2, attr3:val3]]
    // attr1:val1 AND (attr2:val2 OR attr3:val3)
    const facetFilters = filterObjects.map(a => Object.keys(a).map(k => `${k}:${a[k]}`)).map(x => x.length === 1 ? x[0] : x);
    return { facetFilters };
}

export function OPTION_HITS_PAGE(page: number) {
    return { page };
}

export function OPTION_RESPONSE_FIELDS(responseFieldList: string | string[]) {
    const responseFields = typeof responseFieldList === 'string' ? [responseFieldList] : responseFieldList;

    return { responseFields };
}

// optional filters related builders
export function FILTER_IN_STOCK_CITY(cityCode: string) {
    let inStockFilter = `sellingOutFastCities:${cityCode} OR inStockCities:${cityCode}`;
    if (restockable) {
        // restockable is enabled, add the restockableCities:${cityCode} also in the stock check
        inStockFilter += ` OR restockableCities:${cityCode}`;
    }

    return `(${inStockFilter})`;
}

// url parser
export function parseAlgoliaUrl(url) {
    const dummyUrl = new URL(url, 'https://dummy.com');
    let output: any = {};

    const params = Object.fromEntries(dummyUrl.searchParams.entries()) ?? {};
    output.params = params;

    let parsedQuery: any = {};
    output.filters = '';

    if ('q' in params) {
        parsedQuery = parseAlgoliaQuery(params.q);
    }

    if ('sort' in params) {
        const { sort: sortCode } = params;
        const { algoliaConfig: { sorts } } = getAppConfig();

        const selectedSortObject = (sorts as any[]).find(s => s.code === sortCode);
        if (selectedSortObject && 'sortSuffix' in selectedSortObject) {
            output.indexSuffix = selectedSortObject.sortSuffix;
            delete params.sort;
        }
    }

    const categoryRE = /\/c\/([\d-\w]*)\/?/.exec(dummyUrl.pathname);
    output.categoryId = '';
    if (categoryRE?.length > 1) {
        output.categoryId = categoryRE[1];
        parsedQuery.categories = [output.categoryId];
    }

    if (Object.keys(parsedQuery).length > 0) {
        output.filters = composeFilterStringFromQueryObject(parsedQuery);
    }

    return output;
}

function composeFilterStringFromQueryObject(query) {
    let filterString = Object.keys(query).map(facetName => {
        const sameFacetFilter = query[facetName].map(facetValue => {
            facetName = facetName.replace(/"/g, '');
            facetValue = transformFacetValue(facetValue);
            facetName = transformFacetValue(facetName);
            switch (facetName) {
                case 'price':
                    return `${facetName}:${facetValue.split('-').join(' TO ')}`;
                case 'ratingFacet':
                    return `${facetName}>=${facetValue}`;
                case 'inStock':
                    return `(sellingOutFastCities:${cityCode} OR inStockCities:${cityCode})`;
                default:
                    return `${facetName}:${facetValue}`;
            }
        });
        let sameFacetFilterString = sameFacetFilter.join(' OR ');
        if (sameFacetFilter.length > 1) {
            sameFacetFilterString = `(${sameFacetFilterString})`;
        }
        return sameFacetFilterString;
    });
    return filterString.filter(x => x.length > 0).join(' AND ');
}
function transformFacetValue(facetValue) {
    if (facetValue.indexOf('(') > -1 || facetValue.indexOf(' ') > -1 || /^AND|OR|NOT$/.test(facetValue)) return `"${facetValue}"`;
    return facetValue;
}
function parseAlgoliaQuery(q) {
    if (q.startsWith('relevance')) {
        q = ':' + q;
    }
    let argSplits = q.split(':');
    if (argSplits[1] === 'relevance') {
        argSplits = argSplits.slice(2);
    }

    const argObject = argSplits.reduce((acc, current, index, arr) => {
        if (index % 2 === 0) {
            if (current in acc) {
                acc[current].push(arr[index + 1]);
            } else {
                acc[current] = [arr[index + 1]];
            }
        }
        return acc;
    }, {});
    return argObject;
}