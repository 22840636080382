
import { environment } from '../../../environment/env';
import { getAppConfig } from '../config/app-config';
import { APP_CONSTANTS } from '../config/app-constants';
import { getGuestToken } from './token-services';

const getAccessToken = async () => {
  let ACCESS_TOKEN = sessionStorage.getItem('accessToken');
  if (!ACCESS_TOKEN || new Date().getTime() > JSON.parse(ACCESS_TOKEN)?.expiryTime) {
    await getGuestToken();
    ACCESS_TOKEN = await sessionStorage.getItem('accessToken');
  }
  let ACCESS_TOKEN_VALUE = await JSON.parse(ACCESS_TOKEN);
  return ACCESS_TOKEN_VALUE?.access_token || environment.BACKUP_TOKEN;
}

/* Refactoring new methods */
async function getAuthHeader() {
  return getAccessToken()
    .then(token => `Bearer ${token}`)
    .then(Authorization => {
      return { Authorization };
    });
}

export function getUrlWithQueryParams(url, queryParamsOverrides = {}) {
  const isAbsoluteUrl = url?.startsWith('http');

  let urlObject = new URL(url, 'https://dummy');
  const parametersFromUrl = Object.fromEntries(urlObject.searchParams.entries());

  const { jessionId, userSelectedCityCode, userDefaultCityCode } = getAppConfig();
  const defaultQueryParams = {
    appId: APP_CONSTANTS.APP_ID,
    AppSessionToken: jessionId,
    charset: 'utf-8',
    city: userSelectedCityCode || userDefaultCityCode
  };

  let mergedQP = {};
  if ('-*' in queryParamsOverrides) {
    delete queryParamsOverrides['-*'];
    mergedQP = Object.assign({}, parametersFromUrl, queryParamsOverrides);
  } else {
    mergedQP = Object.assign({}, defaultQueryParams, parametersFromUrl, queryParamsOverrides);
  }

  let queryString = '';
  const mergedQueryParamObject = new URLSearchParams(mergedQP);
  if (Object.keys(mergedQP).length > 0) {
    queryString = '?' + mergedQueryParamObject.toString();
  }

  let wholeUrl = '';
  if (isAbsoluteUrl) {
    wholeUrl = `${urlObject.origin}`;
  }
  wholeUrl += urlObject.pathname + queryString;

  return wholeUrl;
}

async function getHeaders(headerOverrides = {}, useAuth = true) {
  const defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  };

  const outputHeaders = Object.assign({}, defaultHeaders, headerOverrides);
  if (useAuth) {
    return getAuthHeader()
      .then(authHeader => Object.assign(outputHeaders, authHeader));
  }
  return outputHeaders;
}
export async function httpGetRaw(url, params = {}, headerOverrides = {}, useAuth = true) {
  const headers = await getHeaders(headerOverrides, useAuth);
  const urlWithParams = getUrlWithQueryParams(url, params);
  const method = 'GET';

  return fetch(urlWithParams, {
    headers,
    method
  }).then(res => {
    if (res.status === 401) {
      sessionStorage.removeItem('accessToken');
      window.location.reload();
    }
    return res;
  });
}

export async function httpGetJSON(url, params = {}, headerOverrides = {}, useAuth = true) {
  return httpGetRaw(url, params, headerOverrides, useAuth).then(res => res.json());
}

export async function httpPostRaw(url, body, headerOverrides = {}, useAuth = true) {
  const headers = await getHeaders(headerOverrides, useAuth);
  const method = 'POST';

  return fetch(url, {
    headers,
    method,
    body
  });
}
export async function httpPostJSON(url, body, headerOverrides = {}, useAuth = true) {
  return httpPostRaw(url, body, headerOverrides, useAuth).then(res => res.json());
}

export async function httpPutRaw(url, body, headerOverrides = {}, useAuth = true) {
  const headers = await getHeaders(headerOverrides, useAuth);
  const method = 'PUT';

  return fetch(url, {
    headers,
    method,
    body
  });
}
export async function httpPutJSON(url, body, headerOverrides = {}) {
  return httpPutRaw(url, body, headerOverrides).then(res => res.json());
}
