<script>
    export let brandName;
    export let name;
</script>

<div class="tile-name-container">
    <div class="product-name">
        {#if brandName !== null}
            <span class="brand-name">{brandName}</span>
            <span class="dot d-inline-flex align-middle"></span>
        {/if}
        <span class="product-name-data">{name}</span>
    </div>
</div>

<style lang="scss">
    @import "../../../styles/colors.new.scss";

    .tile-name-container {
        font-size: 12px;
        line-height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        color: $neutral-dark-01;
        height: auto;
        @include desktop-min-width {
            font-size: 14px;
            line-height: 16px;
        }
        .product-name {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
        .brand-name {
            font-weight: 700;
        }
        .dot {
            height: 100%;
            margin-top: -3px;
            margin-left: 2px;
            margin-right: 2px;
            @include desktop-min-width {
                margin-top: -5px;
                margin-left: 2px;
                margin-right: 2px;
            }
            &::before {
                content: "";
                font-weight: 700;
                height: 4px;
                width: 4px;
                background: $black;
                border-radius: 50%;
            }
        }
    }
</style>
