<script lang="ts" context="module">
    import { getAppConfig } from "../../../../common/config/app-config";
    import { getContextedUrl, getStr } from "../../../../common/util";
    import {
        getServiceBlockForCode,
        getServiceProductCodes,
        jsVasDetailsClickHandler,
    } from "./atc-utils";
    import AtcFormatPrice from "./AtcFormatPrice.svelte";
    import AtcVasPrimePriceDetails from "./AtcVasPrimePriceDetails.svelte";

    const { lang, currency, extraThemeResourcePath } = getAppConfig();
    const imageBaseUrl = `${extraThemeResourcePath}/images`;

    const extra_service_store_device = `${imageBaseUrl}/extra_service_store_device.svg`;
    const extra_service_unpack_device = `${imageBaseUrl}/extra_service_unpack_device.svg`;
</script>

<script lang="ts">
    // export let productattributes;
    export let entry;
    export let product;
    export let parentEntryNumber;
    export let selectedServices;
    // export let childEntries;
    export let loopindex;
    export let showLogo;

    let servicesBlockContent = "";
    let servicesBlockImage = "";
    let servicesBlockLabel = "";
    let servicesBlockLink = "";

    $: if (product?.servicesBlocks?.length > 0) {
        ({
            servicesBlockContent,
            servicesBlockImage,
            servicesBlockLabel,
            servicesBlockLink,
        } = getServiceBlockForCode(
            product.servicesBlocks,
            "servicesExtraCareCoatingPDPContent",
        ));
    }

    function getServiceDetails(selectedServices, vasPrice) {
        if (selectedServices?.length > 0) {
            const selectedService =
                selectedServices?.find(
                    (selectedService) =>
                        selectedService.vasType === vasPrice?.vasType?.code &&
                        selectedService?.priceType === vasPrice?.priceType,
                ) || null;
            return {
                vasOptionSelected: !!selectedService ? "selected" : "",
                selectedEntrynumber: selectedService?.entryNumber || null,
            };
        }
        return {
            vasOptionSelected: "",
            selectedEntrynumber: null,
        };
    }
</script>

<div class="row-flex extra-care-service-details">
    {#if showLogo}
        <div class="col-flex-xs-1 col-flex-md-1">
            <img alt="Extracarepack" src={servicesBlockImage} />
        </div>
    {/if}
    <div class="col-flex-xs-4 col-flex-md-3 c_extra-service-label">
        {servicesBlockLabel}
    </div>
    <div class="col-flex-xs-12 col-flex-md-12 c_extra-vas-content">
        <span>
            {@html servicesBlockContent}
        </span>
    </div>
    <div class="col-flex-xs-12 col-flex-md-12 c_extra-vas-link">
        <a href={servicesBlockLink} target="_blank">
            {getStr("extra.vas.service.learn.more")}
        </a>
    </div>
    <div
        class="col-flex-xs-12 col-flex-md-12 c_vas-details-items c_vas-details-coating js-vas-group-addtocart-{entry.vasGroup}"
    >
        {#each entry.vasPrices as vasPrice, vasPriceIndex}
            {@const serviceProductCodes = getServiceProductCodes(
                vasPrice,
                entry,
            )}
            {@const { vasOptionSelected, selectedEntrynumber } =
                getServiceDetails(selectedServices, vasPrice)}
            <button
                class="c_vas-details-list extra-care-coating js-vas-details-svelte"
                on:click={jsVasDetailsClickHandler}
                class:selected={vasPrice?.defaultWarranty ||
                    vasOptionSelected?.length > 0}
                data-item-add="false"
                data-type={servicesBlockLabel}
                data-service="coating"
                data-source="Cart Popup"
                data-plan={vasPrice.vasType.label}
                data-serviceid={vasPriceIndex + 1}
                data-price="{vasPrice?.servicePrice?.formattedValue} {currency}"
                data-productcode={product.code}
                data-entryNumber="extra-care-coating-{parentEntryNumber}"
            >
                <div class="c_vas-details-list-button">
                    <form
                        id="vasAddToCartForm{product.code}{vasPrice.productCodes}{vasPriceIndex}-{loopindex}"
                        action={getContextedUrl("/cart/vas/add")}
                        modelAttribute="vasAddtocartForm"
                        method="post"
                        class="c_form js-formValidation js-vas-addtocart-form"
                        data-ajax="true"
                        data-submit="vasaddtocart"
                    >
                        <div
                            id="test_vasAddToCartButton_$"
                            style="display:inline"
                        >
                            <input
                                type="hidden"
                                name="vasProductCode"
                                value={vasPrice.productCodes}
                            />
                            <input
                                type="hidden"
                                name="vasType"
                                value={vasPrice.vasType.code}
                            />
                            <input
                                type="hidden"
                                name="priceType"
                                value={vasPrice.priceType}
                            />
                            <input
                                type="hidden"
                                name="priceTo"
                                value={vasPrice.priceTo}
                            />
                            <input
                                type="hidden"
                                name="productCode"
                                value={product.code}
                            />
                            <input
                                type="hidden"
                                name="parentEntryNumber"
                                value={parentEntryNumber}
                            />
                            <input type="hidden" name="qty" value="1" />
                            <input
                                type="hidden"
                                name="showApplyService"
                                value={entry.showApplyService}
                            />
                            <input
                                type="hidden"
                                name="vasEntryShowService"
                                value={entry.showApplyService}
                            />
                            <input
                                type="hidden"
                                name="vasGroup"
                                value={entry.vasGroup}
                            />
                            <input
                                type="hidden"
                                name="serviceGroupNumber"
                                value=""
                            />
                            {#each serviceProductCodes as code}
                                {@const freeGiftData =
                                    vasPrice?.serviceFreeGiftMap[code] || null}
                                {#if !!freeGiftData}
                                    {#each freeGiftData.freeGiftProducts as freeGift}
                                        <input
                                            type="hidden"
                                            name="freeGiftCodesMap[{code}]"
                                            value={freeGift.code}
                                        />
                                        <input
                                            type="hidden"
                                            name="freeGiftRuleIdMap[{code}]"
                                            value={freeGiftData.freeGiftRuleId}
                                        />
                                    {/each}
                                {/if}
                            {/each}
                            <input
                                type="hidden"
                                name="entryNumber"
                                value=""
                                id="vasEntryNumber"
                                class="js-vasEntryNumber"
                            />
                            <button
                                type="button"
                                id="vas-button-{product.code}{vasPrice.productCodes}{vasPriceIndex}"
                                aria-labelledby="vas-button-{product.code}{vasPrice.productCodes}{vasPriceIndex} vasAddToCartForm{product.code}{vasPrice.productCodes}{vasPriceIndex}"
                                class="c_vas-details-list-button-vas extracare"
                            >
                            </button>
                        </div>
                    </form>
                </div>
                <div class="c_vas-details-coating-info">
                    <div
                        class="c_vas-details-list-year c_vas-details-list-year"
                    >
                        {vasPrice.vasType.label}
                    </div>
                    {#if vasPrice.percentageDiscount !== null}
                        <span class="c_product-details-title-reduction">
                            {#if lang === "en"}
                                {vasPrice.percentageDiscount.value}%&nbsp;<span
                                    >{getStr(
                                        "product.pourcentageDiscount.off",
                                    )}</span
                                >
                            {:else}
                                %{vasPrice.percentageDiscount.value}&nbsp;<span
                                    >{getStr(
                                        "product.pourcentageDiscount.off",
                                    )}</span
                                >
                            {/if}
                        </span>
                    {/if}
                    {#if vasPrice.discountedPrice !== null}
                        <div class="c_product-price-prices">
                            <div class="c_product-price-was-is">
                                <span class="c_product-price-previous"
                                    >{vasPrice.servicePrice.value}&nbsp;
                                    {currency}
                                </span>
                            </div>
                            <div class="c_product-price-current">
                                <AtcFormatPrice
                                    priceData={vasPrice.discountedPrice}
                                    displayFreeForZero={true}
                                    addCurrency={true}
                                />
                            </div>
                        </div>
                    {:else}
                        <div class="c_vas-details-list-price">
                            <AtcFormatPrice
                                priceData={vasPrice.servicePrice}
                                displayFreeForZero={true}
                                addCurrency={true}
                            />
                        </div>
                        <AtcVasPrimePriceDetails {vasPrice} />
                    {/if}
                    {#each serviceProductCodes as code}
                        {@const freeGiftData =
                            vasPrice.serviceFreeGiftMap[code] || null}
                        {#if !!freeGiftData}
                            {@const sourceQty =
                                freeGiftData.freeGiftQtuantities["sourceQty"]}
                            {@const targetQty =
                                freeGiftData.freeGiftQtuantities["targetQty"]}
                            <div
                                class="hidden-xs hidden-sm c_gift-items js-freegiftexist"
                                class:js-freegiff-set-fixed={sourceQty ===
                                    targetQty}
                                class:c_gift-items-fixed={sourceQty ===
                                    targetQty}
                            >
                                <div class="c_gift-items-title">
                                    {#if sourceQty < targetQty}
                                        <span class="c_gift-items-title--color">
                                            {getStr(
                                                "product.promotion.freegifts.display.lower.txt",
                                                [sourceQty],
                                            )}
                                        </span>
                                    {:else}
                                        <span class="c_gift-items-title--color">
                                            {getStr(
                                                "text.fixed.freegift.count.display.txt",
                                                [sourceQty],
                                            )}
                                        </span>&nbsp;{getStr(
                                            "product.promotion.freegifts.item.worth.txt",
                                        )}&nbsp;
                                        <span
                                            class="c_gift-items-title--color mobile-font"
                                            >{freeGiftData.freeGiftWorth}
                                            {currency}</span
                                        >
                                    {/if}
                                </div>
                            </div>
                        {/if}
                    {/each}
                </div>
                {#each serviceProductCodes as code}
                    {@const freeGiftData = vasPrice.serviceFreeGiftMap[code]}
                    {#if !!freeGiftData}
                        {@const sourceQty =
                            freeGiftData.freeGiftQtuantities["sourceQty"]}
                        {@const targetQty =
                            freeGiftData.freeGiftQtuantities["targetQty"]}
                        <div
                            class="hidden-md hidden-lg c_gift-items js-freegiftexist"
                            class:js-freegiff-set-fixed={sourceQty ===
                                targetQty}
                            class:c_gift-items-fixed={sourceQty === targetQty}
                        >
                            <div class="c_gift-items-title">
                                {#if sourceQty < targetQty}
                                    <span class="c_gift-items-title--color">
                                        {getStr(
                                            "product.promotion.freegifts.display.lower.txt",
                                            [sourceQty],
                                        )}
                                    </span>
                                {:else}
                                    <span class="c_gift-items-title--color">
                                        {getStr(
                                            "text.fixed.freegift.count.display.txt",
                                            [sourceQty],
                                        )}
                                    </span>&nbsp;{getStr(
                                        "product.promotion.freegifts.item.worth.txt",
                                    )}&nbsp;
                                    <span
                                        class="c_gift-items-title--color mobile-font"
                                        >{freeGiftData.freeGiftWorth}
                                        {currency}</span
                                    >
                                {/if}
                            </div>
                        </div>
                    {/if}
                {/each}
            </button>
        {/each}
    </div>
    <div
        class="apply-service-content-blog js-apply-service-option-{product.code}-extra-care-coating-{parentEntryNumber}"
    >
        <div class="row-flex c_apply-service-title">
            {product.attributeLabels.extraContentApplyService}
        </div>
        <div class="row-flex c_apply-service">
            <div
                data-applyservice="false"
                data-entrynumber={parentEntryNumber}
                data-vasGroup={entry.vasGroup}
                class="col-flex-xs-5 col-flex-sm-5 col-flex-md-5 c_apply-service-content js-apply-service-content selected"
            >
                <span class="selected-icon"></span>
                <img alt="ExtraSetup" src={extra_service_store_device} />
                <div class="c_apply-service-info">
                    {product.attributeLabels.extraTextNotApplyService}
                </div>
            </div>
            <div class="c_apply-service-or-divider">or</div>
            <div
                data-applyservice="true"
                data-entrynumber={parentEntryNumber}
                data-vasGroup={entry.vasGroup}
                class="col-flex-xs-5 col-flex-sm-5 col-flex-md-5 c_apply-service-content js-apply-service-content"
            >
                <span class="selected-icon"></span>
                <img alt="ExtraSetup" src={extra_service_unpack_device} />
                <div class="c_apply-service-info">
                    {product.attributeLabels.extraTextApplyService}
                </div>
            </div>
        </div>
    </div>
</div>
