<script context=module lang=ts>
    import { PriceSplitProviderType } from "../../../lazy-components/driver/common-mapper";
    export type PriceSplitObjectType = { providerName: PriceSplitProviderType, imageUrl: string };

    const userJoodLevel = getJoodMembershipType(); 
    const isResponsive = isMobile();
    const title = isResponsive
        ? getStr("extra.split.it.way")
        : getStr("extra.split.price.provider.title");
</script>

<script lang="ts">
    import { getAppConfig } from "../../../../../common/config/app-config";
    import {
        getJoodMembershipType,
        getStr,
        isMobile,
    } from "../../../../../common/util";
    import type { ProductTilePriceGroupVariantType } from "./ProductTilePriceNewGroup.svelte";

    export let variant: ProductTilePriceGroupVariantType;
    export let algoliaProductHit;

    const {
        countryCode,
        algoliaConfig: {
            tasheelInfo: { tasheelImageUrl, tasheelEnabled },
        },
    } = getAppConfig();
    const tabbyImageLogoUrl = getStr("tabbyImageLogoUrl");
    const tamaraImageLogoUrl = getStr("tamaraImageLogoUrl");

    $: providers = getProviderListFromAlgoliaHit(algoliaProductHit);
    $: visible = providers?.length > 0;

    function getProviderListFromAlgoliaHit(
        algoliaProductHit,
    ): PriceSplitObjectType[] {
        const providers: PriceSplitObjectType[] = [];

        // baseeta / tasheel
        if (tasheelEnabled && algoliaProductHit?.priceValueTasheel > 0) {
            providers.push(getProvider("baseeta"));
        }

        // tamara
        const {
            plpEligibleForTamara,
            plpEligibleForTamaraBlue,
            plpEligibleForTamaraGold,
        } = algoliaProductHit;
        let targetFlag = plpEligibleForTamara;
        if (userJoodLevel === "BASICPRIME")
            targetFlag = plpEligibleForTamaraBlue;
        else if (userJoodLevel === "VIPPRIME")
            targetFlag = plpEligibleForTamaraGold;

        if (countryCode === "SA" && targetFlag) {
            providers.push(getProvider("tamara"));
        }

        // tabby
        if (algoliaProductHit?.plpEligibleForTabby) {
            providers.push(getProvider("tabby"));
        }

        return providers;
    }
    function getProvider(
        providerName: PriceSplitProviderType,
    ): PriceSplitObjectType {
        let imageUrl = "";

        switch (providerName) {
            case "baseeta":
                imageUrl = tasheelImageUrl;
                break;
            case "tamara":
                imageUrl = tamaraImageLogoUrl;
                break;
            case "tabby":
                imageUrl = tabbyImageLogoUrl;
                break;
        }

        return { providerName, imageUrl };
    }
</script>

{#if visible && countryCode !== "OM"}
    <section
        class="product-split-price-card-container variant-{variant}"
        class:single-provider={providers.length == 1}
    >
        <h5>{title}</h5>
        <section class="provider-list">
            {#each providers as provider}
                <section class="provider">
                    <img
                        src={provider.imageUrl}
                        class="img-{provider.providerName}"
                        alt={provider.providerName}
                    />
                </section>
            {/each}
        </section>
    </section>
{/if}

<style lang="scss">
    @import "../../../styles/colors.new.scss";

    .product-split-price-card-container {
        flex-grow: 1;
        border-radius: 8px;
        padding: 8px 16px;
        background-color: $neutral-light-04;

        @include responsive {
            padding: 4px 8px;
            flex-basis: 100%;
        }
        &.single-provider {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        h5 {
            padding: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            color: $neutral-dark-01;
            margin-bottom: 8px;

            @include responsive {
                font-size: 10px;
                line-height: 11px;
                margin-bottom: 2px;
            }
        }
        .provider-list {
            display: flex;
            align-items: center;

            .provider {
                padding: 0 16px;

                @include responsive {
                    padding: 0 10px;
                }

                &:first-of-type {
                    padding-left: 0;
                }
                &:last-of-type {
                    padding-right: 0;
                }
                &:not(:first-of-type) {
                    border-left: 1px solid $secondary-gray-02;
                }

                .img-tamara,
                .img-tabby,
                .img-baseeta {
                    height: 25px;
                    object-fit: contain;

                    @include responsive {
                        height: 20px;
                    }
                }
            }
        }

        &.variant-plp-list {
            @include responsive {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            h5 {
                @include responsive {
                    font-size: 12px;
                }
            }
        }
    }
</style>
