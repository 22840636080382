<script lang="ts">
    export let text = "";
    export let recommendationType = 1;

    let recommendationClassName = "bestSelling";
    if (Number(recommendationType) == 2) {
        recommendationClassName = "popular";
    }
</script>

<div class="recommendation-subtitle {recommendationClassName}">
    {@html text}
</div>

<style lang="scss">
    @import "../../../../common/styles/colors.new.scss";

    .recommendation {
        &-subtitle {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            @include desktop-min-width {
                font-size: 18px;
                line-height: 20px;
            }
            &.popular {
                color: $primary-white;
            }
            .highlight {
                font-weight: 700;
            }
        }
    }
</style>
