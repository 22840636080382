<script lang="ts">
    import LayoutGrid, { Cell } from "@smui/layout-grid";
    import Text from "../../../common/components/Text.svelte";
    import PictureTag from "../../../common/lazy-components/PictureTag.svelte";
    import { getAppConfig } from "../../../../common/config/app-config";
    import AtcPrice from "./AtcPrice.svelte";
    import AtcTasheel from "./AtcTasheel.svelte";
    import AtcBrandName from "./AtcBrandName.svelte";
    import { getJoodMembershipType } from "../../../../common/util";
    import AtcJoodDetails from "./AtcJoodDetails.svelte";
    import AtcGifts from "./AtcGifts.svelte";

    export let cartData;
    const { lang, primeEnabled } = getAppConfig();
    $: basePrice = cartData?.entry?.basePrice?.value;
    $: totalPrice =
        cartData?.entry?.totalPrice?.value / cartData?.entry?.quantity;
    $: cashBackPrice =
        cartData?.entry?.cashBackPrice?.value / cartData?.entry?.quantity;
    const productVipPrimeData =
        cartData?.entry.product?.productVipPrimeData || {};
    const productBasicPrimeData =
        cartData?.entry.product?.productBasicPrimeData || {};
    let showPopup = false;
    $: showPopup =
        primeEnabled &&
        (productVipPrimeData.primePrice?.value > 0 ||
            productBasicPrimeData.primePrice?.value > 0);
    const userPrimeLevel = getJoodMembershipType();
</script>

<section class="add-to-cart-header-section">
    <LayoutGrid>
        <Cell
            class="add-to-cart-item c_add-to-cart-popup-header"
            spanDevices={{ phone: 4, tablet: 8, desktop: 12 }}
        >
            <span class="c_icon c_icon--tick"></span>
            <div class="title">
                <Text key={"extra.addtocart.greatchoice"} />
                {#if cartData?.bundleQtyAdded > 0}
                    <Text key={"extra.addtocart.bundle.added"} />
                {/if}
            </div>
        </Cell>
        <Cell
            class="add-to-cart-item c_add-to-cart-popup-item"
            spanDevices={{ phone: 4, tablet: 8, desktop: 12 }}
        >
            <div class="c_add-to-cart-popup-item-inner">
                {#if !cartData?.bundleQtyAdded}
                    <div class="thumb c_add-to-cart-popup-item-img">
                        <a href={cartData?.entry?.product?.url}>
                            <PictureTag
                                image={cartData?.entry?.product
                                    ?.amplienceProductBaseUrl}
                                alt="Product-image"
                                width={"100%"}
                                height={"100%"}
                            />
                        </a>
                    </div>
                {/if}
                <div class="details c_add-to-cart-popup-item-details">
                    {#if cartData?.bundleQtyAdded > 0}
                        <div class="c_add-to-cart-popup-bundle">
                            {#if cartData?.entries}
                                {#each cartData.entries as modific}
                                    <div
                                        class="c_add-to-cart-popup-bundle-item col-md-3 col-xs-3"
                                    >
                                        <div
                                            class="thumb c_add-to-cart-popup-bundle-img"
                                        >
                                            <a href={modific?.product?.url}>
                                                <PictureTag
                                                    image={modific?.product
                                                        ?.amplienceProductBaseUrl}
                                                    alt="Product-image"
                                                    width={"100%"}
                                                    height={"100%"}
                                                />
                                            </a>
                                        </div>
                                        <span>{modific.product.name}</span>
                                    </div>
                                {/each}
                            {/if}
                        </div>
                        <div class="c_vas-details-divider row-flex"></div>
                    {:else}
                        <AtcBrandName {cartData} />
                        <div class="c_add-to-cart-popup-item-price">
                            <AtcPrice entry={cartData?.entry} />
                        </div>
                        <AtcTasheel entry={cartData?.entry} />
                        <AtcGifts entry={cartData?.entry} />
                        {#if showPopup}
                            <div
                                class="c_joodcart-wrapper c_joodprimecart c_joodcart jood-cart-popup js-jood-cart-popup jood-addtocart-popup"
                            >
                                {#if primeEnabled}
                                    {#if userPrimeLevel === "VIPPRIME" && productVipPrimeData.primePrice?.value > 0}
                                        <AtcJoodDetails
                                            product={cartData?.entry?.product}
                                            variant="pdp"
                                            {basePrice}
                                            joodTypePDPClass="jood-vip-subscriber"
                                            joodType="VIP"
                                        />
                                    {:else if userPrimeLevel === "BASICPRIME"}
                                        {#if productBasicPrimeData.primePrice?.value > 0}
                                            <AtcJoodDetails
                                                product={cartData?.entry
                                                    ?.product}
                                                variant="pdp"
                                                joodType="BASIC"
                                                {basePrice}
                                                joodTypePDPClass="jood-basic-subscriber"
                                            />
                                        {/if}
                                        {#if productVipPrimeData.primePrice?.value > 0}
                                            <AtcJoodDetails
                                                product={cartData?.entry
                                                    ?.product}
                                                variant="pdp"
                                                joodType="VIP"
                                                {basePrice}
                                                joodTypePDPClass="jood-basic-subscriber-gold-card"
                                                showGoldTerms={true}
                                            />
                                        {/if}
                                    {:else if productVipPrimeData.primePrice?.value > 0}
                                        <AtcJoodDetails
                                            product={cartData?.entry?.product}
                                            variant="pdp"
                                            joodType="VIP"
                                            joodTypePDPClass="jood-guest"
                                            {basePrice}
                                            showGoldTerms={true}
                                        />
                                    {/if}
                                {/if}
                            </div>
                        {/if}
                    {/if}
                </div>
            </div>
        </Cell>
    </LayoutGrid>
</section>

<style lang="scss">
    @import "../../../common/styles/colors.new.scss";

    .add-to-cart-header-section {
        :global {
            .mdc-layout-grid {
                padding: 0;
            }
            .mdc-layout-grid__inner {
                gap: 0;
            }
        }
    }
</style>
