import { getAppConfig } from "../../../../common/config/app-config";

export function getServiceBlockForCode(servicesBlocks, targetCode) {
    const servicesBlock = servicesBlocks?.find(
        (x) => x.code === targetCode,
    );
    if (!!servicesBlock) {
        return {
            servicesBlockContent: servicesBlock.content,
            servicesBlockImage: servicesBlock.imageUrl,
            servicesBlockLabel: servicesBlock.label,
            servicesBlockLink: servicesBlock.url
        };
    }
    return null;
};

export function getServiceProductCodes(vasPrice, entry) {
    if (vasPrice?.productCodes?.length > 0) {
        return vasPrice.productCodes.split(",");
    }
    return entry?.serviceProductCode?.split(",") || [];
}

// variables ported from window.ACC.vasaddtocart.js to support svelte addToCart popup
const classSelected = 'selected';
const selectorVasPrice = '.js-vas-cart-price';
const selectorAddToCartLmdService = '.js-apply-lmd-service';
const selectorAcNotes = '.js-ac-notes';
const selectorButton = '.js-vas-details-svelte';
const selectorAcFloorSelect = '.js-ac-floor-select';
const selectorVasSelect = '.js-vas-change';
const selectorVasCartPrice = '.js-vas-current-price';
let isAcServiceClicked = false;


/* methods ported from window.ACC.vasaddtocart.js to support svelte addToCart popup START */
export function jsVasDetailsClickHandler(event) {
    const currentTarget = event.currentTarget;
    
    currentTarget
    .closest('.c_vas-details-items')
    .querySelectorAll(`.js-vas-details-svelte.${classSelected}`)
    .forEach(x => x.classList.remove(classSelected));
    
    const data = currentTarget.dataset;
    if (data.itemAdd !== 'true') {
        currentTarget.classList.add(classSelected);
    } else {
        currentTarget.classList.remove(classSelected);
    }
    
    const formId = currentTarget.querySelector('form')?.getAttribute('id');
    isAcServiceClicked = false;
    submitForm(formId, currentTarget);
}

export function submitForm(formId: string, currentTarget: HTMLDivElement) {
    window.ACC.loader.showLoader();
    const form = <HTMLFormElement>document.getElementById(formId);
    const data = currentTarget.dataset;
    const vasType = (new FormData(form))?.get('vasType');
    const { pageId, currency } = getAppConfig();
    
    submitFormUsingFetchAPI(formId, { defaultWarranty: data.itemAdd })
        .then(response => {
            var entry = response.entry;
            var status = response.statusCode;
            var cartTotal = response.cartTotal;
            window.ACC.vasaddtocart.var.productData = response.cartData.entries[0].product;
            window.ACC.extragtm.click.serviceSelectionAction('extra_service',data.type, vasType, '');
            if (status === 'success' || status === 'VasEntryUpdated') {
                window.ACC.mixpanel.trackServiceAddedToCart(window.ACC.vasaddtocart.var.productData, data.type, data.source, data.price, data.plan, data.addon, data.serviceid, response.cartData.mandateServiceSelectionEnabled);
            }
            console.log('Test console log');
            document.querySelector(selectorVasPrice).innerHTML = `${currency} <strong>${cartTotal}</strong>`;
            handleServiceSelection(currentTarget);
            // $(window.ACC.vasaddtocart.selector.vasPrice)?.html(cartTotal);
            if ((entry !== null) && (status === 'success')) {
                
                const addToCartLMDServiceList = document.querySelectorAll(selectorAddToCartLmdService);
                if(entry.vasGroup !== null && entry.vasGroup === 'EXTRA_SETUP' && addToCartLMDServiceList.length > 0)
                {
                    addToCartLMDServiceList.forEach(element => element.classList.remove('disabled'));
                } 
                if(entry.entryType === "SPLITACSERVICE")
                {
                    currentTarget
                        .closest('.c_vas-details-items')
                        .querySelector<HTMLInputElement>('input[name="entryNumber"]')
                        .value = entry.serviceGroupNumber;

                    if (pageId === 'productDetails') {
                        const vasFloorOptionsNextSibling = currentTarget
                            .closest('.vas-floor-options')
                            .nextElementSibling;
                        const closestVasMountLabels = currentTarget.closest('.vas-mount-labels');

                        vasFloorOptionsNextSibling.classList.remove('hide');
                        if (data.onwall === 'true') {
                            closestVasMountLabels.querySelector('.js-ac-change-svelte form button')?.setAttribute('data-item-add','false');
                            closestVasMountLabels.querySelector('.js-ac-wall-select-svelte')?.classList.add(classSelected);
                            closestVasMountLabels.querySelector('.js-ac-change-svelte')?.classList.remove(classSelected);
                            vasFloorOptionsNextSibling.querySelector('.c_vas-details-floor-notes-text')?.classList.add('hide');
                            vasFloorOptionsNextSibling.querySelector('.c_vas-details-floor-notes-text')?.classList.remove('show');
                            vasFloorOptionsNextSibling.querySelector('.c_vas-details-wall-notes-text')?.classList.remove('hide');
                        } else {
                            closestVasMountLabels.querySelector('.js-ac-wall-select-svelte')?.classList.remove(classSelected);
                            currentTarget.closest('.js-ac-change-svelte')?.classList.add(classSelected);
                            vasFloorOptionsNextSibling.querySelector('.c_vas-details-wall-notes-text')?.classList.add('hide');
                            vasFloorOptionsNextSibling.querySelector('.c_vas-details-wall-notes-text')?.classList.remove('show');
                            vasFloorOptionsNextSibling.querySelector('.c_vas-details-floor-notes-text')?.classList.remove('hide');
                        }
                        
                        currentTarget.closest('.js-ac-change-svelte')?.classList.add(classSelected);
                        // if (currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-vasprice') === '0') {
                        //     currentTarget.closest('.vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-floor-select .vas-ac-floor-price').innerHTML = ("<strong class='c_cart-totals--rgtclr free'>" + currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-free') + "</strong>");
                        // } else {	
                        //     currentTarget.closest('.vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-floor-select .vas-ac-floor-price').innerHTML = ("<strong>" + currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-vasprice') + "</strong>");
                        // }
                        // currentTarget.closest('.vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-wall-select-svelte .vas-ac-wall-price').innerHTML = ("<strong>" + currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-wallmountprice') + "</strong>");
                        currentTarget.closest('.vas-floor-options')?.parentElement.querySelector('.c_vas-cart-details-mounts')?.classList.remove('hide');
                        if (response && response.acSetupVasTypes === null) {
                            currentTarget.closest('.c_vas-details-items').querySelector<HTMLInputElement>('form input[name="acSetupIndex"]').value = '0';
                            currentTarget.closest('.c_vas-details-items').querySelector<HTMLInputElement>('form input[name="previousVasType"]').value = '';
                            currentTarget.setAttribute('data-item-add','false');
                            if (currentTarget.getAttribute('data-acSetupAddedAsFree') === 'false') {
                                currentTarget.closest('.vas-floor-options')?.parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-floor-select .currency')?.classList.remove('hide');
                                currentTarget.closest('.js-ac-change-svelte.selected .vas-floor-options')?.parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-wall-select-svelte .currency')?.classList.remove('hide');
                            }
                            currentTarget.closest('.vas-floor-options')?.parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-wall-select-svelte .default-selected-price')?.classList.remove('hide');
                        }
                    }
                }
                else
                {
                    if (currentTarget.closest('.c_vas-details-items')?.parentElement.querySelectorAll('.c_combined-vas .js-addons')?.length) {
                        var addons = currentTarget.closest('.c_vas-details-items')?.parentElement.querySelectorAll('.c_combined-vas .js-addons');
                        var addonsMsg = currentTarget.closest('.c_vas-details-items')?.parentElement.querySelectorAll('.c_combined-vas .c_add-ons-msg');
                        for (var key in addons) {
                            if (addons.hasOwnProperty(key)) {
                                if (currentTarget.getAttribute('data-vasType') === addons[key].getAttribute('data-vasType')) {
                                    addons[key].classList.remove('hide');
                                } else{
                                    addons[key].classList.add('hide');
                                }
                                if (currentTarget.getAttribute('data-vasType') === addonsMsg[key].getAttribute('data-vasType')) {
                                    addonsMsg[key].classList.remove('hide');										
                                } else{
                                    addonsMsg[key].classList.add('hide');
                                    addonsMsg[key].classList.remove('show');
                                }
                            }
                        }
                        currentTarget.closest('.c_vas-details-items').parentElement.querySelector<HTMLInputElement>('.c_combined-vas .js-addons input[name="entryNumber"]').value = (entry.entryNumber);
                        currentTarget.closest('.c_vas-details-items').parentElement.querySelector<HTMLInputElement>('.c_combined-vas .js-addons input[name="serviceGroupNumber"]').value = (entry.serviceGroupNumber);
                    }
                    if (currentTarget.closest('.c_vas-details-content')?.classList.contains('no-plan-selected')) {
                        currentTarget.closest('.c_vas-details-content')?.classList.remove('no-plan-selected');
                    }
                    if (currentTarget.getAttribute('data-vasType') === 'noPlan') {
                        currentTarget.closest('.c_vas-details-content .js-apply-services .col-flex-md-12')?.classList.add('hide');
                        currentTarget.closest('.c_vas-details-content .c_no-service-div')?.classList.remove('hide');
                        currentTarget.closest('.c_vas-details-content').parentElement.querySelector<HTMLDivElement>('.no-plan-header').innerText = (currentTarget.closest('.c_vas-details-content')?.getAttribute('data-noplan'));
                        currentTarget.closest('.c_vas-details-content')?.classList.add('no-plan-selected');
                    } else {
                        currentTarget.closest('.c_vas-details-content .js-apply-services .col-flex-md-12')?.classList.remove('hide');
                        currentTarget.closest('.c_vas-details-content .c_no-service-div')?.classList.add('hide');
                        currentTarget.closest('.c_vas-details-content').parentElement.querySelector<HTMLDivElement>('.no-plan-header').innerText = (currentTarget.closest('.c_vas-details-content')?.getAttribute('data-selection'));
                        currentTarget.closest('.c_vas-details-content')?.classList.remove('no-plan-selected');
                    }
                    currentTarget.closest('.c_vas-details-items')?.parentElement.querySelector('.c_combined-vas')?.classList.remove('hide');
                    const inputs = currentTarget.closest('.c_vas-details-items').querySelectorAll<HTMLInputElement>('[name="entryNumber"], [name="serviceGroupNumber"]');
                    inputs.forEach(x => x.value = x.name === 'entryNumber' ? entry.entryNumber : entry.serviceGroupNumber);
                }
                const _nextSibling = currentTarget.closest('.c_vas-details-items')?.nextElementSibling;
                if (_nextSibling && _nextSibling.classList.contains('js-vas-free-gift-template')) _nextSibling.remove();

                // $($("#vasFreeGiftTemplate")?.tmpl(response))?.insertAfter(currentTarget.closest('.c_vas-details-items')); // @@7749: Todo 
                
            } else if (status === 'VasEntryUpdated') {
                if (pageId === 'productDetails' || pageId === 'search' || pageId === 'productList' || pageId === 'homepage') {
                    if (isAcServiceClicked) {
                        const closestVasMountLabels = currentTarget.closest('.vas-mount-labels');
    
                        currentTarget.closest('.c_vas-details-items')?.querySelector(selectorButton)?.setAttribute('data-item-add','false');
                        currentTarget.closest('.c_vas-details-items')?.parentElement.querySelector(selectorAcNotes)?.classList.remove('hide');
                        // if (currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-vasprice') === '0') {
                        //     currentTarget.closest('.vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-floor-select .vas-ac-floor-price').innerHTML = ("<strong class='c_cart-totals--rgtclr free'>" + currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-free') + "</strong>");
                        // } else {	
                        //     currentTarget.closest('.vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-floor-select .vas-ac-floor-price').innerHTML = ("<strong>" + currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-vasprice') + "</strong>");
                        // }
                        // currentTarget.closest('.vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-wall-select-svelte .vas-ac-wall-price').innerHTML = (window.ACC.config.currency + " " + "<strong>" + currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-wallmountprice') + "</strong>");
                        currentTarget.closest('.vas-floor-options')?.parentElement.querySelector('.c_vas-cart-details-mounts')?.classList.remove('hide');
                        if (currentTarget.getAttribute('data-onwall') === 'true') {
                            closestVasMountLabels.querySelector('.js-ac-wall-select-svelte')?.classList.add(classSelected);
                            closestVasMountLabels.querySelector('.js-ac-change-svelte')?.classList.remove(classSelected);
                            currentTarget.closest('.vas-floor-labels')?.querySelector(`${selectorAcNotes} .c_vas-details-floor-notes-text`)?.classList.add('hide');
                            currentTarget.closest('.vas-floor-labels')?.querySelector(`${selectorAcNotes} .c_vas-details-floor-notes-text`)?.classList.remove('show');
                            currentTarget.closest('.vas-floor-labels')?.querySelector(`${selectorAcNotes} .c_vas-details-wall-notes-text`)?.classList.remove('hide');
                            
                        } else {
                            closestVasMountLabels.querySelector('.js-ac-wall-select-svelte')?.classList.remove(classSelected);
                            currentTarget.closest('.js-ac-change-svelte')?.classList.add(classSelected);
                            currentTarget.closest('.vas-floor-labels')?.querySelector(`${selectorAcNotes} .c_vas-details-wall-notes-text`)?.classList.add('hide');
                            currentTarget.closest('.vas-floor-labels')?.querySelector(`${selectorAcNotes} .c_vas-details-wall-notes-text`)?.classList.remove('show');
                            currentTarget.closest('.vas-floor-labels')?.querySelector(`${selectorAcNotes} .c_vas-details-floor-notes-text`)?.classList.remove('hide');
                        }
                        if (response && response.acSetupVasTypes === null) {
                            currentTarget.closest('.c_vas-details-items').querySelector<HTMLInputElement>('form input[name="acSetupIndex"]').value = '0';
                            currentTarget.closest('.c_vas-details-items').querySelector<HTMLInputElement>('form input[name="previousVasType"]').value = '';
                        }
                        if (currentTarget.getAttribute('data-acSetupAddedAsFree') !== 'true') {
                            currentTarget.closest('.c_vas-details-items form .js-vas-details-svelte')?.classList.remove(classSelected);
                        }
                    } else{
                        if (currentTarget.closest('.c_vas-details-items')?.parentElement.querySelectorAll('.c_combined-vas .js-addons')?.length) {
                            var updatedAddons = currentTarget.closest('.c_vas-details-items')?.parentElement.querySelectorAll('.c_combined-vas .js-addons');
                            for (var updatedKey in updatedAddons) {
                                if (updatedAddons.hasOwnProperty(updatedKey)) {
                                    if (currentTarget.getAttribute('data-vasType') === updatedAddons[updatedKey].getAttribute('data-vasType')) {
                                        updatedAddons[updatedKey].classList.remove('hide');
                                    } else{
                                        updatedAddons[updatedKey].classList.add('hide');
                                    }
                                }
                            }
                            const parentElement = currentTarget.closest('.c_vas-details-items')?.parentElement;
                            const addons = parentElement?.querySelectorAll('.c_combined-vas .js-addons');
                            const addonMsgs = parentElement?.querySelectorAll('.c_combined-vas .c_add-ons-msg');
                            const vasType = currentTarget.getAttribute('data-vasType');

                            if (addons?.length) {
                                addons.forEach((addon, index) => {
                                    const addonVasType = addon.getAttribute('data-vasType');
                                    const addonMsg = addonMsgs?.[index]; // assuming addons and addonMsgs have matching indices
                                    if (vasType === addonVasType) {
                                        addon.classList.remove('hide');
                                        addonMsg?.classList.remove('hide');
                                    } else {
                                        addon.classList.add('hide');
                                        addonMsg?.classList.add('hide');
                                        addonMsg?.classList.remove('show');
                                    }
                                })
                            };

                            const vasDetailsContent = currentTarget.closest('.c_vas-details-content');
                            const vasDetailsItems = currentTarget.closest('.c_vas-details-items');
                            const parentElem = vasDetailsItems?.parentElement;
                            const combinedVas = parentElem?.querySelector('.c_combined-vas');
                            const applyServices = vasDetailsContent?.querySelector('.js-apply-services');
                            const noServiceDiv = vasDetailsContent?.querySelector('.c_no-service-div');
                            const noPlanHeader = vasDetailsContent?.parentElement?.querySelector<HTMLDivElement>('.no-plan-header');

                            if (currentTarget.getAttribute('data-vasType') === 'noPlan') {
                                combinedVas?.querySelector('.js-addons')?.classList.add('hide');
                                combinedVas?.querySelector('.c_add-ons-msg')?.classList.replace('show', 'hide');
                                vasDetailsContent?.classList.add('no-plan-selected');
                                applyServices?.classList.add('hide');
                                noServiceDiv?.classList.remove('hide');
                                noPlanHeader.innerText = vasDetailsContent?.getAttribute('data-noplan') ?? '';
                            } else {
                                applyServices?.classList.remove('hide');
                                noServiceDiv?.classList.add('hide');
                                noPlanHeader.innerText = vasDetailsContent?.getAttribute('data-selection') ?? '';
                                vasDetailsContent?.classList.remove('no-plan-selected');
                            }
                            
                        }
                        if (currentTarget.getAttribute('data-vasType') === 'noPlan') {
                            currentTarget.closest('.c_vas-details-content .js-apply-services .col-flex-md-12')?.classList.add('hide');
                            currentTarget.closest('.c_vas-details-content').parentElement.querySelector<HTMLDivElement>('.no-plan-header').innerText = (currentTarget.closest('.c_vas-details-content')?.getAttribute('data-noplan'));
                            currentTarget.closest('.c_vas-details-content .c_no-service-div')?.classList.remove('hide');
                            currentTarget.closest('.c_vas-details-content')?.classList.add('no-plan-selected');
                        } else {
                            currentTarget.closest('.c_vas-details-content .js-apply-services .col-flex-md-12')?.classList.remove('hide');
                            currentTarget.closest('.c_vas-details-content').parentElement.querySelector<HTMLDivElement>('.no-plan-header').innerText = (currentTarget.closest('.c_vas-details-content')?.getAttribute('data-selection'));
                            currentTarget.closest('.c_vas-details-content .c_no-service-div')?.classList.add('hide');
                            currentTarget.closest('.c_vas-details-content')?.classList.remove('no-plan-selected');
                        }
                        currentTarget.closest('.c_vas-details-items')?.parentElement.querySelector('.c_combined-vas')?.classList.remove('hide');
                    }
                    
                }
                if (entry !== null){
                    currentTarget.closest('.c_vas-details-items')?.querySelector(selectorButton)?.setAttribute('data-item-add','false');

                    const _nextSibling = currentTarget.closest('.c_vas-details-items')?.nextElementSibling;
                    if (_nextSibling && _nextSibling.classList.contains('js-vas-free-gift-template')) _nextSibling.remove();
                    //$(dataAttri)?.closest('.c_vas-details-items')?.append($("#vasFreeGiftTemplate")?.tmpl(response));
                    // $($("#vasFreeGiftTemplate")?.tmpl(response))?.insertAfter(currentTarget.closest('.c_vas-details-items')); //@@7749 Todo
                }
            } 
            else if (status !== 'VasEntryUpdated' && (status !== 'success')) {

                if (pageId === 'productDetails') {
                    if (!isAcServiceClicked) {
                        currentTarget.closest('.c_vas-details-content .c_no-service-div')?.classList.add('hide');
                        if (currentTarget.getAttribute('data-service') === 'safeguard') {
                            currentTarget.closest('.c_vas-details-content')?.classList.add('no-plan-selected');
                        }
                        currentTarget.closest('.c_vas-details-content').parentElement.querySelector<HTMLDivElement>('.no-plan-header').innerText = (currentTarget.closest('.c_vas-details-content')?.getAttribute('data-noselection'));
                    }
                    if (entry?.entryType === 'SPLITACSERVICE') {
                        const closestVasMountLabels = currentTarget.closest('.vas-mount-labels');
                        const vasFloorOptionsNextSibling = currentTarget
                        .closest('.vas-floor-options')
                        .nextElementSibling;
                        if (currentTarget.getAttribute('data-acSetupAddedAsFree') === 'true') {
                            currentTarget.closest('.js-ac-change-svelte')?.setAttribute('data-item-add','true');
                            currentTarget.setAttribute('data-item-add','true');
                        } else {
                            currentTarget.closest('.js-ac-change-svelte')?.setAttribute('data-item-add','false');
                            currentTarget.setAttribute('data-item-add','false');
                        }
                        currentTarget.closest<HTMLInputElement>('.c_vas-details-items input[name="entryNumber"]').value = '';
                        currentTarget.closest('.js-ac-change-svelte')?.classList.remove('selected');
                        currentTarget.classList.remove('selected');
                        closestVasMountLabels.querySelector('.js-ac-wall-select-svelte')?.classList.remove(classSelected);
                        closestVasMountLabels.querySelector('.js-ac-change-svelte')?.classList.remove(classSelected);
                        if(currentTarget.getAttribute('data-splitAcService') === 'true' ) {
                            currentTarget.closest('.c_vas-details-items')?.parentElement.querySelector(selectorAcNotes)?.classList.add('hide');
                            vasFloorOptionsNextSibling.classList.add('hide');
                        }
                    }
                    if (!currentTarget.getAttribute('data-additional-aws-productCode') && !currentTarget.classList.contains('js-addons') && !currentTarget.classList.contains('js-addons')) {
                        currentTarget.closest('.c_vas-details-items')?.parentElement.querySelector('.c_combined-vas')?.classList.add('hide');
                    }
                    
                    if ((currentTarget.getAttribute('data-type') === 'ESP' || currentTarget.getAttribute('data-vasType') === 'AWS')) {
                        currentTarget.closest('.c_vas-details-content')?.classList.add('no-plan-selected');
                    }
                }
                
                currentTarget.querySelector<HTMLInputElement>('.c_vas-details-items input[name="entryNumber"]').value = '';
                currentTarget.querySelector<HTMLInputElement>('.c_vas-details-items input[name="serviceGroupNumber"]').value = '';
                
                const _nextSibling = currentTarget.closest('.c_vas-details-items')?.nextElementSibling;
                if (_nextSibling && _nextSibling.classList.contains('js-vas-free-gift-template')) _nextSibling.remove();

                currentTarget.closest('.c_vas-details-items')?.classList.remove(classSelected);
                currentTarget.closest('.c_vas-details-items')?.setAttribute('data-item-add','true');
                currentTarget.setAttribute('data-item-add','true');
                
                
                var pageIdIsProductDetails = pageId === 'productDetails';
                if (response.updatedVasEntryNumbers !== null) {
                    if (entry) {
                        if (entry.entryType !== "SPLITACSERVICE") {
                            window.ACC.vasaddtocart.updateEntryNumber(response);
                        }
                    } else if (status === 'VasEntryRemoved' && pageIdIsProductDetails) {
                            window.ACC.vasaddtocart.updateEntryNumber(response);
                    }
                }


            }
            
            if(currentTarget.getAttribute('data-item-add') !== 'true'){
                currentTarget.setAttribute('data-item-add','true');
            }else{
                currentTarget.setAttribute('data-item-add','false');
            }
            if ((status === 'success' || status === 'VasEntryUpdated') && entry === null) {
                if (isAcServiceClicked) {
                    const vasFloorOptionsNextSibling = currentTarget.closest('.vas-floor-options')?.nextElementSibling;
    
                    if (currentTarget.getAttribute('data-acSetupAddedAsFree') === 'true') {
                        currentTarget.querySelector('form').querySelector<HTMLInputElement>('input[name="entryNumber"]').value = '0';
                        currentTarget.querySelector('form').querySelector<HTMLInputElement>('input[name="previousVasType"]').value = '';
                        if (response && response.acSetupVasTypes && response.acSetupVasTypes.length && currentTarget.getAttribute('data-item-add') === 'true') {
                            currentTarget.querySelector('form').querySelector<HTMLInputElement>('input[name="acSetupIndex"]').value = (response.acSetupVasTypes.length - 1)?.toString();
                            currentTarget.querySelector('form').querySelector<HTMLInputElement>('input[name="previousVasType"]').value = (form.querySelector<HTMLInputElement>('input[name="vasType"]')?.value);
                            currentTarget.classList.add(classSelected);
                            currentTarget.setAttribute('data-item-add','true');
                            vasFloorOptionsNextSibling.classList.remove('hide');
                            vasFloorOptionsNextSibling.querySelector('.c_vas-details-floor-notes-text')?.classList.remove('hide');
                            vasFloorOptionsNextSibling.querySelector('.c_vas-details-wall-notes-text')?.classList.add('hide');
                            vasFloorOptionsNextSibling.querySelector('.c_vas-details-wall-notes-text')?.classList.remove('show');
                        } else{
                            currentTarget.classList.remove(classSelected);
                            if (currentTarget.getAttribute('data-onwall') === 'true') {
                                vasFloorOptionsNextSibling.classList.remove('hide');
                            } else {
                                vasFloorOptionsNextSibling.classList.add('hide');
                            }
                        }
                        
                    }else {
                        if (response && response.acSetupVasTypes === null) {
                            vasFloorOptionsNextSibling.querySelector('.c_vas-details-floor-notes-text')?.classList.add('hide');
                            vasFloorOptionsNextSibling.querySelector('.c_vas-details-floor-notes-text')?.classList.remove('show');
                            vasFloorOptionsNextSibling.querySelector('.c_vas-details-wall-notes-text')?.classList.remove('hide');
                            // $(dataAttri)?.closest('.c_vas-details-items form button')?.setAttribute('data-item-add','true');
                            currentTarget.setAttribute('data-item-add','true');
                            vasFloorOptionsNextSibling.classList.remove('hide');
                            if (currentTarget.getAttribute('data-onwall') === 'true') {
                                vasFloorOptionsNextSibling.querySelector('.c_vas-details-floor-notes-text')?.classList.add('hide');
                                vasFloorOptionsNextSibling.querySelector('.c_vas-details-floor-notes-text')?.classList.remove('show');
                                vasFloorOptionsNextSibling.querySelector('.c_vas-details-wall-notes-text')?.classList.remove('hide');
                            } else {
                                vasFloorOptionsNextSibling.querySelector('.c_vas-details-floor-notes-text')?.classList.remove('hide');
                                vasFloorOptionsNextSibling.querySelector('.c_vas-details-wall-notes-text')?.classList.add('hide');
                                vasFloorOptionsNextSibling.querySelector('.c_vas-details-wall-notes-text')?.classList.remove('show');
                            }
                            currentTarget.querySelector('form').querySelector<HTMLInputElement>('input[name="acSetupIndex"]').value = '0';
                            currentTarget.querySelector('form').querySelector<HTMLInputElement>('input[name="previousVasType"]').value = '';
                        }
                    }
                }
            }

            var productCode = currentTarget.getAttribute('data-productcode');
            var entryNumber = currentTarget.getAttribute('data-entryNumber');
            if(entry !== null && response.showApplyService !== undefined && response.showApplyService === true){
                document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .c_apply-service')?.forEach(x => x.classList.add('show-flex'));
                document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .c_apply-service-title')?.forEach(x => x.classList.add('show-flex'));
                if(entry.applyService === true){
                    document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .js-apply-service-content[data-applyservice="true"]')?.forEach(x => x.classList.add('selected'));
                    document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .js-apply-service-content[data-applyservice="false"]')?.forEach(x => x.classList.remove('selected'));
                } else {
                    document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .js-apply-service-content[data-applyservice="true"]')?.forEach(x => x.classList.remove('selected'));
                    document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .js-apply-service-content[data-applyservice="false"]')?.forEach(x => x.classList.add('selected'));
                }
            } else {
                document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .c_apply-service-title')?.forEach(x => x.classList.remove('show-flex'));
                document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .c_apply-service')?.forEach(x => x.classList.remove('show-flex'));
            }
            window.ACC.loader.hideLoader();
            return false;
        })
        .catch(error => {
            console.error('Error:', error);
            window.ACC.loader.hideLoader();
            window.ACC.main.isSessionExpired(error, window.ACC.config.encodedContextPath);
        });
}

export function onChangeSubmitForm (formId: string, currentTarget: HTMLDivElement) {
    console.log('window.ACC.vasaddtocart.js - window.ACC.vasaddtocart.onChangeSubmitForm(): executed');
    window.ACC.loader.showLoader();
    var form = <HTMLFormElement>document.getElementById(formId);
    const fd = new FormData(form);
    const data = currentTarget.dataset;
    
    submitFormUsingFetchAPI(formId, { defaultWarranty: data.itemAdd })
    .then(cartData => {
        window.ACC.loader.hideLoader();
        var status = cartData.statusCode;
        var entry = cartData.entry;
        
        if (cartData && cartData.cartData && cartData.cartData.entries.length > 0) {
            cartData.cartData.entries.forEach(function (element) {
                if (element.product.code === fd.get('productCode')) {
                    window.ACC.vasaddtocart.var.productData = element.product;
                }
            });
        }
        
        window.ACC.extragtm.click.serviceSelectionAction('extra_service', data.type, currentTarget.parentElement.querySelector<HTMLInputElement>("input[name='vasType']")?.value, '');
        // Events required on cart page service selection MAVD-13284 (point 1)
        if (status === 'success' || status === 'VasEntryUpdated') {
            window.ACC.mixpanel.trackServiceAddedToCart(window.ACC.vasaddtocart.var.productData,data.type, data.source, data.price, data.plan, data.addon, data.serviceid, cartData.cartData.mandateServiceSelectionEnabled);
        }
        if ((entry !== null) && (entry !== undefined)) {
            if(entry.entryType === "SPLITACSERVICE") {
                currentTarget.closest<HTMLInputElement>('.js-ac-change-svelte.selected input[name="entryNumber"]').value = (entry.serviceGroupNumber);
                document.querySelector(selectorAcFloorSelect)?.classList.remove(classSelected);
            }
            currentTarget.closest('.c_vas-cart-details_mb .js-vas-free-gift-template')?.remove();
            // currentTarget.closest('.c_vas-cart-details_mb')?.append($("#vasFreeGiftTemplate")?.tmpl(cartData)); // @@7749 Todo
        }
        else if (status === "VasEntryRemoved") {
            // window.ACC.main.showCouponCodeSnackbar('', false, serviceDeleteMsg);
            currentTarget.querySelector<HTMLInputElement>('.c_vas-cart-details-inner input[name="entryNumber"]').value = '0';
            currentTarget.querySelector<HTMLInputElement>('.c_vas-cart-details-inner input[name="serviceGroupNumber"]').value = '';
        }
        if(!JSON.parse(data.itemAdd)){
            currentTarget.closest('.c_vas-cart-details-inner')?.querySelector(selectorVasSelect)?.setAttribute('data-item-add', 'false');
            data.itemAdd = 'true';
            if (window.ACC.config.pageId === 'productDetails') {
                currentTarget.closest('.js-ac-change-svelte.selected .vas-floor-options')?.parentElement.querySelectorAll('.c_vas-cart-details-mounts')?.forEach(x => x.classList.remove('hide'));
                currentTarget.closest('.js-ac-change-svelte.selected .vas-floor-options')?.parentElement.querySelectorAll('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-floor-select')?.forEach(x => x.classList.add(classSelected));
            }
            var vascurrentprice = parseFloat(data.vasprice);
            if ((entry !== null) && (entry !== undefined) && entry.entryType !== "SPLITACSERVICE") {
                var vasaddedprice = parseFloat(entry.totalPrice.value.toFixed(window.ACC.config.currencyDecimal));
                if(entry.combinedVas === true && entry.combinedVasTotalPrice !== null){
                    vasaddedprice = parseFloat(entry.combinedVasTotalPrice.value.toFixed(window.ACC.config.currencyDecimal));
                }
                if(vasaddedprice !== vascurrentprice){
                    var templateData = {
                        "servicePrice" : parseFloat(data.vasserviceprice)?.toFixed(window.ACC.config.currencyDecimal),
                        "discountedPrice" : vasaddedprice
                    };
                    
                    // currentTarget.closest('.c_vas-details-list .c_vas-details-list-price')?.html($("#vasDiscountedPriceTemplate")?.tmpl(templateData)); // @@7749 ToDo
                }
                vascurrentprice = vasaddedprice;
            }
            
            if(vascurrentprice > 0){
                var showprice = vascurrentprice.toFixed(window.ACC.config.currencyDecimal);
                currentTarget.closest('.c_vas-cart-details_mb').querySelector(selectorVasCartPrice).innerHTML = (window.ACC.config.currency + " " + showprice);
            } else {
                currentTarget.closest('.c_vas-cart-details_mb').querySelector(selectorVasCartPrice).innerHTML = (data.free);
            }
        }else{
            data.itemAdd = 'false';
            currentTarget.closest('.c_vas-cart-details_mb').querySelector(selectorVasCartPrice).innerHTML = "";
        }

        var refreshCartData = false;
        if(cartData !== "" && cartData !== null){
            if(cartData.cartData !== "" && cartData.cartData !== null)
            {
                refreshCartData = true;
                window.ACC.cart.refreshCartPageWithJSONResponse(cartData.cartData);
            }
            else
            {
                const elementsToRemove = document.querySelectorAll('.js-cart-totals, .js-cart-voucher, .js-right-footer, .js-cart-right-inner, .js-cart-right-actions');
                elementsToRemove.forEach(element => {
                    element.remove();
                });

                // $('#ajaxCart')?.html($("#vasCartTotalsTemplate")?.tmpl(cartData)); // @@7749 Todo
                // $(window.ACC.cart.selector.expressDeliveryContainer)?.html($(window.ACC.cart.selector.expressDeliveryContent)?.tmpl(cartData)); // @@7749 Todo
                if(cartData.couponMessage !== "" && cartData.couponMessage !== null){
                    window.ACC.cart.showError(cartData.couponMessage);
                }
            }
        }
        var productCode = currentTarget.getAttribute('data-productcode');
        var entryNumber = currentTarget.getAttribute('data-entryNumber');
        if(refreshCartData === false){
            if((cartData && cartData.showApplyService !== undefined) && (cartData && cartData.showApplyService === true)) {
                document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .c_apply-service')?.forEach(x => x.classList.add('show-flex'));
                document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .c_apply-service-title')?.forEach(x => x.classList.add('show-flex'));

                if(entry.applyService === true){
                    document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .js-apply-service-content[data-applyservice="true"]')?.forEach(x => x.classList.add('selected'));
                    document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .js-apply-service-content[data-applyservice="false"]')?.forEach(x => x.classList.remove('selected'));
                    
                } else {
                    document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .js-apply-service-content[data-applyservice="true"]')?.forEach(x => x.classList.remove('selected'));
                    document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .js-apply-service-content[data-applyservice="false"]')?.forEach(x => x.classList.add('selected'));                    
                }
            } else {
                document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .c_apply-service')?.forEach(x => x.classList.remove('show-flex'));
                document.querySelectorAll('.js-apply-service-option-'+productCode+'-'+entryNumber + ' .c_apply-service-title')?.forEach(x => x.classList.remove('show-flex'));
            }
        }
        if(cartData.acSetupVasTypes !== null) {
            if ((status === 'VasEntryUpdated') && entry === null) {
                if (currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-bracketAllowed') === 'false') {
                    currentTarget.closest('.vas-floor-options')?.parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .vas-mount-text.js-ac-wall-select-svelte')?.classList.add("hide");
                }
                if (currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-vasprice') === '0') {	
                    currentTarget.closest('.js-ac-change-svelte.selected .vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-floor-select .vas-ac-floor-price').innerHTML = ("<strong class='c_cart-totals--rgtclr free'>" + currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-free') + "</strong>");
                    currentTarget.closest('.js-ac-change-svelte.selected .vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-wall-select-svelte .vas-ac-wall-price').innerHTML = (window.ACC.config.currency + " " + "<strong>" + currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-wallmountprice') + "</strong>");
                } else {	
                    currentTarget.closest('.js-ac-change-svelte.selected .vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-floor-select .vas-ac-floor-price').innerHTML = (window.ACC.config.currency + " " + "<strong>" + currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-vasprice') + "</strong>");
                    currentTarget.closest('.js-ac-change-svelte.selected .vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-wall-select-svelte .vas-ac-wall-price').innerHTML = (window.ACC.config.currency + " " + "<strong>" + currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-wallmountprice') + "</strong>");
                }
            } else if ((status === 'success') && entry === null) {
                currentTarget.closest('.js-ac-change-svelte.selected .vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-wall-select-svelte .vas-ac-wall-price').innerHTML = (window.ACC.config.currency + " " + "<strong>" + currentTarget.closest('.js-ac-change-svelte.selected')?.getAttribute('data-wallmountprice') + "</strong>");
            } else if ((status === 'success') && entry !== null) {
                if (document.querySelector('.js-ac-change-svelte.selected')?.getAttribute('data-vasprice') === '0') {
                    document.querySelector('.js-ac-change-svelte.selected .vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-floor-select .vas-ac-floor-price').innerHTML = ("<strong class='c_cart-totals--rgtclr free'>" + document.querySelector('.js-ac-change-svelte.selected')?.getAttribute('data-free') + "</strong>");
                    document.querySelector('.js-ac-change-svelte.selected .vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-wall-select-svelte .vas-ac-wall-price').innerHTML = (window.ACC.config.currency + " " + "<strong>" + document.querySelector('.js-ac-change-svelte.selected')?.getAttribute('data-wallmountprice') + "</strong>");

                } else {
                    document.querySelector('.js-ac-change-svelte.selected .vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-floor-select .vas-ac-floor-price').innerHTML = ("<strong>" + document.querySelector('.js-ac-change-svelte.selected')?.getAttribute('data-vasprice') + "</strong>");
                    document.querySelector('.js-ac-change-svelte.selected .vas-floor-options').parentElement.querySelector('.c_vas-cart-details-mounts .vas-mount-labels .js-ac-wall-select-svelte .vas-ac-wall-price').innerHTML = (window.ACC.config.currency + " " + "<strong>" + document.querySelector('.js-ac-change-svelte.selected')?.getAttribute('data-wallmountprice') + "</strong>");
                }
            }
        }
        return false;
    })?.catch(error => {
        window.ACC.loader.hideLoader();
        console.error(error);
        window.ACC.main.isSessionExpired(error, window.ACC.config.encodedContextPath);
    });
}
/* methods ported from window.ACC.vasaddtocart.js to support svelte addToCart popup END */


/* Helper methods */
export function submitFormUsingFetchAPI(formId, additionalData): Promise<any> {
    const theForm = <HTMLFormElement>document.getElementById(formId);
    if (!!theForm) {
        const formData = new FormData(theForm);
        const { CSRFToken } = getAppConfig();
        formData.append('CSRFToken', CSRFToken);

        const body = new URLSearchParams();

        if (!!additionalData) {
            for(const [ k, v ] of Object.entries(additionalData)) {
                formData.append(k, v.toString());
            }
        }
        return new Promise((resolve, reject) => {
            fetch(theForm.action, {
                method: theForm.method,
                body: new URLSearchParams(Array.from<string[]>(formData.entries() as any)).toString(),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })?.then(response => {
                if (response.ok) {
                    resolve(response.json());
                } else {
                    reject(response.json());
                }
            })?.catch(error => {
                reject(error);
            }); 
        });
        
    } else {
        return Promise.reject(`Form with id ${formId} not present in DOM. Cannot submit a non-existent form!`);
    }
}

/**
 * Handles the service selection on the product details page.
 *
 * Loops through all the siblings of the element that triggered the event and
 * sets the data attribute `itemAdd` to false if the sibling is not the same
 * element as the one that triggered the event and if they have the class
 * `js-vas-details-svelte`.
 *
 * This is necessary because the data attribute `itemAdd` is used to determine if a service should
 * be added or removed from the cart.
 *
 * @param {ele} ele the element that triggered the event
 */
export function handleServiceSelection(ele: HTMLElement) {
    const siblings = ele.parentNode?.querySelectorAll<HTMLElement>('.js-vas-details-svelte, .js-vas-ac-change-svelte');

    if (siblings) {
        for (const sibling of siblings) {
            if (sibling !== ele && (isAcServiceClicked || sibling.classList.contains('js-vas-details-svelte'))) {
                sibling.dataset.itemAdd = 'false';
            }
        }
    }
}

/**
 * Handles the service selection on the add to cart popup for the AC service.
 *
 * If the element that triggered the event contains a form with an id, this function will call
 * the submitForm() function with the form id and the element that triggered the event.
 *
 * @param {event} event the event that triggered the function
 */
export function vasOnACChange(event: any) {
    const { currentTarget } = event;
    const form = currentTarget.querySelector('form');
    const formId = form?.getAttribute('id');
    
    if (formId) {
        // Set the flag to indicate that the AC service was clicked
        isAcServiceClicked = true;
        
        // Remove the `selected` class from all the other elements that have the class `js-vas-ac-change-svelte`
        currentTarget.closest('.c_vas-details-items').querySelectorAll(`.js-vas-ac-change-svelte.${classSelected}`).forEach(el => el.classList.remove(classSelected));
        
        submitForm(formId, currentTarget);
    }
}
