<script lang="ts">
    import { getAppConfig } from "../../../../../common/config/app-config";
    import {
        formatNumber,
        getJoodMembershipType,
        getStr,
        isMobile,
        roundToNDigits,
        type JoodMembershipType,
    } from "../../../../../common/util";
    import type { ProductTilePriceGroupVariantType } from "./ProductTilePriceNewGroup.svelte";

    export let algoliaProductHit;
    export let priceType: JoodMembershipType = "NOPRIME";
    export let variant: ProductTilePriceGroupVariantType;
    export let isMegasaleProduct = [];

    const {
        unbxdEnabled,
        extraThemeResourcePath,
        currency,
        encodedContextPath,
        countryCode,
        algoliaConfig,
        unbxdSearchConfig,
        lang,
    } = getAppConfig();
    const userJoodMembershipType: JoodMembershipType = getJoodMembershipType();
    const joodSubscribeLinkHref = `${encodedContextPath}/jood/benefits`;
    const isResponsive = isMobile();
    const discountDecimals = +(unbxdEnabled
        ? unbxdSearchConfig.decimalCount
        : algoliaConfig.decimalCount);

    $: visible = getComponentVisibility(priceType, userJoodMembershipType);
    $: subscribeLinkVisible = getSubscribeLinkVisibility(
        priceType,
        userJoodMembershipType,
    );
    $: subscribeLinkText = getSubscribeLinkText(
        priceType,
        userJoodMembershipType,
        variant,
    );
    $: title = getTitle(priceType);
    $: displayPrice = getPrice(priceType, algoliaProductHit);
    $: wasPrice = algoliaProductHit.wasPrice;
    $: savedPrice = wasPrice - displayPrice;
    $: hasSavings = savedPrice > 0;
    $: savedPercent =
        hasSavings &&
        roundToNDigits((savedPrice * 100) / wasPrice, discountDecimals);
    $: savedPercentTagOnTopRight = shouldPercentTagBeOnTopRight(
        priceType,
        userJoodMembershipType,
        algoliaProductHit,
    );
    $: savedPercentVisible = getSavedPercentVisibility(algoliaProductHit);
    $: titleVisible = getTitleVisibility(algoliaProductHit);
    $: megaSaleClass =
        priceType === "NOPRIME" && isMegasaleProduct?.length
            ? "megasale-theme"
            : "";
    $: joodCardImageUrl = getJoodCardImageUrl(priceType, variant);
    $: bottomDisclaimerVisible = getBottomDisclaimerVisibility(
        priceType,
        userJoodMembershipType,
        variant,
    );

    // Utility Functions
    function getBottomDisclaimerVisibility(
        priceType: JoodMembershipType,
        userJoodMembershipType: JoodMembershipType,
        variant: ProductTilePriceGroupVariantType,
    ) {
        /*
            Rules:
            1. Only visible for restock-popup variant
            2. Only visible on gold price for non-prime user
        */
        const visible =
            variant === "restock-popup" &&
            priceType === "VIPPRIME" &&
            userJoodMembershipType === "NOPRIME";
        return visible;
    }
    function getJoodCardImageUrl(
        priceType: JoodMembershipType,
        variant: ProductTilePriceGroupVariantType,
    ) {
        if (variant !== "restock-popup" || priceType === "NOPRIME") return null;
        if (priceType === "BASICPRIME")
            return `${extraThemeResourcePath}/images/jood/jood-blue-card.png`;
        return `${extraThemeResourcePath}/images/jood/jood-gold-card.png`;
    }
    function getTitle(priceType: JoodMembershipType) {
        switch (priceType) {
            case "NOPRIME":
                return getStr("extra.jood.price.title");
            case "BASICPRIME":
                return getStr("extra.jood.blue.price.title");
            case "VIPPRIME":
                return getStr("extra.jood.gold.price.title");
            default:
                return getStr("extra.jood.price.title");
        }
    }
    function getTitleVisibility(algoliaHit) {
        /*
            Rules:
            1. if gray price, hide title if product does not have blue/gold prices
            2. if non-gray price, show title always
            3. if variant = product-tile, hide non-jood title
        */
        const isNonJoodPrice = priceType === "NOPRIME";
        const hasBluePrice = algoliaHit?.basicPrimePrice > 0;
        const hasGoldPrice = algoliaHit?.vipPrimePrice > 0;

        return (
            !isNonJoodPrice ||
            (isNonJoodPrice &&
                (hasBluePrice || hasGoldPrice) &&
                variant !== "product-tile")
        );
    }
    function getPrice(priceType: JoodMembershipType, algoliaProductHit: any) {
        let sourcePrice;
        switch (priceType) {
            case "NOPRIME":
                sourcePrice = algoliaProductHit.price;
                break;
            case "BASICPRIME":
                sourcePrice = algoliaProductHit.basicPrimePrice;
                break;
            case "VIPPRIME":
                sourcePrice = algoliaProductHit.vipPrimePrice;
                break;
        }

        return sourcePrice;
    }

    function getComponentVisibility(
        priceType: JoodMembershipType,
        userJoodMembershipType: JoodMembershipType,
    ): boolean {
        /*
            Rules: (gray: non-prime, blue: basic-prime, gold: vip-prime)
            1. if gray member, show gray / gold prices
            2. if blue member, show gray / blue / gold prices
            3. if gold member, show gray / gold prices
        */
        let visibility = false; // ["NOPRIME", "VIPPRIME"].indexOf(userJoodMembershipType) > -1 && priceType !== "BASICPRIME";
        if (userJoodMembershipType === "BASICPRIME") {
            visibility = true; // rule 2
        } else {
            visibility = priceType !== "BASICPRIME"; // rule 1 & 3
        }

        return visibility;
    }
    function getSubscribeLinkVisibility(
        priceType: JoodMembershipType,
        userJoodMembershipType: JoodMembershipType,
    ): boolean {
        /*
            Rules:
            1. if gray member, subscribe link on gold price
            2. if blue member, subscribe link on gold price
            3. if gold member, no subscribe link
        */
        if (variant === "pdp") return false;

        let visibility = false;
        if (
            userJoodMembershipType === "NOPRIME" ||
            userJoodMembershipType === "BASICPRIME"
        ) {
            visibility = priceType === "VIPPRIME"; // rule 1 & 2
        } else {
            visibility = false; // rule 3
        }

        return visibility;
    }
    function getSubscribeLinkText(
        priceType: JoodMembershipType,
        userJoodMembershipType: JoodMembershipType,
        variant: ProductTilePriceGroupVariantType,
    ): string {
        /*
            Rules:
            1. if gray member, subscribe text on gold price
            2. if blue member, upgrade text on gold price
            3. if gold member, no subscribe text
        */
        let text: string = "";
        if (variant === "restock-popup") {
            text = getStr("primer.subscribe.button.label");
        } else if (
            userJoodMembershipType === "NOPRIME" &&
            priceType === "VIPPRIME"
        ) {
            text =
                variant === "plp-list"
                    ? getStr("extra.jood.subscribe.message")
                    : getStr("extra.jood.gold.subscribe.message"); // rule 1
        } else if (
            userJoodMembershipType === "BASICPRIME" &&
            priceType === "VIPPRIME"
        ) {
            text = getStr("extra.jood.gold.upgrade.message"); // rule 2
        }

        return text;
    }
    function shouldPercentTagBeOnTopRight(
        priceType: JoodMembershipType,
        userJoodMembershipType: JoodMembershipType,
        algoliaHit,
    ): boolean {
        /*
            Rules:
            1. if no gold / blue price, show on top-right in gray price
            2. if blue member, show on top-right in gold price
            3. if gold member, don't show
            4. if product-tile variant, show on top-right
            5. if plp-list responsive, show on top-right
        */
        const hasBluePrice = algoliaHit?.basicPrimePrice > 0;
        const hasGoldPrice = algoliaHit?.vipPrimePrice > 0;

        if (variant === "product-tile") return true;
        if (isResponsive && variant === "plp-list") return true;

        let showOnTopRight = false; // rule 3
        if (!hasBluePrice && !hasGoldPrice && priceType === "NOPRIME") {
            showOnTopRight = true; // rule 1
        } else if (
            userJoodMembershipType === "BASICPRIME" &&
            priceType === "VIPPRIME"
        ) {
            showOnTopRight = true; // rule 2
        }

        return showOnTopRight;
    }
    function getSavedPercentVisibility(algoliaProductHit) {
        return (
            countryCode !== "OM" ||
            algoliaProductHit?.priceValueDiscountPercentageFlag
        );
    }
    $: displayPriceFormat = `<strong>${formatNumber(displayPrice)}</strong>`;
</script>

{#if visible && (displayPrice > 0 || priceType === "NOPRIME")}
    <section
        class="{megaSaleClass} product-price-new-container test jood-{priceType} user-{userJoodMembershipType} variant-{variant}"
        class:rtl={lang == "ar"}
        class:discount-applied-section={savedPercent > 0}
    >
        {#if variant === "restock-popup"}
            <section class="left">
                <img src={joodCardImageUrl} alt="Jood Card" />
            </section>
        {/if}
        <section class="middle">
            {#if titleVisible}
                <h4>{title}</h4>
            {/if}
            <section class="price">
                {@html getStr("jood.price.with.currency", [
                    currency,
                    displayPriceFormat,
                ])}
                <!-- {currency} <strong>{formatNumber(displayPrice)}</strong> -->
                <span class="vat">{getStr("extra.vatLable")}</span>
            </section>
            {#if hasSavings}
                <section class="savings">
                    <span class="striked-off">{formatNumber(wasPrice)}</span>
                    <strong class="orange-text">
                        {@html getStr("extra.cart.saved.amt", [
                            currency,
                            formatNumber(savedPrice),
                        ])}
                    </strong>
                </section>
                {#if savedPercentVisible}
                    <section
                        class="save-percent-tag"
                        class:top-right-tag={savedPercentTagOnTopRight}
                    >
                        {getStr("product.percentageDiscount.off", [
                            savedPercent,
                        ])}
                    </section>
                {/if}
            {/if}
            {#if variant !== "restock-popup" && subscribeLinkVisible}
                <a
                    class="subscribe-upgrade-link bottom-link"
                    on:click|stopPropagation
                    href={joodSubscribeLinkHref}
                >
                    {subscribeLinkText}
                </a>
            {/if}
        </section>
        {#if variant === "restock-popup" && subscribeLinkVisible}
            <section class="right">
                <a
                    class="subscribe-upgrade-link side-link"
                    on:click|stopPropagation
                    href={joodSubscribeLinkHref}
                >
                    {subscribeLinkText}
                </a>
            </section>
        {/if}
        {#if bottomDisclaimerVisible}
            <section class="bottom">
                {getStr("extra.pdp.prime.gold.members.disclaimer.text")}
            </section>
        {/if}
    </section>
{/if}

<style lang="scss">
    @import "../../../../common/styles/colors.new.scss";

    .product-price-new-container {
        border-radius: 12px;
        padding: 8px;
        color: $neutral-dark-01;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-grow: 1;
        flex-basis: 45%;
        flex-wrap: wrap;
        position: relative;
        gap: 12px;
        overflow: hidden;

        &.jood-NOPRIME {
            background-color: $neutral-light-04;
        }
        &.jood-BASICPRIME {
            background-color: $jood-blue-light;
        }
        &.jood-VIPPRIME {
            background-color: $jood-gold-light;
        }

        &.jood-VIPPRIME.user-VIPPRIME {
            border: 1px solid $jood-gold-border;
        }
        &.jood-BASICPRIME.user-BASICPRIME {
            border: 1px solid $jood-blue-border;
        }

        &:nth-of-type(3) {
            flex-basis: 100%;
        }
        &.discount-applied-section.rtl {
            section.price {
                display: flex;
                align-items: baseline;
                gap: 4px;
                .vat {
                    width: 115px;
                }
            }
        }

        &.variant-pdp {
            padding: 12px;
            justify-content: flex-start;

            .price {
                margin-top: 12px;
                font-size: 28px;
                line-height: 32px;
                @include responsive {
                    font-size: 22px;
                }

                &:first-child {
                    margin-top: 0;
                }
                .vat {
                    @include responsive {
                        display: inline;
                    }
                }
            }
            .striked-off,
            .orange-text {
                font-size: 16px;
                line-height: 18px;

                @include responsive {
                    font-size: 14px;
                }
            }
        }
        &.variant-plp-list,
        &.variant-product-tile {
            display: flex;
            flex-direction: column;
            gap: 4px;

            @include responsive {
                flex-basis: 100%;
            }

            h4 {
                font-weight: 400;

                @include responsive {
                    font-size: 10px;
                    line-height: 14px;
                }
            }
            .price {
                @include responsive {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }

        &.variant-plp-list {
            @include responsive {
                h4 {
                    font-size: 12px;
                    line-height: 16px;
                }
                .price {
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }

        &.variant-restock-popup {
            h4 {
                font-weight: 400;
            }
            .price {
                font-size: 24px;
                line-height: 26px;
            }
            .save-percent-tag {
                display: none;
            }
            .subscribe-upgrade-link {
                font-size: 13px;
            }
            .striked-off,
            .orange-text {
                font-size: 14px;
                line-height: 16px;
            }
        }

        .left {
            img {
                max-width: 35px;
                object-fit: contain;
                border-radius: 4px;
            }
        }
        .middle {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            flex-grow: 1;
        }
        .right {
            align-self: center;
            padding-right: 20px;
        }
        .bottom {
            flex-basis: 100%;
            padding: 12px;
            background: $primary-white;
            margin: -8px;
            margin-top: 0;
            flex-grow: 1;
            border-top: 1px solid $secondary-gray-02;
            color: $neutral-dark-02;
            font-size: 11px;
            line-height: 12px;
            font-weight: 400;
        }
        h4 {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: $neutral-dark-02;
            margin: 0;
        }
        .price {
            font-weight: 400;
            font-size: 20px;
            line-height: 22px;

            .vat {
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                color: $neutral-dark-04;

                @include responsive {
                    display: none;
                }
            }
        }
        .savings {
            display: flex;
            align-items: center;
            gap: 6px;
        }
        .striked-off {
            color: $neutral-dark-03;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-decoration: line-through;
        }
        .orange-text {
            color: $functional-orange;
            font-size: 12px;
            line-height: 14px;
        }
        .save-percent-tag {
            display: inline-block;
            padding: 4px 8px;
            border-radius: 5px;
            color: $primary-white;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            background-color: $functional-orange;
            margin-top: 4px;

            @include responsive {
                font-size: 9px;
                line-height: 10px;
                padding: 2px 5px;
            }
            &.top-right-tag {
                position: absolute;
                right: 16px;
                top: 8px;
                margin-top: 0;

                @include responsive {
                    right: 8px;
                }
            }
        }
        .subscribe-upgrade-link {
            margin-top: 8px;
            color: $tertiary-blue-02;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
        }
        &.megasale-theme {
            .save-percent-tag {
                background-color: $extraTagBg;
                color: $extraTagTextColor;
            }
            .orange-text {
                color: $extraTagBg;
            }
        }
        &.rtl {
            .save-percent-tag.top-right-tag {
                right: 10px;
            }
            .price .vat {
                font-size: 10px;
            }
        }
    }
</style>
