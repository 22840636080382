<script lang="ts">
    import Button, { Label } from "@smui/button";
    import Text from "../../Text.svelte";
</script>

<div class="tile-action-btn-container">
    <div class="buy-now-btn">
        <Button>
            <Label><Text key="extra.buynowbutton" /></Label>
        </Button>
    </div>
</div>

<style lang="scss">
    @import "../../../styles/colors.new.scss";

    :global {
        .tile-action-btn-container {
            .buy-now-btn {
                .mdc-button {
                    width: 100%;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 700;
                    min-height: 32px;
                    border-radius: 100px;
                    color: $primary-black;
                    background-color: $color-yellow;
                    .mdc-button__label {
                        white-space: nowrap;
                    }
                    @include desktop-min-width {
                        min-height: 36px;
                        font-size: 16px;
                        line-height: 18px;
                        padding: 15px 68px;
                    }
                }
            }
        }
    }
</style>
