<script lang="ts">
    import { getAppConfig } from "../../../../common/config/app-config";
    import { getPriceHTMLOrFree } from "../../../../common/util";
    import Text from "../../../common/components/Text.svelte";

    export let entry;
    const {
        lang,
        currency,
        algoliaConfig: { tasheelInfo },
    } = getAppConfig();
    $: tasheel = entry?.product?.tasheelPriceValuesList?.[0] || null;
</script>

{#if entry?.product?.tasheelPriceValuesList?.length > 0}
    <div class="c_add-to-cart-popup-item-tasheel">
        <div class="info-container">
            <span class="tasheel-info">
                <span class="price-currencyContainer">
                    {#if entry?.product?.tasheelCashBackPriceValuesList?.length > 0}
                        {@html getPriceHTMLOrFree(
                            entry.product.tasheelCashBackPriceValuesList[0]
                                .priceValueTasheel.value,
                        )}
                    {:else}
                        {@html getPriceHTMLOrFree(
                            tasheel.priceValueTasheel.value,
                        )}
                    {/if}
                </span>
                <strong
                    >/
                    <Text key={"extra.mon.text"} />
                </strong>
            </span>
            <div class="tasheel-img">
                <img
                    src={tasheelInfo.tasheelImageUrl}
                    class="hide-on-mobile"
                    alt="Taqsset Extra"
                />
                <img
                    src={tasheelInfo.tasheelIconUrl}
                    class="hide-on-desktop"
                    alt="Taqsset Extra"
                />
            </div>
        </div>
        {#if tasheelInfo.tasheelInstallmentMonths && tasheelInfo.tasheelPercentage}
            <div class="installment-container">
                <span class="for-gcc">
                    <Text
                        key="extra.tasheel.priceValue.desc"
                        args={[
                            tasheelInfo.tasheelPercentage,
                            tasheelInfo.tasheelInstallmentMonths,
                        ]}
                    />
                </span>
            </div>
        {/if}
    </div>
{/if}
