import {
  APP_CONSTANTS,
  getBaseWebServiceUrl,
} from "../../../common/config/app-constants";
import {
  httpPostJSON,
  httpGetJSON,
  httpGetRaw,
} from "../../../common/services/ajax-services";
import {
  heroBannerImages,
  popularCategoriesData,
  topBrandImages,
  testimonialData,
  mainContentData,
  amplienceData,
  richRelavance,
  footerData,
} from "../store/home_store";
import { getAppConfig } from "../../../common/config/app-config";
import { rrGetHomePagePlacements } from '../../../common/services/rich-relevance';
import { delayPromise } from "../../common/services/hybris";

let BASE_WEB_SERVICE_URL = getBaseWebServiceUrl();

const {
  lang = "en",
  countryCode,
  CSRFToken,
  encodedContextPath,
  jessionId: DEVICE_ID,
}: any = getAppConfig();
const localeData = `${lang}-${countryCode?.toLowerCase()}`;
const localeAmplienceData = `${lang}-SA`;
const LANGUAGE_SELECTED = lang;

export async function getFooterData() {
  const url = `${BASE_WEB_SERVICE_URL}/footerlinks`;
  const params = {
    fields: "FULL",
    lang: LANGUAGE_SELECTED,
    deviceId: DEVICE_ID,
  };
  const footerResponse = await httpGetJSON(url, params);
  footerData.set(footerResponse);
}
export async function getHeroBannerData(heroBannerId) {
  const url = `${APP_CONSTANTS.AMPLIENCE_API}${heroBannerId}`;
  const params = { depth: "all", format: "inlined", "-*": true };
  const response = await httpGetRaw(url, params);
  if (response.status === 200) {
    const bannerData = await response.json();
    processHeroBannerData(bannerData, heroBannerImages);
    return true;
  } else {
    throw new Error(`ERROR fetching banner data`);
  }
}
export function processHeroBannerData(data, storeObj) {
  let imageBaseUrl = APP_CONSTANTS.AMPLIENCE_IMAGE_BASE_URL;
  let bannerData = data;
  let tempData = [];
  let slides = bannerData.content.slotContent.slides;
  slides.forEach((slide) => {
    let imgObj;
    let linkName;
    const currentLocale = `${lang}-SA`;
    slide.backgroundImage.forEach((data) => {
      const localeSpecificImage = data.image.values.find(
        (x) => x.locale === currentLocale
      );
      let imageName = localeSpecificImage.value.name;
      imgObj = { url: `${imageBaseUrl}${imageName}.webp` };
    });

    if (slide.links) {
      slide.links.forEach((data) => {
        linkName =
          data.url?.values?.filter(
            (data) =>
              data?.locale.toLowerCase() === localeAmplienceData.toLowerCase()
          )[0]?.value || "";
      });
    }

    tempData.push({ imgObj, linkName });
  });

  storeObj.set(tempData);
}
export async function getPopularCategoriesData() {
  const url = `${BASE_WEB_SERVICE_URL}/getPopularCategories`;
  const params = {
    fields: "FULL",
    lang: LANGUAGE_SELECTED,
    deviceId: DEVICE_ID,
  };
  const apiResponse = await httpGetJSON(url, params);

  if (apiResponse) {
    await popularCategoriesData.set(apiResponse);
    return true;
  }
  return false;
}
export async function getTopBrandsData(topBrandId) {
  const url = `${APP_CONSTANTS.AMPLIENCE_API}${topBrandId}`;
  const params = { depth: "all", format: "inlined", "-*": true };
  const response = await httpGetRaw(url, params);

  if (response.status === 200) {
    const bannerData = await response.json();
    processHeroBannerData(bannerData, topBrandImages);
    return true;
  } else {
    throw new Error(`ERROR fetching Top brands data`);
  }
}

export async function getAmplienceData(key, templateName, locale = "en-SA") {
  //locale is hardcoded as for other data is not coming
  const url = `${APP_CONSTANTS.AMPLIENCE_API_HTML_BASE_URL}${key}`;
  const params = {
    template: templateName,
    locale,
  };
  const headers = {
    contentType: "text/html",
  };

  const response = await httpGetRaw(url, params, headers, false);
  const apiResponse = await response.text();
  if (apiResponse) {
    await amplienceData.update((state) => {
      state = { ...state, ...{ [key]: apiResponse } };
      return state;
    });
    return true;
  }
  return false;
}
export async function getMainContent() {
  const url = `${BASE_WEB_SERVICE_URL}/getHomePageContent`;
  const params = {
    fields: "FULL",
    lang: LANGUAGE_SELECTED,
    deviceId: DEVICE_ID,
  };
  const apiResponse = await httpGetJSON(url, params);
  if (apiResponse) {
    await apiResponse?.contentList?.sort(
      (a, b) => a.displayOrder - b.displayOrder
    );
    await mainContentData.set(apiResponse);
    return true;
  }
  return false;
}
export async function getDealsProductsAsync(uid) {
  const url = `${BASE_WEB_SERVICE_URL}/${APP_CONSTANTS.GET_DEALS_PRODUCTS}`;
  const params = {
    fields: "FULL",
    lang: LANGUAGE_SELECTED,
    slotId: uid
  };
  const promise = httpGetJSON(url, params);
  if (APP_CONSTANTS.IS_DEMO_MODE) {
    return promise.then(delayPromise(2000));
  }
  return promise;
}

export async function getTestimonialData() {
  const client_id =
    LANGUAGE_SELECTED === "ar"
      ? `${LANGUAGE_SELECTED}.extrastores.com`
      : "extrastores.com";
  const limit = 9;
  const params = {
    "-*": true,
    client_id,
    limit,
  };
  const url = `https://d54dwt9c63t87.cloudfront.net/seller_reviews.json`;
  let apiResponse = await httpGetJSON(url, params, {}, false);
  if (apiResponse) {
    await testimonialData.set(apiResponse);
    return true;
  }
  return false;
}

export async function getRichRelavance() {

  let placements = await rrGetHomePagePlacements();
  placements = placements.map((data) => {
    let facetFilter = [];
    data.items.forEach(d => facetFilter.push(`productCode:${d.id}`));
    data.facetFilter = facetFilter;
    data.key = data.placement_name.split(".")[1];

    // temp patch
    if (data.key === 'history') data.key = 'app_history';

    return data;
  });
  richRelavance.set(placements);

  return true;
}

export async function postNewsletterSubscription({ email, CSRFToken }) {
  const body = `email=${email}&CSRFToken=${CSRFToken}`;
  const url = `/${APP_CONSTANTS.NEWSLETTER_POST}`;

  return httpPostJSON(url, body, {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  });
}

export async function addToWishlist(productCode) {
  if (typeof window['ACC'].loader.showLoader === 'function') {
    window['ACC'].loader.showLoader();
}
  const body = `productCode=${productCode}&CSRFToken=${CSRFToken}`;
  const url = `${encodedContextPath}/${APP_CONSTANTS.ADD_TO_WISHLIST}`;

  return httpPostJSON(url, body);
}
export async function removeFromWishlist(productCode) {
  if (typeof window['ACC'].loader.showLoader === 'function') {
    window['ACC'].loader.showLoader();
}
  const body = `pk=${productCode}&CSRFToken=${CSRFToken}`;
  const url = `${encodedContextPath}/${APP_CONSTANTS.REMOVE_FROM_WISHLIST}`;

  return httpPostJSON(url, body);
}
export function getLatestOrdersByCountAsync(count) {
  const relativeUrl = APP_CONSTANTS.GET_LATEST_ORDERS.replace('{count}', count);

  const url = `${encodedContextPath}/${relativeUrl}`;
  const params = {
    fields: "FULL",
    lang: LANGUAGE_SELECTED,
    deviceId: DEVICE_ID,
  };

  return httpGetJSON(url, params);
}
export function getCartItemDetailsByCountAsync(count) {
  const relativeUrl = APP_CONSTANTS.GET_CART_ITEM_DETAILS.replace('{count}', count);

  const url = `${encodedContextPath}/${relativeUrl}`;
  const params = {
    fields: "FULL",
    lang: LANGUAGE_SELECTED,
    deviceId: DEVICE_ID,
  };

  const promise = httpGetJSON(url, params);
  if (APP_CONSTANTS.IS_DEMO_MODE) {
    return promise.then(delayPromise(2000));
  }
  return promise;
}