<script lang="ts">
    import DeliveryInfo from "./DigitalGiftCardProduct/DeliveryInfo.svelte";
    import PdpSectionCard from "./PDPSectionCard.svelte";
    import ProductDelivery from "./ProductDelivery.svelte";

    export let data: any;
    export let pdpGiftCardPriceData: any;
</script>

<PdpSectionCard titleKey="{data?.digitalDeliveryItem ? 'extra.pdp.digital.delivery.option' : 'extra.pdp.delivery.option'}" icon="icon-standard_delivery">
    {#if data?.digitalDeliveryItem}
        <DeliveryInfo data={pdpGiftCardPriceData} />
    {:else}
        <ProductDelivery {...data} />
    {/if}
</PdpSectionCard>
