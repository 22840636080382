<script lang="ts" context="module">
    import { getAppConfig } from "../../../../common/config/app-config";
    import { getStr } from "../../../../common/util";

    const { currency } = getAppConfig();
</script>

<script lang="ts">
    export let vasPrice;
    export let joodType;
    export let oldVasPrice = null;
    export let vasLabel;
    export let vasName = "";

    let vasImg = "jood-gold-card.png";
    let vasTitle = "extra.pdp.prime.gold.display.text";
    let vasTitleClass = "gold";

    $: if (joodType === "BASIC") {
        vasImg = "jood-blue-card.png";
        vasTitle = "extra.pdp.prime.blue.display.text";
        vasTitleClass = "blue";
    }
</script>

<section class="c_joodcart c_joodvas-subs-price">
    <div class="c_joodvas-subs-price-wrapper">
        <div class="c_vas-type">
            <span class="vas-label">
                {vasLabel}
            </span>
            {#if vasName?.length > 0}
                <span class="vas-type-name">
                    <strong>{vasName}</strong>
                </span>
            {/if}
        </div>
        <section class="c_joodvas-subs-details">
            {#if vasPrice > 0}
                <div class="c_joodvas-subs-label">{getStr(vasTitle)}</div>
                <section class="c_joodvas-price-details">
                    {currency}
                    <span class="fw-bold">
                        {vasPrice}
                    </span>
                </section>
            {/if}
            {#if oldVasPrice > 0}
                <section class="c_joodvas-old-price-details">
                    <span class="striked-off">{oldVasPrice}</span>
                    <strong class="orange-text">
                        {@html getStr("extra.cart.saved.amt", [
                            currency,
                            oldVasPrice - vasPrice,
                        ])}
                    </strong>
                </section>
            {/if}
        </section>
    </div>
</section>
