<script lang="ts">
    import { getAppConfig } from "../../../../common/config/app-config";
    import Icon from "../../../common/components/Icon.svelte";
    import Text from "../../../common/components/Text.svelte";

    export let entry;
    let sourceQty;
    let targetQty;
    let price;
    let discountedPrice;
    let freeGiftProductsData;
    let acPriceDiscountData;
    const { currency } = getAppConfig();

    $: if (entry?.product?.freeGiftDisplay) {
        const {
            freeGiftQtuantities,
            freeGiftProducts,
            acPriceDiscountValue,
            freeGiftWorth,
        } = entry?.product?.freeGiftDisplay;
        sourceQty = freeGiftQtuantities?.sourceQty;
        targetQty = freeGiftQtuantities?.targetQty;
        price = freeGiftWorth;
        discountedPrice =
            acPriceDiscountValue?.formattedValue / entry?.quantity;
        freeGiftProductsData = freeGiftProducts;
        acPriceDiscountData = acPriceDiscountValue;
    }
</script>

{#if entry?.freeGiftNotAvailable}
    <div class="c_product-details-freegift-outofstock-info">
        {entry?.freeGiftNotAvailableMessage}
    </div>
{/if}
{#if entry?.product?.freeGiftDisplay}
    <div class="c_gift-block c_item--grid__gift">
        <div class="gift-details-container">
            {#if sourceQty === targetQty && freeGiftProductsData !== null}
                <Icon
                    glyph="gift"
                    mobileSize={20}
                    desktopSize={24}
                    type="png"
                />
                <div class="c_gift-block-text">
                    <div class="gift-msg">
                        <Text
                            key="extra.cart.free.gift.worth"
                            args={[sourceQty, currency, price]}
                        />
                    </div>
                    <div class="gift-submsg">
                        <Text key="extra.pdp.gift.submsg.gift.in.cart" />
                    </div>
                </div>
            {:else if acPriceDiscountData && acPriceDiscountData?.value > 0 && freeGiftProductsData === null}
                <Icon
                    glyph="gift"
                    mobileSize={20}
                    desktopSize={24}
                    type="png"
                />
                <div class="c_gift-block-text">
                    <div class="gift-msg">
                        <Text
                            key="extra.ac.promotion.additional.discount.applied.text"
                            args={[currency, discountedPrice]}
                        />
                    </div>
                </div>
            {:else}
                <Icon
                    glyph="gift"
                    mobileSize={20}
                    desktopSize={24}
                    type="png"
                />
                <div class="c_gift-block-text">
                    <div class="gift-msg">
                        <Text
                            key="extra.cart.free.gift.worth.selection"
                            args={[sourceQty, currency, price]}
                        />
                    </div>
                    <div class="gift-submsg">
                        <Text key="extra.pdp.gift.submsg.gift.in.cart" />
                    </div>
                </div>
            {/if}
        </div>
    </div>
{/if}

<style lang="scss">
    @import "../../../common/styles/colors.new.scss";

    .gift-details-container {
        display: flex;
        align-items: center;
        gap: 4px;
    }
</style>
