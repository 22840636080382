<script lang="ts">
    import { getAppConfig } from "../../../common/config/app-config";
    import { getStr } from "../../../common/util";
    import Icon from "../../common/components/Icon.svelte";
    import Text from "../../common/components/Text.svelte";

    export let expressDeliveryMode;
    export let onDemandDeliveryMode;

    const deliveryItemsMetaData = {
        onDemand: {
            icon: "icon-on_demand_delivery",
            titleKey: "extra.pdp.delivery.location.onDemand.title",
            amount: 30,
            subtitleKey: "extra.pdp.delivery.location.onDemand.subtitle",
        },
        express: {
            icon: "icon-express_delivery",
            titleKey: "extra.pdp.delivery.location.express.delivery.title",
            amount: 19,
            subtitleKey:
                "extra.pdp.delivery.location.express.delivery.subtitle",
        },
    };

    const mainTitleKey = "extra.pdp.delivery.location.title";
    const mainSubtitleKey = "extra.pdp.delivery.location.subtitle";
    const { currency } = getAppConfig();

    $: deliveryOptions = getDeliveryOptions(
        deliveryItemsMetaData,
        expressDeliveryMode,
        onDemandDeliveryMode,
    );

    function getDeliveryOptions(
        metadata,
        expressDeliveryMode,
        onDemandDeliveryMode,
    ) {
        const outputOptions = [];
        if (onDemandDeliveryMode !== null)
            outputOptions.push(
                Object.assign({}, metadata.onDemand, {
                    amount: onDemandDeliveryMode.deliveryCost.value,
                }),
            );
        if (expressDeliveryMode !== null)
            outputOptions.push(
                Object.assign({}, metadata.express, {
                    amount: expressDeliveryMode.deliveryCost.value,
                }),
            );

        return outputOptions;
    }
</script>

{#if deliveryOptions?.length > 0}
    <section class="on-demand-pdp-block-container">
        <h3 class="text-bold"><Text key={mainTitleKey} /></h3>
        <p class="main-subtitle text-normal"><Text key={mainSubtitleKey} /></p>
        <section class="delivery-options">
            {#each deliveryOptions as { icon, titleKey, amount, subtitleKey }}
                <section class="delivery-option">
                    <div class="first-line">
                        <Icon
                            type="primary"
                            desktopSize={34}
                            mobileSize={24}
                            glyph={icon}
                        />
                        <h3 class="text-bold"><Text key={titleKey} /></h3>
                        <p
                            class:green={+amount === 0}
                            class="text-bold text-amount"
                        >
                            {amount > 0
                                ? `${amount} ${currency}`
                                : getStr("delivery.fee.free")}
                        </p>
                    </div>
                    <div class="second-line">
                        <p class="text-normal"><Text key={subtitleKey} /></p>
                    </div>
                </section>
            {/each}
        </section>
    </section>
{/if}

<style lang="scss">
    @import "../../common/styles/colors.new.scss";

    .on-demand-pdp-block-container {
        h3,
        p {
            margin: 0;
        }
        .text-bold {
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            color: $neutral-dark-01;
        }
        .text-normal {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: $black;

            @include desktop-min-width {
                font-size: 14px;
            }

            &.main-subtitle {
                color: $neutral-dark-01;
            }
        }
        .text-amount {
            color: $functional-orange;

            &.green {
                color: $functional-green-01;
            }
        }

        .main-subtitle {
            margin-block: 4px 18px;
        }
        .delivery-options {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @include desktop-min-width {
                flex-direction: row;
                justify-content: space-between;
            }

            .delivery-option {
                display: flex;
                flex-direction: column;
                gap: 2px;

                @include desktop-min-width {
                    max-width: 45%;
                }

                .first-line {
                    display: flex;
                    gap: 4px;
                    align-items: center;

                    h3 {
                        flex-grow: 1;
                    }
                }
            }
        }
    }
</style>
