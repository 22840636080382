<script lang="ts">
    export let data: any;

    $: attributeLabels = data?.attributeLabels;
</script>

<div class="product-delivery-info-container">
    {#if attributeLabels?.digitalDeliverylabel?.imageUrl && attributeLabels?.digitalDeliverylabel?.content}
        <div class="img-text-wrapper">
            <img
                src={attributeLabels?.digitalDeliverylabel?.imageUrl}
                alt="delivery info"
            />
            <div class="title-text">
                {@html attributeLabels?.digitalDeliverylabel?.content}
            </div>
        </div>
        {#if attributeLabels?.digitalDeliveryInstruction?.content}
            <div class="sub-title-text">
                {attributeLabels?.digitalDeliveryInstruction?.content}
            </div>
        {/if}
    {/if}
</div>

<style lang="scss">
    @import "../../../common/styles/colors.new.scss";

    .product-delivery-info-container {
        background-color: $primary-white;
        padding: 8px;
        border-radius: 8px;

        .img-text-wrapper {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 8px;
        }
        img {
            height: 24px;
            object-fit: contain;
        }
        .title-text {
            color: $neutral-dark-01;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
        }
        .sub-title-text {
            color: $neutral-dark-02;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
        }
    }
</style>
