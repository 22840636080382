<script lang="ts" context="module">
    import { getAppConfig } from "../../../../common/config/app-config";
    import { getJoodMembershipType, getStr } from "../../../../common/util";
    import AtcFormatPrice from "./AtcFormatPrice.svelte";
    const customerJoodType = getJoodMembershipType();
    const { currency } = getAppConfig();
</script>

<script lang="ts">
    export let vasPrice;
</script>

{#if vasPrice?.primeDiscount}
    {#if customerJoodType === "BASICPRIME"}
        <div class="c_product-primemember">
            <div class="c_product-primemember-txt blue-bg white-font">
                {getStr("extra.pdp.prime.blue.display.text")}
            </div>
            <div class="c_product-primemember-price">
                <AtcFormatPrice
                    priceData={vasPrice.basicPrimeDiscountPrice}
                    displayFreeForZero={true}
                    addCurrency={true}
                />
            </div>
        </div>
    {:else if customerJoodType === "VIPPRIME"}
        <div class="c_product-primemember">
            <div class="c_product-primemember-txt gold-bg">
                {getStr("extra.pdp.prime.gold.display.text")}
            </div>
            <div class="c_product-primemember-price">
                <AtcFormatPrice
                    priceData={vasPrice.vipPrimeDiscountPrice}
                    displayFreeForZero={true}
                    addCurrency={true}
                />
            </div>
        </div>
    {/if}
{/if}
