<script lang="ts">
    import Tag from "./Tag.svelte";
    import { getAppConfig } from "../../../../common/config/app-config";
    const {
        primeLevel,
        isMegaSaleDayXStarted,
        joodMegaSaleTag,
        isMegaSaleDuringAccessStarted,
    } = getAppConfig();

    export let productTags;
    if (productTags && productTags.length > 0) {
        productTags.map((x) => {
            if (x.productStatus === "JOOD_DAY") {
                delete x.textColorCode;
            }
        });
    }
    const getMegaSaleStyleDetails = (tag, type) => {
        if (tag?.productStatus === joodMegaSaleTag) {
            if (!tag?.[type] || tag?.[type] == "" || tag?.[type] == null)
                return type;
        }
        return "";
    };
</script>

{#if productTags}
    <section class="tag-container">
        {#each productTags as tag}
            {#if (isMegaSaleDayXStarted && tag?.productStatus === joodMegaSaleTag) || (isMegaSaleDuringAccessStarted && tag?.productStatus === joodMegaSaleTag && primeLevel)}
                <Tag
                    {...tag}
                    megaSaleTextFlag={getMegaSaleStyleDetails(
                        tag,
                        "backgroundColorCode",
                    )}
                    megaSaleBgFlag={getMegaSaleStyleDetails(
                        tag,
                        "textColorCode",
                    )}
                />
            {:else if tag?.productStatus != joodMegaSaleTag}
                <Tag {...tag} />
            {/if}
        {/each}
    </section>
{/if}

<style lang="scss">
    @import "../../styles/colors.new.scss";
    .tag-container {
        display: flex;
        gap: 4px;
        padding: 8px 16px 0;
        // background-color: $neutral-light-04;

        @include desktop-min-width {
            background-color: transparent;
            padding: 0;
        }

        @include responsive {
            margin: 0;
            padding: 8px 0 0;
        }
    }
</style>
