<script lang="ts" context="module">
    import { getAppConfig } from "../../../../../common/config/app-config";
    import {
        JoodMembershipType,
        formatNumber,
        getJoodMembershipType,
        getStr,
        isMobile,
        reverseChildNodesForArabicLang,
        slideOpenAnimation,
    } from "../../../../../common/util";

    const { currency, themeResourcePath, encodedContextPath } = getAppConfig();
    const joodCardImageUrl = `${themeResourcePath}/images/jood/jood-gold-card.png`;
    const isResponsive = isMobile();
</script>

<script lang="ts">
    import Icon from "../../Icon.svelte";
    import JoodDayBannerNew from "../../../../product-listing-search/components/JoodDayBannerNew.svelte";
    import type { ProductTilePriceGroupVariantType } from "./ProductTilePriceNewGroup.svelte";

    export let algoliaProductHit;
    export let variant: ProductTilePriceGroupVariantType;

    const title = isResponsive
        ? getStr("extra.plus.jood.gold.text")
        : getStr("extra.pdp.prime.gold.members.display.text");

    let open: boolean = false;
    let priceType: JoodMembershipType = "VIPPRIME";
    const joodSubscribeLinkHref = `${encodedContextPath}/jood/benefits`;

    const userJoodMembershipType: JoodMembershipType = getJoodMembershipType();

    $: displayPrice = algoliaProductHit?.vipPrimePrice;
    $: wasPrice = algoliaProductHit.wasPrice;
    $: savedPrice = wasPrice - displayPrice;
    $: hasSavings = savedPrice > 0;
    $: visible = displayPrice > 0;
    $: subscribeLinkVisible = getSubscribeLinkVisibility(
        priceType,
        userJoodMembershipType,
    );
    $: subscribeLinkText = getSubscribeLinkText(
        priceType,
        userJoodMembershipType,
        variant,
    );

    function getSubscribeLinkVisibility(
        priceType: JoodMembershipType,
        userJoodMembershipType: JoodMembershipType,
    ): boolean {
        /*
            Rules:
            1. if gray member, subscribe link on gold price
            2. if blue member, subscribe link on gold price
            3. if gold member, no subscribe link
        */
        const { tacticalBasicPromo, tacticalVipPromo } = algoliaProductHit;
        const hasTacticalPromo = tacticalBasicPromo || tacticalVipPromo;

        let visibility = true;
        if (hasTacticalPromo) {
            visibility = false;
        }

        return visibility;
    }

    function getSubscribeLinkText(
        priceType: JoodMembershipType,
        userJoodMembershipType: JoodMembershipType,
        variant: ProductTilePriceGroupVariantType,
    ): string {
        /*
            Rules:
            1. if gray member, subscribe text on gold price
            2. if blue member, upgrade text on gold price
            3. if gold member, no subscribe text
        */
        let text: string = "";
        if (userJoodMembershipType === "NOPRIME" && priceType === "VIPPRIME") {
            text =
                variant === "plp-list"
                    ? getStr("extra.jood.subscribe.message")
                    : getStr("extra.jood.gold.subscribe.message"); // rule 1
        } else if (
            userJoodMembershipType === "BASICPRIME" &&
            priceType === "VIPPRIME"
        ) {
            text = getStr("extra.jood.gold.upgrade.message"); // rule 2
        }

        return text;
    }
</script>

{#if visible}
    <button
        class="product-tile-price-gold-accordion variant-{variant}"
        class:open
        on:click|preventDefault|stopPropagation={() => (open = !open)}
    >
        <section class="down-arrow" class:rotate180={open}>
            <Icon
                type="font"
                glyph="chevron_down"
                mobileSize={14}
                desktopSize={14}
            />
        </section>
        {#if !open}
            <section class="header" transition:slideOpenAnimation>
                <h2>{title}</h2>
                <section class="price" use:reverseChildNodesForArabicLang>
                    {currency} <strong>{displayPrice}</strong>
                </section>
            </section>
        {:else}
            <section class="content" transition:slideOpenAnimation>
                <section class="gold-price-section">
                    {#if !isResponsive || variant === "pdp"}
                        <img src={joodCardImageUrl} alt="Jood gold" />
                    {/if}
                    <section class="gold-price-details">
                        <h2>{title}</h2>
                        <section class="price">
                            <span use:reverseChildNodesForArabicLang
                                >{currency}
                                <strong>{formatNumber(displayPrice)}</strong
                                ></span
                            >
                            <span class="vat">{getStr("extra.vatLable")}</span>
                        </section>
                        {#if hasSavings}
                            <section class="savings">
                                <span class="striked-off"
                                    >{formatNumber(wasPrice)}</span
                                >
                                <strong class="orange-text">
                                    {@html getStr("extra.cart.saved.amt", [
                                        currency,
                                        formatNumber(savedPrice),
                                    ])}
                                </strong>
                            </section>
                        {/if}
                        {#if subscribeLinkVisible}
                            <a
                                class="subscribe-upgrade-link"
                                on:click|stopPropagation
                                href={joodSubscribeLinkHref}
                            >
                                {subscribeLinkText}
                            </a>
                        {/if}
                    </section>
                </section>
                <JoodDayBannerNew
                    {variant}
                    bannerType="VIPPRIME"
                    {algoliaProductHit}
                    backgoundOverride="NOPRIME"
                />
            </section>
        {/if}
    </button>
{/if}

<style lang="scss">
    @import "../../../../common/styles/colors.new.scss";

    .product-tile-price-gold-accordion {
        border-radius: 12px;
        background-color: $jood-gold-light;
        color: $neutral-dark-01;
        position: relative;
        flex-basis: 100%;
        text-align: start;
        border: none;
        padding: 0;
        font-family: inherit;
        @include responsive {
            border-radius: 8px;
        }

        .down-arrow {
            position: absolute;
            right: 12px;
            top: 16px;
            color: $icon-only-icon-blue;
            transition: transform 250ms ease;

            @include responsive {
                right: 4px;
                top: 4px;
            }
            &.rotate180 {
                transform: rotateZ(180deg);
            }
        }
        .header {
            padding: 12px 32px 12px 12px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;

            @include responsive {
                padding: 8px 20px 8px 8px;
            }
        }
        h2 {
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;
            margin: 0;

            @include responsive {
                font-size: 10px;
                line-height: 11px;
            }
        }
        .price {
            font-size: 22px;
            line-height: 22px;
            font-weight: 400;

            @include responsive {
                font-size: 10px;
                line-height: 11px;
            }

            .vat {
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                color: $neutral-dark-04;

                @include responsive {
                    display: none;
                }
            }
        }
        .content {
            padding: 10px 8px;
            display: flex;
            flex-direction: column;
            gap: 14px;

            @include responsive {
                gap: 4px;
            }

            .gold-price-section {
                display: flex;
                align-items: flex-start;
                gap: 6px; // 12px; reduced to fix MAVD-13926

                img {
                    height: 50px;
                    object-fit: contain;
                    border-radius: 4px;
                }
                .price {
                    margin-top: 8px;
                    font-size: 28px;
                    line-height: 32px;

                    @include responsive {
                        font-size: 16px;
                        line-height: 18px;
                        margin-top: 4px;
                    }

                    &:first-child {
                        margin-top: 0;
                    }
                }
                .savings {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }
                .striked-off {
                    color: $neutral-dark-03;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    text-decoration: line-through;

                    @include responsive {
                        font-size: 10px;
                        line-height: 14px;
                    }
                }
                .orange-text {
                    color: $functional-orange;
                    font-size: 14px;
                    line-height: 18px;

                    @include responsive {
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
                .subscribe-upgrade-link {
                    margin-top: 8px;
                    color: $tertiary-blue-02;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }

        &.variant-product-tile {
            .content .gold-price-section img {
                height: 44px;
            }
        }

        &.variant-plp-list {
            @include responsive {
                .down-arrow {
                    right: 6px;
                    top: 6px;
                }
                .header {
                    padding: 8px 24px 8px 8px;
                    h2 {
                        font-size: 12px;
                        line-height: 11px;
                    }
                    .price {
                        font-size: 16px;
                        line-height: 11px;
                    }
                }
            }
        }
        &.variant-pdp {
            @include responsive {
                .down-arrow {
                    right: 8px;
                    top: 14px;

                    :global(i) {
                        font-size: 16px;
                    }
                }
                .header {
                    padding: 12px 30px 12px 12px;
                }
                h2 {
                    font-size: 14px;
                    line-height: 14px;
                }
                .price {
                    font-size: 22px;
                    line-height: 24px;
                }

                .content {
                    display: flex;
                    gap: 8px;
                    flex-direction: column;
                    .gold-price-section {
                        .gold-price-details {
                            .price {
                                font-size: 22px;
                                line-height: 18px;
                                margin-top: 6px;
                            }
                            .savings {
                                .striked-off,
                                .orange-text {
                                    font-size: 14px;
                                    line-height: 14px;
                                    margin-top: 4px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
