<script lang="ts">
    import VDivider from "./../VDivider.svelte";
    import {
        giftCardAmount,
        productDetails,
        tasheelPrice,
    } from "../../store/pdp_store";
    import { formatNumber } from "../../../../common/util";
    import Text from "./../../../common/components/Text.svelte";
    import Icon from "../../../common/components/Icon.svelte";
    import { getAppConfig } from "../../../../common/config/app-config";

    export let data: any;
    export let isMobileSticky = false;

    const {
        algoliaConfig: { tasheelInfo },
        currency,
        lang,
    } = getAppConfig();

    let isTasheelShow = false;

    $: tasheelImageUrl = data?.tasheelImageUrl;
    $: tahsheelPaymentAttributes = data?.tahsheelPaymentAttributes;
    $: tasheelMinPrice = tahsheelPaymentAttributes?.tasheelMinPrice;
    $: tasheelInstallMonths = tahsheelPaymentAttributes?.tasheelMonths;
    $: tasheelPercentage = tahsheelPaymentAttributes?.tasheelPercentage;

    $: if (parseFloat($giftCardAmount) > parseFloat(tasheelMinPrice)) {
        isTasheelShow = true;
    } else {
        isTasheelShow = false;
    }
</script>

<section
    class={$giftCardAmount > 0
        ? "gift-card-price-container product_price"
        : "gift-card-price-container product_price hide"}
>
    {#if isMobileSticky}
        <div class="tasheel-wrapper">
            {lang === "en" ? currency + " " : ""}<strong
                >{formatNumber($giftCardAmount)}</strong
            >{lang === "ar" ? " " + currency : ""}
            {#if isTasheelShow && tasheelPrice && tasheelImageUrl && tasheelPercentage}
                <Text
                    key="extra.pdp.tasheel.installment.text"
                    args={[currency, $tasheelPrice, tasheelInstallMonths]}
                />
            {/if}
        </div>
    {:else}
        <div class="product_price__left">
            <div class="price-row">
                <div class="amount-info-tasheel">
                    {#if lang == "en"}{currency}
                    {/if}
                    <span class="amount">{formatNumber($giftCardAmount)}</span>
                    {#if lang == "ar"}{currency}
                    {/if}
                </div>
                <span class="vat-info"><Text key="extra.vatLable" /></span>
            </div>
        </div>

        <!-- {#if tasheelPrice && tasheelImageUrl && tasheelPercentage && !$productDetails.isDigitalGiftCard} -->
        {#if isTasheelShow && tasheelPrice && tasheelImageUrl && tasheelPercentage}
            <VDivider />
            <div class="product_price__right">
                <div class="price-row">
                    <div class="amount-info-tasheel">
                        <span class="amount">
                            {#if lang == "en"}
                                <span class="tasheel-currency">{currency}</span>
                            {/if}
                            {$tasheelPrice}
                            {#if lang == "ar"}
                                <span class="tasheel-currency">{currency}</span>
                            {/if}
                        </span>
                        <span class="amount"
                            >/<Text key="extra.pdp.monthLable" /></span
                        >
                    </div>
                    <span class="tasheel-info">
                        <Icon
                            type="url"
                            glyph={`${tasheelInfo.tasheelImageUrl ? tasheelInfo.tasheelImageUrl : tasheelImageUrl}`}
                            mobileSize={16}
                            desktopSize={25}
                        />
                    </span>
                </div>
                <div class="finance-row">
                    <div class="finance-text">
                        <Text
                            key="extra.tasheel.priceValue.desc"
                            args={[tasheelPercentage, tasheelInstallMonths]}
                        />
                    </div>
                </div>
            </div>
        {/if}
    {/if}
</section>

<style lang="scss">
    @import "../../../common/styles/colors.new.scss";

    .tasheel-wrapper {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: $primary-black;
        white-space: nowrap;
    }
    .product_price {
        display: flex;
        color: $neutral-dark-01;
        gap: 16px;
        background-color: $primary-white;
        padding: 24px 16px 0;
        &.gift-card-price-container {
            &.hide {
                display: none;
            }
        }
    }
    .tasheel-currency {
        font-weight: 400;
    }

    .price-row,
    .discount-row {
        display: flex;
        align-items: baseline;
        gap: 5px;
    }
    .price-row {
        .amount-info-tasheel {
            font-weight: 400;
            font-size: 18px;
            line-height: 20px;
            display: flex;
            white-space: nowrap;
            .amount {
                font-weight: 700;
            }
        }
    }
    .discount-row {
        align-items: center;
    }
    .vat-info {
        color: $neutral-dark-04;
        font-weight: 400;
        font-size: 10px;
        line-height: 11px;
    }
    .old-price {
        text-decoration: line-through;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
    }
    .price-savings {
        color: $functional-orange;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
    }
    .percent-discount {
        color: $primary-white;
        background-color: $functional-orange;
        padding: 4px 8px;
        border-radius: 5px;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
    }
    .offer-options {
        color: $icon-only-icon-blue;
        margin-top: 12px;

        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
    }
    .tasheel-info {
        align-self: center;
    }
    .finance-text {
        font-weight: 400;
        font-size: 11px;
        line-height: 12px;
    }

    @include desktop-min-width {
        .product_price {
            padding: 24px 0 0;
        }
        .price-row {
            .amount-info-tasheel {
                font-weight: 400;
                font-size: 28px;
                line-height: 32px;
                .amount {
                    font-weight: 700;
                }
            }
        }
        .vat-info {
            font-size: 12px;
            line-height: 14px;
        }
        .old-price,
        .price-savings {
            font-size: 16px;
            line-height: 18px;
        }
        .offer-options {
            margin-top: 14px;
        }
        .finance-text {
            font-size: 16px;
            line-height: 18px;
        }
    }
</style>
