<script lang="ts">
    export let index = 1;
    export let showDoubleDigit = false;
    export let recommendationType = 1;

    let dounleDigitIndex = [];

    let recommendationClassName = "bestSelling";
    if (Number(recommendationType) == 2) {
        recommendationClassName = "popular";
    }

    if (showDoubleDigit) {
        if (String(index).length == 1) {
            dounleDigitIndex.push("0", index);
        } else {
            dounleDigitIndex = String(index).split("");
        }
    } else {
        dounleDigitIndex.push(index);
    }
</script>

<div class="index-container {recommendationClassName}">
    {#each dounleDigitIndex as index}
        <span class="index-digit">{index}</span>
    {/each}
</div>

<style lang="scss">
    @import "../../../../common/styles/colors.new.scss";
    .index-container {
        font-size: 200px;
        line-height: 150px;
        font-weight: 700;

        display: flex;
        flex-direction: column;
        background-color: transparent;
        margin-top: 3px;
        @include desktop-min-width {
            font-size: 280px;
            line-height: 210px;
        }
        &.bestSelling {
            color: $neutral-light-04;
            text-shadow:
                -2px 2px 0 $neutral-dark-05,
                2px 2px 0 $neutral-dark-05,
                2px -2px 0 $neutral-dark-05,
                -2px -2px 0 $neutral-dark-05;
        }
        &.popular {
            color: $primary-black;
            text-shadow:
                -2px 2px 0 $color-yellow,
                2px 2px 0 $color-yellow,
                2px -2px 0 $color-yellow,
                -2px -2px 0 $color-yellow;
        }
    }
</style>
