<script lang="ts">
    import { onMount } from "svelte";
    import { getAppConfig } from "../../../../common/config/app-config";
    import { formatDealTimer } from "../../../../common/util";

    export let product;
    export let fontSizeMobile = 10;
    export let lineHeightMobile = 12;
    export let fontWeight = 700;
    export const timerTag = true;

    export let fontSizeDesktop = 14;
    export let lineHeightDesktop = 16;

    export let paddingTop = 12;
    export let paddingBottom = 4;
    export let paddingLeft = 8;
    export let paddingRight = 8;
    export let paddingTopDesktop = 16;
    export let paddingBottomDesktop = 4;
    export let paddingLeftDesktop = 12;
    export let paddingRightDesktop = 12;

    export let borderRadLTop = 0;
    export let borderRadRTop = 0;
    export let borderRadLBot = 8;
    export let borderRadRBot = 8;

    export const bgColorCode = "#f4ca24";
    export let theme = "";

    const { lang } = getAppConfig();

    let dealTimerIntervalId = null;

    $: dealTimer = product?.dealTimer;

    function setupDealTimerInterval() {
        dealTimerIntervalId = setInterval(() => {
            const dealTime = dealTimer;
            dealTime.secDiff = +dealTimer.secDiff - 1;
            if (dealTime.secDiff < 0) {
                dealTime.minDiff = +dealTimer.minDiff - 1;
                dealTime.secDiff = 59;
            }
            if (dealTime.minDiff < 0) {
                dealTime.hDiff = +dealTimer.hDiff - 1;
                dealTime.minDiff = 59;
            }
            if (dealTime.hDiff < 0) {
                dealTime.deals = false;
                clearInterval(dealTimerIntervalId);
            }
            dealTimer = formatDealTimer(dealTime);
        }, 1000);
    }

    onMount(() => {
        if (product.dealTimer.deals === true) {
            setupDealTimerInterval();
        }
        return () => {
            clearInterval(dealTimerIntervalId);
        };
    });
</script>

{#if dealTimer.hDiff}
    <section
        class="tag-wrapper {theme}"
        style:--pad-top="{paddingTop}px"
        style:--pad-bottom="{paddingBottom}px"
        style:--pad-left="{paddingLeft}px"
        style:--pad-right="{paddingRight}px"
        style:--pad-top-desktop="{paddingTopDesktop}px"
        style:--pad-bottom-desktop="{paddingBottomDesktop}px"
        style:--pad-left-desktop="{paddingLeftDesktop}px"
        style:--pad-right-desktop="{paddingRightDesktop}px"
        style:--font-size="{fontSizeMobile}px"
        style:--line-height="{lineHeightMobile}px"
        style:--font-size-desktop="{fontSizeDesktop}px"
        style:--line-height-desktop="{lineHeightDesktop}px"
        style:--font-weight={fontWeight}
        style:--border-top-left-radius="{borderRadLTop}px"
        style:--border-top-right-radius="{borderRadRTop}px"
        style:--border-bottom-left-radius="{borderRadLBot}px"
        style:--border-bottom-right-radius="{borderRadRBot}px"
    >
        <div class="deal-time" class:rtl={lang == "ar"}>
            <!-- {#if lang == 'ar'}
            <span class="d-none">Arabic</span>
            <span class="time-space hour">{dealTimer.hDiff}</span>:<span class="time-space minute">{dealTimer.minDiff}</span>:<span class="time-space sec">{dealTimer.secDiff}</span>
        {:else} -->
            <span class="time-space hour">{dealTimer.hDiff}</span>:<span
                class="time-space minute">{dealTimer.minDiff}</span
            >:<span class="time-space sec">{dealTimer.secDiff}</span>
            <!-- {/if} -->
        </div>
    </section>
{/if}

<style lang="scss">
    @import "../../styles/colors.new.scss";
    .tag-wrapper {
        padding: var(--pad-top) var(--pad-right) var(--pad-bottom)
            var(--pad-left);
        font-size: var(--font-size);
        line-height: var(--line-height);
        font-weight: var(--font-weight);
        border-radius: var(--border-top-left-radius)
            var(--border-top-right-radius) var(--border-bottom-left-radius)
            var(--border-bottom-right-radius);
        width: fit-content;
        background-color: $functional-yellow;
        &.orange {
            color: $primary-white;
            background-color: $functional-orange;
        }

        &.yellow {
            background-color: $functional-yellow;
            color: $neutral-dark-01;
        }

        &.gray {
            background-color: $secondary-gray-02;
            color: $primary-blue;
        }
        @include desktop-min-width {
            font-size: var(--font-size-desktop);
            line-height: var(--line-height-desktop);
            padding: var(--pad-top-desktop) var(--pad-right-desktop)
                var(--pad-bottom-desktop) var(--pad-left-desktop);
        }
        .deal-time {
            white-space: nowrap;
            
            @include desktop-min-width {
                &.rtl {
                    direction: initial;
                }
            }
            .time-space {
                @include desktop-min-width {
                    &.hour {
                        &::after {
                            content: " ";
                        }
                    }
                    &.minute {
                        &::after {
                            content: " ";
                        }
                        &::before {
                            content: " ";
                        }
                    }
                    &.sec {
                        &::before {
                            content: " ";
                        }
                    }
                }
            }
        }
    }
</style>
