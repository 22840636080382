import { get } from 'svelte/store';
import { getAppConfig } from '../../../common/config/app-config';
import { APP_CONSTANTS,getBaseWebServiceUrl } from '../../../common/config/app-constants';
import { httpGetJSON, httpPostRaw, } from '../../../common/services/ajax-services';
import { cartLoginDetails } from '../store/header_store';

let BASE_WEB_SERVICE_URL = getBaseWebServiceUrl();
const { encodedContextPath, jessionId, lang, CSRFToken } = getAppConfig();

export async function getMegaMenuJSON({ fields = 'FULL', lang = 'en', deviceId = jessionId }) {
    const url = `${BASE_WEB_SERVICE_URL}/${APP_CONSTANTS.MEGA_MENU_URL}`;
    return httpGetJSON(url, { fields, lang, deviceId });
}

export async function getCityList(countryCode) {
    // /cities/{countryCode}
    const relativeUrl = APP_CONSTANTS.CITY_LIST.replace('@COUNTRY', countryCode);
    const url = `${BASE_WEB_SERVICE_URL}/${relativeUrl}`;
    return httpGetJSON(url, {});
}

export async function changeCity(city, encodedContextPath, csrfToken) {
    const { lang, jessionId } = getAppConfig();
    const firstRequestQS = {
        fields: 'FULL',
        lang,
        deviceId: jessionId,
        cityCode: city.code
    };
    await httpPostRaw(
        `${BASE_WEB_SERVICE_URL}/stores/updateSessionCity`
    );

    const csrfString = `CSRFToken=${csrfToken}`;
    const relativeUrl = APP_CONSTANTS.CHANGE_CITY_URL.replace('@CITY', city.code);
    const url = `${encodedContextPath}/${relativeUrl}`;
        // .replace(APP_CONSTANTS.EXTRA_BASE_URL, "https://localhost:9002");
    return httpPostRaw(url, csrfString);
}

export function getCartLoginDetails(fromCache = false) {
    // fromCache: true => return cartDetails from header_store, if available
    if (fromCache && get(cartLoginDetails) !== null) {
        return Promise.resolve(get(cartLoginDetails));
    }
    const url = `${encodedContextPath}/${APP_CONSTANTS.CART_LOGIN_DETAILS}`;
    return httpGetJSON(url).then(cartDetails => {
        cartLoginDetails.set(cartDetails);
        return cartDetails;
    });
}
export async function getHeaderLinks({ fields = 'FULL', lang = 'en', deviceId = jessionId }) {
    const url = `${BASE_WEB_SERVICE_URL}/${APP_CONSTANTS.HEADER_LINKS}`;
    return httpGetJSON(url, {fields, lang, deviceId});
}

export async function changeLanguagePost() {
    const newLang = lang === 'en' ? 'ar' : 'en';
    const body = `code=${newLang}&CSRFToken=${CSRFToken}`;
    const url = `${encodedContextPath}/${APP_CONSTANTS.LANGUAGE_CHANGE_POST}`;

    return httpPostRaw(url, body, { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' })
        .then(res => {
            window.location.assign(res.url);
        });
}