<script>
    export let width = "1";
</script>

<div class="divider" style="width: {width}px;"></div>

<style lang="scss">
    @import "../../common/styles/colors.new.scss";
    .divider {
        background-color: $secondary-gray-02;
    }
</style>
