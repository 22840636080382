import { get, Writable } from "svelte/store";
import { getAppConfig } from "../../../common/config/app-config";
import { cartLoginDetails } from "../../header/store/header_store";
import { getUrlParam, parseUrlQuery } from "../../product-listing-search/services/plp-data-services";
import { facetFilters } from "../../product-listing-search/store/plp-data-store";
import { brandFacetFilters, brandPriceFilters } from "../store/brand_store";

var PrevBannerId = null;
var alreadyViwedBannerId = [];

export const {
  APP_CONFIG,
  algoliaConfig: { countdownThreshold },
  sellingOutFast
} = getAppConfig();


export function categoryCodeParser(url) {
  let categoryCode = url.split("c/")[1]?.split("/facet")[0];
  return categoryCode;
}

export function parseUrl(qry) {
  parseUrlQuery(qry);
  let optionalFacet = get(facetFilters);
  return optionalFacet;
}

export function getHitsPerPageFromUrl(urlParams) {
  return getUrlParam("pageSize", urlParams) === null ? APP_CONFIG.algoliaConfig.selectedPageSize : getUrlParam("pageSize", urlParams);
}

export function getPageFromUrl(urlParams) {
  return urlParams.split("&pg=").length > 1 ? urlParams.split("&pg=")[1].split("&")[0] : 0;
}

export function createFacetFilter(filterName, filterValue) {
  if (get(brandFacetFilters).indexOf(`${filterName}:${filterValue}`) === -1) {
    brandFacetFilters.update(x => {
      x = [...get(brandFacetFilters), `${filterName}:${filterValue}`];
      return x
    });
  } else {
    brandFacetFilters.set(get(brandFacetFilters).filter(item => item !== `${filterName}:${filterValue}`));
  }
  return get(brandFacetFilters);
}

export function createCategoryFacetFilter(filterName, filterValue, catFamilyFacetFilters: Writable<any[]>, catSubFamilyFacetFilters: Writable<any[]>, catBrandFacetFilters: Writable<any[]>,) {
  if (filterName === "familyEn" || filterName === "familyAr") {
    if (get(catFamilyFacetFilters).indexOf(`${filterName}:${filterValue}`) === -1) {
      catFamilyFacetFilters.update(x => {
        x = [...get(catFamilyFacetFilters), `${filterName}:${filterValue}`];
        return x
      });
    } else {
      catFamilyFacetFilters.set(get(catFamilyFacetFilters).filter(item => item !== `${filterName}:${filterValue}`));
    }
    return get(catFamilyFacetFilters);
  }

  if (filterName === "subFamilyEn" || filterName === "subFamilyAr") {
    if (get(catSubFamilyFacetFilters).indexOf(`${filterName}:${filterValue}`) === -1) {
      catSubFamilyFacetFilters.update(x => {
        x = [...get(catSubFamilyFacetFilters), `${filterName}:${filterValue}`];
        return x
      });
    } else {
      catSubFamilyFacetFilters.set(get(catSubFamilyFacetFilters).filter(item => item !== `${filterName}:${filterValue}`));
    }
    return get(catSubFamilyFacetFilters);
  }

  if (filterName === "brandEn" || filterName === "brandAr") {
    if (get(catBrandFacetFilters).indexOf(`${filterName}:${filterValue}`) === -1) {
      catBrandFacetFilters.update(x => {
        x = [...get(catBrandFacetFilters), `${filterName}:${filterValue}`];
        return x
      });
    } else {
      catBrandFacetFilters.set(get(catBrandFacetFilters).filter(item => item !== `${filterName}:${filterValue}`));
    }
    return get(catBrandFacetFilters);
  }

  // if (get(categoryFacetFilters).indexOf(`${filterName}:${filterValue}`) === -1) {
  //   categoryFacetFilters.update( x => {
  //         x = [...get(categoryFacetFilters),`${filterName}:${filterValue}`];
  //         return x
  //     });
  // } else {
  //   categoryFacetFilters.set(get(categoryFacetFilters).filter(item =>    item !== `${filterName}:${filterValue}`));
  // }
  // return get(categoryFacetFilters);
}



export function priceFilterRange(arr, n) {
  arr = arr.sort(function (x, y) { return x - y });

  if (n < 2) return [arr];

  const size = Math.ceil(arr.length / n);

  const result = arr.reduce((resultArray, item, index) => {
    const arrayIndex = Math.floor(index / size)
    if (!resultArray[arrayIndex]) {
      resultArray[arrayIndex] = []
    }
    resultArray[arrayIndex].push(item)
    return resultArray
  }, [])
  return result
}

export function formatPrice(num) {
  return Intl.NumberFormat('en-US').format(num);
}

export function createPriceFilter(from, to) {
  from = +from;
  to = +to;
  if (get(brandPriceFilters).indexOf(`price:${from} TO ${to}`) === -1) {
    brandPriceFilters.update(x => {
      x = [...get(brandPriceFilters), `price:${from} TO ${to}`];
      return x
    });
  } else {
    brandPriceFilters.set(get(brandPriceFilters).filter(item => item !== `price:${from} TO ${to}`));
  }
  return get(brandPriceFilters);
}

export function createPriceFilterCategory(from, to, categoryPriceFilters: Writable<any[]>) {
  from = +from;
  to = +to;
  if (get(categoryPriceFilters).indexOf(`price: ${from} TO ${to}`) === -1) {
    categoryPriceFilters.update(x => {
      x = [...get(categoryPriceFilters), `price: ${from} TO ${to}`];
      return x
    });
  } else {
    categoryPriceFilters.set(get(categoryPriceFilters).filter(item => item !== `price: ${from} TO ${to}`));
  }
  return get(categoryPriceFilters);
}

export function createCatergoryDiscountFilter(from, to, categoryDiscountFilter: Writable<any[]>) {
  from = +from;
  to = +to;
  if (get(categoryDiscountFilter).indexOf(`priceValueDiscountPercentage: ${from} TO ${to}`) === -1) {
    categoryDiscountFilter.update(x => {
      x = [...get(categoryDiscountFilter), `priceValueDiscountPercentage: ${from} TO ${to}`];
      return x
    });
  } else {
    categoryDiscountFilter.set(get(categoryDiscountFilter).filter(item => item !== `priceValueDiscountPercentage: ${from} TO ${to}`));
  }
  return get(categoryDiscountFilter);
}

export function getRanges(arr, n) {
  arr = arr.filter(Number);
  const ranges = [
    { min: 0, max: 25, n: 0 },
    { min: 25, max: 50, n: 0 },
    { min: 50, max: 75, n: 0 },
    { min: 75, max: 100, n: 0 }
  ];

  arr.forEach(p => {
    const index = Math.floor(p / 25);
    ranges[index].n++;
  });

  return ranges;
}

export function brandGtmFilterEvent(filterClicked, rank) {
  if (window['ACC'].extragtm !== undefined) {
    window['ACC'].extragtm.click.plpFilterClick(filterClicked, rank);
  }
}

export function brandGtmSortEvent(sortClicked) {
  if (window['ACC'].extragtm !== undefined) {
    window['ACC'].extragtm.click.plpSortClicks(sortClicked);
  }
}

export function brandGtmBannerClickEvent(bannerId, bannerName, brandName, isSponsored) {
  if (window['ACC'].extragtm !== undefined) {
    window['ACC'].extragtm.click.trackBannerClick(bannerId, bannerName);
  }
  window['ACC'].mixpanel.trackBannerClickV2("", bannerId, bannerName, brandName, isSponsored);
}

export function brandGtmBannerViewEvent(bannerId, bannerName, imageUrl) {
  if (window['ACC'].extragtm !== undefined) {
    window['ACC'].extragtm.trackBannerView(bannerId, bannerName, imageUrl);
  }
}

export function gtmBannerViewEvent(bannerId, bannerName, brandName, isSponsored) {
  if (bannerId !== PrevBannerId) {
    if (alreadyViwedBannerId.includes(bannerId) === false) {
      window['ACC'].extragtm.trackBannerView(bannerId, bannerName);
      window['ACC'].mixpanel.trackBannerView(bannerId, bannerName, brandName, isSponsored);
    }
    alreadyViwedBannerId.push(bannerId);
    PrevBannerId = bannerId;
  }
}

export function getBottomTags(product, index) {
  let bottomTags = [];
  if (product.endDateDiscount !== "" && product.endDateDiscount !== null && product.endDateDiscount !== undefined) {
    let dateEnd = product.endDateDiscount;
    let dateEndInmilliSeconds = new Date(dateEnd).getTime();
    let currentTime = new Date().toLocaleString("en-US", { hour12: false });
    let currentTimeInMilli = new Date(currentTime).getTime();
    let duration = dateEndInmilliSeconds - currentTimeInMilli;
    let hours = duration / (1000 * 60 * 60);
    let absoluteHours = Math.floor(hours);
    let h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;
    let minutes = (hours - absoluteHours) * 60;
    let absoluteMinutes = Math.floor(minutes);
    let m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;
    let seconds = (minutes - absoluteMinutes) * 60;
    let absoluteSeconds = ((duration % 60000) / 1000).toFixed(0);
    let s = Number(absoluteSeconds) > 9 ? absoluteSeconds : '0' + absoluteSeconds;
    if (hours >= 0 && hours <= countdownThreshold) {
      product['dealTimer'] = {
        "deals": true,
        "hDiff": h,
        "minDiff": m,
        "secDiff": s,
        "distance": null
      };
    }
  }
  let userCityCode =
    (get(cartLoginDetails) as any)?.city?.code || window["ACC"].config.userDefaultCityCode || window["ACC"].config.userSelectedCityCode;
  let isComingSoon = product?.comingSoon || false;
  let isInStock =
    !isComingSoon &&
    ((product?.inStockCities?.length &&
      product.inStockCities.indexOf(userCityCode) != -1) ||
      product.stock?.stockLevelStatus === "inStock");
  let isSellingOutFast =
    !isInStock &&
    ((product?.sellingOutFastCities?.length &&
      product.sellingOutFastCities.indexOf(userCityCode) != -1) ||
      product.stock?.stockLevelStatus === "lowStock");
  let isOutOfStock = !(isComingSoon || isInStock || isSellingOutFast);
  if (product?.dealTimer?.deals === true) {
    bottomTags.push({ timerTag: true, product })
  }
  if (isSellingOutFast && sellingOutFast) {
    bottomTags.push({
      content: sellingOutFast,
      borderRadLBot: 12,
      borderRadRBot: 12,
      theme: "yellow",
    });
  } else {
    // To  match hight of all tile, even if tag is not present in any of the tile
    bottomTags.push({ content: "dummy", theme: "dummy" });
  }
  return bottomTags;
};