<script>
    import { getAppConfig } from "../../../common/config/app-config";
    const { isMegaSaleDuringAccessStarted, joodEarlyAccessLiveNowAnimation } =
        getAppConfig();
    export let pageClass;
</script>

{#if isMegaSaleDuringAccessStarted == true && joodEarlyAccessLiveNowAnimation}
    <div class="c_megasale--livenow {pageClass}">
        <img src={joodEarlyAccessLiveNowAnimation} alt="Live Now" />
    </div>
{/if}
