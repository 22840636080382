import { getAppConfig } from "../../../common/config/app-config";
import { APP_CONSTANTS, getBaseWebServiceUrl } from "../../../common/config/app-constants";
import { getUrlWithQueryParams, httpGetJSON, httpPostRaw } from "../../../common/services/ajax-services";

export function getGroupedCMSComponentsFromHybrisAsync(componentUidGroups: any[][], isFirstComponentsCall = false) {
    // this method is introduced to reduce the number of calls sent to hybris
    // componentUidGroups => [[uid1, uid2, uid3], [uid4, uid5]]
    // { uid1: 0, uid2: 0, uid3: 0, uid4: 1, uid5: 1 }

    const uidToGroupIndexMap = {};
    const allUids = [];
    componentUidGroups.forEach((uidGroup, groupIndex) => {
        uidGroup.forEach(uid => {
            uidToGroupIndexMap[uid] = groupIndex;
            allUids.push(uid);
        });
    });

    return getCMSComponentsFromHybrisAsync(allUids, isFirstComponentsCall)
        .then(cmsLinks => cmsLinks.component)
        .then(linksData => {
            const outputLength = (new Set(Object.values(uidToGroupIndexMap))).size;
            const outputArray = Array(outputLength).fill(null).map(x => new Array(0));

            linksData.forEach(l => {
                if (l.uid in uidToGroupIndexMap) {
                    const index = uidToGroupIndexMap[l.uid];
                    outputArray[index].push(l);
                }
            });

            return outputArray;
        }
        );

}
export function getCMSComponentsFromHybrisAsync(componentUids, isFirstComponentsCall = false) {
    const webServiceEndpoint = getBaseWebServiceUrl();
    const { GET_CMS_COMPONENTS, appId } = APP_CONSTANTS;
    const cmsComponentUrl = `${webServiceEndpoint}/${GET_CMS_COMPONENTS}`;
    const { lang, jessionId } = getAppConfig();
    const componentIds = componentUids.join(',');

    const params = {
        fields: 'FULL',
        lang,
        deviceId: jessionId,
        componentIds,
        pageSize: 50
    };

    const headers = {
        Appsessiontoken: jessionId,
        appId
    };

    if (isFirstComponentsCall || !APP_CONSTANTS.IS_DEMO_MODE) {
        return httpGetJSON(cmsComponentUrl, params, headers);
    } else {
        return httpGetJSON(cmsComponentUrl, params, headers).then(delayPromise(2000));
    }
}

export function delayPromise(delayMS: number) {
    return (data) => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(data), delayMS);
        });
    };
}
export function log(data, prefix: string = '[Log] ') {
    console.log(prefix, data);
    return data;
}
export function retryPromise(retryCount, promiseInput) {
    return new Promise(async (resolve, reject) => {
        for (let i = 0; i < retryCount; i++) {
            try {
                const result = await promiseInput;
                resolve(result);
            } catch (error) {
                if (i == retryCount - 1) {
                    reject(error);
                }
            }
        }
    });
}

function updateSessionCitySvelteAsync(cityCode: string) {
    const webServiceEndpoint = getBaseWebServiceUrl();
    const { POST_UPDATE_SESSION_CITY, appId } = APP_CONSTANTS;
    const cmsComponentUrl = `${webServiceEndpoint}/${POST_UPDATE_SESSION_CITY}`;
    const { lang, jessionId } = getAppConfig();

    const params = {
        fields: 'FULL',
        lang,
        deviceId: jessionId,
        cityCode,
        '-*': true
    };

    const headers = {
        Appsessiontoken: jessionId,
        appId
    };

    const url = getUrlWithQueryParams(cmsComponentUrl, params);
    return httpPostRaw(url, {}, headers);
}

window["updateSessionCity__Svelte"] = updateSessionCitySvelteAsync;
