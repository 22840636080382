<script lang="ts">
    import { getAppConfig } from "../../../../common/config/app-config";
    import { getProductTags } from "../../../../common/util";
    import TagContainer from "../../../common/components/tag/TagContainer.svelte";
    import Text from "../../../common/components/Text.svelte";

    export let cartData;
    const { lang } = getAppConfig();
    $: brandName =
        lang === "en"
            ? cartData?.entry?.product?.brandNameEn
            : cartData?.entry?.product?.brandName;
</script>

<div>
    <TagContainer
        productTags={getProductTags(cartData?.entry?.product, true)}
    />
    <div class="c_add-to-cart-popup-item-details-name">
        {#if brandName && cartData?.entry?.product?.brandNameEn != "Default Value"}
            <span class="brand">{brandName}</span>
            <span class="hidden-xs hidden-sm dot d-inline-flex align-middle"
            ></span>
        {/if}
        <a class="name" href={cartData?.entry?.product?.url}
            >{cartData?.entry?.product?.name}</a
        >
        {#if cartData?.bundleQtyAdded > 0}
            <span class="isbundle">
                <span class="clr_green">+</span>
                <Text
                    key="addtocart.bundle.product.label"
                    args={[cartData.bundleQtyAdded]}
                />
            </span>
        {/if}
    </div>
</div>
