<script lang="ts">
  import { onMount } from "svelte";
  import Tab, { Label } from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  import ProductTileMain from "../../common/components/productTile/ProductTileMain.svelte";
  import { Splide, SplideSlide } from "@splidejs/svelte-splide";
  import type { Options } from "@splidejs/splide";
  import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
  import { getAppConfig } from "../../../common/config/app-config";
  import {
    isDirectionRTL,
    applyRTLArrowCss,
    getProductDataForGTM,
  } from "../../../common/util";
  import { afterUpdate } from "svelte";

  let { encodedContextPath = "en-sa", lang } = getAppConfig();

  export let historyItems;
  export let wishlistItems;
  export let mainTitle = "";
  export let historyTitle = "";
  export let wishlistTitle = "";
  export let showTabs = true;

  let dealEnd = "December 31, 2022 00:00:00";
  let bg = "#04061B";
  let color = "#FFD400";

  const isRTL = isDirectionRTL();
  let sliderOption: Options = {
    direction: isRTL ? "rtl" : "ltr",
    type: "slide",
    arrows: historyItems.length > 4 || wishlistItems.length > 4 ? true : false,
    pagination: false,
    perPage: 4,
    perMove: 1,
    gap: 24,
    fixedWidth: "auto",
    breakpoints: {
      480: {
        gap: 8,
        pagination: false,
        wheel: false,
        perPage: 2,
      },
    },
    classes: {
      prev: "splide__arrow--prev splide_arrow-prev",
      next: "splide__arrow--next splide_arrow-next",
    },
  };

  function handleRRItemClick(
    event,
    rrClickTrackingURL,
    productType,
    indexInArray,
  ) {
    const href = event.target.closest("a.recently-viewed-link").href;
    if (navigator.sendBeacon && rrClickTrackingURL) {
      navigator.sendBeacon(rrClickTrackingURL);
    }
    // productType can be 'recent' or 'wishlist'
    const targetProduct =
      productType === "recent" ? historyItems[indexInArray] : null;
    if (targetProduct) {
      window["ACC"].mixpanel.track("Items_that_Caught_Clicks", {
        Item_Name: targetProduct.name,
        Item_Family: targetProduct.rmsCategory,
        Price: targetProduct.price.value,
        Item_SKU: targetProduct.code,
      });
    }
    // window.location.assign(href);
  }

  $: visibleTabs = generateActiveTabs();
  let active;

  function generateActiveTabs() {
    const t = [];
    if (historyItems && historyItems.length > 0) {
      t.push({
        id: 1,
        label: historyTitle
          ? historyTitle
          : lang == "en"
            ? "Items that caught your eye!"
            : "منتجات اعجبتك!",
      });
    }
    if (wishlistItems && wishlistItems.length > 0) {
      t.push({ id: 2, label: wishlistTitle });
    }
    active = t.length > 0 ? t[0] : { id: -1 };
    return t;
  }

  afterUpdate(() =>
    document.documentElement.style.setProperty("--isRTL", `${isRTL}`),
  );

  onMount(() => {
    let parent = document.querySelectorAll(".recent-product-tile");
    applyRTLArrowCss(parent, "auto", "1em", "1em", "auto");
  });

  export function onViewport() {
    if (historyItems.length > 0) {
      window["ACC"].extragtm.click.trackViewList("", "", historyItems);
    }
    if (wishlistItems.length > 0) {
      window["ACC"].extragtm.click.trackViewList("", "", wishlistItems);
    }
  }
</script>

<section class="recent-container c_wrapper--content_scroller">
  <div class="recent-header">
    <!-- changes made to display title from RR directly -->
    <div class="mdc-typography--headline5">
      {showTabs
        ? mainTitle
        : historyTitle.length > 1
          ? historyTitle
          : visibleTabs?.[0]?.label}
    </div>
  </div>
  <div>
    {#if showTabs}
      <TabBar tabs={visibleTabs} let:tab bind:active>
        <Tab {tab} minWidth>
          <Label>{tab.label}</Label>
        </Tab>
      </TabBar>
    {/if}

    <div class="tile-container">
      {#if !showTabs || active?.id === 1}
        <div class="recent-product-tile">
          <Splide options={{ ...sliderOption }}>
            {#each historyItems as { plpEligibleForTamara, plpEligibleForTamaraBlue, plpEligibleForTamaraGold, plpEligibleForTabby, brandName, name, code, amplienceProductBaseUrl, rrClickUrl, url, basicPrimePrice, vipPrimePrice, primeEnabled, primeLevel, tacticalVipPromo, tacticalBasicPromo, nameEn, productCode, brandEn, allCategoriesEn, priceValueDiscount, allPromotions, price, variantsEn }, historyItemIndex}
              {@const prodData = {
                nameEn,
                productCode,
                brandEn,
                allCategoriesEn,
                priceValueDiscount,
                allPromotions,
                price,
                variantsEn,
              }}
              <SplideSlide>
                <a
                  href={`${encodedContextPath}${url}`}
                  class="recently-viewed-link"
                  data-product-id={code}
                  on:click|preventDefault|stopPropagation={(e) =>
                    handleRRItemClick(
                      e,
                      rrClickUrl,
                      "recent",
                      historyItemIndex,
                    )}
                >
                  <ProductTileMain
                    {plpEligibleForTamara}
                    {plpEligibleForTamaraBlue}
                    {plpEligibleForTamaraGold}
                    {plpEligibleForTabby}
                    {basicPrimePrice}
                    {vipPrimePrice}
                    {primeEnabled}
                    {primeLevel}
                    {tacticalVipPromo}
                    {tacticalBasicPromo}
                    {brandName}
                    {name}
                    {code}
                    {amplienceProductBaseUrl}
                    showAllFields={false}
                    showBottomTags={false}
                    productBottomTags={null}
                    productDataForGTM={getProductDataForGTM(
                      prodData,
                      historyItemIndex,
                    )}
                  />
                </a>
              </SplideSlide>
            {/each}
          </Splide>
        </div>
      {:else if active?.id === 2 && showTabs}
        <div class="saved-product-tile">
          <Splide options={{ ...sliderOption }}>
            {#each wishlistItems as { plpEligibleForTamara, plpEligibleForTamaraBlue, plpEligibleForTamaraGold, plpEligibleForTabby, brandName, name, code, amplienceProductBaseUrl, url, rrClickUrl, basicPrimePrice, vipPrimePrice, primeEnabled, primeLevel, tacticalVipPromo, tacticalBasicPromo, nameEn, productCode, brandEn, allCategoriesEn, priceValueDiscount, allPromotions, price, variantsEn }, wishlistItemIndex}
              {@const prodData = {
                nameEn,
                productCode,
                brandEn,
                allCategoriesEn,
                priceValueDiscount,
                allPromotions,
                price,
                variantsEn,
              }}
              <SplideSlide>
                <a
                  href={`${encodedContextPath}${url}`}
                  class="recently-viewed-link"
                  data-product-id={code}
                  on:click|preventDefault|stopPropagation={(e) =>
                    handleRRItemClick(
                      e,
                      rrClickUrl,
                      "wishlist",
                      wishlistItemIndex,
                    )}
                >
                  <ProductTileMain
                    {plpEligibleForTamara}
                    {plpEligibleForTamaraBlue}
                    {plpEligibleForTamaraGold}
                    {plpEligibleForTabby}
                    {basicPrimePrice}
                    {vipPrimePrice}
                    {primeEnabled}
                    {primeLevel}
                    {tacticalVipPromo}
                    {tacticalBasicPromo}
                    {brandName}
                    {name}
                    {code}
                    {amplienceProductBaseUrl}
                    showAllFields={false}
                    showBottomTags={false}
                    productBottomTags={null}
                    productDataForGTM={getProductDataForGTM(
                      prodData,
                      wishlistItemIndex,
                    )}
                  />
                </a>
              </SplideSlide>
            {/each}
          </Splide>
        </div>
      {/if}
    </div>
  </div>
</section>

<style lang="scss">
  @import "../../common/styles/colors.new.scss";

  .recent-container :global {
    padding: 40px 16px;

    @include desktop-min-width {
      padding: 80px 48px;
    }
    .recent-header {
      display: flex;
      justify-content: space-between;
      .mdc-typography--headline5 {
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        @include desktop-min-width {
          font-size: 36px;
          line-height: 40px;
        }
      }
      .button-shaped-round {
        border-radius: 100px;
        border-color: $primary-blue;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
      }
    }
    .mdc-tab-bar {
      border-bottom: 1px solid $secondary-gray-02;
    }
    .mdc-tab-scroller__scroll-content {
      .mdc-tab {
        background-color: $primary-white;
        border: none;
        padding: 10px 20px;
        .mdc-tab__text-label {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          color: $color-bg-black;

          @include desktop-min-width {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
      .mdc-tab--active {
        border-bottom: 2px solid $icon-only-icon-blue;
        .mdc-tab__text-label {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: $icon-only-icon-blue;

          @include desktop-min-width {
            font-size: 16px;
            line-height: 18px;
          }
        }
        .mdc-tab-indicator__content--underline {
          border-color: $icon-only-icon-blue;
        }
      }
    }

    .tile-container {
      margin: 16px 0 0px 0;
      @include desktop-min-width {
        margin: 40px 0 0px 0;
      }
    }

    .recently-viewed-link {
      text-decoration: none;
      color: unset;
    }

    .view-div :global {
      margin: 40px 0;
      color: $icon-only-icon-blue;
      display: flex;
      align-items: center;
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
      }
      .icon-chevron_right {
        color: $icon-only-icon-blue;
      }
    }
  }

  :global {
    .recent-product-tile {
      .splide--rtl > .splide__arrows .splide__arrow--prev,
      .splide--rtl
        > .splide__slider
        > .splide__track
        > .splide__arrows
        .splide__arrow--prev,
      .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev {
        left: auto !important;
        right: 1em !important;
      }
      .splide--rtl > .splide__arrows .splide__arrow--next,
      .splide--rtl
        > .splide__slider
        > .splide__track
        > .splide__arrows
        .splide__arrow--next,
      .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next {
        right: auto !important;
        left: 1em !important;
      }
      .splide.splide--slide {
        &.splide--rtl {
          direction: unset;
        }
      }
    }
  }
  :global {
    .recent-container {
      .splide__arrow {
        background: $primary-black !important;
        border-radius: 50%;
        display: none;
        height: 48px;
        opacity: 0;
        width: 48px;
        svg {
          fill: $primary-white;
        }
      }
      .splide__arrow:disabled {
        opacity: 0;
        pointer-events: none;
      }
      .splide__arrow:hover svg {
        fill: $primary-white !important;
      }
    }
    .recent-container:hover .splide__arrow {
      display: inline-block;
      opacity: 0.7;
    }
    .recent-container:hover .splide__arrow:disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
</style>
