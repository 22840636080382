<script lang="ts">
    import { getAppConfig } from "../../../../common/config/app-config";
    import { getPriceHTMLOrFree } from "../../../../common/util";
    import Text from "../../../common/components/Text.svelte";

    export let entry;
    const { lang, currency } = getAppConfig();
    $: basePrice = entry?.basePrice?.value;
    $: totalPrice = entry?.totalPrice?.value / entry?.quantity;
    $: cashBackPrice = entry?.cashBackPrice?.value / entry?.quantity;
    $: acPriceDiscount =
        entry?.appliedAcPriceDiscount?.value > 0
            ? entry?.appliedAcPriceDiscount?.value / entry?.quantity
            : 0;
    $: totalSaving =
        (entry?.product?.savingAmount?.value || 0) +
        (entry?.product?.cashBackPrice || 0) +
        (entry?.appliedAcPriceDiscount?.value > 0 ? acPriceDiscount : 0);
</script>

{#if entry}
    {#if basePrice !== totalPrice && (entry?.product?.savingAmount || entry?.product?.cashBackPrice || entry?.appliedAcPriceDiscount?.value > 0)}
        <div class="price-container">
            <span class="price-currencyContainer">
                <span class="price"
                    >{@html getPriceHTMLOrFree(basePrice - totalSaving)}</span
                >
            </span>
            <span class="vat"><Text key={"extra.addtocart.inc.of.vat"} /></span>
        </div>
        <div class="actual-price-container">
            <span class="price">{basePrice}</span>
            {#if totalSaving > 0}
                <span class="saving">
                    <Text key={"extra.addtocart.save"} />
                    <span class="currency">{currency}</span>{totalSaving}
                </span>
            {/if}
            {#if entry?.saveNonJoodPercentage?.value > 0}
                <span class="c_todays-deals-card-off-tag dis-off-tag">
                    <Text
                        key={"product.percentageDiscount.off"}
                        args={[entry?.saveNonJoodPercentage?.value]}
                    />
                </span>
            {/if}
        </div>
    {:else if basePrice !== totalPrice && entry?.saveNonJoodPercentage?.value > 0}
        <div class="price-container">
            <span class="price-currencyContainer">
                <span class="price">{@html getPriceHTMLOrFree(totalPrice)}</span
                >
            </span>
            <span class="vat"><Text key={"extra.addtocart.inc.of.vat"} /></span>
        </div>
        <div class="actual-price-container">
            <span class="price">{basePrice}</span>
            <span class="saving">
                <Text key={"extra.addtocart.save"} />
                {basePrice - totalPrice}
                <span class="currency">{currency}</span>
            </span>
            {#if entry?.saveNonJoodPercentage?.value > 0}
                <span class="c_todays-deals-card-off-tag dis-off-tag">
                    <Text
                        key={"product.percentageDiscount.off"}
                        args={[entry?.saveNonJoodPercentage?.value]}
                    />
                </span>
            {/if}
        </div>
    {:else}
        <div class="price-container">
            <div class="price-container">
                <span class="price-currencyContainer">
                    <span class="price"
                        >{@html getPriceHTMLOrFree(basePrice)}</span
                    >
                </span>
                <span class="vat"
                    ><Text key={"extra.addtocart.inc.of.vat"} /></span
                >
            </div>
        </div>
    {/if}
{/if}
