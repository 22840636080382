<script lang="ts">
  import Text from "./../../common/components/Text.svelte";

  import { createEventDispatcher } from "svelte";
  export let isPolicyChecked = false;
  export let isPopup = false;
  const dispatch = createEventDispatcher();
  function policyCheckBoxhandle(event) {
    dispatch("policyChange", event.target.checked);
  }
</script>

<div class="form-element-group privacy-link-group">
  <div class="selection-box-policy" class:isPopup>
    <label class="custom-checkbox">
      <input
        id="isPolicyChecked"
        name="isPolicyChecked"
        class="s-form-control"
        type="checkbox"
        checked={isPolicyChecked}
        on:change={policyCheckBoxhandle}
        autocomplete="off"
      />
      <span></span>
    </label>
    <div class="privacy-content">
      <Text key="extra.pdpl.notifyme.text" />
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../common/styles/colors.new.scss";
  .selection-box-policy {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    padding-left: 25px;
    padding-right: 15px;
    &.isPopup {
      justify-content: inherit;
      margin: 0;
      padding: 0;
      .custom-checkbox {
        padding: 0;
      }
    }
    @include desktop-max-width {
      padding-left: 15px;
      padding-right: 15px;
    }
    .custom-checkbox {
      @include desktop-max-width {
        margin-top: 5px;
      }
    }
    .custom-checkbox input {
      display: none;
    }
    .custom-checkbox span {
      border: 2px solid $x-wallet-background;
      float: right;
      height: 18px;
      width: 18px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      font-size: 15px;
      color: $x-wallet-background;
    }
    .custom-checkbox input:checked + span:before {
      content: "✔";
      background: $x-wallet-background;
      color: $primary-white;
      padding: 3px;
      font-size: 11px;
      line-height: 11px;
    }
    .privacy-content {
      color: $neutral-dark-01;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      padding-left: 11px;
      @include desktop-max-width {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
</style>
