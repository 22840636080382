<script lang="ts">
  import ImageTag from "../../../lazy-components/ImageTag.svelte";
  import PictureTag from "../../../lazy-components/PictureTag.svelte";

  export const code = null;
  export let amplienceProductBaseUrl; // previous imageUrl
  export let mobileWidth;
  export let desktopWidth;
  export let mobileHeight;
  export let desktopHeight;
</script>

<div
  class="tile-image-container d-flex justify-content-center"
  style:--desktop-img-height="{desktopHeight}px"
  style:--mobile-img-height="{mobileHeight}px"
  style:--desktop-img-width="{desktopWidth}px"
  style:--mobile-img-width="{mobileWidth}px"
>
  <!-- <PictureTag
    image={amplienceProductBaseUrl}
    mobileImage={amplienceProductBaseUrl}
    templateName="productTile"
  /> -->
  <ImageTag
    imageUrls={[amplienceProductBaseUrl]}
    widths={[mobileWidth, desktopWidth]}
    heights={[mobileHeight, desktopHeight]}
    objectPosition="center center"
  />
</div>

<style lang="scss">
  @import "../../../styles/colors.new.scss";

  .tile-image-container {
    max-width: 100%;
    height: var(--mobile-img-height);

    @include desktop-min-width {
      height: var(--desktop-img-height);
    }
    .image-wrapper {
      margin: 0 auto;
      @include desktop-min-width {
      }
    }
  }
</style>
