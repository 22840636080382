import { getAppConfig } from "../../../common/config/app-config";
import { APP_CONSTANTS } from "../../../common/config/app-constants";
import { httpGetJSON } from "../../../common/services/ajax-services";
import { getCookie, getLangSpecificFieldname, getProductTags, getStr, setCookie } from "../../../common/util";
import { getProductsFromAlgoliaAsync } from "./algolia";

// can contain Promise (ongoing api call), false (no data), or object (valid data)
let richRelevanceDataMap = {};

export const placementIdGroupsForAPI = {
    'home_page.best-selling-widget': 1,
    'home_page.rank1': 1,
    'home_page.rank2': 1,
    'home_page.rank3': 1,
};

window['RRMAP'] = richRelevanceDataMap;


export function getRichRelevanceDataForPlacementIdAsync(placementId, additionalParameters = {}, useCache = true) {
    if (placementId in richRelevanceDataMap && useCache) {
        if (richRelevanceDataMap[placementId] instanceof Promise) {
            // rich relevance call is ongoing
            return richRelevanceDataMap[placementId];
        } else if (richRelevanceDataMap[placementId] === false) {
            // tried to get data before, no data found
            return Promise.resolve(null);
        } else {
            // already found the data, just return it
            return Promise.resolve(richRelevanceDataMap[placementId]);
        }
    }

    // if placement is grouped with other placements, fetch all of them in a single call
    let placementIds = [placementId];
    if (placementId in placementIdGroupsForAPI) {
        const groupId = placementIdGroupsForAPI[placementId];
        placementIds = Object.keys(placementIdGroupsForAPI)
            .filter(placementId => groupId === placementIdGroupsForAPI[placementId]);
    }

    type resolveFn = (value: any) => void;
    const placementResolves: { [key: string]: resolveFn } = {};
    placementIds.forEach(pi => {
        const miniPromise = new Promise((resolve) => {
            placementResolves[pi] = resolve;
        });
        richRelevanceDataMap[pi] = miniPromise;
    });

    getRRDataAsync(placementIds, additionalParameters)
        .then(res => {
            // processResponseFromRR(res, placementId)
            if ('placements' in res) {
                res.placements.forEach(p => {
                    if (p.placement in placementResolves) {
                        placementResolves[p.placement](p);
                        delete placementResolves[p.placement];
                    }
                });
            }

            // resolve null to remaining miniPromises
            Object.keys(placementResolves).forEach(pr => {
                placementResolves[pr](null);
            });

        });

    return richRelevanceDataMap[placementId];
}

function getRRDataAsync(placementIdArray, additionalParameters) {
    const { GET_RR_BASE_URL, GET_RR_PLACEMENTS_DATA, RR_API_KEY: apiKey, RR_API_CLIENT_KEY: apiClientKey } = APP_CONSTANTS;
    const url = `${GET_RR_BASE_URL}${GET_RR_PLACEMENTS_DATA}`;
    const { jessionId: sessionId, userId, encodedContextPath } = getAppConfig();
    const rid = encodedContextPath.replace('/', '');
    const placements = placementIdArray.join('|');
    const rcs = getCookie('rr_rcs') || '';

    const params = Object.assign({
        apiKey,
        apiClientKey,
        sessionId,
        userId,
        rid,
        placements,
        includeStrategyData: true,
        excludeItemAttributes: false,
        categoryData: false,
        excludeHtml: true,
        rcs,
        ts: Date.now(),
        ssl: true,
        returnMinimalRecItemData: true,
        '-*': true

    }, additionalParameters);

    return httpGetJSON(url, params).then(res => {
        if ('rcs' in res) {
            setCookie('rr_rcs', res.rcs, null);
        }
        return res;
    });
}

// Utility functions below for RR + Algolia related processing

export function fetchAndMergeProductDataFromAlgoliaToRR(rrRes) {
    if (rrRes !== null) {
        const productCodes = (rrRes.recommendedProducts as any[]).map(r => r.id);
        return getProductsFromAlgoliaAsync(productCodes)
            .then((algoliaResponse: any) => {
                if (algoliaResponse?.hits?.length === 0) {
                    throw new Error('Empty response from Algolia');
                } else {
                    return algoliaResponse;
                }
            })
            .then(algoliaResponse => Object.assign({}, rrRes, {
                hits: (algoliaResponse as any).hits || []
            }));
    }

    // there is no data from rr, hide the component by returning false
    return false;
}

export function makeDataComponentCompatible(rrAlgoliaMergedData) {
    return rrAlgoliaMergedData ? Object.assign(
        {},
        rrAlgoliaMergedData,
        {
            hits: rrAlgoliaMergedData.hits.map((h, i) =>
                getModifiedAlgoliaSingleHit(h, i, rrAlgoliaMergedData.recommendedProducts.find(p => p.id === h.productCode).clickTrackingURL)
            )
        }
    ) : null;
}
const {
    lang,
    userDefaultCityCode,
    userSelectedCityCode,
    countryCode,
    currency,
    currencyIso,
    primeLevel,
    primeEnabled,
    algoliaConfig: { tasheelInfo },
    outOfStock,
    restockable
} = getAppConfig();
const userCityCode = userSelectedCityCode || userDefaultCityCode;


export function getModifiedAlgoliaSingleHit(productData, index, rrClickUrl = undefined) {


    let discountPrice = null;
    let precentageDiscount = null;
    let stockCode = 'outOfStock';

    const productName = productData[getLangSpecificFieldname('name', lang)];
    const brandName = productData[getLangSpecificFieldname('brand', lang)];
    const variant = productData[getLangSpecificFieldname('variants', lang)];
    const productStatus = productData[getLangSpecificFieldname('productStatus', lang)];
    const productStatusHtml = productData[getLangSpecificFieldname('productStatusHtml', lang)];
    const url = productData[getLangSpecificFieldname('url', lang)];

    let {
        wasPrice,
        price,
        vipPrimePrice = 0,
        basicPrimePrice = 0,
        priceValueDiscount,
        hasCashBack,
        cashback,
        inStockCities,
        sellingOutFastCities,
        priceValueDiscountPercentage,
        productCode,
        rmsCategoryName,
        amplienceProductBaseUrl,
        rating,
        numberOfReviews,
        isDigitalGiftCard,
        priceValueTasheel,
        priceMonthsTasheel,
        tacticalBasicPromo,
        tacticalVipPromo,
        freegiftworth,
        offersCount,
        comingSoon,
        dealTimer,
        tacticalBasicPrimePrice,
        tacticalVipPrimePrice
    } = productData;

    let cashBackPrice: string | number = 0;
    let afterCashBackPrice: string | number = 0;
    const _index = `block${index + 1}`;

    if (priceValueDiscount > 0) {
        price = priceValueDiscount;
    }
    if (hasCashBack === true && cashback !== '') {
        const cashbackArray = cashback.split('-');
        if (countryCode === 'SA') {
            cashBackPrice = parseFloat(cashbackArray[0]).toFixed(3);
        } else {
            cashBackPrice = parseFloat(cashbackArray[0]);
        }
    }
    if (priceValueDiscount > 0) {
        afterCashBackPrice = priceValueDiscount - (+cashBackPrice);
    } else {
        afterCashBackPrice = price - (+cashBackPrice);
    }
    if (countryCode !== 'SA') {
        wasPrice = wasPrice.toFixed(3);
        price = price.toFixed(3);
        afterCashBackPrice = afterCashBackPrice.toFixed(3);
    }
    if (inStockCities?.length > 0 && inStockCities?.indexOf(userCityCode) !== -1) {
        stockCode = "inStock";
    } else if (sellingOutFastCities?.length > 0 && sellingOutFastCities?.indexOf(userCityCode) !== -1) {
        stockCode = "lowStock";
    }
    if (priceValueDiscount > 0) {
        discountPrice = {
            currencyIso: currency || currencyIso,
            formattedValue: price,
            maxQuantity: null,
            minQuantity: null,
            priceType: "BUY",
            value: price
        };
    }
    if (priceValueDiscountPercentage > 0) {
        precentageDiscount = {
            currencyIso: currency || currencyIso,
            formattedValue: priceValueDiscountPercentage,
            maxQuantity: null,
            minQuantity: null,
            priceType: "BUY",
            value: priceValueDiscountPercentage
        };
    }

    const output = Object.assign({}, productData, {
        code: productCode,
        name: productName,
        rmsCategory: rmsCategoryName,
        brandName: brandName,
        rrClickUrl,
        variant: variant,
        amplienceProductBaseUrl,
        url: url,
        stock: {
            stockLevelStatus: {
                code: stockCode,
                type: "StockLevelStatus"
            },
            stockLevel: 11,
            stockThreshold: null
        },
        price: {
            currencyIso: currency || currencyIso,
            value: price,
            priceType: "BUY",
            formattedValue: price,
            minQuantity: null,
            maxQuantity: null
        },
        productStatus: productStatus,
        productStatusHtml: productStatusHtml,
        rating: rating,
        numberOfReviews: numberOfReviews,
        hasCashBack: hasCashBack === true ? hasCashBack : null,
        cashBackPrice: cashBackPrice,
        discountPrice: discountPrice,
        percentageDiscount: precentageDiscount,
        isDigitalGiftCard: isDigitalGiftCard,
        aftreCashBackPrice: {
            currencyIso: currency || currencyIso,
            value: afterCashBackPrice,
            priceType: "BUY",
            formattedValue: afterCashBackPrice,
            minQuantity: null,
            maxQuantity: null
        },
        primeLevel: primeLevel,
        primeEnabled: primeEnabled,
        basicPrimePrice: basicPrimePrice,
        vipPrimePrice: vipPrimePrice,
        index: _index,
        tasheelPriceData: {
            // priceValueTasheel: {
            //     formattedValue: priceValueTasheel,
            //     currencyIso: currency || currencyIso
            // },
            // tasheelInstallmentMonths: priceMonthsTasheel,
            // tasheelPercentage: priceValueDiscount
            priceValueTasheel: {
                formattedValue: priceValueTasheel,
                currencyIso: currency
            },
            tasheelInstallmentMonths: tasheelInfo?.tasheelInstallmentMonths,
            tasheelPercentage: tasheelInfo?.tasheelPercentage
        },
        tacticalBasicPromo: tacticalBasicPromo,
        tacticalVipPromo: tacticalVipPromo,
        productTags: getProductTags(productData),
        freeGiftCount: getFreeGiftData(freegiftworth),
        offersLabel: offersCount ? offersCount > 1 ? offersCount + ' ' + getStr("extra.product.cashback.offers") : offersCount + ' ' + getStr("extra.pdp.offerlable") : null,

        comingSoon: comingSoon || false,
        inStockCities,
        sellingOutFastCities,
        dealTimer,
        showAllFields: true,
        tacticalVipPrimePrice: tacticalVipPrimePrice,
        tacticalBasicPrimePrice: tacticalBasicPrimePrice,
    });

    return output;
}

function getFreeGiftData(freegiftworth) {
    if (!!freegiftworth) {
        var freegiftArray = freegiftworth?.split("-");
        var sourceQuantiy = freegiftArray[2] || 0;
        return sourceQuantiy;
    }
    return null;
}
