<script lang="ts">
    import { _ } from "svelte-i18n";
    import { getStr } from "../../../../../common/util";

    export let offersLabel;
    export let freeGiftCount = null;
</script>

<div class="tile-offer-container">
    <div class="content">
        {#if offersLabel}
            <span class="offers"
                >{@html offersLabel}
                {#if freeGiftCount}
                    <span class="dot d-inline-flex align-middle"> </span>
                {/if}
            </span>
        {/if}
        {#if freeGiftCount}
            <span class="free-gift">
                {getStr("extra.homepage.deals.giftCount", [freeGiftCount])}
            </span>
        {/if}
    </div>
</div>

<style lang="scss">
    @import "../../../styles/colors.new.scss";
    .tile-offer-container {
        font-size: 12px;
        line-height: 14px;
        @include desktop-min-width {
            font-size: 14px;
            line-height: 16px;
        }
        .content {
            font-weight: 500;
            color: $functional-orange;
            .offers {
                .dot {
                    width: 2px;
                    height: 2px;
                    background: $functional-orange;
                    border-radius: 50%;
                    margin: 0 2px;
                }
            }
        }
    }
</style>
