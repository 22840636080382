<script lang="ts">
    import { getContext, onMount } from "svelte";
    import { getAppConfig } from "../../../common/config/app-config";
    import StockTag from "./StockTag.svelte";
    import { checkLocationDisabled } from "../../../common/util";
    import { islocationDisabled } from "../../product-listing-search/store/plp-data-store";
    export let inStock = true;
    export let title;
    export let location;
    export let stockStatus;
    export let stockCount = null;
    export let isStorePickup = null;
    export let code;
    export let cfsPosPickUpInStoreData;
    export let isCollectFromStoreEnabled;

    const qacAtcPopup = getContext("isQacAtcPopup");

    onMount(async () => {
        await checkLocationDisabled();
    });
    // const { code, cfsPosPickUpInStoreData, isCollectFromStoreEnabled } = window['ACC'].config.productDetails;
    const { encodedContextPath } = getAppConfig();
    const storename = cfsPosPickUpInStoreData?.defaultPos
        ? cfsPosPickUpInStoreData.defaultPos.name
        : "";
    const getLatitude = window["ACC"].cookie.get("latitude")
        ? window["ACC"].cookie.get("latitude")
        : "";
    const getLongitude = window["ACC"].cookie.get("longitude")
        ? window["ACC"].cookie.get("longitude")
        : "";
    $: href =
        storename && storename.length > 0
            ? `${encodedContextPath}/store-pickup/${code}/pointOfServices/${storename}?locationDisabled=${$islocationDisabled}&latitude=${getLatitude}&longitude=${getLongitude}`
            : "";
</script>

<section class="product_delivery_head">
    <div class="store-pickup-title">
        {title}
        {#if location !== null}
            <span
                class="location js-user-select-city-store"
                class:qacPopupOpen={qacAtcPopup}
                data-productcode={code}
                data-storepickup={isStorePickup}
                data-storename={storename}
                data-iscfsenabled={isCollectFromStoreEnabled}
                data-href={href}>{location}</span
            >
        {/if}
    </div>
    <StockTag stockFlag={inStock} {stockStatus} {stockCount} />
</section>

<style lang="scss">
    @import "../../common/styles/colors.new.scss";
    .delivery_head {
        &-stock {
            &__available {
                align-items: flex-start;
                display: flex;
                justify-content: space-between;
                line-height: normal;
                margin: 0 12px 10px 12px;
                padding: 10px 12px;

                @include desktop-max-width {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                    margin: 12px 0 15px;
                    padding: 0 12px 20px;
                    align-items: center;
                    text-align: center;
                    flex-direction: column-reverse;
                }

                @include desktop-min-width {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 20px;
                    margin: 12px 0 20px;
                    padding: 0 10px 10px;
                    align-items: flex-start;
                    text-align: center;
                    flex-direction: row;
                }

                & > span:first-child {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;

                    & + .icon {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        @include desktop-max-width {
                            font-size: 12px;
                            margin-right: 8px;
                        }
                        @include desktop-min-width {
                            font-size: 14px;
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
    }
    .location {
        color: $icon-only-icon-blue;
    }
    .product_delivery_head {
        align-items: center;
        display: flex;
        gap: 8px;

        &.space-between {
            justify-content: space-between;
        }
        .store-pickup-title {
            font-weight: 400;
            margin: 0;

            @include desktop-max-width {
                font-size: 14px;
                line-height: 16px;
            }

            @include desktop-min-width {
                font-size: 16px;
                line-height: 18px;
            }

            .qacPopupOpen {
                color: $neutral-dark-01;
            }
            .location {
                cursor: pointer;
                font-weight: 500;
                font-size: inherit;
                line-height: inherit;
            }
        }
    }
</style>
