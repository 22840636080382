<script lang="ts" context="module">
    import { isMobile } from "../../../common/util";
    import {
        brandGtmBannerClickEvent,
        gtmBannerViewEvent,
    } from "../../brand/services/brand-data-services";
    import PictureTag from "../lazy-components/PictureTag.svelte";
    import { transformationTemplates } from "../services/ampliance-transforamtion-template-mapper";
    import MegaSaleOnlineNow from "./MegaSaleOnlineNow.svelte";
    import MegaSaleVoucher from "./MegaSaleVoucher.svelte";
    import { getAppConfig } from "../../../common/config/app-config";
    import viewport from "./useViewportAction";
    const { primeLevel, isMegaSaleDuringAccessStarted } = getAppConfig();

    const isResponsive = isMobile();
</script>

<script lang="ts">
    export let data;
    export let index;
    export let slidesLength;
    let slidelastIndexFlag = false;

    console.log("index: " + slidesLength);
    const height = "100%";
    const width = "100%";

    $: ({ link, image, mobileImage, bannerId, bannerName } = data);
</script>

{#if index == 0}
    {#if isMegaSaleDuringAccessStarted && primeLevel}
        <MegaSaleVoucher pageClass="home-megasale-voucher" />
    {/if}
    <MegaSaleOnlineNow pageClass="home-megasale-banner" />
{/if}
<a
    class="carousel-slide"
    href={link || undefined}
    data-banner-name={bannerName}
    data-banner-id={bannerId}
    data-banner-image={isResponsive ? mobileImage : image}
>
    <PictureTag
        fetchpriority={((index === 0 || index === slidesLength - 1) &&
            "high") ||
            "auto"}
        {width}
        {height}
        {image}
        {mobileImage}
    />
</a>

<style lang="scss">
    @import "../styles/colors.new.scss";
    .carousel-slide {
        min-height: 480px;

        @include responsive {
            min-height: 450px;
        }
    }
</style>
