<script lang="ts" context="module">
    export type VasPriceTileVariantType =
        | "base"
        | "addon"
        | "saving"
        | "ac-mount"
        | "lmd";
</script>

<script lang="ts">
    import { getAppConfig } from "../../../common/config/app-config";
    import { getPriceHTMLOrFree, getStr } from "../../../common/util";
    import Icon from "../../common/components/Icon.svelte";

    export let data;
    export let active: boolean;
    export let variant: VasPriceTileVariantType = "base";
    export let readonly = false;

    const { currency } = getAppConfig();

    $: isNoPlan = data?.noPlanOption;
    $: visible = !data?.noPlanOption || variant !== "saving";
    $: displayPrice = getDisplayPrice(variant, data);
    $: oldPrice = data?.servicePrice?.value;
    $: savings = oldPrice - displayPrice;

    function getDisplayPrice(variant: VasPriceTileVariantType, data) {
        return variant === "saving"
            ? data?.vipPrimeDiscountPrice?.value
            : data?.discountedPrice?.value || data?.servicePrice?.value;
    }
</script>

{#if visible}
    <button
        class="vas-price-tile variant-{variant}"
        class:active
        class:readonly
        disabled={readonly}
        on:click
        class:no-plan-tile={isNoPlan}
    >
        {#if isNoPlan}
            <section class="name">{data?.vasType?.name}</section>
        {:else}
            <section class="label">{data?.vasType?.label}</section>
            {#if variant !== "saving" && data?.vasType?.name}
                <section class="name">{data?.vasType?.name}</section>
            {/if}
            <section class="price">
                {@html getPriceHTMLOrFree(displayPrice)}
            </section>
            {#if variant === "saving" && savings > 0}
                <section class="savings">
                    <span class="striked-off">{oldPrice}</span>
                    <strong class="orange-text">
                        {@html getStr("extra.cart.saved.amt", [
                            currency,
                            savings,
                        ])}
                    </strong>
                </section>
            {/if}
        {/if}
        {#if active}
            <section class="active-icon">
                <Icon glyph="check" type="font" desktopSize={24} />
            </section>
        {/if}
    </button>
{/if}

<style lang="scss">
    @import "../../common/styles/colors.new.scss";

    .vas-price-tile {
        position: relative;
        text-align: start;
        background-color: $primary-white;
        padding: 12px 16px;
        border: 1px solid $secondary-gray-02;
        border-radius: 12px;
        color: $neutral-dark-01;

        &.no-plan-tile {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &.active {
            border-color: $primary-blue;
        }
        &.variant-base {
            // flex-grow: 1;
            flex-basis: 30%;
            flex-shrink: 1;
        }
        &.variant-addon,
        &.variant-ac-mount {
            flex-basis: 50%;
        }
        &.variant-saving {
            border: 0;
            padding: 8px;

            .name {
                color: $neutral-dark-01;
            }
        }
        &.variant-lmd {
            flex-basis: calc(50% - 6px);
        }
        &.readonly {
            cursor: default;
        }

        .label {
            font-size: 12px;
            line-height: 14px;
            font-weight: 400;
            color: $neutral-dark-03;
        }
        .name {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            margin-top: 2px;
        }
        .price {
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;
            margin-top: 8px;
        }

        .active-icon {
            color: $icon-only-icon-blue;
            position: absolute;
            right: 4px;
            top: 2px;
        }

        .savings {
            .striked-off {
                color: $neutral-dark-03;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                text-decoration: line-through;
            }
            .orange-text {
                color: $functional-orange;
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
</style>
