<script lang="ts">
    import { getAppConfig } from "../../../common/config/app-config";
    import {
        getJoodMembershipBasedPropertyValue,
        isValueSponsoredOrExtraSponsored,
    } from "../../../common/util";
    import CarouselContainerVer1 from "./amplienceBanners/CarouselContainerVer1.svelte";
    import ExtraBannerContainer1by5Variant1 from "./amplienceBanners/ExtraBannerContainer1by5Variant1.svelte";
    import ExtraBestSectionTileCarousel from "./amplienceBanners/ExtraBestSectionTileCarousel.svelte";
    import ExtraBrandTileCarousel from "./amplienceBanners/ExtraBrandTileCarousel.svelte";
    import StageSponsorbannerContainer1x2Ver1 from "./amplienceBanners/StageSponsorbannerContainer1x2Ver1.svelte";
    import ImageBannerVer1 from "./amplienceBanners/imageBannerVer1.svelte";
    import SlotVariant1Ver1 from "./amplienceBanners/slotVariant1Ver1.svelte";
    import StageBannerContainer1x3Ver1 from "./amplienceBanners/stageBannerContainer1x3Ver1.svelte";

    let onAmplienceComponentLoad: () => void;
    $: amplienceTemplateName = metaData?.amplienceTemplateName;
    $: amplienceComponent =
        amplienceTemplateName in bannerTemplateMap
            ? bannerTemplateMap[amplienceTemplateName]
            : null;

    export let metaData;
    export function onLoad() {
        if (amplienceTemplateName && amplienceComponent) {
            const result = onAmplienceComponentLoad();
            trackBannerViewEvent();
            if (typeof result === "undefined") {
                return Promise.resolve(true);
            }
            return Promise.resolve(result);
        }
        console.error(
            "No component found for amplience template:",
            amplienceTemplateName,
        );
        return Promise.resolve(false);
    }

    const bannerTemplateMap = {
        slotVariant1Ver1: ImageBannerVer1,
        stageSlotVariant1: SlotVariant1Ver1,
        imageBannerVer1: ImageBannerVer1,
        bannerContainer1x1Ver1: ImageBannerVer1,
        stageBannerContainer1x3Ver1: StageBannerContainer1x3Ver1,
        carouselContainerVer1: CarouselContainerVer1, // for brand page first banner
        extraBrandTileCarousel: ExtraBrandTileCarousel,
        extraBannerContainer1by5Variant1: ExtraBannerContainer1by5Variant1,
        extraBestSectionTileCarousel: ExtraBestSectionTileCarousel,
        stageSponsorbannerContainer1x2Ver1: StageSponsorbannerContainer1x2Ver1,
    };

    let mixpanelEventObject = null;
    function trackBannerViewEvent() {
        const banner_id = getJoodMembershipBasedPropertyValue(
            metaData,
            "amplienceId",
            "blueAmplienceId",
            "goldAmplienceId",
        );
        const isSponsored =
            metaData &&
            isValueSponsoredOrExtraSponsored(metaData.sponsoredType);
        const { name: banner_name, brandCode: brand } = metaData;
        const { pageId } = getAppConfig();

        mixpanelEventObject = {
            banner_id,
            banner_name,
            brand,
            sponsored_flag: isSponsored,
        };

        // window['ACC'].mixpanel.trackBannerView(
        //     banner_id,
        //     banner_name,
        //     brand || '',
        //     isSponsored
        // );
    }
    function trackBannerClickEvent() {
        console.log(
            "Track Banner Click Event:",
            JSON.stringify(mixpanelEventObject),
        );
        const { banner_id, banner_name, brand, sponsored_flag } =
            mixpanelEventObject;
        const obj = {
            banner_name: banner_name,
            banner_id: banner_id,
            banner_url: this.getAttribute("href"),
        };
        localStorage.setItem("bannerDetails", JSON.stringify(obj));
        // window['ACC'].mixpanel.trackBannerClickV2(
        //     banner_id,
        //     banner_name,
        //     brand || '',
        //     sponsored_flag
        // );
    }
</script>

{#if amplienceComponent}
    <svelte:component
        this={amplienceComponent}
        {metaData}
        bind:onLoad={onAmplienceComponentLoad}
        on:click={trackBannerClickEvent}
    />
{/if}
