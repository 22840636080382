import { _ } from 'svelte-i18n';
import { get } from "svelte/store";
import {
    algoliaUserToken,
    beginRange,
    categoryBreadCrumbs,
    categoryData,
    categoryDataCode,
    categoryFacets,
    categoryStaticBottomContent,
    categoryStaticTopContent,
    colorInfo,
    compareProducts,
    countryCode,
    currentSortValue,
    enableComparison,
    endRange,
    facetFilters,
    facetList,
    facetListCopy,
    facetNumericFilters,
    facetNumericFiltersCopy,
    facetQueryList,
    facetsStats,
    familyLevel,
    filteredOptionsData,
    filterInfo,
    filterString,
    filterStringCopy,
    isCategoryNoResult,
    isCategoryStaticBottomContent,
    isCategoryStaticTopContent,
    isFocused,
    isInitialNoProductPageLoadCompleted,
    isInitialPageLoadCompleted,
    isMobile,
    isNewSearch,
    isPageReloadWithFilters,
    isPriceFilter,
    isSearch,
    isSearchResult,
    itemPerPageList,
    lang,
    localFacetSelectionMap,
    localizationConfig,
    menuLink,
    pageLoader,
    pagination,
    plpRMNBannerData,
    priceEndDisplay,
    priceFilterIndex,
    priceStartDisplay,
    priceString,
    productList,
    productSKU,
    productWishList,
    ratingFilterIndex,
    ratingFilterPosition,
    ratingSelectd,
    ratingString,
    saecrhQueryId,
    searchTerm,
    searchValue,
    selectedFilters,
    selectedPageSize,
    seoMetaData,
    sortCodeMap,
    sortSuffixMap,
    unxFacets,
    unxLastFilterUsedMobile,
    unxResponse,
    unxSelectedPageSize,
    unxSelectedRatings,
    userCity
} from '../store/plp-data-store';

import { getAppConfig } from "../../../common/config/app-config";
import { APP_CONSTANTS, getBaseWebServiceUrl } from "../../../common/config/app-constants";
import { httpGetJSON, httpGetRaw, httpPostRaw } from '../../../common/services/ajax-services';
import { rrGetCategoryPagePlacements, rrGetSearchPagePlacements } from '../../../common/services/rich-relevance';
import { algoliaClient, algoliaConfigdata, algoliaIndexConfig } from '../../../common/store/algolia-config-store';
import { combineURLSearchParams, getLangSpecificFieldname, isValueExtraSponsored, isValueSponsored, joinUrls, parseCashback, parsePriceString, parseUnbxdResponseQueryParamsToSearchParams, parseUrlToUnbxdSP, removeAllProdFromLS, replaceSpecialCharactersInURL, roundDownHalfToFixedPlaces } from "../../../common/util";
import { getDataFromAmplienceAsync, parseImageBannerData } from "../../common/services/amplience";
import { reAssignPriceValues } from "../../common/services/reassign-price-values";
import { convertUnbxdFacetsToSearchParams, getInStockFilterForCity, getSearchAPIResults } from "../../common/services/unbxd";

const { userDefaultCityCode, userSelectedCityCode, unbxdEnabled, algoliaConfig: { filtersTobeExcludedInURL }, currencyDecimal, isMegaSaleDuringAccessStarted, primeLevel } = getAppConfig();
const userCityCode = userSelectedCityCode || userDefaultCityCode;

export const APP_CONFIG = getAppConfig();
export const seoFilterPathPrefix = '/facet/';

const plpSpecificSP: URLSearchParams = new URLSearchParams([
    ['stats', 'price'],
    ['selectedfacet', 'true'],
    ['facet.multiselect', 'true'],
    ['page', '1'],
    ['rows', '24'],
    ['bfrule', getInStockFilterForCity(userCityCode)],
    ['boost', 'if(eq(query($bfrule),false),0,1)'],
    ['analytics', 'true']
]);

let isBrandCategory = false;
export function getDataParams() {
    if (get(facetFilters).length > 0) {
        //sendAlogoliaFilterEvent();
    }

    var searchDataParams: any = {};
    if (get(currentSortValue) !== "relevance" && get(currentSortValue) !== undefined && get(currentSortValue) !== "" && get(currentSortValue) !== null) {
        searchDataParams.relevancyStrictness = 0;
    }

    else {
        searchDataParams.optionalFilters = [
            "sellingOutFastCities:" + get(userCity) + "<score=" + (get(algoliaConfigdata) as any).sellingOutFastCitiesScore + ">",
            "inStockCities:" + get(userCity) + "<score=" + (get(algoliaConfigdata) as any).inStockCitiesScore + ">",
        ];
    }
    if (get(isSearch)) {
        var productSkuRegx = /\d{5,}/;
        if (get(searchTerm).match(productSkuRegx) !== null && !isNaN(get(searchTerm) as any)) {
            searchDataParams.filters = "productCode:" + get(searchTerm);
            searchDataParams.facets = ["productFeatures" + get(lang) + ".*",].concat(get(algoliaConfigdata)["defaultFilters" + get(lang)]);
        }

        searchDataParams.hitsPerPage = get(selectedPageSize);
        searchDataParams.page = get(pagination).currentPage;
        searchDataParams.getRankingInfo = 1;
        searchDataParams.clickAnalytics = true;


        if (get(facetFilters).length > 0) {
            searchDataParams.facetFilters = getGroupedFacetsSelection(get(facetFilters), true);
        }

        searchDataParams.facets = get(algoliaConfigdata)["defaultFilters" + get(lang)].concat(["productFeatures" + get(lang) + ".*",]);

    }

    else {
        searchTerm.set("*");
        var _categoryData = null;
        var _categoryCode = null;
        if (get(categoryData) !== null) {
            _categoryData = get(categoryData);
            _categoryCode = _categoryData.hits[0].code;

        }
        else {
            _categoryCode = get(categoryDataCode);
        }

        var currentKSATime = new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh", hour12: false });
        var dealTime;
        filterString.set("categories:" + _categoryCode);
        if (_categoryCode === "todayDeals") {
            var dayStartTime = new Date(currentKSATime).setHours(0, 0, 0);
            dealTime = new Date(currentKSATime).setHours(23, 59, 59);
            filterString.set("endDateDiscountInMills<=" + (dealTime - dayStartTime));
        }


        if (_categoryCode === "24hour-deals") {
            //dealTime = new Date(currentKSATime).getTime() + 86400000;
            filterString.set("endDateDiscountInMills<=86400000");
        }

        if (_categoryCode === "72hour-deals") {
            //dealTime = new Date(currentKSATime).getTime() + 259200000;
            filterString.set("endDateDiscountInMills<=259200000");

        }



        pagination.setCurrentPage(location.href.split("&page=").length > 1 ? location.href.split("&page=")[1].split("&")[0] : get(pagination).currentPage);
        if (get(facetFilters).length > 0) {
            searchDataParams.facetFilters = getGroupedFacetsSelection(get(facetFilters), true);
        }

        var _categoryFacets = [];
        if ((_categoryData !== null && _categoryData.hits[0].categoryFacets !== undefined) && (_categoryData.hits[0].categoryFacets !== null)) {
            _categoryFacets = _categoryData.hits[0].categoryFacets.map(function (facet) {
                var facetItem = "productFeatures" + get(lang) + "." + facet['title' + get(lang)];
                return facetItem;
            });
            categoryFacets.set(_categoryFacets.concat(get(algoliaConfigdata)["defaultFilters" + get(lang)]));
            searchDataParams.facets = get(categoryFacets);
        }
        else {
            searchDataParams.facets = ["productFeatures" + get(lang) + ".*",].concat(get(algoliaConfigdata)["defaultFilters" + get(lang)]);

        }
        searchDataParams.hitsPerPage = get(selectedPageSize);
        searchDataParams.page = get(pagination).currentPage;
        searchDataParams.getRankingInfo = 1;
        searchDataParams.clickAnalytics = true;


        if (_categoryData.hits[0].algoliaRuleName !== undefined && _categoryData.hits[0].algoliaRuleName !== "") {
            searchDataParams.ruleContexts = [
                _categoryData.hits[0].algoliaRuleName
            ];

        }
        else {
            searchDataParams.filters = get(filterString);
        }
    }

    if (Object.keys(get(facetNumericFilters)).length > 0) {
        if (get(productSKU) === null) {
            searchDataParams.numericFilters = get(facetNumericFilters);
        }
    }

    if (get(isMobile)) {
        searchDataParams.responseFields = [
            'facets', 'facets_stats', 'renderingContent'
        ];
    }
    return searchDataParams;
}

export function gotoTop() {
    document.body.scrollTop = 170;
    document.documentElement.scrollTop = 170;
}

export function mobileFilter() {
    isMobile.set(true);
    if (unbxdEnabled) {
        unxLastFilterUsedMobile.set(unxGetCurrentFacetsSearchParams());
    } else {
        filterStringCopy.set(JSON.parse(JSON.stringify(get(filterString))));
        facetNumericFiltersCopy.set(JSON.parse(JSON.stringify(get(facetNumericFilters))));
        facetListCopy.set(JSON.parse(JSON.stringify(get(facetList))));
    }
}
export function cancelMobileFilter() {
    isMobile.set(false);
    if (unbxdEnabled) {
        updatePLPUsingSearchParams(get(unxLastFilterUsedMobile));
        unxLastFilterUsedMobile.set(null);
    } else {
        filterString.set(get(filterStringCopy));
        facetNumericFilters.set(get(facetNumericFiltersCopy));
        facetList.set(get(facetListCopy));
        pageLoadAfterFilterChange();
    }
}

export function applyMobileFilter() {
    isMobile.set(false);
    if (unbxdEnabled) {
        unxLastFilterUsedMobile.set(null);
    } else {
        pageLoadAfterFilterChange();
    }
}

export function handleSearch() {
    document.querySelector(".form-control.search-field")?.addEventListener("keydown", function (event: any) {
        if (event.key === 'Enter' && event.target.value !== "") {
            event.preventDefault();
            const previousSearchTerm = encodeURIComponent(get(searchTerm));
            searchTerm.set(event.target.value);
            const currentSearchTerm = encodeURIComponent(get(searchTerm));
            var queryUrl = "?text=" + currentSearchTerm;
            if (previousSearchTerm !== currentSearchTerm) {
                currentSortValue.set('relevance');
            }
            get(pagination).currentPage = 0;
            searchValue.set('')
            isFocused.set(false);
            document.getElementById("search-input").blur();
            isNewSearch.set(true);
            resetFilterSelection();
            history.replaceState({}, null, queryUrl);
            getProductList();
            return false;
        }
    });


    document.querySelector(".btn.btn-secondary.text-primary.btn-search")?.addEventListener("click", function (event) {

        if (document.getElementById("search-input")['value']) {
            event.preventDefault();
            get(pagination).currentPage = 0;
            const previousSearchTerm = encodeURIComponent(get(searchTerm));
            searchTerm.set(document.getElementById("search-input")['value']);
            const currentSearchTerm = encodeURIComponent(get(searchTerm));
            if (previousSearchTerm !== currentSearchTerm) {
                currentSortValue.set('relevance');
            }
            var queryUrl = "?text=" + currentSearchTerm;
            searchValue.set('')
            isFocused.set(false);
            document.getElementById("search-input").blur();
            isNewSearch.set(true);
            resetFilterSelection();
            history.replaceState({}, null, queryUrl);
            getProductList();
        }
    });


}

export function removeGroupFilters(filterIndex) {

    beginRange.set("");
    endRange.set("");
    selectedFilters.set(get(selectedFilters).filter(function (item, index) {

        if (item.filterIndex === filterIndex) {
            get(facetList)[filterIndex].values[item.valueIndex].isSelected = false;
        }
        else {
            return item;
        }
    }))

    pageLoadAfterFilterChange();
}


export function removeSelectedFilter(index) {
    var filterIndex = get(selectedFilters)[index].filterIndex;
    var valueIndex = get(selectedFilters)[index].valueIndex;
    get(facetList)[filterIndex].values[valueIndex].isSelected = false;
    get(selectedFilters).splice(index, 1);
    pageLoadAfterFilterChange();
}

export function clearAllSelectedFilters() {

    // ACC.algoliaproductlist.var.beginRange = "";
    // ACC.algoliaproductlist.var.endRange = "";
    beginRange.set("");
    endRange.set("");
    get(selectedFilters).forEach(function (filter, index) {
        var filterIndex = filter.filterIndex;
        var valueIndex = filter.valueIndex;
        get(facetList)[filterIndex].values[valueIndex].isSelected = false;

    });
    selectedFilters.set([]);
    priceString.set("");
    priceFilterIndex.set("");
    ratingString.set("");
    ratingFilterPosition.set("");

    ratingSelectd.set([]);
    ratingString.set("");
    pageLoadAfterFilterChange();

}


export function changePageSize(pageSize) {
    itemPerPageList.set(pageSize);
    selectedPageSize.set(pageSize);
    get(pagination).currentPage = 0;
    pageLoad();
}

export function doSort(sortValue) {

    if (sortValue !== undefined && sortValue !== "") {
        currentSortValue.set(sortValue);
    }
    else {
        currentSortValue.set("relevance");
    }

    window.scrollTo(0, 0);

    if (unbxdEnabled) {
        const currentSort = get(currentSortValue);
        const sort = currentSort === "relevance" ? '' : sortValue.replace(/_/g, ' ').trim();
        unxUpdatePageSetting({ sort, page: 0 });
    } else {
        itemPerPageList.set(window['ACC'].config.algoliaConfig.selectedPageSize);
        selectedPageSize.set(window['ACC'].config.algoliaConfig.selectedPageSize);
        get(pagination).currentPage = 0;
        pageLoad();
    }
}

export function generateSortMap() {
    var _this = this;
    sortCodeMap.set({});
    sortSuffixMap.set({});
    window['ACC'].config.algoliaConfig.sorts.forEach(function (sortObj) {

        if (sortObj.defaultSort === true) {
            //	sortObj["sortSuffix"] = sortObj.code;
            get(sortSuffixMap).relevance = sortObj;
            get(sortCodeMap).relevance = sortObj;
        }
        else {
            get(sortSuffixMap)[sortObj.sortSuffix] = sortObj;
            get(sortCodeMap)[sortObj.code] = sortObj;
        }
    });
}

export function generateUrlQuery(res) {

    var params = res.params;
    var text = getUrlParam("query", params);
    if (!get(isSearch)) {
        text = "";
    }

    var hitsPerPage = getUrlParam("hitsPerPage", params);
    var page = getUrlParam("page", params);
    var sortOption = get(currentSortValue) === undefined || get(currentSortValue) === null ? "relevance" : get(sortSuffixMap)[get(currentSortValue)].code;

    var filtersForUrl = get(facetFilters).map(function (item) {
        if (item === "inStock:" + get(userCity) + "_inStock") {
            return "inStock:true";
        }
        if (item.split(":")[0] === "deliveryFacet") {
            isInitialPageLoadCompleted.set(true);
            var filterValue = item.split(":")[1];
            var newValue = get(userCity) + "_" + filterValue.split("_")[1];

            if (filterValue.split("_")[1])
                return "deliveryFacet:" + newValue;
            else {
                // return "deliveryFacet:"+get(userCity)+"_"+filterValue.split("_")[0];
                return "deliveryFacet:" + filterValue;
            }
        }

        else {
            return item;
        }
    });

    var _facetFilters = filtersForUrl.join(":");


    if (_facetFilters !== "") {
        _facetFilters = ":" + _facetFilters;
    }
    if (get(priceString) !== "") {
        var priceRange = get(priceString).replace("-", " TO ").split(" TO ");
        var beginPrice = priceRange[0];
        var endPrice = priceRange[1];
        _facetFilters = _facetFilters + ":price:" + beginPrice + "-" + endPrice;
    }

    if (get(ratingString) !== "") {
        _facetFilters = _facetFilters + ":ratingFacet:" + get(ratingString);
    }

    var queryUrl = "?q=" + text + ":relevance" + _facetFilters + "&text=" + text + "&" + "pageSize=" + hitsPerPage + "&" + "pg=" + page;
    if (sortOption !== '' && sortOption !== null && sortOption !== undefined) {
        queryUrl += "&" + "sort=" + sortOption.replace("_", "");
    }

    if (get(beginRange) !== "" && get(endRange) !== "") {
        queryUrl += "&" + "begin_ranges=" + get(beginRange) + "&end_ranges=" + get(endRange);
    }


    if (getAppConfig()['pageId'] !== 'search') {
        queryUrl = getSEOUrl(location.pathname, queryUrl);
    }

    if (get(isInitialPageLoadCompleted)) {
        history.replaceState({}, null, queryUrl);
    }

}

function getSEOUrl(path, queryUrl) {
    // path can contain ::: /en-sa/path-to-product/facet/sdfsdfs
    if (path.indexOf(seoFilterPathPrefix) > -1) {
        path = path.split(seoFilterPathPrefix)[0];
    }
    const seoFilters = get(facetFilters).filter(x => filtersTobeExcludedInURL.indexOf(x.split(':')[0]) == -1);
    let seoFilterString = seoFilters.filter(x => x.trim() !== '').join(':');
    seoFilterString = replaceSpecialCharactersInURL(seoFilterString);
    const seoUrl = joinUrls(path, seoFilterPathPrefix, seoFilterString) + queryUrl;

    return seoUrl;
}

export function parsePLpData(products) {
    var $productSKU = get(productSKU);
    var $lang = get(lang);
    var $compareProducts = get(compareProducts);

    if ($productSKU !== null) {
        //_this.facetList = [];
        //_this.selectedFilters = [];
    }
    var _productList = products.map(function (product) {
        var parsedProduct = product;
        var freegiftinfo = {}; var dealTimer: any = { 'deals': false }; var cashBackPrice: any = 0; var afterCashBackPrice = 0;
        parsedProduct.isCompare = false;

        $compareProducts.map((val) => {
            if (val.productCode === parsedProduct.productCode) {
                parsedProduct.isCompare = true;
            }
        })
        if (product.freegiftworth !== "") {
            var freegiftArray = product.freegiftworth.split('-');
            var freeGiftSet = false;
            var freeGitftWorth = 0;
            var targetQuantity = 0;
            var sourceQuantiy = 0;
            var freeGiftMap = [];
            if (freegiftArray.length === 5) {
                freeGitftWorth = freegiftArray[0];
                targetQuantity = freegiftArray[1];
                sourceQuantiy = freegiftArray[2];
                freeGiftMap = freegiftArray[3];
                freeGiftSet = freegiftArray[4];
            }
            freegiftinfo = {
                "hasFreegift": true,
                "freeGiftSet": freeGiftSet,
                "freeGitftWorth": freeGitftWorth,
                "targetQuantity": targetQuantity,
                "sourceQuantiy": sourceQuantiy,
                "freeGiftMap": freeGiftMap
            };
        } else {
            freegiftinfo = { "hasFreegift": false };
        }
        if (product.endDateDiscount !== "" && product.endDateDiscount !== null && product.endDateDiscount !== undefined) {

            var dateEnd = product.endDateDiscount;
            var dateEndInmilliSeconds = new Date(dateEnd).getTime();
            var currentTime = new Date().toLocaleString("en-US", { hour12: false });
            var currentTimeInMilli = new Date(currentTime).getTime();
            var duration = dateEndInmilliSeconds - currentTimeInMilli;
            var hours = duration / (1000 * 60 * 60);
            var absoluteHours = Math.floor(hours);
            var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;
            var minutes = (hours - absoluteHours) * 60;
            var absoluteMinutes = Math.floor(minutes);
            var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;
            var seconds = (minutes - absoluteMinutes) * 60;
            //var absoluteSeconds = Math.floor(seconds);
            var absoluteSeconds = ((duration % 60000) / 1000).toFixed(0);
            var s = Number(absoluteSeconds) > 9 ? absoluteSeconds : '0' + absoluteSeconds;
            if (hours >= 0 && hours <= (get(algoliaConfigdata) as any).countdownThreshold) {
                dealTimer = {
                    "deals": true,
                    "hDiff": h,
                    "minDiff": m,
                    "secDiff": s,
                    "distance": null
                };
            }
        }
        if (product.hasCashBack === true && product.cashback !== '') {
            var cashbackArray = product.cashback.split('-');
            cashBackPrice = Number(cashbackArray[0])?.toFixed(currencyDecimal);
        }
        if (product.priceValueDiscount > 0) {
            afterCashBackPrice = product.priceValueDiscount - cashBackPrice;
            parsedProduct.priceValueDiscountPercentage = roundDownHalfToFixedPlaces(parsedProduct.priceValueDiscountPercentage, window['ACC'].config.decimalCount);
        } else {
            afterCashBackPrice = product.price - cashBackPrice;
        }
        parsedProduct.freegiftInfo = freegiftinfo;
        parsedProduct.dealTimer = dealTimer;
        parsedProduct.cashBackPrice = cashBackPrice;
        parsedProduct.afterCashBackPrice = afterCashBackPrice;
        parsedProduct.isWishList = get(productWishList).indexOf(product.productCode) !== -1 ? true : false;
        parsedProduct.urlEn = APP_CONFIG.encodedContextPath + product['url' + $lang];
        return parsedProduct;

    })
    productList.set(_productList);

    if (document.querySelector(".c_search-autosuggestion")) {
        setTimeout(function () { (document.querySelector(".c_search-autosuggestion") as any).style.display = "none"; }, 500);
    }
    pageLoader.hideLoader();
    if (get(productList).length > 0) {
        isSearchResult.set(true);
        isNewSearch.set(false);
    }


}


export function mergeFacets(responses) {
    var currentFacet = {};
    var facetProductResponse = responses[0].facets;
    //var facetProductResponse = _this.sortAndFilterFacets(responses);
    var facetResonseObj = JSON.parse(JSON.stringify(responses[0].facets));
    if (responses.length > 1) {
        get(facetQueryList).forEach(function (facet, index) {
            currentFacet = responses[index + 1].facets[facet];
            facetResonseObj[facet] = JSON.parse(JSON.stringify(currentFacet));
        });

        get(facetFilters).forEach(function (item, index) {
            var facetKey = item.split(":")[0];
            var valueKey = item.split(":")[1];
            var slectedFilter;
            if (facetResonseObj[facetKey][valueKey] !== undefined) {
                slectedFilter = facetResonseObj[facetKey][valueKey] + ":::selected";
            }
            else if (facetProductResponse[facetKey][valueKey] !== undefined) {
                slectedFilter = facetProductResponse[facetKey][valueKey] + ":::selected";
            }
            else {
                slectedFilter = "0:::selected";
            }


            facetResonseObj[facetKey][valueKey] = slectedFilter;
        });

    }
    generateFacetList(facetResonseObj, responses);

}

export function sort_by_key(items, key, reverse = false) {
    const mux = reverse ? -1 : 1;
    return items.sort(function (a, b) {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 * mux : ((x > y) ? 1 * mux : 0));
    });
};


export const selectFilter = (filterName, filterIndex, valueIndex) => {

    isPageReloadWithFilters.set(false);
    beginRange.set("");
    endRange.set("");
    facetList.setSelection(filterName, filterIndex, valueIndex);
    const currentFilter = get(facetList).filter(x => x.name === filterName);
    if (currentFilter.length > 0) {
        const currentSelectedFilterValues = currentFilter[0].values.filter(x => x.isSelected).map(x => x.value).join(",")
        const selectedFilterString = filterName + ": " + currentSelectedFilterValues;
        window['ACC'].extragtm.click.plpFilterClick(selectedFilterString, filterIndex + 1);
    }
    if (get(facetList)[filterIndex].values[valueIndex].isSelected) {
        var selectedFilterObj = { filterIndex: filterIndex, valueIndex: valueIndex, filterValue: get(facetList)[filterIndex].values[valueIndex].value, filterTitle: get(facetList)[filterIndex].values[valueIndex].title };
        selectedFilters.push(selectedFilterObj);
    }
    else {

        var filterIndexToRemove;
        get(selectedFilters).forEach(function (filter, index) {
            if (filter.filterIndex === filterIndex && filter.valueIndex === valueIndex) {
                filterIndexToRemove = index;
            }
        });

        selectedFilters.spliceItem(filterIndexToRemove, 1);
    }
    pageLoadAfterFilterChange({ filter_rank: filterIndex, filter_name: filterName });

}

export function pageLoadAfterFilterChange(eventObj = {}) {
    pagination.setCurrentPage(0);
    var selectedFiltersToQuery = [];
    facetNumericFilters.set([]);
    facetFilters.set([]);
    if (get(ratingString) !== '') {
        var ratingvalue = parseInt(get(ratingString));
        var ratingMinFilter = 'rating>=' + ratingvalue;
        facetNumericFilters.addItem(ratingMinFilter);
    }
    if (get(priceString) !== '') {
        var priceObjArray = [];
        if (get(priceString).indexOf('TO') !== -1) {
            priceObjArray = get(priceString).split('TO');
        } else if (get(priceString).indexOf('-') !== -1) {
            priceObjArray = get(priceString).split('-');
        }
        if (priceObjArray.length === 2) {
            /*var priceMinFilter = 'price>'+priceObjArray[0];
            var priceMaxFilter = 'price<'+priceObjArray[1];
            _this.facetNumericFilters.push(priceMinFilter);
            _this.facetNumericFilters.push(priceMaxFilter);*/
            var priceFilter = "price : " + priceObjArray[0] + " TO " + priceObjArray[1];
            facetNumericFilters.addItem(priceFilter);
        }
    }
    get(facetList).forEach(function (filter) {
        var filterObject = { name: filter.name, selectedValues: [] };
        filter.values.forEach(function (item) {
            if (item.isSelected) {
                var filterString = null;
                filterObject.selectedValues.push(item);
                if (get(algoliaConfigdata)["defaultFilters" + get(lang)].indexOf(item.filter) !== -1) {
                    filterString = item.filter + ":" + item.value;
                }
                else {
                    filterString = "productFeatures" + get(lang) + "." + item.filter + ":" + item.value;
                }
                facetFilters.push(filterString);
            }

        });
        if (filterObject.selectedValues.length > 0) {
            selectedFiltersToQuery.push(filterObject);
        }
    });

    pageLoad();
    setTimeout(() => {
        if (Object.keys(eventObj).length > 0) {
            const urlParams = new URLSearchParams(window.location.search);
            const sortsValue = urlParams.get('sort');

            var _filters = [];
            var _sorts = [{
                type: 'price',
                value: sortsValue
            }];

            selectedFiltersToQuery.map((val) => {
                val.selectedValues.map((v1) => {
                    _filters.push({ type: val.name, value: v1.value });
                })
            })
            filteredOptionsData.set(_filters);

            window['ACC'].mixpanel.track('Product List Filtered',
                {
                    list_id: window['ACC'].config?.categoryCode && window['ACC'].config.categoryCode,
                    filters: _filters,
                    sorts: _sorts,
                    products: getItemList(get(productList))
                }
            );
            //Removed code for BrandPage Viewed event

        }
    }, 1000);
}

export function pageLoad() {
    getProductList();

}

export function getItemList(prodData) {
    let resultList = [];
    function getCategoriesObject(categoriesList) {
        let categoryObj = { item_category1: '', item_category2: '', item_category3: '' };
        categoriesList.toReversed().forEach((data, index) => {
            categoryObj[`item_category${index + 1}`] = data;
        });
        return categoryObj;
    }

    resultList = prodData.map((item, i) => {
        return {
            product_id: item.productCode,
            category: item?.allCategoriesEn?.join(','),
            name: item.nameEn,
            brand: item.brandEn,
            price: item.price,
            discount_price: item.afterCashBackPrice ? item.afterCashBackPrice : item.price,
            position: i + 1,
            ...getCategoriesObject(item?.allCategoriesEn)
        };
    });

    return resultList;
}


export function generateFacetList(facets, responses) {
    //var facetLimit =  get(algoliaConfigdata).displayFacetCount;
    var $lang = get(lang);
    var $userCity = get(userCity);

    var factesArray = [];
    Object.keys(facets).forEach(function (key, facetIndex) {
        var facetObj = { name: "", originalName: "", title: "", isVisible: true, showAll: false, values: [] };
        facetObj.originalName = key;
        if (key.split("productFeatures" + $lang + '.')[1] !== undefined || key.split(".").length === 1) {
            if (key.split("productFeatures" + $lang + '.')[1] !== undefined) {
                facetObj.name = key.split("productFeatures" + $lang + '.')[1];
                facetObj.title = key.split("productFeatures" + $lang + '.')[1];
            }
            else if (key.split(".").length === 1) {
                if (get(filterInfo)[key] !== undefined) {
                    facetObj.name = key;
                    facetObj.title = get(filterInfo)[key].name;
                }
                else {
                    facetObj.name = key;
                    facetObj.title = key;
                }
            }
            var facetValueObj = facets[key];

            Object.keys(facetValueObj).forEach(function (key) {
                var facetValueItem: any = {};
                facetValueItem.count = facetValueObj[key];
                facetValueItem.isSelected = false;
                if (facetValueObj[key].toString().split(":::selected").length > 1) {
                    facetValueItem.isSelected = true;
                    facetValueItem.count = parseInt(facetValueObj[key]);
                }

                facetValueItem.filter = facetObj.name;
                facetValueItem.value = key;



                if (facetObj.name === "inStock") {
                    if ($userCity + "_inStock" === key) {
                        facetValueItem.title = get(localizationConfig).availabilityFacetInStock.name;
                        facetObj.values.push(facetValueItem);
                    }
                    if ("comingSoonEnabled" === key) {
                        facetValueItem.title = get(localizationConfig).availabilityFacetComingSoon.name;
                        facetObj.values.push(facetValueItem);
                    }
                    if ($userCity + "outOfStock" === key) {
                        facetValueItem.title = get(localizationConfig).availibilityFacetOutOfStock.name;
                        facetObj.values.push(facetValueItem);
                    }
                }

                if (facetObj.name === "offersFacet") {

                    if ("newArrivals" === key) {
                        facetValueItem.title = get(localizationConfig).offersFacetNewArrival.name;
                        facetObj.values.push(facetValueItem);
                    }

                    if ("onlineExclusive" === key) {
                        facetValueItem.title = get(localizationConfig).offersFacetOnlineExclusive.name;
                        facetObj.values.push(facetValueItem);
                    }

                    if ("productOffers" === key) {
                        facetValueItem.title = get(localizationConfig).offersFacetProductOffers.name;
                        facetObj.values.push(facetValueItem);

                    }

                    if ("preorder" === key) {
                        facetValueItem.title = get(localizationConfig).offersFacetPreorder.name;
                        facetObj.values.push(facetValueItem);

                    }

                    if ("topSellers" === key) {
                        facetValueItem.title = get(localizationConfig).offersFacetTopsellers.name;
                        facetObj.values.push(facetValueItem);

                    }

                    if ("dealsOfTheDay" === key) {
                        facetValueItem.title = get(localizationConfig).offersFacetDealsOfTheDay.name;
                        facetObj.values.push(facetValueItem);

                    }
                    if ("clearenceDeal" === key) {
                        facetValueItem.title = get(localizationConfig).offersFacetClearanceDeal.name;
                        facetObj.values.push(facetValueItem);

                    }

                    if ($userCity + "_goingOutOfStock" === key) {
                        facetValueItem.title = get(localizationConfig).offersFacetGoingOutOfStock.name;
                        facetObj.values.push(facetValueItem);
                    }

                }
                if (facetObj.name === "deliveryFacet") {

                    if ("codEnabled" === key) {
                        facetValueItem.title = get(localizationConfig)?.deliveryFacetCodEnabled?.name;
                        facetObj.values.push(facetValueItem);
                    }
                    if ($userCity + "_homeDeliveryEnabled" === key) {
                        facetValueItem.title = get(localizationConfig)?.deliveryFacetHomeDelivery?.name;
                        facetObj.values.push(facetValueItem);
                    }
                    if ($userCity + "_collectFromStoreEnabled" === key) {
                        facetValueItem.title = get(localizationConfig)?.deliveryFacetCollectFromStore?.name;
                        facetObj.values.push(facetValueItem);
                    }

                }


                if (facetObj.name === "hasFreeGifts") {
                    if (key === "true") {
                        facetValueItem.title = "Yes";
                        facetObj.values.push(facetValueItem);
                    }
                }


                if (facetObj.name !== "inStock" && facetObj.name !== "deliveryFacet" && facetObj.name !== "offersFacet" && facetObj.name !== "hasFreeGifts") {
                    facetValueItem.title = key;
                    facetObj.values.push(facetValueItem);
                }

            });
            if (facetObj.values.length > 0) {
                if (facetObj.name === "family" + $lang && get(familyLevel) === "firstLevel") {
                    factesArray.push(facetObj);
                }

                if (facetObj.name === "subFamily" + $lang && (get(familyLevel) === "firstLevel" || get(familyLevel) === "family")) {

                    factesArray.push(facetObj);
                }
                else if (facetObj.name !== "family" + $lang && facetObj.name !== "subFamily" + $lang) {
                    factesArray.push(facetObj);
                }

            }
        }
    });

    // facetList.set(factesArray);
    var renderingContent = responses[0].renderingContent;
    var renderingSortedFilters = [];
    var facetLimitRendering = (get(algoliaConfigdata) as any).displayFacetCount;
    //var remainingFacetFliterShow = false;
    var remainingFacetFliterShow = (get(algoliaConfigdata) as any).remainingFacetFliterShow;
    if (renderingContent && renderingContent.facetOrdering !== undefined && renderingContent.facetOrdering.facets.order.length > 0) {
        if (isBrandCategory) {
            renderingContent.facetOrdering.facets.order = renderingContent.facetOrdering.facets.order.filter(x => ['brandEn', 'brandAr'].indexOf(x) == -1);
        }
        renderingContent.facetOrdering.facets.order.forEach(function (value) {
            factesArray.find(function (item, i) {
                if (item.name === value || item.originalName === value) {
                    if (renderingContent.facetOrdering.values[value] !== undefined) {
                        if (renderingContent.facetOrdering.values[value].order !== undefined && renderingContent.facetOrdering.values[value].sortRemainingBy !== undefined) {
                            let facetFilterValuesMatched = []; let facetFilterValuesUnMatched = [];
                            renderingContent.facetOrdering.values[value].order.forEach(function (faceValue) {
                                factesArray[i].values.forEach(function (facetFiltersActualArray, d) {
                                    if (facetFiltersActualArray.value === faceValue) {
                                        facetFilterValuesMatched.push(facetFiltersActualArray);
                                    }
                                });
                            });
                            factesArray[i].values.forEach(function (facetFiltersActualArray) {
                                if (facetFilterValuesMatched.indexOf(facetFiltersActualArray) === -1) {
                                    facetFilterValuesUnMatched.push(facetFiltersActualArray);
                                }

                            });
                            if (renderingContent.facetOrdering.values[value].sortRemainingBy === 'count') {
                                sort_by_key(facetFilterValuesUnMatched, 'count', true);
                            } else if (renderingContent.facetOrdering.values[value].sortRemainingBy === 'alpha') {
                                sort_by_key(facetFilterValuesUnMatched, 'value');
                            } else if (renderingContent.facetOrdering.values[value].sortRemainingBy === 'hidden') {
                                facetFilterValuesUnMatched = [];
                            }
                            factesArray[i].values = facetFilterValuesMatched.concat(facetFilterValuesUnMatched);
                            renderingSortedFilters.push(factesArray[i]);
                        } else if (renderingContent.facetOrdering.values[value].order !== undefined) {
                            var facetFilterValuesMatch = []; var facetFilterValuesUnMatch = [];
                            renderingContent.facetOrdering.values[value].order.forEach(function (faceValue) {
                                factesArray[i].values.forEach(function (facetFiltersActualArray, d) {
                                    if (facetFiltersActualArray.value === faceValue) {
                                        facetFilterValuesMatch.push(facetFiltersActualArray);
                                    }
                                });
                            });
                            if (remainingFacetFliterShow === true) {
                                factesArray[i].values.forEach(function (facetFiltersActualArray) {
                                    if (facetFilterValuesMatch.indexOf(facetFiltersActualArray) === -1) {
                                        facetFilterValuesUnMatch.push(facetFiltersActualArray);
                                    }
                                });
                            }
                            factesArray[i].values = facetFilterValuesMatch.concat(facetFilterValuesUnMatch);
                            if (factesArray[i].values.length > 0) {
                                renderingSortedFilters.push(factesArray[i]);
                            }
                        } else if (renderingContent.facetOrdering.values[value].sortRemainingBy !== undefined) {
                            if (renderingContent.facetOrdering.values[value].sortRemainingBy === 'count') {
                                sort_by_key(factesArray[i].values, 'count', true);
                            } else if (renderingContent.facetOrdering.values[value].sortRemainingBy === 'alpha') {
                                sort_by_key(factesArray[i].values, 'value');
                            } else if (renderingContent.facetOrdering.values[value].sortRemainingBy === 'hidden') {
                                factesArray[i].values = [];
                            }
                            renderingSortedFilters.push(factesArray[i]);
                        }
                    } else {
                        renderingSortedFilters.push(factesArray[i]);
                    }
                }
            });
        });
        /*factesArray.forEach(function(item,i){
            var filteredObj = factsPriorityFirstArray.find(function(filterItem, j){
                if(item.name === filterItem.name){
                    return true;
                }
            });
            if(filteredObj === undefined){
                factsPrioritySecond.push(factesArray[i]);
            }
        });*/

        facetList.set(renderingSortedFilters.slice(0, facetLimitRendering));

        markFilterSelection(get(facetList));
    } else {
        var factsPriorityFirstArray = [];
        var factsPrioritySecond = [];
        (get(algoliaConfigdata) as any).filterInfo.forEach(function (filterItem) {
            factesArray.find(function (item, i) {
                if (item.name === filterItem.code) {
                    if (!isBrandCategory || ['brandEn', 'brandAr'].indexOf(item.originalName) == -1) {
                        factsPriorityFirstArray.push(factesArray[i]);
                    }
                }
                // else if(filterItem.code === 'ratingFacet' && item.name === 'rating'){
                // 	factsPriorityFirstArray.push(factesArray[i]);
                // }
            });
        });
        factesArray.forEach(function (item, i) {
            var filteredObj = factsPriorityFirstArray.find(function (filterItem, j) {
                if (item.name === filterItem.name) {
                    return true;
                }
            });

            if (filteredObj === undefined) {
                if (!isBrandCategory || ['brandEn', 'brandAr'].indexOf(item.originalName) == -1) {

                    factsPrioritySecond.push(factesArray[i]);
                }
            }
        });
        //_this.facetList = factesArray;
        var facetLimit = (get(algoliaConfigdata) as any).displayFacetCount; // ACC.config.algoliaConfig.displayFacetCount
        var sortedFilters = factsPriorityFirstArray.concat(factsPrioritySecond);
        facetList.set(sortedFilters.slice(0, facetLimit)); // commented temp
        markFilterSelection(get(facetList));
    }
}


export function markFilterSelection(list) {
    selectedFilters.set([]);
    list.forEach(function (facetItem, filterIndex) {
        facetItem.values.forEach(function (facetValue, valueIndex) {
            if (facetValue.isSelected) {
                var selectedFilterObj = { filterIndex: filterIndex, valueIndex: valueIndex, filterValue: get(facetList)[filterIndex].values[valueIndex].value, filterTitle: get(facetList)[filterIndex].values[valueIndex].title };
                selectedFilters.addItem(selectedFilterObj);
            }
        });

    });

}

export function getProductList() {

    if (unbxdEnabled) {
        loadInitialUnbxdRequest();
        return;
    }


    isPriceFilter.set(false);
    if (get(priceString)) {
        isPriceFilter.set(true);
    }
    isCategoryNoResult.set(false);
    //this.checkForSKU();

    var _searchTerm;
    if (get(isSearch)) {
        _searchTerm = decodeURIComponent(get(searchTerm));
    }
    else {
        _searchTerm = "";
    }

    if (window['ACC']?.autocomplete?.isTermInvalidNumber(_searchTerm)) {
        return;
    }
    //this.productList = [];
    pageLoader.showLoader();
    if (get(isSearch)) {
        var searchLink = location.href.split("?")[0] + "?text=" + _searchTerm + "&page=" + get(pagination).currentPage;
    }
    var productIndex = (get(currentSortValue) === "relevance" || get(currentSortValue) === null || get(currentSortValue) === undefined) ? get(algoliaIndexConfig).productIndexKey : get(algoliaIndexConfig).productIndexKey + get(currentSortValue);
    var productQuery = {
        indexName: productIndex,
        query: _searchTerm,
        params: getDataParams()
    };
    var facetQuery = null;
    var algoliaQueryList = [productQuery];
    if (get(facetFilters) && get(facetFilters).length > 0) {

        facetQueryList.set([]);

        const features = [];

        const uniqueFacetFilters = [...new Set(get(facetFilters))];
        facetFilters.set(uniqueFacetFilters);

        get(facetFilters).forEach(function (facet) {
            const [facetName, facetValue] = facet.split(':');
            if (get(facetQueryList).indexOf(facetName) === -1) {
                facetQueryList.push(facetName);
            }

            // seo related code here
            if (['brandEn', 'brandAr'].indexOf(facetName) > -1) {
                seoMetaData.setBrand([...get(seoMetaData).brand, facetValue]);
            } else if (['subFamilyEn', 'subFamilyAr'].indexOf(facetName) > -1) {
                seoMetaData.setSubFamily([...get(seoMetaData).subFamily, facetValue]);
            } else if (['familyEn', 'familyAr'].indexOf(facetName) > -1) {
                seoMetaData.setFamily([...get(seoMetaData).family, facetValue]);
            } else if (facetName.indexOf('productFeaturesEn.') > -1 && features.length < 2) {
                const featureString = `${facetValue} ${facetName.split('productFeaturesEn.')[1]}`;
                features.push(featureString);
            }
        });

        const facetKeys = get(facetFilters).map(x => x.split(':')[0]);
        const uniqueFacetKeys = [...new Set(facetKeys)];
        seoMetaData.setIsMultipleValueUnderSameFacet(facetKeys.length !== uniqueFacetKeys.length);

        seoMetaData.setFeatures(features);

        get(facetQueryList).forEach(function (facet) {
            var excludedFacetArray = get(facetFilters).filter(function (facetTofilter) {
                if (facetTofilter.split(":")[0] !== facet) {
                    return true;
                }
            });

            facetQuery = {
                indexName: productIndex,
                query: _searchTerm,
                params: {
                    hitsPerPage: get(selectedPageSize),
                    responseFields: [
                        'facets'
                    ],
                    facets: facet
                }

            };

            if (!get(isSearch)) {

                var searchParams = getDataParams();

                if (searchParams.ruleContexts !== undefined && searchParams.ruleContexts.length > 0) {
                    facetQuery.params.ruleContexts = searchParams.ruleContexts;
                }
                else {
                    facetQuery.params.filters = get(filterString);
                }
            }

            if (get(facetNumericFilters) !== undefined && get(facetNumericFilters).length > 0) {
                facetQuery.params.numericFilters = get(facetNumericFilters);
            }

            if (excludedFacetArray.length > 0) {
                facetQuery.params.facetFilters = getGroupedFacetsSelection(excludedFacetArray, false);
            }
            algoliaQueryList.push(facetQuery);

        });
    }

    if (!unbxdEnabled) {
        algoliaQueryList = addRMNItemsToAlgoliaQueryList(algoliaQueryList);
        get(algoliaClient).multipleQueries(algoliaQueryList).
            then(function (response) {
                response = mergeRMNHitsIntoSingleAlgoliaResponse(response);
                renderPLPRMNBanner(response);
                var res = response.results[0];
                if (res?.hits?.length > 0 && isMegaSaleDuringAccessStarted == true && primeLevel) {
                    res.hits = reAssignPriceValues(res?.hits);
                }
                if (get(isSearch) && res.userData !== undefined && res.userData[0]?.redirect) {
                    window.location = APP_CONFIG.encodedContextPath + res.userData[0].redirect;
                }
                else {
                    if (get(isMobile)) {
                        if (res.facets !== undefined) {
                            mergeFacets(response.results);
                        }
                        if (res.facets_stats !== undefined) {
                            facetsStats.set(res.facets_stats);
                            if (res.facets_stats.price) {
                                priceEndDisplay.set((res.facets_stats.price.max).toString());
                                priceStartDisplay.set((res.facets_stats.price.min).toString());
                            }

                        }
                        pageLoader.hideLoader();

                    }
                    else {

                        if (res.hits.length === 0) {
                            isInitialNoProductPageLoadCompleted.set(true);
                            if (get(isSearch)) {
                                if (response.results.length > 1) {
                                    pageLoader.hideLoader();
                                    isCategoryNoResult.set(true);
                                }
                                else {
                                    sessionStorage.setItem('searchTerm', _searchTerm);
                                    window.location.href = APP_CONFIG.encodedContextPath + '/search/?text=';
                                }
                            } else {
                                pageLoader.hideLoader();
                                isCategoryNoResult.set(true);
                                sessionStorage.removeItem('searchTerm');
                            }

                        } else {
                            //sessionStorage.removeItem('searchTerm');
                            saecrhQueryId.set(res.queryID);
                            parsePLpData(res.hits);
                            pagination.setPagination(res);
                            // _this.setPagination(res);
                            // _this.gotoTop();
                            // _this.generateUrlQuery(res);


                            //var dynamicQuery = "?"+res.params+"&sortIndex="+get(currentSortValue);
                            if (get(isSearch)) {
                                //history.replaceState({}, null, dynamicQuery);
                                //history.replaceState({}, null, searchLink);
                            }
                            else {
                                //history.replaceState({}, null, dynamicQuery);
                                //history.replaceState({}, null, searchLink);
                            }

                            if (res.facets !== undefined) {
                                mergeFacets(response.results);
                            }
                            if (res.facets_stats !== undefined) {
                                facetsStats.set(res.facets_stats);
                                if (res.facets_stats.price) {
                                    priceEndDisplay.set((res.facets_stats.price.max).toString());
                                    priceStartDisplay.set((res.facets_stats.price.min).toString());
                                }

                            }

                            generateUrlQuery(res);
                            isInitialPageLoadCompleted.set(true);
                        }
                    }
                }
            }
            ).then(function () {
            });
    } else {
        loadInitialUnbxdRequest();
    }
}

export function loadInitialUnbxdRequest() {
    // Unbxd is enabled as the data provider
    const unxSP = parseUrlToUnbxdSP(document.location.href);
    const combinedPLPSP = combineURLSearchParams([plpSpecificSP, unxSP]);

    unxSetBreadcrumbs();

    window['ACC'].loader.showLoader();
    getSearchAPIResults(combinedPLPSP)
        .then(r => unxResponse.set(r))
        .then(() => {
            // set all category related variables
            const { category } = getAppConfig();
            enableComparison.set(category?.enableCompare);

            if (category?.categoryName?.length > 0) {
                seoMetaData.setCategory(category?.categoryName);
            } else {
                seoMetaData.setCategory('');
            }
        }).finally(() => {
            window['ACC'].loader.hideLoader();
        });
}
export function unxSetBreadcrumbs() {
    const { category, lang } = getAppConfig();
    const nameFieldname = lang === 'ar' ? 'nameAr' : 'nameEn';
    const breadcrumbsArray = (category?.breadcrumbs || []).map(b => Object.assign({}, b, { [nameFieldname]: b.name }));
    categoryBreadCrumbs.set(breadcrumbsArray);
}
export function renderPLPRMNBanner(response) {
    const firstResult = response.results[0];
    const hasBannerData = 'userData' in firstResult && 'amplienceId' in firstResult.userData[0];

    if (hasBannerData) {
        getDataFromAmplienceAsync(firstResult.userData[0].amplienceId)
            .then(parseImageBannerData)
            .then(plpRMNBannerData.set);
    } else {
        plpRMNBannerData.set(null);
    }
}
export function addRMNItemsToAlgoliaQueryList(algoliaQueryList: any[]) {

    if (algoliaQueryList.length > 0) {
        const mainQuery = algoliaQueryList.at(0);
        if ('params' in mainQuery) {
            const facetFilters = mainQuery.params.facetFilters || [];

            // extra special facets
            const extraSpecialFacetFilters = [...facetFilters, ['sponsoredType:EXTRASPECIAL']];
            // sponsored facets
            const sponsoredFacetFilters = [...facetFilters, ['sponsoredType:SPONSORED']];
            // non-sponsored facets
            const nonSponsoredFacetFilters = [...facetFilters];

            const newMainQueryParams = [];

            const isFirstPage = mainQuery.params.page === 0;
            const isRelevanceSort = mainQuery.indexName === get(algoliaIndexConfig).productIndexKey;

            if (isFirstPage && isRelevanceSort) {
                newMainQueryParams.push({ facetFilters: extraSpecialFacetFilters, hitsPerPage: 1, clickAnalytics: false });
                newMainQueryParams.push({ facetFilters: sponsoredFacetFilters, hitsPerPage: 2, clickAnalytics: false });
                newMainQueryParams.push({ facetFilters: nonSponsoredFacetFilters });
            } else if (isFirstPage && !isRelevanceSort) {
                newMainQueryParams.push({ facetFilters: extraSpecialFacetFilters, hitsPerPage: 1, clickAnalytics: false });
                newMainQueryParams.push({ facetFilters: nonSponsoredFacetFilters });
            } else {
                newMainQueryParams.push({ facetFilters: nonSponsoredFacetFilters });
            }

            const newMainQueries = newMainQueryParams
                .map(newQueryParams => Object.assign({}, mainQuery.params, newQueryParams))
                .map(params => Object.assign({}, mainQuery, { params }));

            console.log('newQueries :::', newMainQueries);
            algoliaQueryList.shift();
            algoliaQueryList = [...newMainQueries, ...algoliaQueryList];
        }
    }

    console.log("Query list going :::", algoliaQueryList);
    return algoliaQueryList;
}

export function mergeRMNHitsIntoSingleAlgoliaResponse(algoliaResponse: { results: any[] }) {
    const allHitsFromResponse = algoliaResponse
        .results.filter(res => 'hits' in res)
        .map(res => res.hits).flat();
    const mainResult = algoliaResponse.results.filter(res => 'hitsPerPage' in res && res.hitsPerPage > 10).at(0);
    const otherResults = algoliaResponse.results.filter(res => !('hitsPerPage' in res));
    mainResult.hits = allHitsFromResponse;

    if (mainResult.hits.length > mainResult.hitsPerPage) {
        mainResult.hits.length = mainResult.hitsPerPage;
    }

    const { page } = mainResult;
    const isFirstPage = page === 0;
    const hasExtraSpecialHit = mainResult.hits > 0 && isValueExtraSponsored(mainResult.hits[0].sponsoredType);
    mainResult.hits.forEach((currentHit, currentHitIndex) => {
        // first page ::: EXTRASPECIAL => null for i > 0
        if (isFirstPage && currentHitIndex > 0) {
            if (isValueExtraSponsored(currentHit.sponsoredType)) {
                currentHit.sponsoredType = null;
            } else if (isValueSponsored(currentHit.sponsoredType)) {
                if ((currentHitIndex === 2 && !hasExtraSpecialHit) || currentHitIndex > 2) {
                    currentHit.sponsoredType = null;
                }
            }
        } else if (!isFirstPage) {
            currentHit.sponsoredType = null;
        }
    });

    const productCodeSet = new Set<string>();
    mainResult.hits = mainResult.hits.filter(h => {
        if (!productCodeSet.has(h.productCode)) {
            productCodeSet.add(h.productCode);
            return true;
        }
        return false;
    });
    // mainResult.nbHits = mainResult.hits.length;

    algoliaResponse.results = [mainResult, ...otherResults];
    console.log('mergeRMNHitsIntoSingleAlgoliaResponse :::', allHitsFromResponse);
    return algoliaResponse;
}

export function getGroupedFacetsSelection(array, isFull) {
    if (isFull) {
        localFacetSelectionMap.set({});
    }
    var _localFacetSelectionMap = {};
    array.forEach(function (item) {
        var facetname = item.split(":")[0];
        if (_localFacetSelectionMap[facetname]) {
            _localFacetSelectionMap[facetname].push(item);
        }
        else {
            _localFacetSelectionMap[facetname] = [item];
        }


    });
    var facetGroups = [];
    Object.keys(_localFacetSelectionMap).forEach(function (key, facetIndex) {
        facetGroups.push(_localFacetSelectionMap[key]);
    });
    if (isFull) {
        localFacetSelectionMap.set(_localFacetSelectionMap);
    }
    return facetGroups;
}

export function getFilterInfo() {
    var _filterInfo = {}
    var _localizationConfig = {};
    (get(algoliaConfigdata) as any).filterInfo.forEach(function (item) {
        if (item.facet === true) {
            _filterInfo[item.code] = item;
        }
        else {
            _localizationConfig[item.code] = item;
        }
    });
    filterInfo.set(_filterInfo);
    localizationConfig.set(_localizationConfig);

}

export async function getInitialSearchList() {
    var urlParams = decodeURIComponent(location.href).split("?")[1];

    let theSearchTerm = decodeURIComponent(location.href).split("text=")[1].split("&")[0] || location.href.split("q=")[1].split("&")[0];
    theSearchTerm = theSearchTerm.replace(/\+/g, ' ');
    searchTerm.set(theSearchTerm);
    pagination.update((state) => {
        state.currentPage = getUrlParam("pg", urlParams) === null ? 0 : parseInt(getUrlParam("pg", urlParams));
        return state;
    })
    selectedPageSize.set(getUrlParam("pageSize", urlParams) === null ? APP_CONFIG.algoliaConfig.selectedPageSize : getUrlParam("pageSize", urlParams))


    if (getUrlParam("sort", urlParams) !== null && getUrlParam("sort", urlParams) !== "relevance") {
        currentSortValue.set(get(sortCodeMap)[getUrlParam("sort", urlParams)].sortSuffix);

    }
    else {
        currentSortValue.set(getUrlParam("sort", urlParams));
    }

    if (get(currentSortValue) !== "relevance" && currentSortValue !== null) {
        algoliaIndexConfig.update((state) => {
            state.productIndex = get(algoliaClient).initIndex(get(algoliaIndexConfig).productIndexKey + get(currentSortValue))
            return state;
        })
    }
    else {
        algoliaIndexConfig.update((state) => {
            state.productIndex = get(algoliaIndexConfig).productOriginalIndex;
            return state;
        })

    }

    if (getUrlParam("q", urlParams) !== null) {
        parseUrlQuery(getUrlParam("q", urlParams));
    }
    isNewSearch.set(true);
    getProductList();


};

export function getUrlParam(key, params) {
    if (params.indexOf(key) !== -1 && params.split(key + "=").length > 1) {
        return decodeURIComponent(params.split(key + "=")[1].split("&")[0]);
    } else if (key === 'q') {
        return getSEOParam();
    } else {
        return null;
    }
};

function getSEOParam() {
    const urlPath = location.pathname;
    if (urlPath.indexOf(seoFilterPathPrefix) > -1) {
        let seoFilterString = ':relevance:' + decodeURIComponent(urlPath.split(seoFilterPathPrefix)[1]);
        const queryStartIndex = seoFilterString.indexOf('?');
        if (queryStartIndex > -1) {
            seoFilterString = seoFilterString.substring(0, queryStartIndex);
        }
        if (seoFilterString && seoFilterString.trim().length > 0) {
            return seoFilterString;
        }
    }
    return null;
}

export function parseUrlQuery(query) {
    var queryParams = query.split(":");
    if (queryParams.length > 1) {
        for (let x = 2; x < queryParams.length; x = x + 2) {
            if (queryParams[x] !== "" && queryParams[x] !== undefined) {
                if (queryParams[x] !== "price" && queryParams[x] !== "productFeaturesEn.Color" && queryParams[x] !== "inStock" && queryParams[x] !== "deliveryFacet" && queryParams[x] !== "ratingFacet") {
                    var filterVal = queryParams[x + 1].replace(/\+/g, " ");
                    var filter = queryParams[x] + ":" + filterVal;
                    facetFilters.update((state) => {
                        state = [...state, filter]
                        return state;
                    })
                }
                if (queryParams[x] === "price") {
                    priceString.set(queryParams[x + 1].replace("-", " TO "));
                    if (get(priceString) !== '') {
                        var priceObj = 'price : ' + get(priceString);
                        facetNumericFilters.update((state) => {
                            state = [...state, priceObj];
                            return state;
                        })
                    }
                }


                if (queryParams[x] === "ratingFacet") {
                    ratingString.set(queryParams[x + 1]);
                    ratingSelectd.update((state) => {
                        state = [...state, +queryParams[x + 1]];
                        return state
                    });
                    var ratingvalue = parseInt(queryParams[x + 1]);
                    var ratingMinFilter = 'rating>=' + ratingvalue;
                    facetNumericFilters.update((state) => {
                        state = [...state, ratingMinFilter];
                        return state
                    });

                }

                if (queryParams[x] === "productFeaturesEn.Color") {
                    if (colorInfo[queryParams[x + 1]] !== undefined) {
                        var ColorFilter = queryParams[x] + ":" + colorInfo[queryParams[x + 1]].label;
                        facetFilters.update((state) => {
                            state = [...state, ColorFilter];
                            return state
                        });
                    }


                }

                if (queryParams[x] === "inStock") {
                    var inStockFilter = queryParams[x] + ":" + get(userCity) + "_inStock";
                    facetFilters.update((state) => {
                        state = [...state, inStockFilter];
                        return state
                    });
                }


                if (queryParams[x] === "deliveryFacet") {
                    var filterValue = queryParams[x + 1];
                    const deliveryType = filterValue.split("_")[1];
                    var newValue = filterValue;
                    if (deliveryType) {
                        newValue = get(userCity) + "_" + filterValue.split("_")[1];
                    }
                    var deliveryFilter = "deliveryFacet:" + newValue;
                    facetFilters.update((state) => {
                        state = [...state, deliveryFilter];
                        return state
                    });
                }


            }
            isPageReloadWithFilters.set(true);
        }
    }
};

export function handleMenuNavigation() {
    const anchorElements: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(".mega-menu-container a.special-anchor");
    anchorElements.forEach(function (navElement) {
        navElement.addEventListener("click", function (event) {
            event.preventDefault();
            menuLink.set((event.currentTarget as HTMLAnchorElement).href);
            let linkUrl = (event.currentTarget as HTMLAnchorElement).href;
            // _this.pagination.currentPage= 0;
            // pagination.setCurrentPage(0);
            pagination.reset();
            getCategoryInfo(linkUrl);

            resetFilterSelection();

            document.querySelector('.mega-menu-container').classList.add('d-none');
            setTimeout(function () {
                document.querySelector('.mega-menu-container').classList.remove('d-none');
            }, 5);
            history.replaceState({}, null, linkUrl);
        });
    });
}

export function getInitialProductList() {
    if (!unbxdEnabled) {
        let categoryLink = location.href;
        getCategoryInfo(categoryLink);
    } else {
        // for unbxd every category url should be handled by browse API
        getProductList();
    }
}

export function resetFilterSelection() {
    facetFilters.set([]);
    selectedFilters.set([]);
    ratingSelectd.set([]);
    facetNumericFilters.set([]);
    ratingString.set('');
    priceString.set('');
    priceFilterIndex.set('');
}

export function getCategoryInfo(link) {
    familyLevel.set(null);
    categoryData.set(null);
    categoryDataCode.set(null);
    var catCode = link.split("/c/")[1].split("?")[0].split(seoFilterPathPrefix)[0];

    if (sessionStorage.getItem('previous-cat-code') === null) {
        sessionStorage.setItem('previous-cat-code', catCode);
        sessionStorage.setItem('current-cat-code', catCode);
    } else if (sessionStorage.getItem('previous-cat-code') !== catCode && sessionStorage.getItem('current-cat-code') !== catCode) {
        sessionStorage.setItem('previous-cat-code', sessionStorage.getItem('current-cat-code'));
        sessionStorage.setItem('current-cat-code', catCode);
    }
    categoryDataCode.set(catCode);
    var categoryTypeIndex = catCode.split("-").length;
    if (categoryTypeIndex === 1) {
        familyLevel.set("firstLevel");

    }

    if (categoryTypeIndex === 2) {
        familyLevel.set("family");

    }

    if (categoryTypeIndex === 3) {
        familyLevel.set("subFamily");
    }

    if (categoryTypeIndex > 1 && isNaN(catCode.split("-")[1])) {
        familyLevel.set("firstLevel");
    }


    var isCategoryValid = false;

    if (location.href.split("&page=").length > 1) {
        pagination.setCurrentPage(location.href.split("&page=")[1].split("&")[0]);
    }
    isCategoryStaticBottomContent.set(false);
    isCategoryStaticTopContent.set(false);
    get(algoliaIndexConfig).categoryIndex.search("*", {
        filters: "code:" + catCode,
        facets: "*",
        getRankingInfo: 1,
        clickAnalytics: true
    }).then(function (res) {
        if (res.hits.length > 0 && res.hits[0].manualVC !== true) {
            isBrandCategory = res.hits[0].isBrandCategory || false;
            handleCategoryStaticContent(res.hits[0]);
            handleBreadcrumb(res.hits[0]);
            res.hits.forEach(function (category) {
                enableComparison.set(category.enableComparison);
                if (catCode === category.code) {
                    isCategoryValid = true;
                    categoryData.set({ hits: [category] });
                }
            });
            if (isCategoryValid) {
                var urlParams = decodeURIComponent(location.href).split("?")[1];

                if (urlParams === undefined && location.pathname.indexOf(seoFilterPathPrefix) > -1) {
                    urlParams = location.pathname.split(seoFilterPathPrefix)[1].split('?')[0];
                }

                if (urlParams !== undefined) {

                    pagination.setCurrentPage(getUrlParam("pg", urlParams) === null ? 0 : getUrlParam("pg", urlParams));
                    selectedPageSize.set(getUrlParam("pageSize", urlParams) === null ? APP_CONFIG.algoliaConfig.selectedPageSize : getUrlParam("pageSize", urlParams));

                    if (getUrlParam("sort", urlParams) !== null && getUrlParam("sort", urlParams) !== "relevance") {
                        currentSortValue.set(get(sortCodeMap)[getUrlParam("sort", urlParams)].sortSuffix);
                    }
                    else {
                        currentSortValue.set(getUrlParam("sort", urlParams));
                    }

                    if (get(currentSortValue) !== "relevance") {
                        // ACC.main.alogolia.productIndex = ACC.main.alogolia.client.initIndex(ACC.main.alogolia.productIndexKey + get(currentSortValue));
                        algoliaIndexConfig.update((state) => {
                            state.productIndex = get(algoliaClient).initIndex(state.productIndexKey + get(currentSortValue));
                            return state;
                        });
                    }
                    else {
                        // ACC.main.alogolia.productIndex = ACC.main.alogolia.productOriginalIndex;
                        algoliaIndexConfig.update((state) => {
                            state.productIndex = get(algoliaClient).initIndex(state.productOriginalIndex);
                            return state;
                        });
                    }

                    if (getUrlParam("q", urlParams) !== null) {
                        parseUrlQuery(getUrlParam("q", urlParams));
                    }
                }
                getProductList();

                // seo meta data processing here
                const seoCategory = res.hits[0][getLangSpecificFieldname('name', get(lang))];
                seoMetaData.setCategory(seoCategory);

                const seoMarketKey = { SA: 'header.saudi-arabia', BH: 'header.bahrain', OM: 'header.oman' }[get(countryCode)];
                const seoMarket = get(_)(seoMarketKey);
                seoMetaData.setMarket(seoMarket);
            }
            else {
                isSearchResult.set(false);
                productList.set([]);
            }
        }
        else {
            isInitialNoProductPageLoadCompleted.set(true);
            // if (res.hits.length > 0 && res.hits[0].url !== undefined) {
            //     window.location = APP_CONFIG.encodedContextPath + res.hits[0].url;
            // }
        }
    }

    ).then(function () {
        if (window['ACC'].algoliaproductlist && sessionStorage.getItem('back-to-product-list') !== "true" && (sessionStorage.getItem('previous-cat-code') !== sessionStorage.getItem('current-cat-code'))) {

            removeAllProdFromLS();
        }
        sessionStorage.setItem('back-to-product-list', "false");
    });


}

export function handleCategoryStaticContent(categoryData) {

    if (categoryData.bottomSeoContentEn || categoryData.bottomSeoContentTitleEn) {
        isCategoryStaticBottomContent.set(true);
        const bottomContent = {
            heading: APP_CONFIG.lang === "en" ? categoryData.bottomSeoContentTitleEn : categoryData.bottomSeoContentTitleAr,
            body: APP_CONFIG.lang === "en" ? categoryData.bottomSeoContentEn : categoryData.bottomSeoContentAr
        };
        categoryStaticBottomContent.set(bottomContent);
    }
    if (categoryData.topSeoContentEn || categoryData.topSeoContentTitleEn) {
        isCategoryStaticTopContent.set(true);
        const topContent = {
            heading: APP_CONFIG.lang === "en" ? categoryData.topSeoContentTitleEn : categoryData.topSeoContentTitleAr,
            body: APP_CONFIG.lang === "en" ? categoryData.topSeoContentEn : categoryData.topSeoContentAr
        };
        categoryStaticTopContent.set(topContent);
    }

}

export function handleBreadcrumb(categoryData) {
    if (categoryData.breadCrumbs !== undefined && categoryData.breadCrumbs.length > 0) {
        categoryBreadCrumbs.set(categoryData.breadCrumbs);
    }
}


export function handleRatingClick(filterIndex, rating, checked) {
    beginRange.set('');
    endRange.set('');

    if (checked) {
        ratingSelectd.push(rating);
    } else {
        let oldRatingArray = get(ratingSelectd);
        const removeIndex = oldRatingArray.indexOf(rating);
        if (removeIndex > -1) {
            oldRatingArray.splice(removeIndex, 1);
            ratingSelectd.set(oldRatingArray);
        }
    }
    ratingString.set('');
    ratingFilterPosition.set('');

    const ratingSelected = get(ratingSelectd);
    if (ratingSelected.length > 0) {
        let previousSelected = 6;
        for (var i = 0; i < ratingSelected.length; i++) {
            if (parseInt(ratingSelected[i]) < previousSelected) {
                ratingString.set(parseInt(ratingSelected[i]));
                previousSelected = +get(ratingString);
            }
        }
    }

    ratingFilterIndex.set(filterIndex);
    if (checked) {
        pageLoadAfterFilterChange({ filter_rank: filterIndex, filter_name: 'rating' });
    }
}

export function removeRatingFilter(ratingFilterValue) {
    ratingString.set('');
    beginRange.set('');
    endRange.set('');

    let selectedRatings = get(ratingSelectd);
    const removeIndex = selectedRatings.indexOf(ratingFilterValue);
    if (removeIndex > -1) {
        selectedRatings.splice(removeIndex, 1);
        ratingSelectd.set(selectedRatings);
    }
    if (selectedRatings.length > 0) {
        let previousSelected = 6;
        for (var i = 0; i < selectedRatings.length; i++) {
            if (parseInt(selectedRatings[i]) < previousSelected) {
                ratingString.set(parseInt(selectedRatings[i]));
                previousSelected = +get(ratingString);
            }
        }
    }

    pageLoadAfterFilterChange();
}

export function getWarrantyPeriod(warrantyValue) {
    const wvString = warrantyValue.toString();
    if (wvString.indexOf('.') === -1) {
        return "YEAR";
    } else if (wvString.split('.')[1].length < 3) {
        return "MONTH";
    } else {
        return "DAY";
    }
}

export function getWarranty(warrantyValue) {
    if (warrantyValue.toString().indexOf('.') === -1) {
        return +warrantyValue;
    }
    if (warrantyValue.toString().split('.')[1].length < 3) {
        var monthWarranty = warrantyValue.toString().split(".")[1] * 1;
        return monthWarranty;
    } else {
        var daysWarranty = warrantyValue.toString().split(".")[1] * 1;
        return daysWarranty;
    }
}

export function removePriceFilter() {
    priceString.set('');
    priceFilterIndex.set('');
    beginRange.set('');
    endRange.set('');
    pageLoadAfterFilterChange();
}

export function algoliaProductClickEvent(objId, position, pdpUrl) {
    const { algoliaConfig: { searchKey, applicationId } } = getAppConfig();

    const headers = {
        'X-Algolia-API-Key': searchKey,
        'X-Algolia-Application-Id': applicationId,
        'Content-Type': 'application/json; charset=utf-8'
    };
    const body = {
        "events": [
            {
                "eventType": "click",
                "eventName": "Product Clicked",
                "index": get(algoliaIndexConfig).productIndexKey,
                "userToken": get(algoliaUserToken),
                "objectIDs": [objId],
                "queryID": get(saecrhQueryId),
                "positions": [position]

            }
        ],
    };

    const bodyString = JSON.stringify(body);
    const url = "https://insights.algolia.io/1/events";
    httpPostRaw(url, bodyString, headers).then(() => {
        location.href = pdpUrl + '?algoliaQueryId=' + get(saecrhQueryId);
    });
}

export function getRichRelevanceForCategory() {
    return rrGetCategoryPagePlacements()
        .then(placements => processRRPlacements(placements));
}
export function getRichRelevanceForSearch(searchTerm) {
    return rrGetSearchPagePlacements(searchTerm)
        .then(placements => processRRPlacements(placements));
}

export async function processRRPlacements(placements) {
    if (placements && placements.length > 0) {
        placements = placements.map(data => {
            let facetFilter = [];
            data.items.forEach(recommendedData => facetFilter.push(`productCode:${recommendedData.id}`));
            data.facetFilter = facetFilter;
            data.key = data.placement_name.split('.')[1]
            return data;
        });

        return placements;
    }
    return [];
}
export async function getPlacementsForCategory(categoryId) {
    const placements = 'category_page.family_top_rank1|category_page.family_bottom_rank2|category_page.family_bottom_rank3|category_page.history';
    return processRRPlacements({ placements, categoryId });
}

export function getAlgoliaDataForPlacements(placements, userCity = null) {
    let { productIndexKey } = get(algoliaIndexConfig);
    const queries = [];
    placements.forEach((data) => {
        let obj = {
            indexName: productIndexKey,
            query: "*",
            params: {
                filters: `price>0`,
                facetFilters: [data.facetFilter]
            },
        };
        queries.push(obj);
    });

    return get(algoliaClient).multipleQueries(queries).then((data) => {
        data.results = data.results.map((item, index) => {
            item.strategyMessage = placements[index].message;
            item.key = placements[index].key;
            item.hits = item.hits.map((i, _index) =>
                Object.assign({}, i, {
                    rrClickTrackingURL:
                        placements[index].items[_index].clickTrackingURL,
                })
            );
            return item;
        });
        return data.results;
    });
}

export function getPageConfig(pageType) {
    // GET /extracommercewebservices/v2/extra_sa/getPageConfig
    const queryParams = {
        fields: 'FULL',
        lang: get(lang),
        deviceId: getAppConfig()['jessionId'],
        pageType
    };
    const url = `${getBaseWebServiceUrl()}/${APP_CONSTANTS.GET_PAGE_CONFIG}`;
    return httpGetJSON(url, queryParams);
}
export function getParagraphSlotDetails(slotId) {
    // GET /extracommercewebservices/v2/extra_sa/getParagraphSlotDetails
    const queryParams = {
        fields: 'FULL',
        lang: get(lang),
        deviceId: getAppConfig()['jessionId'],
        slotId
    };
    const url = `${getBaseWebServiceUrl()}/${APP_CONSTANTS.GET_PARA_SLOT_DETAILS}`;
    return httpGetJSON(url, queryParams);
}
export function getBannerDataForPLP(isPrime, primeLevel) {
    if (isPrime === 'true') {
        let slotName = '';
        if (primeLevel === "VIPPRIME") slotName = "BottomHeaderSlot-vipPrimeBanner";
        if (primeLevel === "BASICPRIME") slotName = "BottomHeaderSlot-basicPrimeBanner";

        if (slotName.length > 0) {
            if (window['ACC'].extragtm !== undefined) {
                window['ACC'].extragtm.trackBannerView(slotName, 'PLP Banner');
            }
            if (window['ACC'].mixpanel !== undefined) {
                window['ACC'].mixpanel.trackBannerView(slotName, 'PLP Banner');
            }
            return getParagraphSlotDetails(slotName)
                .then(details => details.componentList[0]);
        }
    }

    return getPageConfig('PLP')
        .then(pageConfig => pageConfig?.displayModules[0]?.key)
        .then(key => {
            if (key?.length > 0) {
                return key;
            }
            throw Error('No PLP banner data');
        })
        .then(key => {
            window['ACC'].extragtm.trackBannerView(key, 'PLP Banner');
            window['ACC'].mixpanel.trackBannerView(key, 'PLP Banner');
            return key;
        })
        .then(key => getParagraphSlotDetails(key))
        .then(details => details.componentList[0]);
}
export function getTopLinksUSPData() {
    return getParagraphSlotDetails(APP_CONSTANTS.TOP_HEADER_SLOT_KEY)
        .then(d => d.componentList[0])
        .catch(() => null);
}

export function registerRRItemClick(clickURL) {
    return httpGetRaw(clickURL);
}

export const RR = {
    family_bottom_rank2: null,
    family_bottom_rank3: null,
    history: null,
    result_top_rank1: null,
    result_bottom_rank2: null,
    result_bottom_rank3: null
};

export function processRichRelevanceData(rrData) {
    rrData.forEach(rr => {
        if (rr.key in RR) {
            RR[rr.key] = processRRHits(rr);
        }
    });
}

export function processRRHits(rr) {
    const processed = Object.assign({}, rr);
    processed.hits = processed.hits.map(hit => Object.assign({}, hit, {
        name: hit[getLangSpecificFieldname('name', lang)],
        price: { formattedValue: parsePriceString(hit.wasPrice, countryCode), value: hit.wasPrice },
        aftreCashBackPrice: hit.hasCashBack ? { formattedValue: parsePriceString(hit.price - +parseCashback(hit.cashback, countryCode), countryCode) } : { formattedValue: parsePriceString(hit.price, countryCode) },
        cashBackPrice: parseCashback(hit.cashback, countryCode),
        productStatus: hit[getLangSpecificFieldname('productStatus', lang)],
        productStatusHtml: hit[getLangSpecificFieldname('productStatusHtml', lang)],
        freeGiftQtuantities: hit.freegiftworth,
        percentageDiscount: { formattedValue: hit.priceValueDiscountPercentage.toString(), value: hit.priceValueDiscountPercentage },
        discountPrice: { value: (hit.priceValueDiscount), formattedValue: parsePriceString(hit.priceValueDiscount, countryCode) },
        url: hit[getLangSpecificFieldname('url', lang)],
        code: hit.productCode
    }));

    return processed;
}

export function getAddToWishlistDataPlp(res) {
    var dataObj = {};
    dataObj = {
        product_id: res.productCode,
        name: res.nameEn,
        brand: res.brandNameEn,
        price: res.price.value,
        discount_price: res.discountPrice ? res.discountPrice.value : res.price.value,
        currency: window['ACC'].config.currency,
        item_category1: res.pageCategory,
        item_category2: res.pageSubCategory,
        item_category3: res.rmsCategory
    }

    return dataObj;
}

// Unbxd related methods
export function unxSetPriceRange(lowerLimit: string, upperLimit: string) {
    const { searchMetaData: { queryParams } } = get(unxResponse);
    const qpSP = parseUnbxdResponseQueryParamsToSearchParams(queryParams);
    const combinedSP = combineURLSearchParams([plpSpecificSP, qpSP]);

    const allFilters = combinedSP.getAll('filter');
    const priceFilterExisting = allFilters.find(f => f.startsWith('price:'));
    if (!!priceFilterExisting) {
        // @ts-ignore
        combinedSP.delete('filter', priceFilterExisting);
    }
    combinedSP.append('filter', `price:[${lowerLimit} TO ${upperLimit}]`);

    // reset page to 1
    combinedSP.set('page', '1');

    updatePLPUsingSearchParams(combinedSP);
}
export function unxSetRatingStatus(rating: number, selected: boolean) {
    unxSelectedRatings.setSingleRatingStatus(rating, selected);

    const { searchMetaData: { queryParams } } = get(unxResponse);
    const qpSP = parseUnbxdResponseQueryParamsToSearchParams(queryParams);
    const combinedSP = combineURLSearchParams([plpSpecificSP, qpSP]);

    const allFilters = combinedSP.getAll('filter');
    const ratingFilterExisting = allFilters.find(f => f.startsWith('rating:'));

    if (!!ratingFilterExisting) {
        // @ts-ignore
        combinedSP.delete('filter', ratingFilterExisting);
    }
    const newSelections = get(unxSelectedRatings);
    if (newSelections.length > 0) {
        combinedSP.append('filter', `rating:[${newSelections[0]} TO 5]`);
    }

    // reset page to 1
    combinedSP.set('page', '1');

    updatePLPUsingSearchParams(combinedSP);
}
export function unxSelectFacetValue(facetName: string, facetValue: string) {
    const allSelectedFacets = get(unxFacets).selected;
    const facetOperated = allSelectedFacets.find(f => f.facetName === facetName);

    if (facetOperated) {
        // atleaset one value was already selected previously
        facetOperated.values.push(facetValue);
        unxGetProductsForSelectedFacets(allSelectedFacets);

    } else {
        // first selection made in the facet
        const facetToAdd = Object.assign({}, get(unxFacets).list.find(f => f.facetName === facetName));
        facetToAdd.values = [facetValue];
        unxGetProductsForSelectedFacets([...allSelectedFacets, facetToAdd]);
    }
}
export function unxUnselectFacetValue(facetName: string, facetValue: string) {
    const allSelectedFacets = get(unxFacets).selected;
    const facetOperated = allSelectedFacets.find(f => f.facetName === facetName);

    if (facetOperated) {
        facetOperated.values = facetOperated.values.filter(x => x !== facetValue);
        unxGetProductsForSelectedFacets(allSelectedFacets);
    }
}
export function unxUnselectFacet(facetName: string) {
    const allOtherSelectedFacets = get(unxFacets).selected.filter(f => f.facetName !== facetName);
    if (facetName === 'rating') {
        unxSelectedRatings.setBulkSelection([]);
    }
    unxGetProductsForSelectedFacets(allOtherSelectedFacets);
}
export function unxClearAllFilters() {
    unxSelectedRatings.setBulkSelection([]);
    unxGetProductsForSelectedFacets([]);
}
export function unxUpdatePageSetting(newSettings: any) {
    const { searchMetaData: { queryParams } } = get(unxResponse);
    const newQueryParams = Object.assign({}, queryParams, newSettings);
    const qpSP = parseUnbxdResponseQueryParamsToSearchParams(newQueryParams);
    const combinedSP = combineURLSearchParams([plpSpecificSP, qpSP]);

    if ('rows' in newSettings) {
        unxSelectedPageSize.set(<number>newSettings.rows);
    }

    updatePLPUsingSearchParams(combinedSP);
}
export function unxGetCurrentFacetsSearchParams(): URLSearchParams {
    const { searchMetaData: { queryParams } } = get(unxResponse);
    const qpSP = parseUnbxdResponseQueryParamsToSearchParams(queryParams);
    const combinedSP = combineURLSearchParams([plpSpecificSP, qpSP]);

    return combinedSP;
}
export function unxGetProductsForSelectedFacets(selectedFacets: any[]) {
    const { searchMetaData: { queryParams } } = get(unxResponse);

    const selectedFacetsSP = convertUnbxdFacetsToSearchParams(selectedFacets);
    const qpSP = parseUnbxdResponseQueryParamsToSearchParams(queryParams);
    qpSP.delete('filter');

    // reset page number to 1
    selectedFacetsSP.set('page', '1');

    const combinedSP = combineURLSearchParams([plpSpecificSP, qpSP, selectedFacetsSP]);

    updatePLPUsingSearchParams(combinedSP);
}
export function updatePLPUsingSearchParams(searchParams: URLSearchParams) {
    getSearchAPIResults(searchParams)
        .then(r => unxResponse.set(r));
}
export function unxRedirectToEmptyPage(unxResponseInstance) {

    if (unxResponseInstance?.response?.products?.length === 0) {
        isInitialNoProductPageLoadCompleted.set(true);
        if (get(isSearch)) {
            sessionStorage.setItem('searchTerm', unxResponseInstance.searchMetaData?.queryParams?.q);
            window.location.href = APP_CONFIG.encodedContextPath + '/search/?text=';
        } else {
            pageLoader.hideLoader();
            isCategoryNoResult.set(true);
            sessionStorage.removeItem('searchTerm');
        }
    }
}