<script lang="ts">
    import { getStr } from "../../../../common/util";
    import { primeLevel } from "../../../product-listing-search/store/plp-data-store";
    import Icon from "../Icon.svelte";

    export let label;
    export let backgroundColorCode = "#000000";
    export let copyToClipBoardAllowed = false;
    export let fontSize = 10;
    export let iconURL = null;
    export let textColorCode = "#FFFF";
    export let productStatus = null;
    export let theme = "";
    export let bgImage = "";
    export let bgRepeat = "";
    export let megaSaleTextFlag = "";
    export let megaSaleBgFlag = "";
    function onCopyCodeClicked() {
        navigator.clipboard.writeText(productStatus);
    }
    if (productStatus && productStatus === "JOOD_DAY") {
        if ($primeLevel === "BASICPRIME") {
            textColorCode = getStr("prime.jood.day.color.BASIC");
            bgImage = getStr("prime.jood.day.bg.BASIC");
            bgRepeat = "no-repeat";
        } else if ($primeLevel === "VIPPRIME") {
            textColorCode = getStr("prime.jood.day.color.VIP");
            bgImage = getStr("prime.jood.day.bg.VIP");
            bgRepeat = "no-repeat";
        } else {
            textColorCode = getStr("prime.jood.day.color.NONPRIME");
            bgImage = getStr("prime.jood.day.bg.NONPRIME");
            bgRepeat = "round";
        }
    }
</script>

{#if label}
    <section
        class="tag {theme} {megaSaleTextFlag} {megaSaleBgFlag}"
        style:background-color={theme?.length > 0 ? null : backgroundColorCode}
        style:color={theme?.length > 0 ? null : textColorCode}
        style:font-size={fontSize === null ? 10 : fontSize}
        style:background-image={bgImage ? `url(${bgImage})` : ""}
        style:background-repeat={bgRepeat ? bgRepeat : ""}
        style="font-size: {fontSize}px;"
    >
        {#if iconURL && iconURL.length > 0}
            <Icon glyph={iconURL} type="url" size={+fontSize + 4} />
        {/if}
        <span class="text">{@html label}</span>
        {#if copyToClipBoardAllowed}
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a
                href="#"
                role="button"
                class="copy-code"
                on:click={onCopyCodeClicked}>{getStr("copyCouponCodeLabel")}</a
            >
        {/if}
    </section>
{/if}

<style lang="scss">
    @import "../../styles/colors.new.scss";

    .tag {
        padding: 5px 8px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        background: $functional-yellow-04;
        color: $neutral-dark-01;
        width: fit-content;
        font-weight: 700;
        line-height: 12px;

        @include desktop-min-width {
            padding: 4px 8px;
        }
        @include responsive {
            padding: 2px 5px;
        }

        &.orange {
            color: $primary-white;
            background-color: $functional-orange;
        }

        &.yellow-light {
            background-color: functional-yellow-02;
        }

        &.orange-white {
            background-color: $primary-white;
            border: 1px solid $functional-orange;
            color: $functional-orange;
        }
        &.green {
            color: $functional-green-01;
            border: 1px solid $functional-green-01;
            background-color: $primary-white;
        }
        &.gray {
            color: $neutral-dark-04;
            background-color: $neutral-light-03;
        }
        .text {
            font-size: inherit;
            @include responsive {
                width: 100%;
            }
        }
        &.black {
            color: $primary-black;
            border: 1px solid $secondary-gray-02;
            background-color: $primary-white;
            white-space: nowrap;
            border-radius: 8px;
            height: 36px;
            cursor: pointer;
            gap: 4px;
            padding: 10px 20px;
        }
        &.gray-bundle {
            color: $neutral-dark-04;
            background-color: $neutral-light-03;
            line-height: 12px;
            padding: 2px 4px;
            text-transform: uppercase;
        }
        &.gray-bundle-desktop {
            color: $neutral-dark-04;
            background-color: $neutral-light-03;
            line-height: 12px;
            padding: 4px 8px;
            gap: 2px;
            background: $neutral-light-03;
            text-transform: uppercase;
        }
        &.backgroundColorCode {
            background-color: $extraTagBg;
            padding: 4px 25px;
        }
        &.textColorCode {
            color: $extraTagTextColor;
        }
    }
    a.copy-code {
        text-decoration: none;
        color: $icon-only-icon-blue;
    }
    :global {
        html.ltr {
            .tag {
                @include desktop-min-width {
                    span.text {
                        // margin-bottom: -2px;
                    }
                }
            }
        }
        .cashback .tag {
            background-color: $extraTagBg !important;
            color: $extraTagTextColor !important;
        }
    }
</style>
