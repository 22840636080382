<script lang="ts">
    import { getAppConfig } from "../../../../common/config/app-config";
    export let fontSizeMobile = 10;
    export let lineHeightMobile = 12;
    export let fontWeight = 700;

    export let fontSizeDesktop = 14;
    export let lineHeightDesktop = 16;

    export let paddingTop = 12;
    export let paddingBottom = 4;
    export let paddingLeft = 8;
    export let paddingRight = 8;
    export let paddingTopDesktop = 16;
    export let paddingBottomDesktop = 4;
    export let paddingLeftDesktop = 12;
    export let paddingRightDesktop = 12;

    export let borderRadLTop = 0;
    export let borderRadRTop = 0;
    export let borderRadLBot = 8;
    export let borderRadRBot = 8;
    export let isMegasaleProduct = [];

    export const bgColorCode = "#f4ca24";

    export let content = null;
    export let theme = "";
    const { isMegaSaleDuringAccessStarted, primeLevel, isMegaSaleDayXStarted } =
        getAppConfig();
    const megaSaleClass =
        isMegasaleProduct?.length &&
        (isMegaSaleDayXStarted || (isMegaSaleDuringAccessStarted && primeLevel))
            ? "megasale-started"
            : "";
</script>

<section
    class="tag-wrapper {theme} {megaSaleClass}"
    style:--pad-top="{paddingTop}px"
    style:--pad-bottom="{paddingBottom}px"
    style:--pad-left="{paddingLeft}px"
    style:--pad-right="{paddingRight}px"
    style:--pad-top-desktop="{paddingTopDesktop}px"
    style:--pad-bottom-desktop="{paddingBottomDesktop}px"
    style:--pad-left-desktop="{paddingLeftDesktop}px"
    style:--pad-right-desktop="{paddingRightDesktop}px"
    style:--font-size="{fontSizeMobile}px"
    style:--line-height="{lineHeightMobile}px"
    style:--font-size-desktop="{fontSizeDesktop}px"
    style:--line-height-desktop="{lineHeightDesktop}px"
    style:--font-weight={fontWeight}
    style:--border-top-left-radius="{borderRadLTop}px"
    style:--border-top-right-radius="{borderRadRTop}px"
    style:--border-bottom-left-radius="{borderRadLBot}px"
    style:--border-bottom-right-radius="{borderRadRBot}px"
>
    <div class="content">
        {content}
    </div>
</section>

<style lang="scss">
    @import "../../styles/colors.new.scss";
    .tag-wrapper {
        padding: var(--pad-top) var(--pad-right) var(--pad-bottom)
            var(--pad-left);
        font-size: var(--font-size);
        line-height: var(--line-height);
        font-weight: var(--font-weight);
        border-radius: var(--border-top-left-radius)
            var(--border-top-right-radius) var(--border-bottom-left-radius)
            var(--border-bottom-right-radius);
        width: fit-content;
        background-color: $functional-yellow;

        &.orange {
            color: $primary-white;
            background-color: $functional-orange;
        }

        &.yellow {
            background-color: $functional-yellow;
            color: $neutral-dark-01;
        }

        &.gray {
            background-color: $secondary-gray-02;
            color: $primary-blue;
        }
        &.dummy {
            background-color: transparent !important;
            color: transparent !important;
            text-indent: -99999999px !important;
        }
        &.megasale-started {
            color: $extraTagTextColor;
            background: $extraTagBg;
        }
        @include desktop-min-width {
            font-size: var(--font-size-desktop);
            line-height: var(--line-height-desktop);
            padding: var(--pad-top-desktop) var(--pad-right-desktop)
                var(--pad-bottom-desktop) var(--pad-left-desktop);
        }
        .content {
            white-space: nowrap;
        }
    }
</style>
