<script lang="ts">
  import type { Options } from "@splidejs/splide";
  import { Splide, SplideSlide } from "@splidejs/svelte-splide";
  import { onMount } from "svelte";
  import { getAppConfig } from "../../../../../common/config/app-config";
  import {
    applyRTLArrowCss,
    createDealTimerObject,
    getProductDataForGTM,
    getStr,
    isDirectionRTL,
    isPointWithinElementBounds,
    onClickWishlist,
  } from "../../../../../common/util";
  import ProductTileMain from "../../../../common/components/productTile/ProductTileMain.svelte";
  import viewport from "../../../../common/components/useViewportAction";
  import { cartLoginDetails } from "../../../../header/store/header_store";
  import RecommendationTileIndex from "./RecommendationTileIndex.svelte";

  export let recommendationType = 1;
  export let tileData = [];
  export let showTileIndex = true;

  let favIconMobileWidth = 24;
  let favIconDesktopWidth = 32;

  let mobileImageWidth = 146;
  let desktopImageWidth = 268;
  let mobileImageHeight = 130;
  let desktopImageHeight = 268;
  let offerLabel = getStr("extra.pdp.offerlable");
  let offersLabel = getStr("extra.product.cashback.offers");

  onMount(() => {
    const parent = document.querySelectorAll(".product-tile-index-container");
    applyRTLArrowCss(parent, "auto", "1em", "1em", "auto");
  });

  let {
    encodedContextPath = "en-sa",
    sellingOutFast,
    userSelectedCityCode,
    countryCode,
  } = getAppConfig();

  function handleRRItemClick(event, rrClickTrackingURL, href, tile) {
    event.preventDefault();
    // check if wishlist icon clicked
    const wishlistWrapper = event.target
      .closest(".product-tile-container")
      .querySelector(".fav-wrapper");
    if (isPointWithinElementBounds(event, wishlistWrapper)) {
      // do nothing
      return false;
    }

    if (navigator.sendBeacon && rrClickTrackingURL) {
      navigator.sendBeacon(rrClickTrackingURL);
    }
    if (tile !== undefined) {
      window["ACC"].extragtm.click.trackRecommendationsClick(tile);
      window["ACC"].mixpanel.trackRecommendationsClick(tile);
    }
  }

  const isRTL = isDirectionRTL();

  const thumbsOptions: Options = {
    type: "slide",
    arrows: true,
    rewind: false,
    pagination: false,
    perPage: 2,
    perMove: 1,
    autoWidth: true,
    autoHeight: true,
    releaseWheel: true,
    cover: true,
    updateOnMove: true,
    direction: (isRTL && "rtl") || "ltr",
    breakpoints: {
      900: {
        arrows: false,
        width: "92vw",
        perPage: 1,
      },
    },
    classes: {
      arrow: "splide__arrow splide_arrow-bg arrows-bg",
      prev: "splide__arrow--prev splide_arrow-prev",
      next: "splide__arrow--next splide_arrow-next",
    },
  };
  const getTasheelInfo = (tile) => {
    let tasheelInfo = getAppConfig()?.algoliaConfig?.tasheelInfo;
    let tasheelData = {};
    if (Object.keys(tasheelInfo)) {
      tasheelData = {
        priceValueTasheel: {
          formattedValue:
            tile?.tasheelPriceData?.priceValueTasheel?.formattedValue,
          currency: getAppConfig()?.currency,
        },
        tasheelInstallmentMonths: tasheelInfo.tasheelInstallmentMonths,
        tasheelPercentage: tasheelInfo.tasheelPercentage,
      };
    }
    return tasheelData;
  };

  const getBottomTags = (product, index) => {
    let bottomTags = [];
    // if (product.dealTimer.deals === true) {
    //     bottomTags.push({timerTag: true,product})
    // }
    let userCityCode = $cartLoginDetails?.city?.code || userSelectedCityCode;
    let isComingSoon = product?.comingSoon || false;
    let isInStock =
      !isComingSoon &&
      ((product?.inStockCities?.length &&
        product.inStockCities.indexOf(userCityCode) != -1) ||
        product.stock?.stockLevelStatus === "inStock");
    let isSellingOutFast =
      !isInStock &&
      ((product?.sellingOutFastCities?.length &&
        product.sellingOutFastCities.indexOf(userCityCode) != -1) ||
        product.stock?.stockLevelStatus === "lowStock");
    let isOutOfStock = !(isComingSoon || isInStock || isSellingOutFast);
    if (isSellingOutFast && sellingOutFast) {
      bottomTags.push({
        content: sellingOutFast,
        borderRadLBot: 12,
        borderRadRBot: 12,
        theme: "yellow",
      });
    }
    const hasDealTimer =
      ("endDateDeal" in product && product.endDateDeal?.length > 0) ||
      ("endDateDiscount" in product && product.endDateDiscount?.length > 0);

    if (hasDealTimer) {
      const dealTimeEnd = product.endDateDeal || product.endDateDiscount;
      bottomTags.push({
        timerTag: true,
        product: {
          dealTimer: createDealTimerObject(dealTimeEnd),
        },
      });
    }
    if (bottomTags.length === 0) {
      // To  match hight of all tile, even if tag is not present in any of the tile
      bottomTags.push({ content: "dummy", theme: "dummy" });
    }
    return bottomTags;
  };

  const getTileData = (tile, index) => {
    let dataObj = {
      ...tile,
      favIconMobileWidth,
      favIconDesktopWidth,
      mobileImageWidth,
      desktopImageWidth,
      mobileImageHeight,
      desktopImageHeight,
    };
    dataObj["tasheelPriceData"] = { ...getTasheelInfo(tile) };
    dataObj["showActionBtn"] = false;
    dataObj["offersLabel"] =
      tile?.offersCount || tile?.offersLabel
        ? getOfferLabel(tile?.offersLabel || tile?.offersCount)
        : null;
    dataObj["productBottomTags"] = getBottomTags(tile, index);
    dataObj.afterCashBackPrice = tile?.aftreCashBackPrice?.formattedValue
      ? tile?.aftreCashBackPrice?.formattedValue
      : null;
    dataObj.cashBackPrice = tile?.cashBackPrice ? tile.cashBackPrice : null;
    dataObj.hasCashBack = tile?.hasCashBack ? tile.hasCashBack : false;
    dataObj.freeGiftCount = tile?.freeGiftCount
      ? tile.freeGiftCount
      : getFreeGiftLength(tile);
    dataObj.basicPrimePrice = tile?.basicPrimePrice
      ? tile.basicPrimePrice
      : null;
    dataObj.vipPrimePrice = tile?.vipPrimePrice ? tile.vipPrimePrice : null;
    dataObj.primeEnabled = tile?.primeEnabled ? tile.primeEnabled : false;
    dataObj.primeLevel = tile?.primeLevel ? tile.primeLevel : null;
    dataObj.tacticalVipPromo = tile?.tacticalVipPromo
      ? tile.tacticalVipPromo
      : false;
    dataObj.tacticalBasicPromo = tile?.tacticalBasicPromo
      ? tile.tacticalBasicPromo
      : false;

    return dataObj;
  };

  function getFreeGiftLength(data) {
    if ("freegiftworth" in data) {
      let freegiftArray = data.freegiftworth.split("-");
      let sourceQuantiy = freegiftArray[2] ?? 0;
      return sourceQuantiy;
    } else if ("freeGiftQtuantities" in data) {
      const pc = data.freeGiftQtuantities.filter(
        (x) => x.key === "giftProductCount",
      );
      if (pc.length > 0) {
        return pc[0].value;
      }
      return 0;
    } else {
      return 0;
    }
  }

  const getOfferLabel = (count) => {
    // count = Number(count);
    if (!count) {
      return 0;
    } else if (count == 1) {
      return `${count} ${offerLabel}`;
    } else if (count > 1) {
      return `${count} ${offersLabel}`;
    } else {
      return count;
    }
  };
</script>

{#if tileData.length}
  <div
    class="product-tile-index-container rr_parent_item_container"
    use:viewport
    on:enterViewport={() =>
      window["ACC"].extragtm.click.trackViewList("", "", tileData)}
  >
    <Splide
      options={{
        ...thumbsOptions,
      }}
    >
      {#each tileData as tile, index}
        <SplideSlide>
          <div class="tile-index-wrapper d-flex position-relative">
            {#if showTileIndex}
              <div class="tile-index">
                <RecommendationTileIndex
                  index={index + 1}
                  {recommendationType}
                />
              </div>
            {/if}
            <div
              class="main-tile {showTileIndex ? 'index-margin' : ''}"
              class:rtl={isRTL}
            >
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <!-- <a
                        href={`${encodedContextPath}${(tile.url ?? tile.urlEn)}`}
                        class="recomendation-viewed-link"
                        on:click|preventDefault|stopPropagation={ (e) => handleRRItemClick(e, tile?.rrClickUrl)}
                    > -->
              <a
                class="recomendation-viewed-link"
                href={`${encodedContextPath}${tile.url ?? tile.urlEn}`}
                data-product-id={tile.productCode}
                on:click|preventDefault|stopPropagation={(e) =>
                  handleRRItemClick(
                    e,
                    tile?.rrClickUrl,
                    `${encodedContextPath}${tile.url ?? tile.urlEn}`,
                    tile,
                  )}
              >
                <ProductTileMain
                  isRRComponent={true}
                  {...getTileData(tile, index)}
                  on:toggleFav={(data) => {
                    onClickWishlist(data, tile.code || tile.productCode);
                  }}
                  productDataForGTM={getProductDataForGTM(tile, index)}
                />
              </a>
              <!-- </a>         -->
            </div>
          </div></SplideSlide
        >
      {/each}
    </Splide>
  </div>
{/if}

<style lang="scss">
  @import "../../../../common/styles/colors.new.scss";

  .product-tile-index-container {
    :global {
      .splide.splide--draggable.splide--rtl {
        direction: unset;
      }
      .splide__arrow {
        background: $primary-black;
        border-radius: 50%;
        display: none;
        height: 48px;
        opacity: 0;
        width: 48px;
        svg {
          fill: $primary-white;
        }
      }
      .splide__arrow:disabled {
        opacity: 0;
        pointer-events: none;
      }
    }
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    .tile-index-wrapper {
      margin-right: 8px;
      height: 100%;
      @include desktop-min-width {
        margin-right: 24px;
      }
      .main-tile {
        display: flex;
        &.index-margin {
          margin-left: -35px;
          @include desktop-min-width {
            margin-left: -52px;
          }
          &.rtl {
            margin-left: -30px;
            @include desktop-min-width {
              margin-left: -30px;
            }
          }
        }

        :global {
          .tile-content-wrapper {
            display: flex;
            flex-direction: column;
            .product-tile-container {
              flex: 1;
            }
          }
        }
      }
    }

    .recomendation-viewed-link {
      text-decoration: none;
      color: unset;
      :global(.tile-content-wrapper) {
        height: 100%;
      }
    }
  }
  .product-tile-index-container:hover {
    :global {
      .splide__arrow {
        display: inline-block;
        opacity: 0.7;
      }
      .splide__arrow:disabled {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
</style>
