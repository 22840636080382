<script lang="ts">
    import RecommendationCta from "./component/RecommendationCTA.svelte";
    import RecommendationProductCategory from "./component/RecommendationProductCategory.svelte";
    import RecommendationProductTileWithIndex from "./component/RecommendationProductTileWithIndex.svelte";
    import RecommendationSubTitle from "./component/RecommendationSubTitle.svelte";
    import RecommendationTitle from "./component/RecommendationTitle.svelte";
    import { createEventDispatcher } from "svelte";
    import { getAppConfig } from "../../../../common/config/app-config";

    /*
        Schema of input fiels::
            recommendationType = 1;
                1-->> Best selling:: Here are the top 5 best selling in Riyadh;
                2-->> Popular :: Top 10 popular (Top 10 popular in Riyadh)


            title: string;
            subTitle: string;
            productCategoties: Array(string: category name);
            ProductList: Array(object);
                Object : of type ProductTileMain

            showAllFields: boolean;
            showSubTitle: boolean;
            showCategories: boolean;
            showCta: boolean,
            showTileIndex: boolean;
    */

    export let recommendationType = 1;
    /*
           1-->> Best selling:: Here are the top 5 best selling in Riyadh;
           2-->> Popular :: Top 10 popular (Top 10 popular in Riyadh)
        */

    export let message = ""; // previous: title
    export let subTitle = "";
    export let productCategories = [];
    export let productList = [];

    export let showAllFields = true;
    export let showSubTitle = showAllFields;
    export let showCategories = true;
    export let showCta = false;
    export let showTileIndex = showAllFields;
    export let activeCategoryId = -1;
    export let isSponsored = false;

    const dispatch = createEventDispatcher();
    let recommendationClassName = "bestSelling";
    if (recommendationType == 2) {
        recommendationClassName = "popular";
    }

    function onCategoryClick(id) {
        productList = [];
        dispatch("categoryChange", id);
    }

    const { pageType } = getAppConfig();
    $: isCategoryPage = pageType == "CATEGORY" ? true : false;
</script>

<!-- <section class="divider-section">
    <Divider/>
</section> -->
<div class="c_wrapper recommendation-container-bg {recommendationClassName}">
    <div
        class="recommendation-container {recommendationClassName} c_wrapper--content_scroller"
    >
        <section class="title-section">
            <RecommendationTitle
                {recommendationType}
                text={message}
                {isSponsored}
            />
            {#if showSubTitle}
                <div class="sub-title">
                    <RecommendationSubTitle
                        {recommendationType}
                        text={subTitle}
                    />
                </div>
            {/if}
        </section>

        {#if true && showCategories}
            <section class="categories-section">
                <RecommendationProductCategory
                    on:catClick={(e) => onCategoryClick(e.detail)}
                    {recommendationType}
                    categoryList={productCategories}
                    {activeCategoryId}
                />
            </section>
        {/if}
        <section
            class:cat-page__deatils-section={isCategoryPage}
            class="deatils-section {!showCta ? 'bottom-padding' : ''}"
        >
            {#if productList?.length > 0}
                <RecommendationProductTileWithIndex
                    title={message}
                    {recommendationType}
                    tileData={productList}
                    {showTileIndex}
                />
            {:else if productList !== null}
                <section class="recommendation-product-list-skeleton-section">
                    <section
                        class="recommendation-product-tile-skeleton skeleton-item"
                    ></section>
                    <section
                        class="recommendation-product-tile-skeleton skeleton-item"
                    ></section>
                    <section
                        class="recommendation-product-tile-skeleton skeleton-item"
                    ></section>
                </section>
            {/if}
        </section>
        {#if showCta}
            <section class="cta-section">
                <RecommendationCta />
            </section>
        {/if}
    </div>
</div>

<!-- <section class="divider-section">
    <Divider/>
</section> -->

<style lang="scss">
    @import "../../../common/styles/colors.new.scss";

    .recommendation {
        &-container-bg {
            &.bestSelling {
                background-color: $neutral-light-04;
            }
            &.popular {
                background-color: $primary-black;
            }
        }
        &-container {
            padding: 40px 0;
            @include desktop-min-width {
                padding: 80px 0;
                // display: grid;
                // grid-template-columns: 50% 50%;  // this part of Css commented for MAVD-11476
                // grid-template-areas:
                //     "Title Action"
                //     "Categories Categories"
                //     "Details Details";
                // grid-template-rows: minmax(0, auto) 1fr auto;
                // grid-auto-rows: minmax(0, auto);
            }
            &.bestSelling {
                background-color: $neutral-light-04;
            }
            &.popular {
                background-color: $primary-black;
            }
            .title-section {
                padding: 0 16px;
                @include desktop-min-width {
                    grid-area: Title;
                    padding: 0 0 0 48px;
                }
                .sub-title {
                    margin-top: 4px;
                    @include desktop-min-width {
                        margin-top: 12px;
                    }
                }
            }
            .categories-section {
                padding: 16px 0 8px 16px;
                @include desktop-min-width {
                    grid-area: Categories;
                    padding: 16px 48px 0 48px;
                }
            }
            .deatils-section {
                padding: 20px 0;
                &.bottom-padding {
                    padding-bottom: 0;
                }
                @include desktop-min-width {
                    grid-area: Details;
                    padding: 58px 0 0 48px;
                }
                &.cat-page__deatils-section {
                    @include desktop-min-width {
                        padding: 44px 0 0 48px;
                    }
                }

                .recommendation-product-list-skeleton-section {
                    display: flex;
                    gap: 150px;
                    padding-left: 98px;

                    .recommendation-product-tile-skeleton {
                        width: 318px;
                        height: 590px;
                        border-radius: 8px;
                    }
                }
            }
            .cta-section {
                grid-area: Action;
                padding: 0 16px;
                @include desktop-min-width {
                    padding: 0 48px 0 0;
                }
            }
        }
    }
</style>
