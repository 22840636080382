<script lang="ts">
    import SponsoredTag, {
        SponsoredTagVariantType,
    } from "../../../../common/components/SponsoredTag.svelte";

    export let text = "";

    export let recommendationType = 1;
    export let isSponsored = false;

    let recommendationClassName = "bestSelling";
    if (Number(recommendationType) == 2) {
        recommendationClassName = "popular";
    }

    let sponsoredTagVariant: SponsoredTagVariantType;
    $: sponsoredTagVariant =
        recommendationClassName === "popular"
            ? "product-carousal-dark"
            : "product-carousal";
</script>

<div class="recommendation-title {recommendationClassName}">
    {text}
    {#if isSponsored}
        <SponsoredTag variant={sponsoredTagVariant} />
    {/if}
</div>

<style lang="scss">
    @import "../../../../common/styles/colors.new.scss";
    .recommendation {
        &-title {
            font-size: 20px;
            line-height: 22px;
            font-weight: 700;
            display: flex;
            align-items: baseline;

            @include desktop-min-width {
                font-size: 36px;
                line-height: 40px;
            }
            &.popular {
                color: $primary-white;
            }
        }
    }
</style>
