<script lang="ts">
  import Dialog, { Actions, Content } from '@smui/dialog';
  import 'bootstrap/js/dist/modal';
  import { afterUpdate, onMount, setContext } from 'svelte';
  import { getAppConfig } from '../../../common/config/app-config';
  import { getPriceHTMLOrFree, isMobileWidth } from '../../../common/util';
  import Text from '../../common/components/Text.svelte';
  import ExtraAmplienceBannerComponent from '../../common/lazy-components/ExtraAmplienceBannerComponent.svelte';
  import { getCMSComponentsFromHybrisAsync } from '../../common/services/hybris';
  import { openAddToCartDrawer, qacProductCode } from '../store/pdp_store';
  import AtcBasketPromo from './AddToCartPopup/AtcBasketPromo.svelte';
  import AtcHeader from './AddToCartPopup/AtcHeader.svelte';
  import AtcServices from './AddToCartPopup/AtcServices.svelte';
  import PdpDeliveryOptionsCard from './PDPDeliveryOptionsCard.svelte';
  import ProductExtraLinks from './ProductExtraLinks.svelte';

  export let content = null;

  export let open = false;
  let atcBannerMetadata = null;
  let atcBanner = null;
  let atcInitStarted = false;
  export let isQacPopup = false;

  $: if (isQacPopup) {
    $qacProductCode = content?.entry?.product?.code || '';
  }

  setContext('isQacAtcPopup', isQacPopup);
  const mobile = isMobileWidth();
  var myModalEl = null;
  $: if (content && content?.modifications?.length > 0) {
    content.modifications.reduce((acc, curr) => acc || curr?.acSetupAddedAsFree, false);
    content = content.modifications[0];
  }
  $: tasheel = content?.entry?.product?.tasheelPriceValuesList?.[0];
  $: totalPrice = content?.entry?.totalPrice?.value / content?.entry?.quantity;
  $: basePrice = content?.entry?.basePrice?.value;

  // $: hasService = true; // @@7749 hardcode for testing
  $: hasServiceBlocks = content?.entry?.product?.servicesBlocks?.length > 0;
  $: hasLMDConfigurations = content?.entry?.lmdData?.lmdConfigurations?.length > 0;
  $: productNotComingSoon = content?.entry?.product?.productStatus !== 'COMING_SOON';
  $: productNotPromoBundle = !content?.promotionalBundle;
  $: hasVasEligibleProducts =
    (!content?.entry?.entryGroupNumbers || content?.entry?.entryGroupNumbers?.length === 0) &&
    content?.entry?.vasEligibleDataList?.length > 0;

  $: hasService =
    svelteATCEnabled &&
    (hasServiceBlocks || hasLMDConfigurations) &&
    productNotComingSoon &&
    productNotPromoBundle &&
    (hasLMDConfigurations || hasVasEligibleProducts);

  $: if (open) {
    // initialize the amplience banner here
    console.log('[ATC] $openAddToCartDrawer:', $openAddToCartDrawer);
    atcInitStarted = true;
    getCMSComponentsFromHybrisAsync(['extraTasheelBanner-cart']).then(res => {
      if (res?.component?.length > 0) {
        atcBannerMetadata = res.component[0];
        atcBanner?.onLoad();
      }
    });
    addMarginBottomToDialogContent();
  }
  let cartUrl = '/cart';
  const { currency, svelteATCEnabled, encodedContextPath } = getAppConfig();

  onMount(async () => {
    myModalEl = document.getElementById('AddToCartPopUpModal');
  });

  afterUpdate(() => {
    // open = $openAddToCartDrawer;
    const btn = document.querySelector('#continue-shoping-link');
    if (btn) {
      btn.addEventListener('click', () => {
        $openAddToCartDrawer = false;
        document.documentElement.style.overflow = 'scroll';
        open = false;
      });
    }
  });

  function closeHandler(e: CustomEvent<{ action: string }>) {
    $openAddToCartDrawer = false;
    open = false;
  }

  function closeModal() {
    // document.getElementById("AddToCartPopUpModal").classList.remove("show");
    myModalEl.modal('hide');
  }

  let actionSection;
  let contentMarginBottomValue = '';
  function addMarginBottomToDialogContent() {
    setTimeout(() => {
      contentMarginBottomValue = getComputedStyle(actionSection).height;
    }, 500);
  }
</script>

<!-- <div
  class="modal fade addToCartModal"
  class:mobile
  id="AddToCartPopUpModal"
  tabindex="-1"
  aria-labelledby="AddToCartModalLabel"
  aria-hidden="true"
  role="dialog"
>
  <div class="modal-dialog modal-lg modal-fullscreen-sm-down">
    <div class="modal-content">
      <div class="modal-body">
          {@html content}
      </div>
    </div>
  </div>
</div> -->

<div class:qacPopup={isQacPopup}>
  <Dialog
    bind:open
    fullscreen
    aria-labelledby="fullscreen-title"
    surface$style="width:100vw;height:100dvh;max-height:100vh"
    aria-describedby="fullscreen-content"
    on:SMUIDialog:closed={closeHandler}
    class="extraDrawer-container"
  >
    <Content
      id="fullscreen-content"
      on:mouseenter={() => {
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
      }}
      on:mouseleave={() => {
        document.getElementsByTagName('html')[0].style.overflow = 'scroll';
      }}
    >
      {#if svelteATCEnabled}
        {#if content}
          <div
            id="addToCartLayer"
            class="add-to-cart c_add-to-cart-popup add-to-cart-svelte"
            style:margin-bottom={contentMarginBottomValue}
          >
            <div class="c_add-to-cart-popup-content">
              <div class="cart_popup_error_msg"></div>
              <AtcHeader cartData={content} />
              {#if content?.entry?.basketPromotions}
                <AtcBasketPromo entry={content?.entry} />
              {/if}
              {#if hasService}
                <AtcServices data={content} />
              {/if}
              {#if isQacPopup}
                <section class="atc-qac-delivery-detail">
                  <PdpDeliveryOptionsCard
                    data={content?.entry?.product}
                    pdpGiftCardPriceData={content?.entry?.product}
                  />
                  <ProductExtraLinks data={content?.entry?.product} />
                </section>
              {/if}
              <div class="c_banner js-addtocart-ampliance-banner">
                <ExtraAmplienceBannerComponent metaData={atcBannerMetadata} bind:this={atcBanner} />
              </div>
              <div class="c_add-to-cart-popup-related-items">
                <div id="add_to_cart_page-rank1"></div>
              </div>
            </div>
          </div>
        {/if}
      {:else}
        {@html content}
      {/if}
    </Content>
    {#if svelteATCEnabled}
      <Actions>
        <div class="c_add-to-cart-popup-btns-block" bind:this={actionSection}>
          <div class="tasheel-details">
            <span class="price-currencyContainer">
              <span class="js-vas-cart-price">{@html getPriceHTMLOrFree(content?.cartTotal)}</span>
            </span>
          </div>
          {#if content?.entry?.basketPromotions}
            <div class="c_basket-promo-msg">
              {#each content?.entry?.basketPromotions as basketProm}
                {#if !basketProm.promoApplied}
                  {#if basketProm.eligibleMessage}
                    <span class="">
                      {@html basketProm.eligibleMessage}
                    </span>
                  {:else if basketProm.addItemsMessage}
                    <span class="">
                      {@html basketProm.addItemsMessage}
                    </span>
                  {/if}
                {:else if basketProm.successMessage}
                  <span class="c_orange-text">
                    {@html basketProm.successMessage}
                  </span>
                {/if}
              {/each}
            </div>
          {/if}
          <div class="c_add-to-cart-popup-btns">
            <a
              href="{encodedContextPath}{cartUrl}"
              role="button"
              id="link-to-checkout"
              aria-labelledby="link-to-checkout c_add-to-cart-popup-btns"
              class="c_button c_button--primary c_button--large btn-checkout js-redirect-to-checkout add-to-cart-button"
            >
              <Text key="extra.addtocart.gotocart" />
            </a>
            <a
              href="#"
              role="button"
              id="continue-shoping-link"
              aria-labelledby="continue-shoping-link c_add-to-cart-popup-btns"
              class="btn-continue js-popin-close"
              on:click={() => (open = false)}
            >
              <Text key="cart.page.continue" />
            </a>
          </div>
        </div>
      </Actions>
    {/if}
  </Dialog>
</div>

<style lang="scss">
  @import '../../common/styles/colors.new.scss';

  :global {
    html:has(.mdc-dialog--open) {
      overflow: hidden !important;
    }
    .qacPopup {
      overflow-y: initial;
      scrollbar-width: none;
      height: 100%;
      @include desktop-min-width {
        overflow-y: auto;
      }

      .pdp-section-card {
        display: flex;
      }
    }
    .extraDrawer-container {
      .mdc-dialog__container {
        justify-content: flex-end !important;
        z-index: 10000;
        height: 100dvh;
        .mdc-dialog__content {
          // max-height: calc(100% - 145px);
          overflow-y: auto;
          scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
          padding: 0 !important;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .mdc-dialog__actions {
          background-color: $primary-white;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }

        .mdc-dialog__surface {
          width: 655px !important;
          min-height: 100% !important;
          min-width: unset;
        }
      }
    }
    .extraDrawer-container {
      z-index: 10000 !important;
      justify-content: flex-end !important;
      .atc-qac-delivery-detail {
        padding: 0 48px 24px;
        @include responsive {
          padding: 0 0.8rem 0.8rem;
        }
      }
    }
  }

  .addToCartModal {
    padding-right: 0px !important;
  }
  :global(.addToCartModal.show) {
    opacity: 1;
    background-color: #373d4cd1;
  }
  :global(.addToCartModal .c_add-to-cart-popup-content) {
    overflow-y: unset;
    position: unset;
  }

  :global {
    .addToCartModal
      .c_add-to-cart-popup-content
      .c_add-to-cart-popup-item-details
      .c_add-to-cart-popup-item-details-name {
      overflow: hidden;
    }
  }

  .addToCartModal {
    .modal-dialog {
      margin: 0;
      margin-left: auto;
      height: 100%;
      scrollbar-width: none; /* Also needed to disable scrollbar Firefox */

      @include desktop-min-width {
        max-width: 620px;
      }
      &::-webkit-scrollbar {
        display: none;
      }

      .modal-content {
        border-radius: 0;
        height: auto;
        min-height: 100%;
      }
      .modal-header {
        padding-bottom: 1rem;
        background: $neutral-light-04;
        .btn-close-popup {
          box-sizing: content-box;
          width: 10px;
          height: 10px;
          background: transparent
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb(14,29,163)'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
            center/1em auto no-repeat;
          border: 1px solid $icon-only-icon-blue;
          border-radius: 50%;
          padding: 5px;
          margin-left: auto;

          @include desktop-min-width {
            width: 15px;
            height: 15px;
          }
        }
        .btn-close-popup:hover {
          color: #000;
          text-decoration: none;
          opacity: 0.75;
        }
        .btn-close-popup:focus {
          outline: 0;
          box-shadow: 0 0 0 0.25rem rgba(0, 102, 165, 0.25);
          opacity: 1;
        }
        .btn-close-popup:disabled,
        .btn-close-popup.disabled {
          pointer-events: none;
          user-select: none;
          opacity: 0.25;
        }
      }
    }
    .modal-body {
      padding: 0;
    }
    .btn-close {
      margin: 0;
    }
  }

  .mobile {
    .modal-content {
      overflow: auto;
    }
    .modal-header {
      padding: 3.5rem 1.5rem 1rem;
    }
    .modal-body {
      overflow-y: unset;
      padding: 1rem 1.6rem;
    }
  }

  .add-to-cart.add-to-cart-svelte {
    height: auto;
  }
  .add-to-cart-button {
    color: $primary-white;
  }
</style>
