<script lang="ts">
    import Icon from "../../common/components/Icon.svelte";
    import { _ } from "svelte-i18n";
    import { getStr } from "../../../common/util";
    import Text from "../../common/components/Text.svelte";
    import { getAppConfig } from "../../../common/config/app-config";

    export let data: any;

    $: productIconLabelContents = data?.productIconLabelContents;
    $: warranty = data?.warranty;
    $: skuHasWarranty = warranty?.length > 0 && +warranty > 0;
    $: productExtraLinks =
        productIconLabelContents?.filter(
            (x) => !x.isWarranty || (x.isWarranty && skuHasWarranty),
        ) || [];

    const { encodedContextPath, lang, currency } = getAppConfig();

    let warrantyDuration = data?.warrantyDuration;
    if (warrantyDuration === "Y") {
        warrantyDuration = getStr("extra.warranty.Year");
    } else if (warrantyDuration === "M") {
        warrantyDuration = getStr("extra.warranty.Month");
    } else {
        warrantyDuration = getStr("extra.warranty.Day");
    }

    function getContextUrl(url) {
        if (!url) return null;
        if (url.startsWith(encodedContextPath) || url.startsWith("http")) {
            return url;
        }
        return `${encodedContextPath}${url}`;
    }
</script>

{#if data?.isSKUInStock || data?.stock?.stockLevelStatus?.code !== "outOfStock"}
    {#if productExtraLinks?.length > 0}
        <section class="product_extra_links">
            <ul class="font-weight-normal font-size-sm">
                {#each productExtraLinks as d}
                    <li>
                        <a
                            class="link"
                            target="_blank"
                            href={getContextUrl(d.lableUrl)}
                        >
                            {#if d.iconUrl}
                                <Icon
                                    glyph={d.iconUrl}
                                    type="url"
                                    mobileSize={16}
                                    desktopSize={17}
                                />
                            {/if}
                            {#if d.isWarranty}
                                <span class="description">
                                    <Text
                                        key="extra.pdp.arguments.warranty"
                                        args={[warranty, warrantyDuration]}
                                    />
                                </span>
                            {:else if d.label}
                                <span class="description">{d.label}</span>
                            {/if}
                        </a>
                    </li>
                {/each}
            </ul>
        </section>
    {/if}
{/if}

<style lang="scss">
    @import "../../common/styles/colors.new.scss";

    .product_extra_links {
        background-color: $neutral-light-04;
        padding: 16px;
        margin-top: 8px;

        @include desktop-min-width {
            border-radius: 8px;
            padding: 14px 0 14px 16px;
        }
        ul {
            display: flex;
            align-items: center;
            padding: 0px;
            margin: 0px;
            justify-content: center;
            list-style: none;
            gap: 17px;
            @include desktop-min-width {
                gap: 21px;
                justify-content: start;
            }

            li {
                position: relative;

                &:not(:first-child)::after {
                    position: absolute;
                    content: "";
                    width: 3px;
                    height: 3px;
                    border-radius: 48%;
                    background-color: $neutral-dark-04;
                    top: 50%;
                    left: -9px;
                    @include desktop-min-width {
                        left: -11px;
                    }
                }
                a.link {
                    color: $icon-only-icon-blue;
                    text-decoration: none;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    @include desktop-min-width {
                        gap: 4px;
                        font-size: 14px;
                        line-height: 16px;
                    }

                    .description {
                        font-size: 12px;
                        line-height: 14px;
                        text-decoration: underline;
                        text-decoration-skip-ink: none;
                        margin: 0px 3px;
                        @include desktop-min-width {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }
</style>
