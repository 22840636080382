<script lang="ts" context="module">
    import { getAppConfig } from "../../../../common/config/app-config";
    import { getStr } from "../../../../common/util";
    import AtcExtraCareCoatingDetails from "./AtcExtraCareCoatingDetails.svelte";
    import AtcExtraCareDetails from "./AtcExtraCareDetails.svelte";
    import AtcExtraSetupDetails from "./AtcExtraSetupDetails.svelte";
    import AtcExtraSetupServiceDetails from "./AtcExtraSetupServiceDetails.svelte";

    const { currency } = getAppConfig();
</script>

<script lang="ts">
    import AtcExtraAppleCareServiceDetails from "./AtcExtraAppleCareServiceDetails.svelte";
    import AtcVasDetails from "./AtcVasDetails.svelte";

    export let data;
    console.log(data, "data");
    let noPlanActive = false;
    let emptyService = false;
    let displayExtraCareLogo = true;
    let safeGuardSelected = false;
    let applecareContainerClass = "";

    $: serviceEntries = data?.serviceEntries;
    $: entry = data?.entry;
    $: lmdServiceDisplay =
        entry?.vasEligibleDataList?.indexOf(
            (vasEligibleData) => !!vasEligibleData.extraSetupService,
        ) > -1;
    $: displayExtraCareLogo =
        entry?.vasEligibleDataList?.indexOf(
            (vasEligibleData) =>
                !!vasEligibleData.extraCareService ||
                !!vasEligibleData.extraCareCoating,
        ) === -1;
    $: processServiceEntries(serviceEntries);
    $: displayHeader =
        entry?.vasEligibleDataList?.some(
            (vasData) =>
                !vasData?.extraCareService &&
                !vasData?.extraCareCoating &&
                !vasData?.splitAcService &&
                !vasData?.extraSetupService &&
                !vasData?.appleCareService,
        ) ?? false;
    $: serviceHeaderText = getStr(
        noPlanActive
            ? "extra.services.cartpopup.care.text"
            : safeGuardSelected
              ? "extra.services.cartpopup.secure.text"
              : "extra.services.cartpopup.stressfree.text",
    );

    function processServiceEntries(serviceEntries: any[]) {
        if (!serviceEntries) return;

        serviceEntries.forEach((serviceEntry) => {
            if (
                serviceEntry.vasGroup === "ACC" &&
                serviceEntry.priceType === "ACC_PRICE"
            ) {
                applecareContainerClass = "c_vas-details--applecontent";
            }
            if (serviceEntry.safeguardServiceEntry) {
                safeGuardSelected = true;
            }
            if (
                serviceEntry.vasType === "noPlan" &&
                serviceEntry.priceType === "NO_PLAN_PRICE"
            ) {
                noPlanActive = true;
            }
        });
    }
    function processVasEligibleData(
        vasEligibleData,
        index: number,
        totalItems: number,
    ) {
        return {
            coatingClass: vasEligibleData.extraCareCoating
                ? "extra-care-coating-vas-details"
                : vasEligibleData.combinedVas === true &&
                    vasEligibleData.vasPrices?.length > 5
                  ? "js-extra-safecard-essetial-plus-vas-details"
                  : " ",
            lastIndex: index === totalItems - 1 ? "last-add-to-cart-vas" : " ",
            isSafeguardService: !(
                vasEligibleData.extraCareService ||
                vasEligibleData.extraCareCoating ||
                vasEligibleData.splitAcService ||
                vasEligibleData.extraSetupService ||
                vasEligibleData.appleCareService
            ),
        };
    }
</script>

<div id="addToCartService">
    <!-- Moved serviceHeader logic out of forEach -->
    {#if displayHeader}
        <h1 class="service-header no-plan-header">
            {serviceHeaderText}
        </h1>
    {/if}

    {#each entry?.vasEligibleDataList as vasEligibleData, index}
        {@const { coatingClass, lastIndex, isSafeguardService } =
            processVasEligibleData(
                vasEligibleData,
                index,
                entry?.vasEligibleDataList.length,
            )}
        <div
            class="{applecareContainerClass} c_vas-details-content {coatingClass} {lastIndex}"
            class:highlight={vasEligibleData?.highlight}
            class:extra-setup-vas-details={vasEligibleData?.extraSetupService}
            data-noplan={getStr("extra.services.cartpopup.care.text")}
            data-noselection={getStr(
                "extra.services.cartpopup.stressfree.text",
            )}
            data-selection={getStr("extra.services.cartpopup.secure.text")}
        >
            {#if vasEligibleData.extraCareService}
                <AtcExtraCareDetails
                    entry={vasEligibleData}
                    product={entry.product}
                    parentEntryNumber={entry.entryNumber}
                    loopindex={index}
                    showLogo={displayExtraCareLogo}
                />
            {:else if vasEligibleData.extraCareCoating}
                <AtcExtraCareCoatingDetails
                    entry={vasEligibleData}
                    selectedServices={serviceEntries}
                    product={entry.product}
                    parentEntryNumber={entry.entryNumber}
                    loopindex={index}
                    showLogo={displayExtraCareLogo}
                />
            {:else if vasEligibleData.splitAcService}
                <AtcExtraSetupDetails
                    productattributes={entry.product.attributeLabels}
                    pdpAcSetupFreeSelected={data?.acSetupAddedAsFree}
                    selectedServices={serviceEntries}
                    entry={vasEligibleData}
                    product={entry.product}
                    parentEntryNumber={entry.entryNumber}
                    childEntries={entry.childEntries}
                    entryServiceGroupNumber={data?.entryServiceGroupNumber}
                />
            {:else if vasEligibleData.extraSetupService}
                <AtcExtraSetupServiceDetails
                    productattributes={entry.product.attributeLabels}
                    entry={vasEligibleData}
                    selectedServices={serviceEntries}
                    parentEntry={entry}
                    product={entry.product}
                    parentEntryNumber={entry.entryNumber}
                    loopindex={index}
                />
            {:else if vasEligibleData.appleCareService}
                <AtcExtraAppleCareServiceDetails
                    entry={vasEligibleData}
                    selectedServices={serviceEntries}
                    product={entry.product}
                    parentEntryNumber={entry.entryNumber}
                    loopindex={index}
                />
            {:else}
                <AtcVasDetails
                    entry={vasEligibleData}
                    selectedServices={serviceEntries}
                    product={entry.product}
                    parentEntryNumber={entry.entryNumber}
                    loopindex={index}
                />
            {/if}
        </div>
        <div class="c_vas-details-divider row-flex"></div>
    {/each}

    {#if !lmdServiceDisplay}
        {#if entry?.lmdData?.lmdConfigurations?.length > 0}
            <div class="c_lmd-service">
                <div class="c_lmd-service-title">
                    {getStr("addtocart.service.lmd.heading")}
                </div>
                <div class="c_lmd-service-container row-flex">
                    {#each entry?.lmdData?.lmdConfigurations as lmdConfiguration, index}
                        <div
                            data-index={index}
                            class="col-flex-xs-12 col-flex-sm-12 col-flex-md-12 c_lmd-service-content js-apply-lmd-service"
                        >
                            <span class="selected-icon"></span>
                            <div class="c_lmd-service-content-info">
                                {@html lmdConfiguration.description}
                            </div>
                            <div class="c_lmd-service-content-price">
                                {currency}&nbsp;
                                <strong
                                    >{lmdConfiguration.price
                                        ?.formattedValue}</strong
                                >
                            </div>
                            <form class="lmd-service-form-{index}">
                                <input
                                    type="hidden"
                                    name="productCode_{index}"
                                    value={lmdConfiguration.productCode}
                                />
                                <input
                                    type="hidden"
                                    name="parentEntryNumber_{index}"
                                    value={entry.entryNumber}
                                />
                                <input
                                    type="hidden"
                                    class="js-lmd-service-group-number"
                                    name="serviceGroupNumber_{index}"
                                    value=""
                                />
                                <input
                                    type="hidden"
                                    class="{lmdConfiguration.productCode}_entryNumber"
                                    name="entryNumber_{index}"
                                    value=""
                                />
                                <input
                                    type="hidden"
                                    class="{lmdConfiguration.productCode}_entryType"
                                    name="entryType_{index}"
                                    value={lmdConfiguration.description}
                                />
                            </form>
                        </div>
                    {/each}
                </div>
            </div>
        {/if}
    {/if}
</div>
