<script context="module" lang="ts">
    import { getAppConfig } from "../../../../../common/config/app-config";
    import {
        formatNumber,
        getJoodMembershipType,
        isDirectionRTL,
        isMobile,
    } from "../../../../../common/util";
    import { _ } from "svelte-i18n";

    export type ProductTilePriceGroupVariantType =
        | "plp-list"
        | "product-tile"
        | "pdp"
        | "restock-popup";

    const { currency } = getAppConfig();
    const userPrimeLevel = getJoodMembershipType();
    const isResponsive = isMobile();
</script>

<script lang="ts">
    import JoodDayBannerNew from "../../../../product-listing-search/components/JoodDayBannerNew.svelte";
    import Tag from "../../tag/Tag.svelte";
    import ProductSplitPriceCard from "./ProductSplitPriceCard.svelte";
    import ProductTilePriceNew from "./ProductTilePriceNew.svelte";
    import ProductTasheelPrice from "../../../../product-details/components/DigitalGiftCardProduct/ProductTasheelPrice.svelte";
    import ProductOccasions from "../../../../product-details/components/DigitalGiftCardProduct/ProductOccasions.svelte";
    import GiftCardForm from "../../../../product-details/components/DigitalGiftCardProduct/GiftCardForm.svelte";
    import JoodSavingsAccordionCard from "../../../../product-listing-search/components/JoodSavingsAccordionCard.svelte";
    import ProductTilePriceGoldAccordion from "./ProductTilePriceGoldAccordion.svelte";

    export let product;
    export let pdpGiftCardPriceData = null;
    export let variant: ProductTilePriceGroupVariantType = "plp-list";
    export let isMegasaleProduct = [];

    $: isRTL = isDirectionRTL();
    $: cashback = getProductCashback(product);
    $: visible = getComponentVisibility(product, variant);
    $: isPDPVariantWithGiftCard =
        variant === "pdp" && product?.isDigitalGiftCard;
    $: showJoodGoldAccordion = computeIfJoodGoldAccordionVisible();

    function computeIfJoodGoldAccordionVisible() {
        return (
            (variant === "plp-list" ||
                variant === "product-tile" ||
                variant === "pdp") &&
            userPrimeLevel === "BASICPRIME"
        );
    }
    function getProductCashback(algoliaProduct) {
        if (algoliaProduct?.hasCashBack === true) {
            const cashBackType = typeof algoliaProduct.cashback;
            if (cashBackType === "number") {
                return algoliaProduct.cashback;
            } else if (cashBackType === "string") {
                return +algoliaProduct.cashback.split("-")[0];
            }
        }
        return 0;
    }
    function getComponentVisibility(data, variant) {
        const isPDPVariant = variant === "pdp";
        if (!isPDPVariant) {
            return !!data;
        }
        return data?.isComingSoon !== true;
    }
</script>

{#if visible}
    <section class="product-tile-price-new-group variant-{variant}">
        {#if isPDPVariantWithGiftCard}
            <ProductTasheelPrice data={pdpGiftCardPriceData} />
            <ProductOccasions data={pdpGiftCardPriceData} />
            <GiftCardForm data={pdpGiftCardPriceData} />
        {:else}
            <ProductTilePriceNew
                {isMegasaleProduct}
                {variant}
                priceType="NOPRIME"
                algoliaProductHit={product}
            />
            <ProductTilePriceNew
                {isMegasaleProduct}
                {variant}
                priceType="BASICPRIME"
                algoliaProductHit={product}
            />
            <JoodDayBannerNew
                {variant}
                bannerType="BASICPRIME"
                algoliaProductHit={product}
            />
            {#if showJoodGoldAccordion}
                <ProductTilePriceGoldAccordion
                    {variant}
                    algoliaProductHit={product}
                />
            {:else}
                <ProductTilePriceNew
                    {isMegasaleProduct}
                    {variant}
                    priceType="VIPPRIME"
                    algoliaProductHit={product}
                />
                <JoodDayBannerNew
                    {variant}
                    bannerType="VIPPRIME"
                    algoliaProductHit={product}
                />
            {/if}
            <JoodSavingsAccordionCard variant="pdp-product" data={product} />
        {/if}

        {#if variant !== "pdp"}
            <ProductSplitPriceCard {variant} algoliaProductHit={product} />
        {/if}
        <!-- cashback section commented for MAVD-12152    -->
        <!-- {#if variant === "product-tile" && cashback > 0}
        <section class="cashback">
            <Tag label="{!isRTL ? currency:""} {formatNumber(cashback)} {isRTL ? currency:""} {$_('rrBlock.cashback')}" fontSize={11} theme="orange" />
        </section>
    {/if} -->
    </section>
{/if}

<style lang="scss">
    @import "../../../../common/styles/colors.new.scss";
    .product-tile-price-new-group {
        &.variant- {
            &product-tile {
                display: flex;
                flex-direction: column;
                gap: 4px;
            }
            &plp-list,
            &pdp {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
            }
            &.plp-list {
                @include responsive {
                    gap: 4px;
                }
            }
        }
    }
</style>
