<script lang="ts">
    import { isMobileWidth } from "../../../common/util";
    import PictureTag from "../lazy-components/PictureTag.svelte";

    export let data;
    $: isResponsive = isMobileWidth();
</script>

<a class=" get-best-deal-container-slider-slide" href={data?.link}>
    <section class="get-best-deal-container-slider-slide-info">
        <span class="get-best-deal-container-slider-slide-info-title"
            >{data?.headingText}</span
        >
        <span class="get-best-deal-container-slider-slide-info-subTitle"
            >{data?.subHeadingText}</span
        >
    </section>
    <section class="theme-icon">
        <PictureTag
            image={data?.image}
            mobileImage={data?.mobileImage}
            height={isResponsive ? "208px" : "422px"}
            getAllResolutions={false}
        />
    </section>
</a>

<style lang="scss">
    @import "../styles/colors.new.scss";

    .get-best-deal-container-slider-slide {
        position: relative;
        // width: 326px;
        height: 422px;
        display: inline-block;
        @include desktop-max-width {
            height: 208px;
            max-width: 161px;
        }
        &-info {
            position: absolute;
            bottom: 30px;
            left: 20px;
            right: 20px;
            display: flex;
            gap: 12px;
            flex-direction: column;
            @include desktop-max-width {
                bottom: 16px;
                left: 16px;
                right: 16px;
                gap: 8px;
            }
            &-title {
                font-size: 28px;
                font-weight: 700;
                line-height: 32px;
                color: $color-bg-grey;
                @include desktop-max-width {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
            &-subTitle {
                font-size: 18px;
                font-weight: 400;
                line-height: 20px;
                color: $primary-yellow-01;
                @include desktop-max-width {
                    font-size: 10px;
                    line-height: 11px;
                    color: $color-bg-grey;
                }
            }
        }
    }
</style>
