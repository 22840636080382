<script lang="ts">
    import { getStr } from "../../../../common/util";

    export let entry: any;
</script>

{#if entry?.basketPromotions?.length}
    {#each entry?.basketPromotions as basketProm, i}
        <div class="c_addtocart-promotions-text">
            <img src={getStr("extra.promotion.icon.url")} alt="" />
            <div class="c_addtocart-product-special-offer-promomsg">
                {#if !basketProm?.promoApplied}
                    {#if basketProm?.eligibleMessage}
                        <div class="c_addtocart-potential-promotion-msg-text">
                            <span class="heading">
                                {@html basketProm.eligibleMessage}
                            </span>
                        </div>
                    {:else}
                        <div class="c_addtocart-potential-promotion-msg-text">
                            {#if basketProm?.description}
                                <span class="heading">
                                    {@html basketProm.description}
                                </span>
                            {/if}
                            {#if basketProm?.subText}
                                <span class="sub-heading">
                                    {@html basketProm.subText}
                                </span>
                            {/if}
                        </div>
                    {/if}
                {:else}
                    <div class="c_addtocart-potential-promotion-msg-text">
                        {#if basketProm?.successMessage}
                            <span class="heading">
                                {@html basketProm.successMessage}
                            </span>
                        {/if}
                    </div>
                {/if}
            </div>
        </div>
    {/each}
{/if}
