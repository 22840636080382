import { addMessages, init } from 'svelte-i18n';

import en from './en.json';
import ar from './ar.json';

addMessages('en', en);
addMessages('ar', ar);


const defaultLang = 'ar';
const lang = getLangFromPathname();
const dir = lang === 'ar' ? 'rtl' : 'ltr';
document.getElementsByTagName('html')[0].setAttribute('dir', dir);

init({
    fallbackLocale: defaultLang,
    initialLocale: lang
});

function getLangFromPathname() {
    const path = location.pathname;
    const localeRe = /\/(en|ar)-\w{2}/i;

    const result = localeRe.exec(path);
    if (result) {
        return result[1];
    }
    return defaultLang;
}