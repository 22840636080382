<script lang="ts" context="module">
    import { getAppConfig } from "../../../common/config/app-config";
    import {
        formatNumber,
        getJoodMembershipType,
        getStr,
        isMobile,
        reverseChildNodesForArabicLang,
        type JoodMembershipType,
    } from "../../../common/util";

    const { currency, encodedContextPath } = getAppConfig();
    const joodSubscribeLinkHref = `${encodedContextPath}/jood/benefits`;
    const userJoodMembershipType: JoodMembershipType = getJoodMembershipType();
    const isResponsive = isMobile();
</script>

<script lang="ts">
    import { _ } from "svelte-i18n";
    import type { ProductTilePriceGroupVariantType } from "../../common/components/productTile/components/ProductTilePriceNewGroup.svelte";

    export let algoliaProductHit;
    export let bannerType: JoodMembershipType;
    export let variant: ProductTilePriceGroupVariantType;
    export let backgoundOverride: JoodMembershipType = null;

    let textColor, backgroundImage, foregroundImage;

    $: visible = getJoodDayBannerVisibility(
        bannerType,
        userJoodMembershipType,
        algoliaProductHit,
    );
    $: ({ textColor, backgroundImage, foregroundImage } =
        getJoodDayBannerDetails(backgoundOverride ?? userJoodMembershipType));
    $: title = getTitle(bannerType);
    $: displayPrice = getPrice(bannerType, algoliaProductHit);
    $: wasPrice = getWasPrice(bannerType, algoliaProductHit);
    $: savedPrice = wasPrice - displayPrice;
    $: subscribeLinkVisible = getSubscribeLinkVisibility(
        bannerType,
        userJoodMembershipType,
    );
    $: subscribeLinkText = getSubscribeLinkText(
        bannerType,
        userJoodMembershipType,
    );
    $: showOrangeText = getOrangeTextVisibility(
        bannerType,
        userJoodMembershipType,
    );

    // Utility functions
    function getOrangeTextVisibility(
        bannerType: JoodMembershipType,
        userJoodMembershipType: JoodMembershipType,
    ) {
        /*
            If this method returns true => Orange text is shown (Save SAR XXX)
            If this method returns false=> Orange tag is show (Save additional SAR XXX)
        */

        const showOrangeText =
            backgoundOverride ||
            (userJoodMembershipType === "NOPRIME" && bannerType === "VIPPRIME");

        return showOrangeText;
    }
    function getJoodDayBannerVisibility(
        bannerType: JoodMembershipType,
        userJoodMembershipType: JoodMembershipType,
        algoliaHit,
    ) {
        const {
            tacticalBasicPromo,
            tacticalBasicPrimePrice,
            tacticalVipPromo,
            tacticalVipPrimePrice,
        } = algoliaHit;
        const hasTacticalPromo =
            (tacticalBasicPromo &&
                tacticalBasicPrimePrice > 0 &&
                bannerType === "BASICPRIME") ||
            (tacticalVipPromo &&
                tacticalVipPrimePrice > 0 &&
                bannerType === "VIPPRIME");

        /*
            Rules:
            1. if gray member, hide blue banner
            2. if blue member, hide nothing
            3. if gold member, hide blue banner
        */
        let bannerVisible = true;
        if (
            userJoodMembershipType === "NOPRIME" ||
            userJoodMembershipType === "VIPPRIME"
        ) {
            bannerVisible = bannerType !== "BASICPRIME";
        }

        return hasTacticalPromo && bannerVisible;
    }
    function getJoodDayBannerDetails(
        userJoodMembershipType: JoodMembershipType,
    ) {
        let suffix = "NONPRIME";
        if (userJoodMembershipType === "BASICPRIME") suffix = "BASIC";
        else if (userJoodMembershipType === "VIPPRIME") suffix = "VIP";
        else suffix = "NONPRIME";

        let colorKey = `prime.jood.day.color.${suffix}`,
            bgKey = `prime.jood.day.bg.${suffix}`,
            fgKey = `prime.jood.day.logo.${suffix}`;

        let textColor = getStr(colorKey),
            backgroundImage = getStr(bgKey),
            foregroundImage = getStr(fgKey);

        return { textColor, backgroundImage, foregroundImage };
    }
    function getTitle(bannerType: JoodMembershipType) {
        switch (bannerType) {
            case "NOPRIME":
                return getStr("extra.jood.gold.banner.title");
            case "BASICPRIME":
                return getStr("extra.jood.blue.banner.title");
            case "VIPPRIME":
                return getStr("extra.jood.gold.banner.title");
            default:
                return getStr("extra.jood.gold.banner.title");
        }
    }
    function getPrice(bannerType: JoodMembershipType, algoliaProductHit: any) {
        let sourcePrice;
        switch (bannerType) {
            case "NOPRIME":
                sourcePrice = algoliaProductHit.tacticalVipPrimePrice;
                break;
            case "BASICPRIME":
                sourcePrice = algoliaProductHit.tacticalBasicPrimePrice;
                break;
            case "VIPPRIME":
                sourcePrice = algoliaProductHit.tacticalVipPrimePrice;
                break;
        }

        return sourcePrice;
    }
    function getWasPrice(
        bannerType: JoodMembershipType,
        algoliaProductHit: any,
    ) {
        let sourcePrice;
        switch (bannerType) {
            case "NOPRIME":
                sourcePrice = algoliaProductHit.vipPrimePrice;
                break;
            case "BASICPRIME":
                sourcePrice = algoliaProductHit.basicPrimePrice;
                break;
            case "VIPPRIME":
                sourcePrice = algoliaProductHit.vipPrimePrice;
                break;
        }

        return sourcePrice;
    }
    function getSubscribeLinkVisibility(bannerType, userJoodMembershipType) {
        /*
            Rules:
            1. if gray member, subscribe link on gold banner
            2. if blue member, subscribe link on gold banner
            3. if gold member, no subscribe link
        */
        if (variant === "pdp" && backgoundOverride) return true;

        let visibility = false;
        if (
            userJoodMembershipType === "NOPRIME" ||
            userJoodMembershipType === "BASICPRIME"
        ) {
            visibility = bannerType === "VIPPRIME"; // rule 1 & 2
        } else {
            visibility = false; // rule 3
        }

        return visibility;
    }
    function getSubscribeLinkText(bannerType, userJoodMembershipType) {
        /*
            Rules:
            1. if gray member, subscribe text on gold price
            2. if blue member, upgrade text on gold price
            3. if gold member, no subscribe text
        */
        let text: string = ""; // rule 3
        if (
            (userJoodMembershipType === "NOPRIME" ||
                userJoodMembershipType === "BASICPRIME") &&
            bannerType === "VIPPRIME"
        ) {
            text = getStr("extra.jood.gold.banner.upgrade.message"); // rule 1 & 2
        }

        return text;
    }
    $: displayPriceFormat = `<strong>${formatNumber(displayPrice)}</strong>`;
</script>

{#if visible}
    <section
        class="jood-day-banner-new variant-{variant} user-{userJoodMembershipType} banner-{bannerType}"
    >
        <section
            class="jood-day-plp-banner"
            style:background-image="url({backgroundImage})"
        >
            {#if (variant !== "product-tile" && !isResponsive) || variant === "pdp"}
                <img src={foregroundImage} alt="" />
            {/if}
            <section class="banner-details" style:color={textColor}>
                <h4>{title}</h4>
                <section class="price">
                    {@html getStr("jood.price.with.currency", [
                        currency,
                        displayPriceFormat,
                    ])}
                    <!-- {currency} <strong>{formatNumber(displayPrice)}</strong> -->
                    <span class="vat">{getStr("extra.vatLable")}</span>
                </section>
                <section class="savings">
                    <span class="striked-off">{formatNumber(wasPrice)}</span>
                    {#if showOrangeText}
                        <strong class="orange-text"
                            >{@html getStr("extra.jood.saved.amt", [
                                currency,
                                formatNumber(savedPrice),
                            ])}</strong
                        >
                    {:else}
                        <section class="save-percent-tag">
                            {@html getStr("jood.day.saving.text", [
                                currency,
                                formatNumber(savedPrice),
                            ])}
                        </section>
                    {/if}
                </section>
                {#if subscribeLinkVisible}
                    <a
                        class="subscribe-upgrade-link"
                        on:click|stopPropagation
                        href={joodSubscribeLinkHref}
                    >
                        {subscribeLinkText}
                    </a>
                {/if}
            </section>
        </section>
    </section>
{/if}

<style lang="scss">
    @import "../../common/styles/colors.new.scss";

    .jood-day-banner-new {
        border-radius: 12px;
        padding: 8px;
        flex-basis: 100%;

        &.user-NOPRIME.banner-VIPPRIME {
            background-color: $jood-gold-light;
        }

        &.variant-pdp {
            @include responsive {
                padding: 0;
            }
            .jood-day-plp-banner {
                @include responsive {
                    gap: 18px;
                }
                img {
                    @include responsive {
                        max-width: 148px;
                    }
                }
                .banner-details {
                    .price {
                        margin-top: 12px;

                        @include responsive {
                            font-size: 24px;
                            line-height: 18px;
                            margin-top: 8px;
                        }
                    }
                    .savings {
                        @include responsive {
                            margin-top: 4px;
                        }
                        .striked-off {
                            @include responsive {
                                font-size: 14px;
                                line-height: 14px;
                            }
                        }
                        .save-percent-tag {
                            @include responsive {
                                font-size: 10px;
                                line-height: 18px;
                                padding: 2px 4px;
                            }
                        }
                    }
                }
            }
        }
        &.variant-plp-list,
        &.variant-restock-popup {
            &.user-VIPPRIME,
            &.user-BASICPRIME {
                padding: 0;
            }
            .jood-day-plp-banner {
                padding-inline: 6px;
                gap: 8px;

                img {
                    max-width: 140px;
                    object-fit: contain;
                }
                .banner-details {
                    @include responsive {
                        gap: 4px;
                    }
                    h4 {
                        font-weight: 400;

                        @include responsive {
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }
                    .price {
                        @include responsive {
                            font-size: 18px;
                            line-height: 18px;
                        }
                    }
                    .savings {
                        .striked-off,
                        .orange-text {
                            @include responsive {
                                font-size: 12px;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }
        }
        &.variant-product-tile {
            &.user-VIPPRIME,
            &.user-BASICPRIME {
                padding: 0;

                .jood-day-plp-banner {
                    @include responsive {
                        padding-inline: 8px;
                    }
                    .banner-details {
                        gap: 4px;

                        .price {
                            @include responsive {
                                font-size: 18px;
                                line-height: 18px;

                                .vat {
                                    display: none;
                                }
                            }
                        }
                        .savings {
                            @include responsive {
                                gap: 4px;
                            }
                        }
                        .striked-off {
                            @include responsive {
                                font-size: 10px;
                                line-height: 14px;
                            }
                        }
                        .save-percent-tag {
                            @include responsive {
                                font-size: 9px;
                                line-height: 14px;
                                padding: 1px 6px;
                            }
                        }
                        .orange-text {
                            @include responsive {
                                font-size: 12px;
                                line-height: 14px;
                            }
                        }
                    }
                    .banner-details h4 {
                        font-weight: 400;

                        @include responsive {
                            font-size: 10px;
                            line-height: 12px;
                        }
                    }
                }

                .subscribe-upgrade-link {
                    margin-top: 0;
                }
            }
        }
        .jood-day-plp-banner {
            border-radius: 8px;
            display: flex;
            align-items: center;
            color: $neutral-dark-01;
            padding-inline: 12px;
            gap: 36px;
            background-size: cover;

            img {
                max-width: 220px;
                object-fit: contain;
                padding: 4px;
            }
            .banner-details {
                display: flex;
                flex-direction: column;
                padding-block: 8px;
                color: $neutral-dark-01;

                h4 {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    color: inherit;
                    margin: 0;
                }
                .price {
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 32px;

                    .vat {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 14px;
                        padding-left: 4px;
                    }
                }
                .savings {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
                .striked-off {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;
                    text-decoration: line-through;
                }
                .orange-text {
                    color: $functional-orange;
                    font-size: 16px;
                    line-height: 18px;
                }
                .save-percent-tag {
                    display: inline-block;
                    padding: 4px 8px;
                    border-radius: 5px;
                    color: $primary-white;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 14px;
                    background-color: $functional-orange;
                }
            }
        }
        .subscribe-upgrade-link {
            margin-top: 8px;
            color: $tertiary-blue-02;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
        }
    }
</style>
