<script lang="ts" context="module">
    import { getAppConfig } from "../../../common/config/app-config";
    import { isMobile } from "../../../common/util";
    import MegaSaleOnlineNow from "./MegaSaleOnlineNow.svelte";
    import MegaSaleVoucher from "./MegaSaleVoucher.svelte";
    const { primeLevel, isMegaSaleDuringAccessStarted } = getAppConfig();

    const isResponsive = isMobile();
</script>

<script lang="ts">
    import ImageTag from "../lazy-components/ImageTag.svelte";

    export let data;
    export let index;
    export let slidesLength;

    console.log("index: " + slidesLength);
    const height = isMobile() ? "500px" : "480px";
    const width = isMobile() ? "390px" : "1440px";

    $: ({ link, image, mobileImage, bannerId, bannerName } = data);
</script>

{#if index == 0}
    {#if isMegaSaleDuringAccessStarted && primeLevel}
        <MegaSaleVoucher pageClass="home-megasale-voucher" />
    {/if}
    <MegaSaleOnlineNow pageClass="home-megasale-banner" />
{/if}
<a
    class="carousel-slide"
    href={link || undefined}
    data-banner-name={bannerName}
    data-banner-id={bannerId}
    data-banner-image={isResponsive ? mobileImage : image}
>
    <ImageTag
        imageUrls={[mobileImage, image]}
        widths={[768, 1440]}
        heights={[500, 480]}
        fetchpriority={((index === 0 || index === slidesLength - 1) &&
            "high") ||
            "auto"}
        alt="hero banner"
    />
</a>

<style lang="scss">
    @import "../styles/colors.new.scss";
    .carousel-slide {
        min-height: 480px;

        @include responsive {
            min-height: 450px;
        }
    }
</style>
