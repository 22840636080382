<script lang="ts">
    import { getAppConfig } from "../../../../common/config/app-config";
    import { getStr } from "../../../../common/util";
    import Text from "../../../common/components/Text.svelte";
    import {
        giftCardAmount,
        giftcardemail,
        giftcardmsg,
        giftvalidemail,
        tasheelPrice,
    } from "../../store/pdp_store";

    export let data: any;

    $: giftCardPriceDenominations = data?.giftCardPriceDenominations;
    $: minGiftCardAmount = data?.minGiftCardAmount;
    $: maxGiftCardAmount = data?.maxGiftCardAmount;
    $: attributeLabels = data?.attributeLabels;
    $: tahsheelPaymentAttributes = data?.tahsheelPaymentAttributes;

    let currencyDecimal = window["ACC"].config.currencyDecimal;
    let amountSelected = "";

    $: tasheelInstallMonths = tahsheelPaymentAttributes?.tasheelMonths;
    $: tasheelPercentage = tahsheelPaymentAttributes?.tasheelPercentage;

    let emailPlaceholder = getStr("extra.giftcard.receiver.email.placeholder");
    let msgPlaceholder = getStr("extra.giftcard.msgPlaceholder");
    let Enteramountholder = getStr("extra.giftcard.Enteramountholder");
    let message = "";
    let email = "";
    let isEmailEntered = false;
    let isEmailValid = true;
    $: EmailMsgDiv = false;
    let regEmail = new RegExp(
        /^[\w-\+]+(\.[\w]+)*@[\w-]+(\.[\w]+)*(\.[a-z]{2,})$/,
    );
    const { countryCode, currency, lang } = getAppConfig();

    function updateGiftCardAmount() {
        EmailMsgDiv = false;
        if (
            typeof maxGiftCardAmount === "number" &&
            typeof minGiftCardAmount === "number" &&
            +amountSelected >= minGiftCardAmount &&
            +amountSelected <= maxGiftCardAmount
        ) {
            EmailMsgDiv = true;
            $giftCardAmount = parseFloat(amountSelected);
            if (countryCode === "SA") {
                tasheelPrice.set(
                    Math.ceil(
                        ($giftCardAmount +
                            ($giftCardAmount * tasheelPercentage) / 100) /
                            tasheelInstallMonths,
                    ),
                );
            } else {
                $tasheelPrice = (
                    ($giftCardAmount +
                        ($giftCardAmount * tasheelPercentage) / 100) /
                    tasheelInstallMonths
                ).toFixed(currencyDecimal);
            }
        } else {
            EmailMsgDiv = false;
        }
    }
    function updateSelectedAmount(selectedAmount) {
        EmailMsgDiv = true;
        amountSelected = selectedAmount;
        $giftCardAmount = parseFloat(amountSelected);

        if (countryCode === "SA") {
            tasheelPrice.set(
                Math.ceil(
                    ($giftCardAmount +
                        ($giftCardAmount * tasheelPercentage) / 100) /
                        tasheelInstallMonths,
                ),
            );
        } else {
            $tasheelPrice = (
                ($giftCardAmount +
                    ($giftCardAmount * tasheelPercentage) / 100) /
                tasheelInstallMonths
            ).toFixed(currencyDecimal);
        }
    }

    const updateGiftMessage = (e) => {
        message = e.target.value;
        giftcardmsg.set(message);
    };
    const updateGiftEmail = (e) => {
        isEmailValid = false;
        isEmailEntered = true;
        email = e.target.value;
        if (regEmail.test(email)) {
            isEmailValid = true;
        }
        giftcardemail.set(email);
    };
    $: if (isEmailValid && isEmailEntered && EmailMsgDiv) {
        giftvalidemail.set(true);
    } else {
        giftvalidemail.set(false);
    }
</script>

<div class="gift-card-form-container">
    <div class="title-text">{attributeLabels?.giftCardMultipleTopUpLabel}</div>
    <form>
        <div class="d-flex input-container">
            {#if lang == "en"}<span class="currency-text">{currency}</span>{/if}
            <input
                type="number"
                name="giftCardAmount"
                id="giftCardAmount"
                class="form-control-input"
                bind:value={amountSelected}
                placeholder={Enteramountholder}
                on:keyup={(e) => updateGiftCardAmount()}
            />
            {#if lang == "ar"}<span class="currency-text">{currency}</span>{/if}
        </div>
        <div class="amount-info">
            <Text
                key="extra.pdp.min_max_mumLable"
                args={[currency, minGiftCardAmount, maxGiftCardAmount]}
            />
        </div>
    </form>
    <div class="gift-card-amount-options-container">
        <div class="gift-card-amount-options">
            {#if giftCardPriceDenominations}
                {#each giftCardPriceDenominations as giftCardPriceDenomination}
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <div
                        on:click={() =>
                            updateSelectedAmount(
                                giftCardPriceDenomination.value,
                            )}
                        class={amountSelected == giftCardPriceDenomination.value
                            ? "product-amount selected"
                            : "product-amount"}
                    >
                        {#if lang == "en"}{currency}{/if}
                        {giftCardPriceDenomination.value}
                        {#if lang == "ar"}{currency}{/if}
                    </div>
                {/each}
            {/if}
        </div>
    </div>

    {#if EmailMsgDiv}
        <div class="gift-card-email-msg-container">
            <div
                class="d-flex input-container"
                class:isEmailValidCls={!isEmailValid}
            >
                <input
                    type="email"
                    name="giftCardEmail"
                    id="giftCardEmail"
                    class="form-control-input"
                    bind:value={email}
                    placeholder={emailPlaceholder}
                    on:keyup={(e) => updateGiftEmail(e)}
                />
            </div>
            <div class="d-flex input-container">
                <input
                    type="text"
                    name="giftCardMsg"
                    id="giftCardMsg"
                    class="form-control-input"
                    bind:value={message}
                    placeholder={msgPlaceholder}
                    on:keyup={(e) => updateGiftMessage(e)}
                />
            </div>
        </div>
    {/if}
</div>

<style lang="scss">
    @import "../../../common/styles/colors.new.scss";

    .gift-card-amount-options-container {
        display: grid;
    }
    .gift-card-amount-options {
        display: flex;
        overflow-x: auto;
    }

    .gift-card-amount-options {
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .product-amount {
        cursor: pointer;
        background: $primary-white;
        border: 1px solid $neutral-light-02;
        box-shadow: 0px 4px 12px rgba(219, 227, 232, 0.6);
        border-radius: 6px;
        padding: 10px 24px;
        margin-right: 8px;
        min-width: auto;
        overflow: initial;
        color: $color-bg-black;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        white-space: nowrap;
        &.selected {
            color: $color-bg-black;
            border: 1px solid $icon-only-icon-blue;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    input:focus-visible {
        outline: none;
    }

    .gift-card-form-container {
        padding: 0 16px;

        @include desktop-min-width {
            padding: 0;
        }
        .title-text {
            color: $neutral-dark-01;
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;
            padding-bottom: 16px;
        }
        .input-container {
            align-items: center;
            border: 1px solid $secondary-gray-01;
            border-radius: 8px;
            padding: 15px 16px;
            width: 100%;
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 7px;
            font-weight: 400;
            height: 48px;
            &.form-error {
                border: 1px solid $error-red;
            }
            .currency-text {
                font-weight: 700;
                padding-right: 12px;
            }
            .form-control-input {
                border: none;
                color: $neutral-dark-01;
                &::placeholder {
                    color: $secondary-gray-01;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                }
                @include desktop-min-width {
                    font-size: 16px;
                    line-height: 18px;
                    &::placeholder {
                        font-size: 16px;
                    }
                }
            }
        }
        .amount-info {
            color: $secondary-gray-01;
            padding: 6px 0 16px 0;
            font-weight: 400;
            font-size: 11px;
            line-height: 12px;
        }
    }
    .gift-card-email-msg-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 15px;
    }
    .isEmailValidCls {
        border: 1px solid $alert-danger !important;
    }
</style>
