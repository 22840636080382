<script lang="ts" context="module">
    import { getAppConfig } from "../../../../common/config/app-config";
    import { getStr, getContextedUrl } from "../../../../common/util";
    import {
        getServiceBlockForCode,
        getServiceProductCodes,
        jsVasDetailsClickHandler,
    } from "./atc-utils";
    import AtcFormatPrice from "./AtcFormatPrice.svelte";
    import AtcVasPrimePriceDetails from "./AtcVasPrimePriceDetails.svelte";

    const { lang, currency } = getAppConfig();
</script>

<script lang="ts">
    export let entry;
    export let selectedServices;
    export let product;
    export let parentEntryNumber;
    export let loopindex;

    let servicesBlockContent = "";
    let servicesBlockImage = "";
    let servicesBlockLabel = "";
    let servicesBlockLink = "";

    $: if (product?.servicesBlocks?.length > 0) {
        ({
            servicesBlockContent,
            servicesBlockImage,
            servicesBlockLabel,
            servicesBlockLink,
        } = getServiceBlockForCode(
            product.servicesBlocks,
            "servicesAppleCarePDPContent",
        ));
    }

    function getServiceDetails(selectedServices, vasPrice) {
        if (selectedServices?.length > 0) {
            const selectedService = selectedServices.find(
                (ss) =>
                    ss.vasType === vasPrice.vasType.code &&
                    ss.priceType === vasPrice.priceType &&
                    ss.vasGroup === "ACC",
            );

            return {
                applecareVasEntrynumber: selectedService?.entryNumber || "",
                applecareVasServiceGroupNumber:
                    selectedService?.serviceGroupNumber || "",
                itemAddedFlag: !!selectedService,
            };
        } else {
            return {
                applecareVasEntrynumber: "",
                applecareVasServiceGroupNumber: "",
                itemAddedFlag: false,
            };
        }
    }
</script>

<div class="row-flex apple-care-flex">
    <div class="col-flex-xs-1 col-flex-md-1">
        <img alt="ExtraAppleCare" src={servicesBlockImage} />
    </div>
    <div class="col-flex-xs-4 col-flex-md-10 c_apple-care-logo">
        <div class="c_services_content_apple-care-content">
            <div class="c_services_content_apple-care-title">
                {servicesBlockLabel}
            </div>
        </div>
    </div>
    <div class="apple-care-content">
        {#if servicesBlockContent?.length > 0}
            <div
                class="col-flex-xs-12 col-flex-md-12 simple-flex c_vas-details-items"
            >
                <span class="vas-description-applecare">
                    {servicesBlockContent}
                </span>
            </div>
        {/if}
        {#if servicesBlockLink?.length > 0}
            <div class="c_extra-service-link">
                <a href={servicesBlockLink} target="_blank">
                    {getStr("extra.vas.service.learn.more")}
                </a>
            </div>
        {/if}

        <div
            class="col-flex-xs-12 col-flex-md-12 simple-flex c_vas-details-items apple-care js-vas-group-addtocart-{entry.vasGroup}"
        >
            {#each entry.vasPrices as vasPrice, vasPriceIndex}
                {@const {
                    applecareVasEntrynumber,
                    applecareVasServiceGroupNumber,
                    itemAddedFlag,
                } = getServiceDetails(selectedServices, vasPrice)}
                {@const serviceProductCodes = getServiceProductCodes(
                    vasPrice,
                    entry,
                )}
                <button
                    class="c_vas-details-list c_vas-apple-service-list popup-apple-service-list js-vas-details-svelte"
                    on:click={jsVasDetailsClickHandler}
                    class:selected={itemAddedFlag}
                    data-item-add={itemAddedFlag}
                    data-type={entry.vasGroup}
                    data-productcode={product.code}
                    data-entryNumber="extra-apple-care-{parentEntryNumber}"
                >
                    <section class="c_vas-details-list-year">
                        {servicesBlockLabel}
                        <div class="vas-name">
                            {vasPrice.vasType.name}
                        </div>
                        {#if vasPrice.percentageDiscount !== null && vasPrice.percentageDiscount.value > 0}
                            <span class="c_product-details-title-reduction">
                                {#if lang === "en"}
                                    {vasPrice.percentageDiscount
                                        .value}%&nbsp;<span
                                        >{getStr(
                                            "product.pourcentageDiscount.off",
                                        )}</span
                                    >
                                {:else}
                                    %{vasPrice.percentageDiscount
                                        .value}&nbsp;<span
                                        >{getStr(
                                            "product.pourcentageDiscount.off",
                                        )}</span
                                    >
                                {/if}
                            </span>
                        {/if}
                    </section>
                    {#if !!vasPrice.discountedPrice}
                        <div class="c_product-price-prices">
                            <div class="c_product-price-was-is">
                                <span class="c_product-price-previous"
                                    >{vasPrice.servicePrice.value}&nbsp;
                                    {currency}
                                </span>
                            </div>
                            <div class="c_product-price-current">
                                <AtcFormatPrice
                                    priceData={vasPrice.discountedPrice}
                                    displayFreeForZero={true}
                                    addCurrency={true}
                                />
                            </div>
                        </div>
                    {:else}
                        <div class="c_vas-details-list-price">
                            <AtcFormatPrice
                                priceData={vasPrice.servicePrice}
                                displayFreeForZero={true}
                                addCurrency={true}
                            />
                        </div>
                        <AtcVasPrimePriceDetails {vasPrice} />
                    {/if}
                    {#each serviceProductCodes as code}
                        {@const freeGiftData =
                            vasPrice?.serviceFreeGiftMap[code] || null}
                        {#if !!freeGiftData}
                            {@const sourceQty =
                                freeGiftData.freeGiftQtuantities["sourceQty"]}
                            {@const targetQty =
                                freeGiftData.freeGiftQtuantities["targetQty"]}
                            <div
                                class="c_gift-items js-freegiftexist"
                                class:js-freegiff-set-fixed={sourceQty ===
                                    targetQty}
                                class:c_gift-items-fixed={sourceQty ===
                                    targetQty}
                            >
                                <div class="c_gift-items-title">
                                    {#if sourceQty < targetQty}
                                        <span class="c_gift-items-title--color">
                                            {getStr(
                                                "product.promotion.freegifts.display.lower.txt",
                                                [sourceQty],
                                            )}
                                        </span>
                                    {:else}
                                        <span class="c_gift-items-title--color">
                                            {getStr(
                                                "text.fixed.freegift.count.display.txt.vas",
                                                [sourceQty],
                                            )}
                                        </span>&nbsp;{getStr(
                                            "product.promotion.freegifts.item.worth.txt",
                                        )}&nbsp;
                                        <span
                                            class="c_gift-items-title--color mobile-font"
                                        >
                                            {freeGiftData.freeGiftWorth}
                                            {currency}
                                        </span>
                                    {/if}
                                </div>
                            </div>
                        {/if}
                    {/each}
                    {#if vasPrice.servicePrice.value > 0}
                        <div class="c_vas-details-list-button">
                            <form
                                id="vasAddToCartForm{product.code}{vasPriceIndex}-{loopindex}"
                                action={getContextedUrl("/cart/vas/add")}
                                modelAttribute="vasAddtocartForm"
                                method="post"
                                class="c_form js-formValidation js-vas-addtocart-form"
                                data-ajax="true"
                                data-submit="vasaddtocart"
                            >
                                <div
                                    id="test_vasAddToCartButton_$"
                                    style="display:inline"
                                >
                                    <input
                                        type="hidden"
                                        name="vasProductCode"
                                        value={vasPrice.productCodes?.length > 0
                                            ? vasPrice.productCodes
                                            : entry.serviceProductCode}
                                    />
                                    <input
                                        type="hidden"
                                        name="vasType"
                                        value={vasPrice.vasType.code}
                                    />
                                    <input
                                        type="hidden"
                                        name="priceType"
                                        value={vasPrice.priceType}
                                    />
                                    <input
                                        type="hidden"
                                        name="priceTo"
                                        value={vasPrice.priceTo}
                                    />
                                    <input
                                        type="hidden"
                                        name="productCode"
                                        value={product.code}
                                    />
                                    <input
                                        type="hidden"
                                        name="additionalAwsProductCode"
                                        value={vasPrice.additionalAwsProductCode}
                                    />
                                    <input
                                        type="hidden"
                                        name="additionalAwsVasGroup"
                                        value={vasPrice.additionalAwsVasGroup}
                                    />
                                    <input
                                        type="hidden"
                                        name="combinedVas"
                                        value={entry.combinedVas}
                                    />
                                    <input
                                        type="hidden"
                                        name="parentEntryNumber"
                                        value={parentEntryNumber}
                                    />
                                    <input type="hidden" name="qty" value="1" />
                                    <input
                                        type="hidden"
                                        name="showApplyService"
                                        value={entry.showApplyService}
                                    />
                                    <input
                                        type="hidden"
                                        name="vasEntryShowService"
                                        value={entry.showApplyService}
                                    />
                                    <input
                                        type="hidden"
                                        name="vasGroup"
                                        value={entry.vasGroup}
                                    />
                                    <input
                                        type="hidden"
                                        name="serviceGroupNumber"
                                        value={applecareVasServiceGroupNumber}
                                    />
                                    <input
                                        type="hidden"
                                        name="entryNumber"
                                        value={applecareVasEntrynumber}
                                        id="vasEntryNumber"
                                        class="js-vasEntryNumber"
                                    />
                                    {#each serviceProductCodes as code}
                                        {@const freeGiftData =
                                            vasPrice.serviceFreeGiftMap[code] ||
                                            null}
                                        {#if !!freeGiftData}
                                            {#each freeGiftData.freeGiftProducts as freeGift}
                                                <input
                                                    type="hidden"
                                                    name="freeGiftCodesMap[{code}]"
                                                    value={freeGift.code}
                                                />
                                                <input
                                                    type="hidden"
                                                    name="freeGiftRuleIdMap[{code}]"
                                                    value={freeGiftData.freeGiftRuleId}
                                                />
                                            {/each}
                                        {/if}
                                    {/each}
                                    <button
                                        type="button"
                                        id="vas-button-{product.code}{vasPriceIndex}"
                                        aria-labelledby="vas-button-{product.code}{vasPriceIndex} vasAddToCartForm{product.code}{vasPriceIndex}"
                                        class="c_vas-details-list-button-vas"
                                    >
                                    </button>
                                </div>
                            </form>
                        </div>
                    {/if}
                </button>
            {/each}
        </div>
    </div>
</div>
