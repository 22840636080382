import { Readable, derived, writable } from "svelte/store";
import { createPLPProductTileObject, getCookie, sortByPosition } from "../../../common/util";

export const APP_CONFIG = window['ACC'].config;
export const name = writable('THIS IS ALGOLIA PRODUCT / SEARCH  LIST PAGE');
export const searchTerm = writable("");
export const isPriceFilter = writable(false);
export const menuLink = writable("");
export const currency = writable(APP_CONFIG.currency);
export const currencyDecimal = writable(APP_CONFIG.currencyDecimal);
export const countryCode = writable(APP_CONFIG.countryCode);
export const userCity = writable(APP_CONFIG.userSelectedCityCode !== "" ? APP_CONFIG.userSelectedCityCode : APP_CONFIG.userDefaultCityCode);
export const isTransactional = writable(APP_CONFIG.isTransactional);
export const tasheelInfo = writable(APP_CONFIG.algoliaConfig.tasheelInfo);
export const facetsStats = writable({} as any);
export const categoryData = writable(null);
export const lang = writable(APP_CONFIG.lang === "en" ? "En" : "Ar");
export const filterString = writable("");
export const priceString = writable("");
export const priceFilterIndex = writable("");
export const ratingString: any = writable("");
export const ratingFilterIndex = writable("");
export const ratingFilterPosition = writable("");
export const totalRating = writable(5);
export const defaultPageSizes = writable(APP_CONFIG.algoliaConfig.defaultPageSizes);
export const selectedPageSize = writable(APP_CONFIG.algoliaConfig.selectedPageSize);
export const supportingSorts = writable(APP_CONFIG.unbxdEnabled ? APP_CONFIG.unbxdSearchConfig.sorts : APP_CONFIG.algoliaConfig.sorts);
export const sortSelected = writable("relevance");
export const productWishList = writable(APP_CONFIG.userId !== "" ? APP_CONFIG.algoliaConfig.wishlistProducts !== undefined ? APP_CONFIG.algoliaConfig.wishlistProducts : [] : []);
export const isSearch = writable(APP_CONFIG.pageId === "search" ? true : false);
export const currentSortValue = writable("relevance");
export const productSKU = writable(null);
export const isSearchResult = writable(false);
export const isNewSearch = writable(false,);
export const filterInfo = writable(null);
export const localizationConfig = writable(null);
export const enableComparison = writable(false);
export const isInitialPageLoadCompleted = writable(false);
export const isInitialNoProductPageLoadCompleted = writable(false);
export const isPageReloadWithFilters = writable(false);
export const saecrhQueryId = writable(null);
export const priceRange = writable({ begin: null, end: null });
export const familyLevel = writable(null);
export const colorInfo = writable(null);
export const primeLevel = writable(APP_CONFIG.primeLevel);
export const primeEnabled = writable(APP_CONFIG.primeEnabled);
export const algoliaUserToken = writable(APP_CONFIG.userId !== "" ? APP_CONFIG.userId : APP_CONFIG.jessionId.split(".")[0]);
export const categoryDataCode = writable(null);
export const sortCodeMap = writable(null);
export const sortSuffixMap = writable(null);
export const localFacetSelectionMap = writable({});
export const isCategoryStaticBottomContent = writable(false);
export const isCategoryStaticTopContent = writable(false);
export const categoryStaticTopContent = writable({ heading: "", body: "" });
export const categoryStaticBottomContent = writable({ heading: "", body: "" });
export const isCategoryNoResult = writable(false);
export const searchValue = writable('');
export const isFocused = writable(false);
export const totalSelectedFilters = writable(0);
export const item_list_name = writable('');

export const filteredOptionsData = writable([]);
export const priceStartDisplay = writable(null);
export const priceEndDisplay = writable(null);
export const plpView = writable(getCookie('plpView') !== null ? getCookie('plpView') : 'list');
export const categoryBreadCrumbs = writable(null);
export const isMobile = writable(false);
export const filterStringCopy = writable(null);
export const facetNumericFiltersCopy = writable(null);
export const facetListCopy = writable(null);
export const beginRange = writable("");
export const endRange = writable("");
export const productList = productListCRUD();
export const facetList = facetListCRUD();
export const categoryFacets = categoryFacetsCRUD();
export const ratingSelectd = ratingSelectdCRUD();
export const itemPerPageList = itemPerPageListCRUD();
export const facetFilters = facetFiltersCRUD();
export const facetNumericFilters = facetNumericFiltersCRUD();
export const defaultFilters = defaultFiltersCRUD();
export const facetQueryList = facetQueryListCRUD();
export const selectedFilters = selectedFiltersCRUD();
export const compareProducts = writable([]);
export const plpRMNBannerData = writable(null);

export const pageLoader = pageLoaderCRUD();
export const seoMetaData = seoMetaDataCRUD();

function seoMetaDataCRUD() {
    const { subscribe, set, update } = writable({ brand: '', category: '', features: [], market: '', family: '', subFamily: '', isMultipleValueUnderSameFacet: false });

    return {
        subscribe, set, update,

        setBrand: (brand) => {
            return update(s => Object.assign({}, s, { brand }));
        },
        setCategory: (category) => {
            return update(s => Object.assign({}, s, { category }));
        },
        setFeatures: (features) => {
            return update(s => Object.assign({}, s, { features }));
        },
        setMarket: (market) => {
            return update(s => Object.assign({}, s, { market }));
        },
        setFamily: (family) => {
            return update(s => Object.assign({}, s, { family }));
        },
        setSubFamily: (subFamily) => {
            return update(s => Object.assign({}, s, { subFamily }));
        },
        setIsMultipleValueUnderSameFacet: (isMultipleValueUnderSameFacet) => {
            return update(s => Object.assign({}, s, { isMultipleValueUnderSameFacet }));
        }
    };
}

function pageLoaderCRUD() {
    const { subscribe, set, update } = writable(false);
    return {
        subscribe,
        set,
        update,
        showLoader: () => {
            return update((state) => {
                state = true
                return state;
            })
        },
        hideLoader: () => {
            return update((state) => {
                state = false
                return state;
            })
        }

    };

}

function productListCRUD() {
    const { subscribe, set, update } = writable([]);
    return {
        subscribe,
        set,
        update,
        push: (item) => {
            return update((state) => {
                state = [...state, item]
                return state;
            })
        }

    };

}
function facetListCRUD() {
    const { subscribe, set, update } = writable([]);
    return {
        subscribe,
        set,
        update,
        push: (item) => {
            return update((state) => {
                state = [...state, item]
                return state;
            })
        },
        setSelection: (filterName, filterIndex, valueIndex) => {
            return update((state) => {
                state[filterIndex].values[valueIndex].isSelected = state[filterIndex].values[valueIndex].isSelected ? false : true;
                return state;
            })
        },

        clearFilterSelections: (filterName, filterIndex) => {
            return update((state) => {
                state[filterIndex].values.forEach(value => {
                    value.isSelected = false;
                });
                return state;
            })
        }
    }
};
function categoryFacetsCRUD() {
    const { subscribe, set, update } = writable([]);
    return {
        subscribe,
        set,
        update,
        push: (item) => {
            return update((state) => {
                state = [...state, item]
                return state;
            })
        }
    }
};


function ratingSelectdCRUD() {
    const { subscribe, set, update } = writable([]);
    return {
        subscribe,
        set,
        update,
        push: (item) => {
            return update((state) => {
                state = [...state, item]
                return state;
            })
        }
    }
}
function itemPerPageListCRUD() {
    const { subscribe, set, update } = writable([]);
    return {
        subscribe,
        set,
        update,
        push: (item) => {
            return update((state) => {
                state = [...state, item]
                return state;
            })
        }
    }
}
function facetFiltersCRUD() {
    const { subscribe, set, update } = writable([]);
    return {
        subscribe,
        set,
        update,
        push: (item) => {
            return update((state) => {
                state = [...state, item]
                return state;
            })
        }
    }
}
function facetNumericFiltersCRUD() {
    const { subscribe, set, update } = writable([]);
    return {
        subscribe,
        set,
        update,
        push: (item) => {
            return update((state) => {
                state = [...state, item]
                return state;
            })
        },
        addItem: (item) => {
            return update((state) => {
                state = [...state, item]
                return state;
            })
        }
    }
}
function defaultFiltersCRUD() {
    const { subscribe, set, update } = writable([]);
    return {
        subscribe,
        set,
        update,
        push: (item) => {
            return update((state) => {
                state = [...state, item]
                return state;
            })
        }
    }
}
function facetQueryListCRUD() {
    const { subscribe, set, update } = writable([]);
    return {
        subscribe,
        set,
        update,
        push: (item) => {
            return update((state) => {
                state = [...state, item]
                return state;
            })
        }
    }
}


function selectedFiltersCRUD() {
    const { subscribe, set, update } = writable([]);
    return {
        subscribe,
        set,
        update,
        addItem: (item) => {
            return update((state) => {
                state = [...state, item]
                return state;
            })
        },

        spliceItem: (item, index) => {
            return update((state) => {
                state = state.splice(item, index)
                return state;
            })
        },

        push: (item) => {
            return update((state) => {
                state = [...state, item]
                return state;
            })
        }
    }
}



function paginationCRUD() {
    const { subscribe, set, update } = writable({
        totalPages: 0,
        currentPage: 0,
        totalresults: 0,
        resultsPerPage: 6,

    });
    return {
        subscribe,
        set,
        update,
        setTotalPages: (pages) => {
            return update((state) => {
                state.totalPages = pages;
                return state;
            })
        },
        setCurrentPage: (page) => {
            return update((state) => {
                state.currentPage = page;
                return state;
            })
        },

        settotalresults: (results) => {
            return update((state) => {
                state.totalresults = results;
                return state;
            })
        },

        setResultsPerPage: (resultsPerPage) => {
            return update((state) => {
                state.resultsPerPage = resultsPerPage;
                return state;
            })
        },

        reset: () => {
            return update((state) => {
                state.totalPages = 0;
                state.currentPage = 0;
                state.totalresults = 0;
                state.resultsPerPage = 6;
                return state;
            });
        },

        setPagination: (obj) => {
            return update((state) => {
                state.resultsPerPage = obj.hitsPerPage;
                state.totalPages = obj.nbPages;
                state.totalresults = obj.nbHits;
                state.currentPage = obj.page;
                return state;
            })
        }
    };


}
export const pagination = paginationCRUD();
export const islocationDisabled = writable(true);


// PLP Unbxd related primitive and derived stores
export const unxSortOptions = derived(supportingSorts, ($sorts, set) => {
    set(
        $sorts?.map(s => Object.assign({},
            s,
            { unxSortParam: s.code === 'relevance' ? '' : s.code.replace('-', ' ') }
        )) || []
    );
});

function createUNXResponse() {
    const { subscribe, set, update } = writable(null);

    return {
        subscribe,
        update,
        set: (value: any) => {
            if (!value) {
                set(null);
            } else {
                const newValue = JSON.parse(JSON.stringify(value), (key, value) => {
                    if (value === "true") return true;
                    if (value === "false") return false;
                    return value;
                });
                set(newValue);
            }
        }
    };
}
export const unxResponse = createUNXResponse();

export const unxFacets: Readable<any> = derived(unxResponse, ($unxResponse, set) => {
    if (!!$unxResponse) {
        set({
            list: [
                ...$unxResponse?.facets?.range?.list || [],
                ...$unxResponse?.facets?.text?.list || []
            ].sort(sortByPosition),
            selected: [
                ...$unxResponse?.facets?.range?.selected || [],
                ...$unxResponse?.facets?.text?.selected || []
            ].sort(sortByPosition)
        });
    } else {
        set({
            list: [],
            selected: []
        });
    }
});

export const unxSelectedFacetsMap: Readable<any> = derived(unxFacets, ($unxFacets: any, set) => {
    if ($unxFacets?.selected?.length > 0) {
        set(Object.assign(
            {},
            ...$unxFacets
                ?.selected
                ?.map(t => Object.assign({}, { [t.facetName]: t.values }))
        )
        );
    } else {
        set({});
    }
});

export const unxPLPHasProducts: Readable<boolean> = derived(unxResponse, ($unxResponse: any, set) => {
    set(window['ACC'].config.unbxdEnabled && $unxResponse?.response?.products?.length > 0);
});

export const unxProductList: Readable<any[]> = derived(unxResponse, ($unxResponse, set) => {

    set($unxResponse?.response?.products.map(createPLPProductTileObject) || []);
});

export const unxPLPOptionStore = derived(unxResponse, ($unxResponse, set) => {
    set({
        numberOfProducts: $unxResponse?.response?.numberOfProducts || 0
    });

    // setting other stores to support old code
    if (APP_CONFIG.unbxdEnabled) {
        const queryText = $unxResponse?.searchMetaData?.queryParams?.q || '';
        searchTerm.set(queryText);

        const hitsPerPage = +($unxResponse?.searchMetaData?.queryParams?.rows || 0);
        const nbHits = +($unxResponse?.response?.numberOfProducts || 0);
        const page = +($unxResponse?.searchMetaData?.queryParams?.page || 0);
        const nbPages = Math.ceil(nbHits / hitsPerPage);

        productList.set($unxResponse?.response?.products || []);

        pagination.setPagination({
            hitsPerPage,
            nbPages,
            nbHits,
            page
        });
    }
});

export const unxPriceStats: Readable<any> = derived(unxResponse, ($unxResponse, set) => {
    set(
        $unxResponse?.stats?.price || null
    );
});

function selectedRatingCRUD() {
    const { set, update, subscribe } = writable<number[]>(null);

    return {
        subscribe,
        setBulkSelection: (selectedRatingsArray: number[]) => {
            set(selectedRatingsArray.sort());
        },
        setSingleRatingStatus: (starRating, selected: boolean) => {
            update((values) => {
                if (selected) {
                    return Array.from(new Set([...(values || []), starRating])).sort();
                } else {
                    return values.filter(x => x !== starRating);
                }
            });
        }
    };
}

export const unxSelectedRatings = selectedRatingCRUD();
export const unxSelectedPageSize = writable<number>(24);
export const unxLastFilterUsedMobile = writable<URLSearchParams>(null);

export const unxSelectedFacetsForSEO = derived(unxSelectedFacetsMap, ($fMap, set) => {
    if ($fMap) {
        const value = Object.entries<any>($fMap).map(([k, v]) => v.map(v1 => `${k}:${v1}`)).flat();
        set(value);
    } else {
        set(null);
    }
});
