<script lang="ts" context="module">
    import { getAppConfig } from "../../../../common/config/app-config";
    import { getStr } from "../../../../common/util";

    const { lang, currency } = getAppConfig();
</script>

<script lang="ts">
    export let priceData;
    export let displayFreeForZero;
    export let addCurrency;

    $: formattedPrice = priceData?.value;
</script>

{#if priceData?.value > 0}
    {#if addCurrency}
        <span>
            {#if lang === "ar"}
                <strong>{formattedPrice}</strong>
            {/if}
            {currency}
            {#if lang === "en"}
                <strong>{formattedPrice}</strong>
            {/if}
        </span>
    {:else}
        <span>
            <strong>{formattedPrice}</strong>
        </span>
    {/if}
{:else if displayFreeForZero}
    <span class="c_cart-totals--rgtclr free">
        {getStr("text.free")}
    </span>
{:else}
    {formattedPrice}
{/if}
