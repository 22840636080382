
import { environment } from '../../../environment/env';
import { getAppConfig } from "./app-config";

const { countryCode = 'SA', lang } = getAppConfig();
export const APP_CONSTANTS = {
  AMPLIENCE_IMAGE_BASE_URL: "https://media.extra.com/i/aurora/",
  AMPLIENCE_IMAGE_BASE_URL_S: "https://media.extra.com/s/aurora/",
  AMPLIENCE_API: "https://extra.cdn.content.amplience.net/content/id/",
  AMPLIENCE_API_HTML_BASE_URL: 'https://c1.adis.ws/v1/content/aurora/content-item/',
  AMPLIENCE_API_BASE: "https://{hubname}.cdn.content.amplience.net/content",
  MEGA_MENU_URL: "componet/extraCategoryNavComponent",
  IMAGE_360_VIEW_BASE_URL: "https://exprdstrgcdn.blob.core.windows.net/exprdeccdn01/ImagesSections/amp_viewer_kit/index.html?mode=native&set=productSku_800",
  CITY_LIST: "cities/@COUNTRY",
  CHANGE_CITY_URL: "city/@CITY",
  CART_URL: "cart",
  APP_BASE_URL: environment.API_BASE_URL,
  BASE_WEB_SERVICE_URL: '/extracommercewebservices/v2/',
  CART_LOGIN_DETAILS: 'cart/cartLoginDetails',
  ADD_TO_WISHLIST: 'add-to-wishlist',
  REMOVE_FROM_WISHLIST: 'my-account/wishlist/remove-item',
  NEWSLETTER_POST: 'newsletter/subscription',
  ADDTOCART_HTML: 'cart/add',
  ADDTOCART_JSON: 'cart/addToCart',
  ADDBUNDLETOCART_HTML: 'cart/addBundle',
  ADDBUNDLETOCART_JSON: '/cart/addBundleToCart',
  UPDATEMINICART: 'cart/miniCart/SUBTOTAL',
  CLIENT_ID: environment.API_CLIENT_ID,
  CLIENT_SECRET: environment.API_CLIENT_SECRET,
  APP_ID: 'Desktop',
  GET_PAGE_CONFIG: 'getPageConfig',
  GET_PARA_SLOT_DETAILS: 'getParagraphSlotDetails',
  LANGUAGE_CHANGE_POST: '_s/language',
  TOP_HEADER_SLOT_KEY: 'TopHeaderSlot',
  NOTIFY_ALERT: 'notifyAlert',
  NEAREST_STORE: 'stores/getNearestAvailableStore',
  CDN_EXTRASTORE: 'http://cdn.extrastores.com/hybris/new_ui_ux/icons',
  GETPRODUCT: '/getProduct',
  RR_API_KEY: window['ACC'].richRelvenceKey,
  RR_API_CLIENT_KEY: window['ACC'].richRelevanceClientKey,
  TEST_FREAKS_API: 'https://api.testfreaks.com',
  POST_TEST_FREAKS_REVIEW: 'product/reviews/submit',
  TEST_FREAKS_CLIENT_ID: lang === 'en' ? 'extrastores.com' : 'ar.extrastores.com',
  GET_TASHEEL_DETAILS: 'tasheel/tasheelDetails',
  GET_TEST_FREAK_BASE: 'https://js.testfreaks.com/onpage/${clientId}/reviews.json',
  SEND_OTP: 'register/send/otp/{mobileNumber}',
  VALIDATE_OTP: 'register/validate-otp',
  RESEND_OTP: 'register/resend/otp/{mobileNumber}',
  SUBMIT_Questions_FORM: 'tasheel/submitApplication',
  SEND_USER_IDENTITY_PHOTO: 'tasheel/identify/user',
  VERIFY_USER_SEND_OTP: 'user-identity/send/otp/{mobileNumber}',
  VERIFY_USER_RESEND_OTP: 'user-identity/resend/otp/{mobileNumber}',
  VERIFY_USER_VALIDATE_OTP: 'user-identity/validate-otp',
  UPLOAD_NATIONAL_ID: "tasheel/upload/nid",
  CONFIRMCUSTOMERINFO: "tasheel/confirmCustomerInfo",
  SUBMIT_SURROGATE_INCOME: "tasheel/confirmSurrogateIncome",
  GET_CMS_COMPONENTS: "cms/components",
  appId: "IphoneApp",
  AMPLIENCE_HUB_NAME: "extra",
  IS_DEMO_MODE: environment.IS_DEMO_MODE,
  GET_RR_PLACEMENTS_DATA: "api/rrPlatform/recsForPlacements",
  GET_RR_BASE_URL: window['ACC'].richBaseUrl,
  GET_DEALS_PRODUCTS: 'getDealsProducts',
  GET_LATEST_ORDERS: 'account/getLatestOrder/{count}',
  GET_CART_ITEM_DETAILS: 'cart/getCartItemDetails/{count}',
  IS_PRODUCTION: environment.IS_PRODUCTION,
  GET_TOKEN_URL: 'authorizationserver/oauth/token',
  GET_SWOGO_BUNDLE: window['ACC'].config.swogoUrl,
  GET_BUNDLE_TAMARA_PRICE: 'p/getBundlePriceDetails ',
  GET_UNBXD_PRODUCTS_ENDPOINT: 'products',
  GET_UNBXD_SEARCH_ENDPOINT: 'search',
  GET_UNBXD_CATEGORY_ENDPOINT: 'category',
  GET_RELATED_BLOG_POST: 'blog/relatedPosts',
  GET_BLOG_NEXT_POST: 'blog/nextPosts/{catId}',
  UNX_APPEND_TYPE_FILTER_IN_REQUESTS: true,
  BACKUP_TOKEN: environment.BACKUP_TOKEN,
  POST_UPDATE_SESSION_CITY: 'stores/updateSessionCity'
};

export const getBaseWebServiceUrl = () => {
  let cc = countryCode.toLowerCase();
  return `${APP_CONSTANTS.APP_BASE_URL}${APP_CONSTANTS.BASE_WEB_SERVICE_URL}extra_${cc}`;
};