<script lang="ts">
    export let data: any;

    $: digitalGiftcardType = data?.digitalGiftcardType;
    $: attributeLabels = data?.attributeLabels;

    let selectedType = "";
    let selectedSubType = "";

    if (digitalGiftcardType?.length > 0) {
        selectedType = digitalGiftcardType[0].label;
        if (digitalGiftcardType[0].subType?.length > 0) {
            selectedSubType = digitalGiftcardType[0].subType[0].label;
        }
    }

    function selectGiftCardType(selectedGiftCardType) {
        if (digitalGiftcardType?.length > 0) {
            selectedType = selectedGiftCardType;
            const selectedIndex = digitalGiftcardType.findIndex(
                (x) => x.label === selectedType,
            );

            if (selectedIndex > -1) {
                selectedSubType =
                    digitalGiftcardType[selectedIndex].subType[0]?.label;
            }
        }
    }
</script>

{#if digitalGiftcardType != null}
    <div class="product-occasions-container">
        <div class="title-text">
            {attributeLabels?.giftCardMultipleOccasionsLabel}
        </div>
        <div class="occation-options">
            <div class="occation-options-container">
                {#each digitalGiftcardType as digitalGiftcard}
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <div
                        on:click={selectGiftCardType(digitalGiftcard.label)}
                        class={selectedType == digitalGiftcard.label
                            ? "product-occation selected"
                            : "d-flex product-occation"}
                    >
                        {digitalGiftcard.label}
                    </div>
                {/each}
            </div>
        </div>
    </div>

    <div class="product-options-container">
        <div class="title-text">
            {attributeLabels?.giftCardMultipleOptionLabel}
        </div>
        <section class="product-option-details">
            <section class="d-flex product-options">
                <div class="d-flex">
                    {#each digitalGiftcardType as digitalGiftcard}
                        {#if digitalGiftcard.subType != null}
                            <div
                                class={digitalGiftcard.label == selectedType
                                    ? "show-tile"
                                    : "hide"}
                            >
                                {#each digitalGiftcard.subType as cardSubType}
                                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                                    <div
                                        on:click={() =>
                                            (selectedSubType =
                                                cardSubType.label)}
                                        class={selectedSubType ==
                                        cardSubType.label
                                            ? "d-flex product-option selected"
                                            : "d-flex product-option"}
                                    >
                                        <img
                                            src={cardSubType.iconUrl}
                                            alt={cardSubType.label}
                                        />
                                        <div class="option-text">
                                            {cardSubType.label}
                                        </div>
                                    </div>
                                {/each}
                            </div>
                        {/if}
                    {/each}
                </div>
            </section>
        </section>
    </div>
{/if}

<style lang="scss">
    @import "../../../common/styles/colors.new.scss";

    .occation-options-container {
        display: flex;
        gap: 16px;
        overflow-x: auto;
    }

    .occation-options-container {
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .show-tile {
        display: flex;
    }

    .hide {
        display: none;
    }

    .product-occation {
        cursor: pointer;
        color: $color-bg-black;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        background: $primary-white;
        border: 1px solid $secondary-gray-02;
        border-radius: 8px;
        padding: 10px 20px;
        min-width: auto;
        overflow: initial;
        &.selected {
            color: $color-bg-black;
            border: 1px solid $icon-only-icon-blue;
            font-weight: 700;
        }
    }
    .product-occasions-container {
        padding: 0 16px;
        margin-top: 64px;
        @include desktop-min-width {
            padding: 0;
            margin-top: 48px;
        }
        .title-text {
            color: $neutral-dark-01;
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;
            padding-bottom: 16px;
        }
        .occation-options {
            display: grid;
        }
    }

    .product-options-container {
        padding: 0 16px;
        margin-top: 64px;
        @include desktop-min-width {
            padding: 0;
            margin-top: 48px;
        }
        .title-text {
            color: $neutral-dark-01;
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;
            padding-bottom: 16px;
        }
        .product-option-details {
            display: grid;
        }
        .product-options {
            flex-wrap: nowrap;
            overflow-x: auto;
            max-width: fit-content;
            .product-option {
                cursor: pointer;
                align-items: center;
                background: $primary-white;
                border: 1px solid $secondary-gray-02;
                border-radius: 12px;
                padding: 17px 12px;
                margin-right: 16px;
                width: 200px;
                img {
                    width: 64px;
                    height: 64px;
                }
                .option-text {
                    padding: 0 8px;
                    color: $color-bg-black;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                }
                &.selected {
                    border: 1px solid $icon-only-icon-blue;
                    .option-text {
                        font-weight: 700;
                    }
                }
            }
        }
    }
    .product-options {
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
</style>
