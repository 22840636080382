import { writable } from "svelte/store";
export const heroBannerImages =  writable([]);
export const popularCategoriesData = writable([]);
export const topBrandImages =  writable([]);
export const testimonialData =  writable([]);
export const mainContentData= writable({});
export const amplienceData= writable({});
export const richRelavance= writable([]);
export const footerData= writable([]);
export const latestOrderData = writable(null);
export const topLinksData = writable(null);
export const APP_CONFIG = window.ACC.config;
export const primeLevel = writable(APP_CONFIG.primeLevel);
export const primeEnabled = writable(APP_CONFIG.primeEnabled);