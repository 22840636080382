<script lang="ts">
    import { getAppConfig } from "../../../../common/config/app-config";
    import {
        getJoodMembershipType,
        getStr,
        isMobile,
        JoodMembershipType,
        slideOpenAnimation,
    } from "../../../../common/util";
    import AtcJoodPayDay from "./AtcJoodPayDay.svelte";
    import { ProductTilePriceGroupVariantType } from "../../../common/components/productTile/components/ProductTilePriceNewGroup.svelte";
    import Icon from "../../../common/components/Icon.svelte";
    import Text from "../../../common/components/Text.svelte";
    import JoodDayBannerNew from "../../../product-listing-search/components/JoodDayBannerNew.svelte";
    import { convertProductDataToUnbxdProductData } from "../../services/pdp-data-services";
    const { currency, encodedContextPath, themeResourcePath } = getAppConfig();

    export let product;
    export let joodTypePDPClass;
    export let joodType;
    export let basePrice;
    export let showGoldTerms = false;
    const productVipPrimeData = product?.productVipPrimeData || {};
    const productBasicPrimeData = product?.productBasicPrimeData || {};

    let open: boolean =
        joodType === "BASIC" || joodTypePDPClass === "jood-guest"
            ? true
            : false;
    const isResponsive = isMobile();
    const title = getStr("extra.pdp.prime.gold.members.display.text");
    let priceType: JoodMembershipType;
    const joodSubscribeLinkHref = `${encodedContextPath}/jood/benefits`;

    export let variant: ProductTilePriceGroupVariantType;

    const userJoodMembershipType: JoodMembershipType = getJoodMembershipType();
    const joodCardImageUrl =
        joodType === "BASIC"
            ? `${themeResourcePath}/images/jood/jood-blue-card.png`
            : `${themeResourcePath}/images/jood/jood-gold-card.png`;

    $: displayPrice =
        joodType === "BASIC"
            ? productBasicPrimeData?.primePrice.value
            : productVipPrimeData?.primePrice.value;
    $: wasPrice = product?.price?.value;
    $: savedPrice = wasPrice - displayPrice;
    $: hasSavings = savedPrice > 0;
    $: visible = displayPrice > 0;
    $: subscribeLinkVisible = getSubscribeLinkVisibility(
        priceType,
        userJoodMembershipType,
    );
    $: subscribeLinkText = getSubscribeLinkText(
        joodType,
        userJoodMembershipType,
        variant,
    );
    $: joodTypeClass = joodType === "BASIC" ? "jood-basic" : "jood-vip";

    function getSubscribeLinkVisibility(
        priceType: JoodMembershipType,
        userJoodMembershipType: JoodMembershipType,
    ): boolean {
        const tacticalVipPromo = productVipPrimeData?.tacticalPromo;
        const tacticalBasicPromo = productBasicPrimeData?.tacticalPromo;
        const hasTacticalPromo = tacticalBasicPromo || tacticalVipPromo;

        let visibility = true;
        if (hasTacticalPromo) {
            visibility = false;
        }

        return visibility;
    }

    function getSubscribeLinkText(
        joodType,
        userJoodMembershipType: JoodMembershipType,
        variant: ProductTilePriceGroupVariantType,
    ): string {
        return userJoodMembershipType === "NOPRIME" && joodType === "VIP"
            ? variant === "plp-list"
                ? getStr("extra.jood.subscribe.message")
                : getStr("extra.plus.subscribe.button.label")
            : userJoodMembershipType === "BASICPRIME" && joodType === "VIP"
              ? getStr("extra.jood.gold.upgrade.message")
              : "";
    }
</script>

{#if visible}
    <button
        class="product-tile-price-gold-accordion c_jood-card jood-pdp {joodTypeClass} {joodTypePDPClass}"
        class:open
        on:click|preventDefault|stopPropagation={() => (open = !open)}
    >
        <section class="down-arrow" class:rotate180={open}>
            <Icon
                type="font"
                glyph="chevron_down"
                mobileSize={14}
                desktopSize={14}
            />
        </section>
        {#if !open}
            <section class="header" transition:slideOpenAnimation>
                <div class="c_jood-card--guest-label">{title}</div>
                <div class="d-flex">
                    <div class="c_jood-card--guest-price">
                        <div class="tile-price-container d-flex flex-column">
                            <div
                                class="price-with-discount-wrapper price-wrapper"
                            >
                                <div
                                    class="price-and-extra d-flex justify-content-between"
                                >
                                    <div class="price-side">
                                        <Text
                                            key="jood.price.with.currency"
                                            args={[currency, displayPrice]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        {:else}
            <section class="content" transition:slideOpenAnimation>
                <section class="jood-price-section">
                    {#if !isResponsive || variant === "pdp"}
                        <img src={joodCardImageUrl} alt="Jood" />
                    {/if}
                    <section class="gold-price-details">
                        <div class="c_jood-card--guest-label">{title}</div>
                        <div class="d-flex">
                            <div class="c_jood-card--guest-price">
                                <div
                                    class="tile-price-container d-flex flex-column"
                                >
                                    <div
                                        class="price-with-discount-wrapper price-wrapper"
                                    >
                                        <div
                                            class="price-and-extra d-flex justify-content-between"
                                        >
                                            <div class="price-side">
                                                <Text
                                                    key="jood.price.with.currency"
                                                    args={[
                                                        currency,
                                                        displayPrice,
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {#if hasSavings}
                            <div class="jood-saving-price">
                                <span class="strike-base-price"
                                    >{basePrice}</span
                                >
                                <span>
                                    <Text
                                        key="extra.jood.saved.amt"
                                        args={[currency, savedPrice]}
                                    />
                                </span>
                            </div>
                        {/if}
                    </section>
                    {#if subscribeLinkVisible}
                        <a
                            class="subscribe-upgrade-link"
                            on:click|stopPropagation
                            href={joodSubscribeLinkHref}
                        >
                            {subscribeLinkText}
                        </a>
                    {/if}
                </section>
                <JoodDayBannerNew
                    variant="product-tile"
                    algoliaProductHit={convertProductDataToUnbxdProductData(
                        product,
                    )}
                    bannerType={joodType === "BASIC" ? "BASICPRIME" : "NOPRIME"}
                    backgoundOverride={joodType === "BASIC"
                        ? "BASICPRIME"
                        : "NOPRIME"}
                />
            </section>
        {/if}
        {#if showGoldTerms}
            <div
                class="jood-payoffer-disclaimer-text jood-payoffer-disclaimer-wrapper"
            >
                {#if joodTypePDPClass === "jood-guest" || joodTypePDPClass === "jood-basic-subscriber-gold-card"}
                    <div class="c_jood-card-btn-container jood-mobile-btn">
                        <a
                            href="/jood/benefits"
                            class="c_jood-button jood-subscribe-btn"
                        >
                            <Text key="extra.plus.subscribe.button.label" />
                        </a>
                    </div>
                {/if}
                <div class="jood-pdp-disclaimer-text">
                    <Text key="extra.pdp.prime.gold.members.disclaimer.text" />
                </div>
            </div>
        {/if}
    </button>
{/if}

<style lang="scss">
    @import "../../../common/styles/colors.new.scss";

    .product-tile-price-gold-accordion {
        border-radius: 12px;
        color: $neutral-dark-01;
        position: relative;
        flex-basis: 100%;
        text-align: start;
        border: none;
        padding: 0;
        font-family: inherit;
        @include responsive {
            border-radius: 8px;
        }

        .down-arrow {
            position: absolute;
            right: 12px;
            top: 16px;
            color: $icon-only-icon-blue;
            transition: transform 250ms ease;

            @include responsive {
                right: 4px;
                top: 4px;
            }
            &.rotate180 {
                transform: rotateZ(180deg);
            }
        }
        .header {
            padding: 12px 32px 12px 12px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;

            @include responsive {
                padding: 8px 20px 8px 8px;
            }
        }
        h2 {
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;
            margin: 0;

            @include responsive {
                font-size: 10px;
                line-height: 11px;
            }
        }
        .price {
            font-size: 22px;
            line-height: 22px;
            font-weight: 400;

            @include responsive {
                font-size: 10px;
                line-height: 11px;
            }

            .vat {
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                color: $neutral-dark-04;

                @include responsive {
                    display: none;
                }
            }
        }
        .content {
            padding: 10px 8px;
            display: flex;
            flex-direction: column;
            gap: 14px;
            width: 100%;

            @include responsive {
                gap: 4px;
            }

            .jood-price-section {
                display: flex;
                align-items: flex-start;
                gap: 12px;

                img {
                    height: 50px;
                    object-fit: contain;
                    border-radius: 4px;
                }
                .price {
                    margin-top: 8px;
                    font-size: 28px;
                    line-height: 32px;

                    @include responsive {
                        font-size: 16px;
                        line-height: 18px;
                        margin-top: 4px;
                    }

                    &:first-child {
                        margin-top: 0;
                    }
                }
                .savings {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }
                .striked-off {
                    color: $neutral-dark-03;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    text-decoration: line-through;

                    @include responsive {
                        font-size: 10px;
                        line-height: 14px;
                    }
                }
                .orange-text {
                    color: $functional-orange;
                    font-size: 14px;
                    line-height: 18px;

                    @include responsive {
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
                .subscribe-upgrade-link {
                    margin-top: 8px;
                    color: $tertiary-blue-02;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    width: 50%;
                    text-align: end;

                    @include responsive {
                        display: none;
                    }
                }
            }
        }

        &.variant-product-tile {
            .content .jood-price-section img {
                height: 44px;
            }
        }

        &.variant-plp-list {
            @include responsive {
                .down-arrow {
                    right: 6px;
                    top: 6px;
                }
                .header {
                    padding: 8px 24px 8px 8px;
                    h2 {
                        font-size: 12px;
                        line-height: 11px;
                    }
                    .price {
                        font-size: 16px;
                        line-height: 11px;
                    }
                }
            }
        }
        &.variant-pdp {
            @include responsive {
                .down-arrow {
                    right: 8px;
                    top: 14px;

                    :global(i) {
                        font-size: 16px;
                    }
                }
                .header {
                    padding: 12px 30px 12px 12px;
                }
                h2 {
                    font-size: 14px;
                    line-height: 14px;
                }
                .price {
                    font-size: 22px;
                    line-height: 24px;
                }

                .content {
                    display: flex;
                    gap: 8px;
                    flex-direction: column;
                    .jood-price-section {
                        .gold-price-details {
                            .price {
                                font-size: 22px;
                                line-height: 18px;
                                margin-top: 6px;
                            }
                            .savings {
                                .striked-off,
                                .orange-text {
                                    font-size: 14px;
                                    line-height: 14px;
                                    margin-top: 4px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
