import {
	_
} from "svelte-i18n";
const getCashbackPrice = (cashback, countryCode = 'SA') => {
	var cashbackArray = cashback.split('-');
	let cashBackPrice;
	if (countryCode !== 'SA') {
		cashBackPrice = parseFloat(cashbackArray[0]).toFixed(3);
	} else {
		cashBackPrice = parseInt(cashbackArray[0]);
	}
	return cashBackPrice;
}
const getAfterCashBackPrice = (priceValueDiscount, price, cashBackPrice, countryCode) => {
	let afterCashBackPrice = priceValueDiscount > 0 ? (priceValueDiscount - cashBackPrice) : (price - cashBackPrice);
	return countryCode !== 'SA' ? afterCashBackPrice.toFixed(3) : afterCashBackPrice;
}
const getDiscountPriceValue = (priceValueDiscount, price, countryCode) => {
	let discountPriceValue = priceValueDiscount > 0 ? priceValueDiscount : price;
	return countryCode !== 'SA' ? discountPriceValue.toFixed(3) : discountPriceValue;
}
const getFreeGiftData = (freegiftworth) => {
	if (freegiftworth !== "") {
		let freegiftArray = freegiftworth.split("-");
		let freeGitftWorth = freegiftArray[0] ?? 0;
		let targetQuantiy = freegiftArray[2] ?? 0;
		let sourceQuantiy = freegiftArray[2] ?? 0;
		return [{
			key: "targetQty",
			value: targetQuantiy
		}, {
			key: "sourceQty",
			value: sourceQuantiy
		}, {
			key: "price",
			value: freeGitftWorth
		}]
	}
}
export async function getRRTemplateData(resultData, lang = 'en', countryCode = 'SA') {
	let hits = resultData?.hits;
	let langCode = lang === "ar" ? "Ar" : "En";
	let hitsData = hits?.map(productData => {
		let cashBackPrice = productData.hasCashBack === true && productData.cashback !== '' ? getCashbackPrice(productData.cashback, countryCode) : 0;
		let discountPriceValue = getDiscountPriceValue(productData.priceValueDiscount, productData.price, countryCode);
		let productPrice = countryCode !== 'SA' ? productData.wasPrice.toFixed(3) : productData.wasPrice;
		return {
			//	...productData,
			brandName: productData[`brand${langCode}`],
			variants: productData[`variants${langCode}`],
			productStatusHtml: productData[`productStatusHtml${langCode}`],
			productStatus: productData[`productStatus${langCode}`],
			summaryEn: productData[`summary${langCode}`],
			name: productData[`name${langCode}`],
			url: productData[`url${langCode}`],
			//	discountPriceValue: getDiscountPriceValue(productData?.priceValueDiscount, productData?.price, countryCode),
			hasCashBack: productData.hasCashBack,
			cashBackPrice: cashBackPrice,
			aftreCashBackPrice: {
				formattedValue: getAfterCashBackPrice(productData?.priceValueDiscount, productData?.price, cashBackPrice, countryCode),

			},
			amplienceProductBaseUrl: productData.amplienceProductBaseUrl,
			rating: productData.rating,
			numberOfReviews: productData.numberOfReviews,
			comingSoon: productData.comingSoon,
			hasFreeGift: productData.hasFreeGifts,
			//	freegiftworth: getFreeGiftData(productData.freegiftworth),
			freeGiftQtuantities: getFreeGiftData(productData.freegiftworth),
			freegiftworth: productData?.freegiftworth,
			offersLabel: productData.offersCount ? productData.offersCount : null,
			// hasFreeGifts: true,
			// 			freegiftworth:  getFreeGiftData('825-1-1-100230851-false'),
			inStockCities: productData.inStockCities,
			inStock: productData.inStock,
			sellingOutFastCities: productData.sellingOutFastCities,
			//productPrice - wasPrice , dicountPriceValue- price
			wasPrice: {
				value: countryCode !== 'SA' ? productData.wasPrice.toFixed(3) : productData.wasPrice,
				formattedValue: countryCode !== 'SA' ? productData.wasPrice.toFixed(3) : productData.wasPrice,
			},
			discountPrice: {
				value: discountPriceValue,
				formattedValue: discountPriceValue,
			},
			percentageDiscount: {
				value: productData.priceValueDiscountPercentage,
			},
			// price: {
			// 	value: countryCode !== 'SA' ? productData.wasPrice.toFixed(3) : productData.wasPrice
			// },
			price: {
				value: productPrice,
				formattedValue: productPrice,
			},
			rrClickTrackingURL: productData.rrClickTrackingURL
		}
		
	});
	
	return {
		...resultData,
		hits: hitsData
	}
}
