<script lang="ts">
    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();

    export let categoryList = [];
    export let activeCategoryId = -1;

    // can be used to define colors
    export let recommendationType = 1;

    let recommendationClassName = "bestSelling";
    if (Number(recommendationType) == 2) {
        recommendationClassName = "popular";
    }

    function onCategoryClick(id) {
        activeCategoryId = id;
        dispatch("catClick", id);
    }
</script>

{#if categoryList.length}
    <div class="recommendation-categories {recommendationClassName}">
        {#each categoryList as category}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
                on:click={() => onCategoryClick(category.id)}
                data-cat-id={category.id}
                class:active={activeCategoryId === category.id}
                class="item d-flex align-item-center justify-content-center
                {recommendationClassName}"
            >
                {category.label}
            </div>
        {/each}
    </div>
{/if}

<style lang="scss">
    @import "../../../../common/styles/colors.new.scss";

    .recommendation {
        &-categories {
            display: flex;
            gap: 4px;
            overflow-x: scroll;
            overflow-y: hidden;
            scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
            @include desktop-min-width {
                gap: 8px;
            }
            .item {
                font-size: 14px;
                line-height: 16px;
                font-weight: 700;
                padding: 10px 12px;
                height: 36px;
                background-color: transparent;
                border: 1px solid;
                border-radius: 100px;
                white-space: nowrap;
                cursor: pointer;
                &.bestSelling {
                    color: $primary-black;
                    border-color: #00000033;
                    &.active {
                        border-color: $primary-black;
                    }
                }
                &.popular {
                    color: $primary-white;
                    border-color: #ffffff33;
                    &.active {
                        border-color: $primary-white;
                    }
                }
            }
        }
    }
</style>
