<script lang="ts" context="module">
    import {
        getJoodMembershipType,
        getStr,
        getContextedUrl,
    } from "../../../../common/util";
    import { getAppConfig } from "../../../../common/config/app-config";

    const membershipType = getJoodMembershipType();
    const { lang, currency } = getAppConfig();
</script>

<script lang="ts">
    export let vasEntry;
    export let joodType;
    export let linkType;
    export let isRevealState;
    export let typeClass;
    export let pageClass = "";

    $: joodTypeClass = joodType === "BASIC" ? "jood-basic" : "jood-vip";
    $: revealGoldFlexClass = isRevealState
        ? "jood-reveal jood-closed"
        : "jood-open";
    $: joodRevealClass = isRevealState
        ? "js-jood-reveal-container jood-hide"
        : "";
    $: buttonLabel =
        linkType === "upgrade"
            ? "extra.jood.upgrade.to.gold.btn.text"
            : "extra.jood.plus.subscribe.to.gold.button.now";
</script>

<div class="c_joodcart c_joodvas col-12 {pageClass}">
    <div
        class="c_jood-card jood-product-card jood-list-view {typeClass} {joodTypeClass} {revealGoldFlexClass} jood-cart-lang-{lang}"
    >
        {#if isRevealState}
            <div class="reveal-gold-text js-jood-reveal-gold-vas">
                <span class="jood-reveal-label js-jood-reveal-btn">
                    {getStr("extra.pdp.prime.jood.gold.revelprice.text")}
                </span>
                <span
                    class="jood-reveal-label js-jood-reveal-hide-btn jood-hide"
                >
                    {getStr("extra.jood.hide.gold.price.text")}
                </span>

                <span
                    class="c_icon_new c_icon_new--chevron_down jood-arrow jood-arrow-reveal jood-arrow-down"
                ></span>
            </div>
        {/if}
        <div class={joodRevealClass}>
            {#if membershipType === "NOPRIME" || membershipType === "BASICPRIME"}
                <div class="c_jood-offer-message">
                    <span class=""
                        >{getStr("extra.jood.pdp.getoff.gold.text")}</span
                    >
                    <a href={getStr("/jood/benefits")}>
                        {getStr("extra.pdp.jood.tile.subscribetojoobtext")}
                    </a>
                </div>
            {/if}
            <div
                class="c_joodvas-service-container js-cart-vas-scroller-container"
            >
                {#each vasEntry.vasPrices as vasPrice}
                    {#if vasEntry.combinedVas}
                        {#if vasPrice.additionalAwsProductCode === null && vasPrice.additionalAwsVasGroup === null}
                            {#if !!vasPrice.vipPrimeDiscountPrice?.formattedValue && vasPrice.vipPrimeDiscountPrice?.formattedValue !== null && vasPrice.vipPrimeDiscountPrice?.formattedValue?.length > 0}
                                <div
                                    class="c_joodvas-service-wrapper js-cart-vas-scroller-content"
                                >
                                    <div class="c_joodvas-title">
                                        {vasPrice.vasType.label}
                                    </div>
                                    <div class="c_joodvas-gold-container">
                                        <div
                                            class="c_joodvas-gold-price-wrapper"
                                        >
                                            <div class="c_joodvas-gold-price">
                                                {currency}
                                                <span class="fw-bold">
                                                    {vasPrice
                                                        .vipPrimeDiscountPrice
                                                        .formattedValue}
                                                </span>
                                            </div>
                                            {#if parseInt(vasPrice.servicePrice.formattedValue) > 0}
                                                <div
                                                    class="c_joodvas-old-price-details"
                                                >
                                                    <span class="striked-off"
                                                        >{vasPrice.servicePrice
                                                            .formattedValue}</span
                                                    >
                                                    <strong class="orange-text">
                                                        {@html getStr(
                                                            "extra.cart.saved.amt",
                                                            [
                                                                currency,
                                                                parseInt(
                                                                    vasPrice
                                                                        .servicePrice
                                                                        .formattedValue,
                                                                ) -
                                                                    parseInt(
                                                                        vasPrice
                                                                            .vipPrimeDiscountPrice
                                                                            .formattedValue,
                                                                    ),
                                                            ],
                                                        )}
                                                    </strong>
                                                </div>
                                            {/if}
                                        </div>
                                    </div>
                                </div>
                            {/if}
                        {/if}
                    {:else if !!vasPrice.vipPrimeDiscountPrice?.formattedValue && vasPrice.vipPrimeDiscountPrice?.formattedValue !== null && vasPrice.vipPrimeDiscountPrice?.formattedValue?.length > 0}
                        <div
                            class="c_joodvas-service-wrapper js-cart-vas-scroller-content"
                        >
                            <div class="c_joodvas-title">
                                {vasPrice.vasType.label}
                            </div>
                            <div class="c_joodvas-gold-container">
                                <div class="c_joodvas-gold-price-wrapper">
                                    <div class="c_joodvas-gold-price">
                                        {currency}
                                        <span class="fw-bold">
                                            {vasPrice.vipPrimeDiscountPrice
                                                .formattedValue}
                                        </span>
                                    </div>
                                    {#if parseInt(vasPrice.servicePrice.formattedValue) > 0}
                                        <div
                                            class="c_joodvas-old-price-details"
                                        >
                                            <span class="striked-off"
                                                >{vasPrice.servicePrice
                                                    .formattedValue}</span
                                            >
                                            <strong class="orange-text">
                                                {@html getStr(
                                                    "extra.cart.saved.amt",
                                                    [
                                                        currency,
                                                        parseInt(
                                                            vasPrice
                                                                .servicePrice
                                                                .formattedValue,
                                                        ) -
                                                            parseInt(
                                                                vasPrice
                                                                    .vipPrimeDiscountPrice
                                                                    .formattedValue,
                                                            ),
                                                    ],
                                                )}
                                            </strong>
                                        </div>
                                    {/if}
                                </div>
                            </div>
                        </div>
                    {/if}
                {/each}
            </div>

            <div class="c_joodvas-button-container">
                <a
                    href={getContextedUrl("/jood/benefits")}
                    class="jood-subscribe-btn"
                >
                    {getStr(buttonLabel)}
                </a>
            </div>
        </div>
    </div>
</div>
