<script context="module" lang="ts">
  import type { Options } from "@splidejs/splide";
  import { Splide, SplideSlide } from "@splidejs/svelte-splide";
  import { getAppConfig } from "../../../common/config/app-config";
  import "../../../common/i18n/i18n";

  const { tasheelInfo } = window["ACC"].config.algoliaConfig;
  var freeGiftQty = 0;
  const isRTL = isDirectionRTL();

  const thumbsOptions: Options = {
    type: "slide",
    arrows: true,
    rewind: false,
    pagination: false,
    perPage: 4,
    perMove: 1,
    autoWidth: true,
    autoHeight: true,
    releaseWheel: true,
    cover: true,
    updateOnMove: true,
    direction: (isRTL && "rtl") || "ltr",
    breakpoints: {
      900: {
        arrows: false,
        width: "92vw",
        perPage: 1,
      },
    },
    classes: {
      arrow: "splide__arrow splide_arrow-bg arrows-bg",
      prev: "splide__arrow--prev splide_arrow-prev",
      next: "splide__arrow--next splide_arrow-next",
    },
  };
</script>

<script lang="ts">
  import { onMount } from "svelte";
  import { algoliaSearchData } from "../../../common/store/algolia-config-store";
  import {
    createDealTimerObject,
    getProductDataForGTM,
    getProductTags,
    getStr,
    isDirectionRTL,
    isMobile,
    isPointWithinElementBounds,
  } from "../../../common/util";
  import ProductTileMain from "../../common/components/productTile/ProductTileMain.svelte";
  import { getRRTemplateData } from "../../common/components/rrTemplateData.js";
  import SponsoredTag from "../../common/components/SponsoredTag.svelte";
  import { getCartLoginDetails } from "../../header/services/header-service";
  import { cartLoginDetails } from "../../header/store/header_store";
  import { addToWishlist } from "../services/home-data-services";

  export let pageData: any;
  export let isProductCrousel: Boolean = false;
  export let ProductCrouselData: any;
  export let isDealTimer: Boolean = false;
  export const rank = null;
  export let rrType = null;
  export let carouselOptions = {};
  export let isSponsored = false;
  export let isRRComponent = false;
  $: mergedCarouselOptions = Object.assign({}, thumbsOptions, carouselOptions);

  const mobile = isMobile();
  $: offerLabel = getStr("extra.pdp.offerlable");
  $: offersLabel = getStr("extra.product.cashback.offers");

  let {
    lang = "en",
    userSelectedCityCode,
    userDefaultCityCode,
    sellingOutFast = "Selling Out",
    encodedContextPath = "en-sa",
    outOfStock = "Out Of Stock",
    countryCode,
    currencyIso,
    siteBaseUrl,
    primeEnabled,
    primeLevel,
    restockable,
  } = getAppConfig();
  let isOutOfStock;

  let userCityCode = !userSelectedCityCode
    ? userDefaultCityCode
    : userSelectedCityCode;

  $: rrBlockData = null;

  $: {
    if (!isProductCrousel && !isDealTimer) {
      let data = $algoliaSearchData.find((item) => item.key === pageData.key);
      if (data) {
        rrBlockData = getRRTemplateData(data, lang, countryCode);
      } else {
        rrBlockData = null;
      }
    } else {
      rrBlockData = ProductCrouselData;
    }
  }

  // Get the algolia query id from URL
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let uiExperienceLevelQueryParams = params.uiExperienceLevel;

  function onClickWishlist(data, productCode) {
    data.detail.event.stopPropagation();
    data.detail.event.preventDefault();
    if (uiExperienceLevelQueryParams) {
      window.location.href = siteBaseUrl + "/wishlistapp?sku=" + productCode;
      return false;
    }
    // addToWishlist
    addToWishlist(productCode)
      .then((res) => {
        if (res.success === false) {
          window.location.assign(res.redirectUrl);
        } else {
          // add to wishlist success, get new cartDetails
          getCartLoginDetails();
          document.querySelector(".js-wishlist-count").innerHTML =
            res.wishlistSize;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => window["ACC"].loader.hideLoader());
  }

  function handleRRItemClick(event, rrClickTrackingURL, href, productData) {
    event.preventDefault();
    // check if wishlist icon clicked
    const wishlistWrapper = event.target
      .closest(".product-tile-container")
      .querySelector(".fav-wrapper");
    if (isPointWithinElementBounds(event, wishlistWrapper)) {
      // do nothing
      return false;
    }

    if (navigator.sendBeacon && rrClickTrackingURL) {
      navigator.sendBeacon(rrClickTrackingURL);
    }
    if (!uiExperienceLevelQueryParams) {
      if (productData !== undefined) {
        window["ACC"].extragtm.click.trackRecommendationsClick(productData);
        window["ACC"].mixpanel.trackRecommendationsClick(productData);
      }
      if (
        pageData?.key ===
        window["ACC"].richrelevance.vars.similarProductsPlacementName
      ) {
        window["ACC"].mixpanel?.track("Similar_items_Clicks", {
          productData,
        });
      }
      window["ACC"].extragtm.click.productClick(
        ProductCrouselData.strategyMessage,
        productData?.name,
        productData?.code,
        productData?.price?.value,
        productData?.rmsCategory,
        productData?.brandName,
        "",
        "",
        productData?.amplienceProductBaseUrl,
      );
    }
    // window.location.assign(href);
  }

  function getFreeGiftLength(data) {
    if ("freegiftworth" in data) {
      let freegiftArray = data.freegiftworth.split("-");
      let sourceQuantiy = freegiftArray[2] ?? 0;
      return sourceQuantiy;
    } else if ("freeGiftQtuantities" in data) {
      const pc = data.freeGiftQtuantities.filter(
        (x) => x.key === "giftProductCount",
      );
      if (pc.length > 0) {
        return pc[0].value;
      }
      return 0;
    } else {
      return 0;
    }
  }

  const getOfferLabel = (count) => {
    // count = Number(count);
    if (!count) {
      return 0;
    } else if (count == 1) {
      return `${count} ${offerLabel}`;
    } else if (count > 1) {
      return `${count} ${offersLabel}`;
    } else {
      return count;
    }
  };

  const getBottomTags = (product) => {
    let bottomTags = [];
    let currentUserCityCode = $cartLoginDetails?.city?.code || userCityCode;
    let isComingSoon = product?.comingSoon || false;
    let isInStock =
      !isComingSoon &&
      ((product?.inStockCities?.length &&
        product.inStockCities.indexOf(currentUserCityCode) != -1) ||
        product.stock?.stockLevelStatus === "inStock");
    let isSellingOutFast =
      !isInStock &&
      ((product?.sellingOutFastCities?.length &&
        product.sellingOutFastCities.indexOf(currentUserCityCode) != -1) ||
        product.stock?.stockLevelStatus === "lowStock");

    const hasDealTimer =
      ("endDateDeal" in product && product.endDateDeal?.length > 0) ||
      ("endDateDiscount" in product && product.endDateDiscount?.length > 0);

    if (isSellingOutFast && sellingOutFast) {
      bottomTags.push({
        content: sellingOutFast,
        borderRadLBot: 12,
        borderRadRBot: 12,
        theme: "yellow",
      });
    }

    if (hasDealTimer) {
      const dealTimeEnd = product.endDateDeal || product.endDateDiscount;
      bottomTags.push({
        timerTag: true,
        product: {
          dealTimer: createDealTimerObject(dealTimeEnd),
        },
      });
    }

    if (bottomTags.length === 0) {
      // To  match hight of all tile, even if tag is not present in any of the tile
      bottomTags.push({ content: "dummy", theme: "dummy" });
    }
    return bottomTags;
  };

  const getProductTileObject = (productData) => {
    let isComingSoon = productData?.comingSoon || false;
    let isRestockable =
      (restockable && productData?.restockableCities?.includes(userCityCode)) ||
      false;
    let isInStock =
      !isComingSoon &&
      ((productData?.inStockCities?.length &&
        productData.inStockCities.indexOf(userCityCode) != -1) ||
        productData.stock?.stockLevelStatus === "inStock");
    let isSellingOutFast =
      !isInStock &&
      ((productData?.sellingOutFastCities?.length &&
        productData.sellingOutFastCities.indexOf(userCityCode) != -1) ||
        productData.stock?.stockLevelStatus === "lowStock");
    isOutOfStock = !(
      isComingSoon ||
      isInStock ||
      isSellingOutFast ||
      isRestockable
    );
    const tileObject = {
      ...productData,
      brandName: productData?.brandName
        ? productData.brandName
        : lang === "en"
          ? productData.brandEn
          : productData.brandAr,
      name: productData?.name
        ? productData?.name
        : lang === "en"
          ? productData?.nameEn
          : productData?.nameAr,
      price: {
        formattedValue: parseFloat(productData?.price?.formattedValue),
        currencyIso: currencyIso,
      },
      discountPrice: {
        formattedValue: parseFloat(productData?.discountPrice?.formattedValue),
      },
      percentageDiscount: {
        formattedValue: productData.percentageDiscount?.value,
      },
      tasheelPriceData: {
        priceValueTasheel: {
          formattedValue:
            (productData &&
              productData.priceValueTasheel &&
              productData.priceValueTasheel) ||
            productData?.tasheelPriceData?.priceValueTasheel?.formattedValue,
          currencyIso: currencyIso,
        },
        tasheelInstallmentMonths:
          tasheelInfo && tasheelInfo?.tasheelInstallmentMonths,
        tasheelPercentage: tasheelInfo?.tasheelPercentage,
      },
      rating: productData.rating ?? 0,
      productTags: productData?.productTags?.length
        ? productData.productTags
        : productData?.productStatus
          ? [
              {
                label: productData?.productStatus,
                backgroundColorCode: "#FFECA1",
                textColorCode: "#051F2F",
              },
            ]
          : [],
      freeGiftCount: productData?.freeGiftCount
        ? productData.freeGiftCount
        : getFreeGiftLength(productData),
      offersLabel:
        productData?.offersCount || productData?.offersLabel
          ? getOfferLabel(productData?.offersLabel || productData?.offersCount)
          : 0,
      productBottomTags: getBottomTags(productData),
      showAllFields: true,
      showBottomTags: true,
      showActionBtn: false,
      afterCashBackPrice: productData?.aftreCashBackPrice?.formattedValue
        ? productData?.aftreCashBackPrice?.formattedValue
        : null,
      cashBackPrice: productData?.cashBackPrice
        ? productData.cashBackPrice
        : null,
      basicPrimePrice: productData?.basicPrimePrice
        ? productData.basicPrimePrice
        : productData.productBasicPrimeData
          ? productData.productBasicPrimeData.primePrice.value
          : null,
      vipPrimePrice: productData?.vipPrimePrice
        ? productData.vipPrimePrice
        : productData.productVipPrimeData
          ? productData.productVipPrimeData.primePrice.value
          : null,
      primeEnabled: productData?.primeEnabled
        ? productData.primeEnabled
        : primeEnabled || false,
      primeLevel: productData?.primeLevel
        ? productData.primeLevel
        : primeLevel
          ? primeLevel
          : null,
      tacticalVipPromo: productData?.tacticalVipPromo
        ? productData.tacticalVipPromo
        : productData?.productVipPrimeData?.tacticalPromo
          ? productData?.productVipPrimeData?.tacticalPromo
          : false,
      tacticalBasicPromo: productData?.tacticalBasicPromo
        ? productData.tacticalBasicPromo
        : productData?.productBasicPrimeData?.tacticalPromo
          ? productData?.productBasicPrimeData?.tacticalPromo
          : false,
      tacticalBasicPrimePrice: productData?.tacticalBasicPrimePrice
        ? productData?.tacticalBasicPrimePrice
        : productData?.productBasicPrimeData?.tacticalPrice?.formattedValue,
      tacticalVipPrimePrice: productData?.tacticalVipPrimePrice
        ? productData?.tacticalVipPrimePrice
        : productData?.productVipPrimeData?.tacticalPrice?.formattedValue,
      isProductInStock:
        "stock" in productData &&
        productData.stock.stockLevelStatus !== "outOfStock",
      cashback: productData?.cashBackPrice,
    };

    if (rrType === "pdp_category_page-history") {
      tileObject["desktopTileWidth"] = 252;
      tileObject["desktopImageWidth"] = 140;
      tileObject["desktopImageHeight"] = 140;
      tileObject["showAllFields"] = false;
    }
    if (tileObject.tacticalVipPromo || tileObject.tacticalBasicPromo) {
      tileObject["productTags"] = getProductTags(productData);
    }
    return tileObject;
  };

  onMount(() => {
    const parent = document.querySelectorAll(".rr_parent_item_container");
    if (isRTL && parent && parent.length > 0) {
      parent.forEach(function (section) {
        const nextArrow = section.querySelector(".splide__arrow--next");
        const prvArrow = section.querySelector(".splide__arrow--prev");
        if (nextArrow) {
          nextArrow.style.setProperty("right", "auto", "important");
          nextArrow.style.setProperty("left", "1em", "important");
        }
        if (prvArrow) {
          prvArrow.style.setProperty("left", "auto", "important");
          prvArrow.style.setProperty("right", "1em", "important");
        }
      });
    }
  });
</script>

<div class="c_wrapper {isDealTimer ? `bg_blue` : `bg_white`}">
  <div
    class=" rr_parent_item_container c_wrapper--content_scroller {isDealTimer
      ? `bg_blue`
      : `bg_white`}"
    class:mobile
    class:product-carousel={isProductCrousel}
    id={pageData.key}
  >
    {#await rrBlockData}<div />
    {:then rrData}
      {#if rrData && pageData?.display && rrData.hits?.length && !("productList" in rrData)}
        {#if rrData?.strategyMessage}
          <h2
            class="rr_item--headline"
            class:rtl={isRTL}
            class:with-subtitle={!!rrData?.subtitle}
          >
            {rrData?.strategyMessage}
            {#if isSponsored}
              <SponsoredTag variant="product-carousal" />
            {/if}
          </h2>
        {/if}
        {#if rrData?.subtitle}
          <p class="rr_item--subheading">{rrData.subtitle}</p>
        {/if}
        <Splide
          options={{
            ...mergedCarouselOptions,
          }}
        >
          {#if rrData && rrData.hits}
            {#each rrData.hits as productData, i}
              <SplideSlide>
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <a
                  title={productData.productName}
                  href={`${encodedContextPath}${productData.url}`}
                  class="link"
                  data-product-id={productData.code}
                  on:click|preventDefault|stopPropagation={(e) =>
                    handleRRItemClick(
                      e,
                      productData.rrClickTrackingURL || productData.rrClickUrl,
                      `${encodedContextPath}${productData.url}`,
                      productData,
                    )}
                >
                  <ProductTileMain
                    {...getProductTileObject(productData)}
                    on:toggleFav={(data) => {
                      onClickWishlist(data, productData.code);
                    }}
                    productDataForGTM={getProductDataForGTM(productData, i)}
                    {isRRComponent}
                  />
                </a>
              </SplideSlide>
            {/each}
          {/if}
        </Splide>
      {:else if rrData.productList && rrData.productList.length > 0}
        {#if rrData?.message}
          <h2
            class="rr_item--headline"
            class:rtl={isRTL}
            class:with-subtitle={!!rrData?.subtitle}
          >
            {rrData?.message}
            {#if isSponsored}
              <SponsoredTag variant="product-carousal" />
            {/if}
          </h2>
        {/if}
        {#if rrData?.subtitle}
          <p class="rr_item--subheading">{rrData.subtitle}</p>
        {/if}
        <Splide options={{ ...mergedCarouselOptions }}>
          {#each rrData.productList as productData, i}
            <SplideSlide>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <a
                title={productData.name}
                class="link"
                href={`${encodedContextPath}${productData.url}`}
                data-product-id={productData.code}
                on:click|preventDefault|stopPropagation={(e) =>
                  handleRRItemClick(
                    e,
                    productData.rrClickUrl || productData.rrClickTrackingURL,
                    `${encodedContextPath}${productData.url}`,
                    productData,
                  )}
              >
                <ProductTileMain
                  {...getProductTileObject(productData)}
                  showActionBtn={false}
                  on:toggleFav={(data) => {
                    onClickWishlist(data, productData.code);
                  }}
                  productDataForGTM={getProductDataForGTM(productData, i)}
                  {isRRComponent}
                />
              </a>
            </SplideSlide>
          {/each}
        </Splide>
      {/if}
    {/await}
  </div>
</div>

<style lang="scss">
  @import "../../../common/styles/vars";
  @import "../../common/styles/colors.new.scss";

  :global {
    .rr_parent_item_container {
      .splide--rtl > .splide__arrows .splide__arrow--prev,
      .splide--rtl
        > .splide__slider
        > .splide__track
        > .splide__arrows
        .splide__arrow--prev,
      .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev {
        left: auto !important;
        right: 1em !important;
      }
      .splide--rtl > .splide__arrows .splide__arrow--next,
      .splide--rtl
        > .splide__slider
        > .splide__track
        > .splide__arrows
        .splide__arrow--next,
      .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next {
        right: auto !important;
        left: 1em !important;
      }
      .splide.splide--slide {
        .splide__track {
          padding-left: 0 !important;
          .splide__list {
            display: flex;
            gap: 4px;
            @include desktop-min-width {
              gap: 24px;
            }
            .splide__slide {
              a {
                // color: unset;  /Comment color for the fix of MAVD-8565
              }
              .link {
                height: 100%;
              }
              .tile-content-wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
        &.splide--rtl {
          direction: unset;
        }
      }
      .splide__arrow:hover svg {
        fill: $primary-white !important;
      }
      .splide__arrow {
        background: $primary-black !important;
        border-radius: 50%;
        display: none;
        height: 48px;
        opacity: 0;
        width: 48px;
        svg {
          fill: $primary-white;
        }
      }
      .splide__arrow:disabled {
        opacity: 0;
        pointer-events: none;
      }
    }
    .rr_parent_item_container:hover .splide__arrow {
      display: inline-block;
      opacity: 0.7;
    }
    .rr_parent_item_container:hover .splide__arrow:disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  .bg_white {
    background: $primary-white;
  }

  .bg_blue {
    background: $icon-only-icon-blue;
  }
  .rr_ {
    &wrapper--content {
      padding: 40px 0 40px 16px;
      @include desktop-min-width {
        padding: 80px 0 80px 0;
      }
    }
    &item--headline {
      font-size: 20px;
      font-weight: 700;
      line-height: 22px;
      color: $neutral-dark-01;
      text-align: center;
      padding: 0;
      margin-bottom: 20px;
      display: flex;
      margin-top: 0;
      justify-content: unset;
      align-items: baseline;

      @include desktop-min-width {
        font-size: 36px;
        line-height: 40px;
        text-align: left;
        margin-bottom: 50px;
      }

      &.with-subtitle {
        margin-bottom: 4px;

        @include desktop-min-width {
          margin-bottom: 8px;
        }
      }
    }
    &item--subheading {
      margin: 0 0 16px;
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: $neutral-dark-02;

      @include desktop-min-width {
        margin: 0 0 20px;
        font-size: 18px;
        line-height: 20px;
      }
    }
    &parent_item_container {
      width: 100%;
      max-width: 1440px;
      padding: 40px 0 40px 16px;
      @include desktop-min-width {
        padding: 80px 0 80px 48px;
      }
      a {
        text-decoration: none;
      }
      .splide__arrow {
        background: $primary-black;
        display: none;
        height: 48px;
        opacity: 0;
        width: 48px;
        svg {
          fill: $primary-white;
        }
      }
    }
  }
</style>
