<script lang="ts">
    import { _ } from "svelte-i18n";
    import { getStr } from "../../../common/util";
    import Icon from "../../common/components/Icon.svelte";
    import Text from "../../common/components/Text.svelte";

    export let icon = "X";
    export let description = "";
    export let collectInHours = "";
    export let icihtime = "";

    export let deliveryType = null;
    export let deliveryCost = null;
    let currencyDecimal = window["ACC"].config.currencyDecimal;

    $: deliveryCostString = deliveryCost
        ? `${deliveryCost.toFixed(currencyDecimal)} ${window["ACC"].config.currency}`
        : $_(getStr("pdp.free"));
</script>

<section class="product_delivery_description">
    {#if icon.length > 0}
        <Icon glyph={icon} type="primary" mobileSize={24} desktopSize={24} />
    {/if}
    {#if deliveryType === null}
        <div class="description">
            {#if description.length > 0}
                {description}
            {:else if icihtime == ""}
                <Text key="extra.pdp.collect_in_time" args={[collectInHours]} />
            {:else}
                <Text
                    key="extra.pdp.collect_in_time_before"
                    args={[collectInHours, icihtime]}
                />
            {/if}
        </div>
    {:else}
        <div class="delivery-info">
            <div class="delivery-type">{deliveryType}</div>
            <div
                class="delivery-cost"
                class:green={deliveryCost == 0}
                class:orange={deliveryCost > 0}
            >
                {deliveryCostString}
            </div>
        </div>
    {/if}
</section>

<style lang="scss">
    @import "../../common/styles/colors.new.scss";
    .product_delivery_description {
        display: flex;
        align-items: center;
        gap: 8px;

        .description {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
        }

        .delivery-info {
            display: flex;
            flex-grow: 1;
            gap: 8px;
            @include desktop-min-width {
                flex-grow: inherit;
            }
            .delivery {
                &-type {
                    flex-grow: 1;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                }
                &-date {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                }
                &-cost {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;

                    &.green {
                        color: $functional-green-01;
                    }
                    &.orange {
                        color: $functional-orange;
                    }
                }
            }
        }
    }
</style>
