<script lang="ts">
    import {
        openSnackbar,
        snackbarLabel,
    } from "./../../product-details/store/pdp_store.js";
    import Snackbar, { Label } from "@smui/snackbar";
    let snackbarWithoutClose: Snackbar;
    const unsubscribe = openSnackbar.subscribe((value) => {
        if (value) {
            snackbarWithoutClose.open();
        }
    });
</script>

<Snackbar
    labelText={$snackbarLabel}
    class="demo-success"
    bind:this={snackbarWithoutClose}
>
    <Label></Label>
</Snackbar>
