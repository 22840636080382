<script lang="ts" context="module">
	import { getAppConfig } from "../../../../common/config/app-config";
	import { getContextedUrl, getStr } from "../../../../common/util";
	import {
		getServiceBlockForCode,
		jsVasDetailsClickHandler,
		vasOnACChange,
	} from "./atc-utils";
	import AtcFormatPrice from "./AtcFormatPrice.svelte";
	import AtcVasPrimePriceDetails from "./AtcVasPrimePriceDetails.svelte";

	const { lang, currency } = getAppConfig();
</script>

<script lang="ts">
	export let productattributes;
	export let pdpAcSetupFreeSelected;
	export let selectedServices;
	export let entry;
	export let product;
	export let parentEntryNumber;
	export let childEntries;
	let servicesBlockContent = "";
	let servicesBlockImage = "";

	export let entryServiceGroupNumber;

	$: childEntry =
		childEntries?.find(
			(x) => x.serviceGroupNumber === entryServiceGroupNumber,
		) || null;
	$: if (product?.servicesBlocks?.length > 0) {
		({ servicesBlockContent, servicesBlockImage } = getServiceBlockForCode(
			product.servicesBlocks,
			"servicesSetupPDPContent",
		));
	}

	const getServiceDetails = (selectedServices, vasPrice) => {
		const selectedService = selectedServices?.find(
			({ vasType, priceType }) =>
				vasType === vasPrice?.vasType?.code &&
				priceType === vasPrice?.priceType,
		);
		return selectedService
			? {
					wallOptionSelected: selectedService.onWallService
						? "selected"
						: "",
					floorOptionSelected: !selectedService.onWallService
						? "selected"
						: "",
					selectedEntrynumber: selectedService.serviceGroupNumber,
				}
			: {
					wallOptionSelected: "",
					floorOptionSelected: "",
					selectedEntrynumber: "",
				};
	};
</script>

<div class="c_vas-details c_vas-details-container c_split-ac-details">
	<div class="row-flex">
		<div class="col-flex-xs-1 col-flex-md-1 c_extra-setup-logo">
			<img alt="Extrasetup" src={servicesBlockImage} />
		</div>
		<div class="col-flex-xs-9 col-flex-md-9 c_extra-setup-service-info">
			<div class="c_vas-details-heading-label">
				{productattributes.acInstallation}
			</div>
		</div>
		<div class="col-flex-xs-12 col-flex-md-12 c_extra-setup-service-info">
			<p class="c_vas-details-heading-info">{servicesBlockContent}</p>
		</div>
	</div>
	<div class="row-flex c_vas-details-form-details">
		<div
			class="col-flex-xs-12 simple-flex c_vas-details-items js-vas-group-addtocart-SPLITACSERVICE"
		>
			<div class="row-flex c_vas-cart-details-ac-container">
				<div class="c_vas-cart-details-floors">
					<div class="row-flex vas-floor-labels test1">
						{#each entry.vasPrices as vasPrice, vasPriceIndex}
							{@const {
								wallOptionSelected,
								floorOptionSelected,
								selectedEntrynumber,
							} = getServiceDetails(selectedServices, vasPrice)}

							<div class="col-flex-12 vas-floor-options">
								<div class="c_vas-cart-details-mounts">
									<div class="row-flex vas-mount-labels">
										<!-- svelte-ignore a11y-click-events-have-key-events -->
										<div
											class="vas-mount-text js-vas-ac-change-svelte {floorOptionSelected} js-ac-change-svelte"
											on:click={vasOnACChange}
											class:selected={pdpAcSetupFreeSelected ||
												(childEntry?.vasType ===
													vasPrice?.vasType?.code &&
													childEntry?.priceType ===
														vasPrice?.priceType &&
													!childEntry?.onWallService) ||
												floorOptionSelected ===
													"selected"}
											data-vasPrice={vasPrice
												?.servicePrice?.formattedValue}
											data-default-selected={(childEntry?.vasType ===
												vasPrice?.vasType?.code &&
												childEntry?.priceType ===
													vasPrice?.priceType) ||
												(!vasPrice?.productCodes &&
													vasPriceIndex === 0)}
											data-wallMountPrice={vasPrice
												.servicePrice.formattedValue +
												entry.bracketProduct.price
													.value}
											data-item-add={(childEntry?.vasType ===
												vasPrice.vasType.code &&
												childEntry?.priceType ===
													vasPrice.priceType) ||
												(!vasPrice?.productCodes &&
													vasPriceIndex === 0) ||
												floorOptionSelected ===
													"selected"}
											data-bracketAllowed={vasPrice
												.vasType.bracketAllowed}
											data-free={getStr("text.free")}
											data-currency={currency}
											data-indx={vasPriceIndex}
											data-source="Cart Popup"
											data-plan={productattributes.acOnFloor}
											data-serviceid={vasPriceIndex + 1}
											data-addon="no addon"
											data-acSetupAddedAsFree={vasPrice.acSetupAddedAsFree}
											data-price="{vasPrice.servicePrice
												.value} {currency}"
											data-type="ESD"
										>
											{getStr(
												"extra.services.pdp.ac.floor.mount.label",
											)}
											<div class="ac-vas-floor-price-div">
												<span
													class="vas-ac-floor-price"
												>
													{#if vasPrice.percentageDiscount !== null && vasPrice.percentageDiscount.value > 0 && vasPrice.percentageDiscount.value < 100}
														<div
															class="c_product-price-percentage-off"
														>
															<div
																class="percentage-details"
															>
																{#if lang === "en"}
																	{vasPrice
																		.percentageDiscount
																		.value}%&nbsp;<span
																		>{getStr(
																			"product.pourcentageDiscount.off",
																		)}</span
																	>
																{:else}
																	%{vasPrice
																		.percentageDiscount
																		.value}&nbsp;<span
																		>{getStr(
																			"product.pourcentageDiscount.off",
																		)}</span
																	>
																{/if}
															</div>
														</div>
													{/if}
													{#if vasPrice.discountedPrice !== null && vasPrice.servicePrice.value > 0}
														<div
															class="c_product-price-prices"
														>
															<div
																class="c_product-price-was-is"
															>
																<span
																	class="c_product-price-previous"
																	>{vasPrice
																		.servicePrice
																		.value}&nbsp;
																	{currency}
																</span>
															</div>
															<div
																class="c_product-price-current"
															>
																<AtcFormatPrice
																	priceData={vasPrice.discountedPrice}
																	displayFreeForZero={true}
																	addCurrency={true}
																/>
															</div>
														</div>
													{:else}
														<span>
															<AtcFormatPrice
																priceData={vasPrice.servicePrice}
																displayFreeForZero={true}
																addCurrency={true}
															/>
														</span>
														<AtcVasPrimePriceDetails
															{vasPrice}
														/>
													{/if}
												</span>
											</div>
											{#if vasPrice.productCodes?.length > 0}
												<div
													class="c_vas-details-list-button floor-option hide"
												>
													<form
														id="vasAddToCartForm{product.code}{vasPriceIndex}-floor"
														action={getContextedUrl(
															"/cart/vas/add",
														)}
														modelAttribute="vasAddtocartForm"
														method="post"
														class="c_form js-formValidation js-vas-addtocart-form"
														data-ajax="true"
														data-submit="vasaddtocart"
														data-index={vasPriceIndex}
													>
														<div
															id="test_vasAddToCartButton_$"
															style="display:inline"
														>
															<input
																type="hidden"
																name="vasProductCode"
																value={vasPrice.productCodes}
															/>
															<input
																type="hidden"
																name="vasType"
																value={vasPrice
																	.vasType
																	.code}
															/>
															<input
																type="hidden"
																name="priceType"
																value={vasPrice.priceType}
															/>
															<input
																type="hidden"
																name="priceTo"
																value={vasPrice.priceTo}
															/>
															<input
																type="hidden"
																name="productCode"
																value={product.code}
															/>
															<input
																type="hidden"
																name="parentEntryNumber"
																value={parentEntryNumber}
															/>
															<input
																type="hidden"
																name="qty"
																value="1"
															/>
															<input
																type="hidden"
																name="splitAcService"
																value="true"
															/>
															<input
																type="hidden"
																name="entryNumber"
																value={!!selectedEntrynumber
																	? selectedEntrynumber
																	: entryServiceGroupNumber}
																class="js-vasEntryNumber"
															/>
															<input
																type="hidden"
																name="showApplyService"
																value={entry.showApplyService}
															/>
															<input
																type="hidden"
																name="vasEntryShowService"
																value={entry.showApplyService}
															/>
															<input
																type="hidden"
																name="vasGroup"
																value={entry.vasGroup}
															/>
															<input
																type="hidden"
																name="acSetupAddedAsFree"
																value={vasPrice.acSetupAddedAsFree}
															/>
															<input
																type="hidden"
																name="acSetupIndex"
																value="0"
															/>
															<input
																type="hidden"
																name="previousVasType"
																value=""
															/>
															<button
																type="button"
																id="vas-button-{product.code}{vasPriceIndex}-floor"
																data-type="ESD"
																data-splitAcService="true"
																data-free={getStr(
																	"text.free",
																)}
																data-default-selected={(childEntry?.vasType ===
																	vasPrice
																		?.vasType
																		?.code &&
																	childEntry?.priceType ===
																		vasPrice?.priceType) ||
																	(!vasPrice?.productCodes &&
																		vasPriceIndex ===
																			0)}
																aria-labelledby="vas-button-{product.code}{vasPriceIndex}-floor vasAddToCartForm{product.code}{vasPriceIndex}-floor"
																data-acSetupAddedAsFree={vasPrice.acSetupAddedAsFree}
																class="c_vas-details-list-button-vas js-vas-details-svelte"
																class:selected={(childEntry?.vasType ===
																	vasPrice
																		?.vasType
																		?.code &&
																	childEntry?.priceType ===
																		vasPrice?.priceType) ||
																	(!vasPrice?.productCodes &&
																		vasPriceIndex ===
																			0)}
																data-item-add={(childEntry?.vasType ===
																	vasPrice
																		?.vasType
																		?.code &&
																	childEntry?.priceType ===
																		vasPrice?.priceType &&
																	!childEntry?.onWallService) ||
																	floorOptionSelected ===
																		"selected"}
															></button>
														</div>
													</form>
												</div>
											{/if}
										</div>
										<!-- svelte-ignore a11y-click-events-have-key-events -->

										<div
											class="vas-mount-text js-vas-ac-change-svelte {wallOptionSelected} js-ac-wall-select-svelte"
											on:click={vasOnACChange}
											class:selected={vasPrice?.defaultWarranty ||
												childEntry?.onWallService ||
												wallOptionSelected ===
													"selected"}
											data-vasPrice={vasPrice.servicePrice
												.formattedValue}
											data-default-selected={(childEntry?.vasType ===
												vasPrice?.vasType?.code &&
												childEntry?.priceType ===
													vasPrice?.priceType) ||
												(!vasPrice?.productCodes &&
													vasPriceIndex === 0)}
											data-wallMountPrice={vasPrice
												.servicePrice.formattedValue +
												entry.bracketProduct.price
													.value}
											data-bracketAllowed={vasPrice
												.vasType.bracketAllowed}
											data-free={getStr("text.free")}
											data-currency={currency}
											data-indx={vasPriceIndex}
											data-source="Cart Popup"
											data-plan={productattributes.acOnWall}
											data-serviceid={vasPriceIndex + 1}
											data-addon="no addon"
											data-onwall="true"
											data-item-add={childEntry?.onWallService ||
												wallOptionSelected ===
													"selected"}
											data-price="{vasPrice.servicePrice
												.formattedValue +
												entry.bracketProduct.price
													.value} {currency}"
											data-type="ESD"
										>
											{getStr(
												"extra.services.pdp.ac.wall.mount.label",
											)}
											<div class="ac-vas-wall-price-div">
												<span class="vas-ac-wall-price">
													{#if vasPrice.vasType.bracketAllowed}
														{#if vasPrice.onWallPercentageDiscount !== null && vasPrice.onWallPercentageDiscount.value > 0 && vasPrice.onWallPercentageDiscount.value < 100}
															<div
																class="c_product-price-percentage-off"
															>
																<div
																	class="percentage-details"
																>
																	{#if lang === "en"}
																		{vasPrice
																			.onWallPercentageDiscount
																			.value}%&nbsp;<span
																			>{getStr(
																				"product.pourcentageDiscount.off",
																			)}</span
																		>
																	{:else}
																		%{vasPrice
																			.onWallPercentageDiscount
																			.value}&nbsp;<span
																			>{getStr(
																				"product.pourcentageDiscount.off",
																			)}</span
																		>
																	{/if}
																</div>
															</div>
														{/if}
														{#if vasPrice.discountedPrice !== null && vasPrice.servicePrice.value > 0}
															<div
																class="c_product-price-prices"
															>
																<div
																	class="c_product-price-was-is"
																>
																	<span
																		class="c_product-price-previous"
																		>{vasPrice
																			.servicePrice
																			.value +
																			entry
																				.bracketProduct
																				.price
																				.value}&nbsp;
																		{currency}
																	</span>
																</div>
																<div
																	class="c_product-price-current"
																>
																	{currency}
																	{vasPrice
																		.discountedPrice
																		.value +
																		entry
																			.bracketProduct
																			.price
																			.value}
																</div>
															</div>
														{:else}
															{currency}
															<strong
																>{vasPrice
																	.servicePrice
																	.value +
																	entry
																		.bracketProduct
																		.price
																		.value}</strong
															>
														{/if}
													{/if}
												</span>
											</div>
											{#if vasPrice?.vasType?.bracketAllowed}
												<div
													class="c_vas-details-list-button wall-option hide"
												>
													<form
														id="vasAddToCartForm{product.code}{vasPriceIndex}-onwall"
														action={getContextedUrl(
															"/cart/vas/add",
														)}
														modelAttribute="vasAddtocartForm"
														method="post"
														class="c_form js-formValidation js-vas-addtocart-form"
														data-ajax="true"
														data-submit="vasaddtocart"
													>
														<div
															id="test_vasAddToCartButton_$"
															style="display:inline"
														>
															{#if vasPrice?.productCodes?.length > 0 && !vasPrice?.acSetupAddedAsFree}
																<input
																	type="hidden"
																	name="vasProductCode"
																	value={vasPrice.productCodes}
																/>
															{/if}
															<input
																type="hidden"
																name="vasProductCode"
																value={entry
																	.bracketProduct
																	.code}
															/>
															<input
																type="hidden"
																name="vasType"
																value={vasPrice
																	.vasType
																	.code}
															/>
															<input
																type="hidden"
																name="priceType"
																value={vasPrice.priceType}
															/>
															<input
																type="hidden"
																name="priceTo"
																value={vasPrice.priceTo}
															/>
															<input
																type="hidden"
																name="productCode"
																value={product.code}
															/>
															<input
																type="hidden"
																name="parentEntryNumber"
																value={parentEntryNumber}
															/>
															<input
																type="hidden"
																name="qty"
																value="1"
															/>
															<input
																type="hidden"
																name="onWallService"
																value="true"
															/>
															<input
																type="hidden"
																name="splitAcService"
																value="true"
															/>
															<input
																type="hidden"
																name="acSetupIndex"
																value="0"
															/>
															<input
																type="hidden"
																name="previousVasType"
																value=""
															/>

															<input
																type="hidden"
																name="entryNumber"
																value={typeof selectedEntrynumber ===
																"number"
																	? selectedEntrynumber
																	: entryServiceGroupNumber}
																class="js-vasEntryNumber"
															/>
															<button
																type="button"
																id="vas-button-{product.code}{vasPriceIndex}-onwall"
																data-type="ESD"
																data-free={getStr(
																	"text.free",
																)}
																data-onwall="true"
																data-splitAcService="true"
																aria-labelledby="vas-button-{product.code}{vasPriceIndex}-onwall vasAddToCartForm{product.code}{vasPriceIndex}-onwall"
																class="c_vas-details-list-button-vas js-vas-details-svelte"
																class:selected={vasPrice?.defaultWarranty}
																data-item-add={childEntry?.onWallService ||
																	wallOptionSelected ===
																		"selected"}
															></button>
														</div>
													</form>
												</div>
											{/if}
										</div>
									</div>
								</div>
							</div>

							<div
								class="col-flex-xs-12 c_vas-details-wall-mount-notes js-ac-notes"
								class:hide={childEntry?.vasType !==
									vasPrice?.vasType?.code &&
									childEntry?.priceType !==
										vasPrice?.priceType}
							>
								<span class="c_icon_new c_icon_new--information"
								></span>
								<div
									class="c_vas-details-wall-notes-text hide"
									class:show={wallOptionSelected ===
										"selected"}
								>
									{getStr("extra.ac.wall.notes")}
								</div>
								<div
									class="c_vas-details-floor-notes-text hide"
									class:show={pdpAcSetupFreeSelected ||
										(childEntry?.vasType ===
											vasPrice?.vasType?.code &&
											childEntry?.priceType ===
												vasPrice?.priceType &&
											!childEntry.onWallService) ||
										floorOptionSelected === "selected"}
								>
									{getStr("extra.ac.floor.notes")}
								</div>
							</div>
						{/each}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
